import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

export default function ChecklistDetailsRadialChart({ chartData, chartLevel }) {
  const { t } = useTranslation();

  const parsedData = Object.values(chartData)[0];

  const chartLabels = parsedData.map((a) => a.developmentalDomain.title);
  const chartSeries = parsedData.map((a) => parseFloat(a.percentualGrowth) * 100);
  const seriesName =
    chartLevel === 0 ? t('child-stats-page.checklist-info-table.score') : `${t('Level')} ${chartLevel}`;

  const options = {
    plotOptions: {
      radar: {
        size: 140,
      },
    },
    dataLabels: { enabled: false },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: chartLabels,
    },
    yaxis: {
      tickAmount: 5,
      max: 100,
      labels: {
        formatter: function (val, _i) {
          return val.toFixed(0);
        },
      },
    },
  };

  return (
    <div className="container-fluid d-flex justify-content-center border rounded pt-4">
      <Chart
        options={options}
        type="radar"
        series={[{ name: seriesName, data: chartSeries }]}
        height={350}
        width={700}
      />
    </div>
  );
}
