import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  combineSessionsObjectiveSteps,
  deleteIndividualizedPlan,
  getIndividualizedPlans,
  resolvePlanUniqueSkills,
} from '../../../../../services/individualizedPlan';
import { hideLoading, showLoading } from '../../../../../utils/loading';

export default ({ individualizedPlan }) => {
  const { t } = useTranslation();

  const [plans, setPlans] = useState([]);
  const [loadingErrorMessage] = useState('');
  const [busy] = useState(false);
  const [individualizedPlanUniqueSkills, setIndividualizedPlanUniqueSkills] = useState([]);

  // transform the sessions array in the objective dimension array
  const resolveSessionsObjectiveSteps = (objective, sessions, skillSteps) =>
    combineSessionsObjectiveSteps(objective, sessions, skillSteps);

  const deleteCurentIndividualizedPlan = () => {
    deleteIndividualizedPlan(individualizedPlan, (result, err) => {
      if (err) {
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('There was an error removing this plan'),
        });
      }

      return window.Swal.fire({
        icon: 'success',
        title: t('Removed'),
        text: t('Individualized plan successfully removed') + '!',
      }).then((_) => {
        if (plans.length === 1) {
          //if this was the last plan
          window.location.href = `/children/${individualizedPlan.child.id}`;
        } else {
          const plansRemaining = plans.filter((p) => p.id !== individualizedPlan.id);
          window.location.href = `/children/${individualizedPlan.child.id}/plan/${plansRemaining[0].id}`;
        }
      });
    });
  };

  useEffect(
    (_) => {
      showLoading(t, 'loading-fetch-individualized-plans');

      if (individualizedPlan && individualizedPlan.child) {
        getIndividualizedPlans(individualizedPlan.child.id, (result2, err2) => {
          if (!err2) {
            setPlans(result2);
            setIndividualizedPlanUniqueSkills(resolvePlanUniqueSkills(individualizedPlan));
          } else {
            console.error('Error fetching Child Individualized Plans:', err2);
            window.Swal.fire({
              icon: 'error',
              title: t('Error'),
              text: t('There was an error fetching Child Individualized Plans'),
            });
          }
        });
      }

      hideLoading();
    },
    [t, individualizedPlan],
  );

  useEffect(() => {
    if (individualizedPlan) {
      setIndividualizedPlanUniqueSkills(resolvePlanUniqueSkills(individualizedPlan));
    }
  }, [individualizedPlan]);

  return {
    plans,
    individualizedPlanUniqueSkills,

    deleteCurentIndividualizedPlan,
    resolveSessionsObjectiveSteps,

    loadingErrorMessage,
    busy,
  };
};
