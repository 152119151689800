import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import moment from 'moment';
import Swal from 'sweetalert2';

import {
  getChildChecklistAssessments,
  upsertChecklistAssessmentSkill,
  updateChildChecklistAssessment,
  getChecklists,
  deleteChildChecklistAssessment,
  createCheckListAssessment,
  getChecklistSkillsBasicInfoByLevel,
  getChildLastChecklist,
  getChecklistSkillsCodeAndScore,
} from '../../../services/checklistAssessment';
import { get } from '../../../services/children';
import {
  getAllSkillsFiltered,
  getDevelopmentalDomainsByLevel,
  getAvailableLevelsByDevelopmentalDomain,
  evaluateDevelopmentScoreByLevel,
  getAllDevelopmentalDomains,
  getSkillCodesForLevel,
} from '../../../services/denver';
import { useHistory } from '../../../utils/history';

export default () => {
  const { t } = useTranslation();
  const { updateChecklistAssessmentURL } = useHistory();

  const { childId, checklistId } = useParams();

  const query = new URLSearchParams(window.location.search);

  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [editMode] = useState(query.get('mode') === 'edit' || checklistId === 'new');

  const [child, setChild] = useState();
  const [checklistAssessment, setChecklistAssessment] = useState();
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistItemsFiltered, setChecklistItemsFiltered] = useState([]);
  const [denverDevelopmentalDomains, setDenverDevelopmentalDomains] = useState([]);
  const [currentDevelopmentalDomainFilter, setCurrentDevelopmentalDomainFilter] = useState();
  const [currentLevel, setCurrentLevel] = useState(query.get('level') ? parseInt(query.get('level')) : 1);
  const [availableLevels, setAvailableLevels] = useState([1, 2, 3, 4]);
  const [childChecklists, setChildChecklists] = useState([]);
  const [developmentScore, setDevelopmentScore] = useState({});
  const [filterScore, setFilterScore] = useState(query.get('score') ? query.get('score') : 'ALL');

  const scoreText = {
    PASS: '+',
    PASS_FAIL: '+/-',
    FAIL: '-',
  };

  const setChecklistAssessmentDate = (id, newDate) => {
    updateChildChecklistAssessment(id, moment(newDate, t('MM/DD/YYYY')).toISOString(), (_, err) => {
      if (!err) {
        window.location.reload();
      }
    });
  };

  const setSkillScore = (checklistSkill, score, bulkUpdate) => {
    setRequesting(true);
    const skillUpdated = checklistItems.find((it) => it.skill.code === checklistSkill.skill.code);
    if (skillUpdated.score !== score) {
      skillUpdated.score = score;
    } else {
      if (!bulkUpdate) skillUpdated.score = 'NOT_EVALUATED';
    }
    upsertChecklistAssessmentSkill(
      checklistSkill.id,
      checklistAssessment.id,
      skillUpdated.skill.code,
      skillUpdated.score,
      (result, err) => {
        if (result) {
          if (!skillUpdated.id) {
            skillUpdated.id = result.id;
          }
          const replacedChecklist = checklistItems.map((item) => {
            if (item.skill.code === skillUpdated.skill.code) {
              return skillUpdated;
            }
            return item;
          });

          // custom skill
          setChecklistItems(replacedChecklist.filter((p) => !p.skill.code.match(/CTM/)));
        } else {
          console.error(err);
          return window.Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('There was an error making this evaluation'),
          });
        }
        setRequesting(false);
      },
    );
  };

  const setAllSkillScore = (checklistAllSkill, score) => {
    for (let checklistSkill of checklistAllSkill) {
      setSkillScore(checklistSkill, score, true);
    }
  };

  const setAllLevelScore = (level, score) => {
    setRequesting(true);
    Swal.fire({
      title: t('Warning'),
      html: `<div style="font-size: 1rem">
        <p class="lead">${t('checkListAssessment.updateCurrentLevelSkillsModal1')} <strong>${
        scoreText[score]
      }</strong> ${t('checkListAssessment.updateCurrentLevelSkillsModal2')} <strong>${level}</strong>?</p>
        <p class="my-3">${t('checkListAssessment.updateCurrentLevelSkillsModal3')}</p>
        <p class="text-danger"><strong>${t('This action cannot be undone')}!</strong></p>
      </div>`,
      type: 'warning',
      icon: 'warning',
      showCancelButton: true,
      focusCancel: true,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const allSkillsForCurrentLevel = await getSkillCodesForLevel({ level: level });
        const allChecklistSkillsFilteredByLevel = await getChecklistSkillsBasicInfoByLevel(checklistId, level);
        for (let denverSkill of allSkillsForCurrentLevel) {
          const skillUpdated = allChecklistSkillsFilteredByLevel.find((it) => it.skill.code === denverSkill.code);
          if (skillUpdated) skillUpdated.score = score;
          upsertChecklistAssessmentSkill(
            skillUpdated?.id,
            checklistAssessment.id,
            denverSkill.code,
            score,
            (result, err) => {
              if (result) {
                const replacedChecklist = checklistItems.map((item) => ({ ...item, score: score }));
                setChecklistItems(replacedChecklist.filter((p) => !p.skill.code.match(/CTM/)));
              } else {
                console.error(err);
                return window.Swal.fire({
                  icon: 'error',
                  title: t('Error'),
                  text: t('There was an error making this evaluation'),
                });
              }
            },
          );
        }
      }
      setRequesting(false);
      return;
    });
  };

  const deleteCurrentChecklistAssessment = () => {
    deleteChildChecklistAssessment(checklistAssessment, (result, err) => {
      if (err) {
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('There was an error removing this checklist'),
        });
      }

      return window.Swal.fire({
        icon: 'success',
        title: t('Removed'),
        text: t('Checklist successfully removed') + '!',
      }).then((_) => {
        if (childChecklists.length === 1) {
          //if this was the last plan
          window.location.href = `/children/${child.id}`;
        } else {
          window.location.href = `/children/${child.id}/checklist/${childChecklists[1].id}`;
        }
      });
    });
  };

  const reuseChecklistData = async ({ lastChecklist, createdChecklist }) => {
    window.Swal.fire({
      html: `<h4>${t('Fetching Child Assessment Checklist')}</h4>
            <div class="spinner-border text-primary mt-3" role="status">
              <span class="sr-only">${t('Loading')}</span>
            </div>
            <p class='text-danger'>${t("Don't close or refresh this page")}</p>`,
      showConfirmButton: false,
    });

    const lastChecklistSkillsAndScores = await getChecklistSkillsCodeAndScore(lastChecklist.id);

    const promises = lastChecklistSkillsAndScores.map((skill) => {
      return new Promise((resolve, reject) => {
        upsertChecklistAssessmentSkill(null, createdChecklist.id, skill.skill.code, skill.score, (result, err) => {
          if (err) {
            console.error(err);
            return reject(err);
          }
          resolve(result);
        });
      });
    });

    const results = await Promise.all(promises);
    return results;
  };

  useEffect((_) => {
    setBusy(true);
    setLoadingErrorMessage('');

    getAllDevelopmentalDomains((denverDevelopmentalDomains, err) => {
      if (!err) {
        setDenverDevelopmentalDomains(denverDevelopmentalDomains);
        get(childId, (result, err) => {
          if (checklistId === 'new') {
            //creating new checklist
            if (!err) {
              const loggedUser = JSON.parse(window.localStorage['loggedUserData']);
              getAllSkillsFiltered({ developmentDomain: 'CRE', level: 1 }, async (skillsFromDomain, err2) => {
                const lastChecklist = await getChildLastChecklist(childId);
                if (!err2) {
                  createCheckListAssessment(
                    {
                      date: new Date(),
                      child: result,
                      createdBy: loggedUser,
                      skills: skillsFromDomain,
                    },
                    (createdChecklist, err) => {
                      if (err || !createdChecklist) {
                        setBusy(false);
                        return setLoadingErrorMessage(t('There was a problem fetching Child Individualized Plan'));
                      }
                      // if there is a last checklist, ask if the user wants to replicate the data
                      if (lastChecklist) {
                        const lastChecklistDate = moment(lastChecklist.date).format(t('MM/DD/YYYY'));
                        return window.Swal.fire({
                          title: t('Register new Checklist Assessment'),
                          html: `<p>${t('reuse-checklist')} <strong>${lastChecklistDate}</strong>?</p>`,
                          showCancelButton: true,
                          showConfirmButton: true,
                          confirmButtonText: t('Yes'),
                          cancelButtonText: t('No'),
                        })
                          .then(async (confirm) => {
                            if (confirm?.value) {
                              const reusedData = await reuseChecklistData({ lastChecklist, createdChecklist });
                              return reusedData;
                            }
                          })
                          .finally((_) => {
                            window.location.href = `/children/${childId}/checklist/${createdChecklist.id}?level=1&domain=CRE&score=ALL&mode=edit`;
                          });
                      }
                      window.location.href = `/children/${childId}/checklist/${createdChecklist.id}?level=1&domain=CRE&score=ALL&mode=edit`;
                    },
                  );
                } else {
                  setLoadingErrorMessage(t('There was a problem fetching Denver Checklist'));
                  setBusy(false);
                }
              });
            } else {
              setLoadingErrorMessage(t('There was a problem fetching Child Individualized Plan'));
              setBusy(false);
            }
          } else {
            setChild(result);
            setBusy(false);
          }
        });
      } else {
        setLoadingErrorMessage(t('There was a problem fetching Denver Developmental Domains'));
        setBusy(false);
      }
    });
  }, []);

  useEffect(
    (_) => {
      setBusy(true);
      if (child) {
        getChecklists(child.id, (result, err) => {
          if (!err) {
            setChildChecklists(result);
            setChecklistAssessment(result.find((r) => r.id === checklistId));
          } else {
            console.error('Error fetching Child Checklists:', err);
          }
          setBusy(false);
        });
      }
    },
    [child],
  );

  useEffect(
    (_) => {
      let dv = null;
      if (currentDevelopmentalDomainFilter) {
        dv = denverDevelopmentalDomains.find((d) => d.code === currentDevelopmentalDomainFilter.code);
      }
      if (dv) {
        setCurrentDevelopmentalDomainFilter(dv);
      } else {
        setCurrentDevelopmentalDomainFilter(denverDevelopmentalDomains[0]);
      }
    },
    [denverDevelopmentalDomains],
  );

  useEffect(
    (_) => {
      getDevelopmentalDomainsByLevel(currentLevel, (resultDevelopmental, err2) => {
        if (!err2) {
          setDenverDevelopmentalDomains(resultDevelopmental);
        } else {
          setLoadingErrorMessage(t('There was a problem fetching Denver Checklist'));
        }
      });
    },
    [currentLevel],
  );

  useEffect(() => {
    if (child != null && checklistItemsFiltered != null) {
      setDevelopmentScore(evaluateDevelopmentScoreByLevel(checklistItemsFiltered, currentLevel, child));
    }
  }, [checklistItemsFiltered, currentLevel, child]);

  useEffect(
    (_) => {
      setBusy(true);
      setLoadingErrorMessage('');

      if (currentDevelopmentalDomainFilter && currentLevel) {
        getChildChecklistAssessments(
          {
            childId,
            checklistId,
            developmentDomainList: [currentDevelopmentalDomainFilter.code],
            levelList: [currentLevel],
          },
          (evaluatedSkills, err) => {
            if (!err) {
              getAllSkillsFiltered(
                {
                  developmentDomainCode: currentDevelopmentalDomainFilter.code,
                  level: currentLevel,
                },
                (skills, error) => {
                  let mergedList = skills.map((sk) => {
                    const evs = evaluatedSkills.find((es) => es.skill.code === sk.code);
                    if (evs) {
                      return evs;
                    } else {
                      return {
                        childChecklistAssessment: checklistAssessment,
                        score: 'NOT_EVALUATED',
                        skill: sk,
                      };
                    }
                  });
                  setChecklistItems(mergedList.filter((p) => !p.skill.code.match(/CTM/)));
                  updateChecklistAssessmentURL(
                    currentLevel,
                    currentDevelopmentalDomainFilter.code,
                    filterScore,
                    editMode ? 'edit' : 'readOnly',
                  );
                  getAvailableLevelsByDevelopmentalDomain(currentDevelopmentalDomainFilter.code, (res, err) => {
                    if (!err) {
                      setAvailableLevels(res);
                    }
                  });
                  setBusy(false);
                },
              );
            } else {
              setLoadingErrorMessage(t('There was a problem fetching Child Denver Checklist'));
              setBusy(false);
            }
          },
        );
      }
    },
    [t, currentDevelopmentalDomainFilter, filterScore, currentLevel],
  );

  useEffect(() => {
    if (!filterScore || filterScore === 'ALL') {
      setChecklistItemsFiltered(checklistItems);
    } else {
      setChecklistItemsFiltered(checklistItems.filter((c) => c.score === filterScore));
    }
  }, [checklistItems, filterScore]);

  return {
    child,
    checklistId,
    checklistAssessment,
    editMode,

    childChecklists,
    checklistItemsFiltered,
    developmentScore,

    denverDevelopmentalDomains,
    currentDevelopmentalDomainFilter,
    setCurrentDevelopmentalDomainFilter,
    currentLevel,
    setCurrentLevel,
    availableLevels,

    loadingErrorMessage,
    busy,
    requesting,

    filterScore,
    setFilterScore,

    setSkillScore,
    setAllSkillScore,
    setAllLevelScore,

    deleteCurrentChecklistAssessment,

    setChecklistAssessmentDate,
  };
};
