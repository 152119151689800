import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({ doSearch, children, searchText }) => {
  const { t } = useTranslation();

  let searchTimer = null;
  let searchTextDef = searchText ? searchText : 'Go to';
  const triggerSearchTimed = (text) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout((_) => {
      doSearch(text);
    }, 400);
  };

  return (
    <nav className="navbar">
      <span className="sidebar-toggler">
        <i data-feather="menu"></i>
      </span>
      <div className="navbar-content">
        <form className="search-form">
          <div className="input-group">
            {doSearch && (
              <>
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <i data-feather="search"></i>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  onChange={(evt) => triggerSearchTimed(evt.target.value)}
                  id="navbarForm"
                  placeholder={t(searchTextDef) + '...'}
                />
              </>
            )}
          </div>
        </form>
        <div className="flex-ccc">{children}</div>
      </div>
    </nav>
  );
};
