import { LevelFormat, convertInchesToTwip } from 'docx';

const docxTemplate = {
  defaultPageProperties: {
    page: {
      margin: {
        top: 1000,
        right: 800,
        bottom: 800,
        left: 1000,
      },
    },
  },
  defaultListsProperties: {
    numbering: {
      config: [
        {
          reference: 'list-numbering',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1',
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                },
              },
            },
          ],
        },
        {
          reference: 'bullet-points',
          levels: [
            {
              level: 0,
              format: LevelFormat.BULLET,
              text: '●',
              style: {
                paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                },
              },
            },
          ],
        },
      ],
    },
  },
};

export default docxTemplate;
