import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import moment from 'moment';

import AccessControl from './AccessControl';
import AccountActivate from './AccountActivate';
import Login from './Login';
import Sidebar from './partials/Sidebar';
import ResetPassword from './ResetPassword';
import {
  Children,
  ChildDetails,
  ChildChecklistAssessment,
  ChildInterventionSession,
  ChildWeeklyComment,
  IndividualizedPlanProvider,
  ChildIndividualizedPlan,
} from './routes/children';
import { ChildrenDashBoard } from './routes/dashboard/';
import Checklist from './routes/denver/Checklist';
import ChecklistDetails from './routes/denver/ChecklistDetails';
import Profile from './routes/profile/Profile';
import { ReportPage } from './routes/reports/ReportPage';
import StaffMemberDetails from './routes/staff/StaffMemberDetails';
import StaffMembers from './routes/staff/StaffMembers';
import { ChildStats } from './routes/stats';
import { refreshToken } from './services/auth';
import stutzlabLogo from './stutzlab-logo.png';

function App() {
  const { t } = useTranslation();

  const [user] = useState(window.localStorage['loggedUserData']);

  const logout = () => {
    window.localStorage.removeItem('loggedUserData');
    window.localStorage.removeItem('LoginData');
    window.localStorage.removeItem('token');
    window.location.href = '/';
  };

  const isAuthenticated = () => {
    var loggedUserData = JSON.parse(window.localStorage.getItem('loggedUserData'));
    if (loggedUserData) {
      return new Date().getTime() < new Date(loggedUserData.accessTokenExpiration).getTime();
    }
    return false;
  };

  useEffect(() => {
    window.jQuery.validator.addMethod(
      'validDate',
      (value) => {
        return moment(value, t('MM/DD/YYYY')).isValid();
      },
      t('Please enter a valid date in the format MM/DD/YYYY'),
    );
  }, [t]);

  if (!user) {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenAccountActivate = urlParams.get('AccountActivate');
    const tokenResetPassword = urlParams.get('ResetPassword');
    if (tokenAccountActivate) {
      return <AccountActivate />;
    } else if (tokenResetPassword) {
      return <ResetPassword />;
    } else {
      return <Login />;
    }
  }

  if (user) {
    if (!isAuthenticated()) {
      logout();
    } else {
      refreshToken();
    }
    return (
      <div className="main-wrapper">
        <Sidebar onLogout={logout} />

        <div className="page-wrapper">
          <Router>
            <Switch>
              <Route path="/children/:childId/checklist/:checklistId">
                <AccessControl requiredPermissions={['child']}>
                  <ChildChecklistAssessment />
                </AccessControl>
              </Route>
              <Route path="/children/:childId/plan/:planId">
                <AccessControl requiredPermissions={['child']}>
                  <IndividualizedPlanProvider>
                    <ChildIndividualizedPlan />
                  </IndividualizedPlanProvider>
                </AccessControl>
              </Route>
              <Route path="/children/:childId/session/:sessionId">
                <AccessControl requiredPermissions={['child']}>
                  <ChildInterventionSession />
                </AccessControl>
              </Route>
              <Route path="/children/:childId/comment/">
                <AccessControl requiredPermissions={['child']}>
                  <ChildWeeklyComment />
                </AccessControl>
              </Route>
              <Route path="/children/:id">
                <AccessControl requiredPermissions={['child']}>
                  <ChildDetails />
                </AccessControl>
              </Route>
              <Route path="/children">
                <AccessControl requiredPermissions={['child']}>
                  <Children />
                </AccessControl>
              </Route>
              <Route path="/reports">
                <AccessControl requiredPermissions={['denver']}>
                  <ReportPage />
                </AccessControl>
              </Route>
              <Route path="/stats/:id">
                <AccessControl requiredPermissions={['child']}>
                  <ChildStats />
                </AccessControl>
              </Route>
              <Route path="/dashboard">
                <AccessControl requiredPermissions={['child']}>
                  <ChildrenDashBoard />
                </AccessControl>
              </Route>
              <Route path="/staff/:id">
                <AccessControl requiredPermissions={['staffMember']}>
                  <StaffMemberDetails />
                </AccessControl>
              </Route>
              <Route path="/staff">
                <AccessControl requiredPermissions={['staffMember']}>
                  <StaffMembers />
                </AccessControl>
              </Route>
              <Route path="/denver/:id">
                <AccessControl requiredPermissions={['denver']}>
                  <ChecklistDetails />
                </AccessControl>
              </Route>
              <Route path="/denver/">
                <AccessControl requiredPermissions={['denver']}>
                  <Checklist />
                </AccessControl>
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/">
                <Redirect to="/children/" />
              </Route>
            </Switch>
          </Router>

          <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
            <p className="text-muted text-center text-md-left">
              Copyright © {new Date().getFullYear()}
              <a href="https://www.earlydenver.com" target="_blank" rel="noopener noreferrer">
                Early Denver {process.env.REACT_APP_VERSION}
              </a>
              .
              <img src="/assets/images/favicon.png" alt="DENVER LOGO" style={{ margin: '0px .2rem', width: '15px' }} />
              {t('All rights reserved')}
            </p>
            <p className="text-muted text-center text-md-left mb-0 d-none d-md-block">
              {t('Handcrafted With')} <i className="mb-1 text-primary ml-1 icon-small mr-1" data-feather="heart"></i>
              {t('by')}{' '}
              <a href="https://github.com/stutzlab" target="_blank" rel="noopener noreferrer">
                <img alt="stutz lab" src={stutzlabLogo} width="22px" className="ml-1 mb-1" />
              </a>
            </p>
          </footer>
        </div>
      </div>
    );
  }
}

export default App;
