import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Fuse from 'fuse.js';
import Inputmask from 'inputmask';
import moment from 'moment';

import AccessControl from '../../../AccessControl';
import { Loading } from '../../../components';
import { getTypicalLevelByAge } from '../../../services/denver';
import { getNumberFromSkillCode } from '../../../utils/legacy';
import { getIntervalInMonths } from '../../../utils/time';
import { withChildrenPermission } from '../../../withChildrenPermission';
import ChecklistFilter from '../../denver/components/ChecklistFilter';
import { ButtonMinus, ButtonPlus, ButtonPlusMinus, ChildChecklistAssessmentSummary } from '../components';
import useChildChecklistAssessmentModel from './useChildChecklistAssessmentModel';

// eslint-disable-next-line no-unused-vars
function ChildChecklistAssessment({ levelMode = 'inline' }) {
  const $ = window.$;
  $(document).ready(function () {
    Inputmask().mask(document.querySelectorAll('input'));
  });

  const { t } = useTranslation();

  const {
    child,
    checklistId,
    checklistAssessment,
    editMode,
    childChecklists,
    checklistItemsFiltered,
    developmentScore,
    setChecklistAssessmentDate,
    denverDevelopmentalDomains,
    currentDevelopmentalDomainFilter,
    setCurrentDevelopmentalDomainFilter,
    currentLevel,
    setCurrentLevel,
    availableLevels,
    filterScore,
    setFilterScore,
    setSkillScore,
    setAllSkillScore,
    setAllLevelScore,
    deleteCurrentChecklistAssessment,
    busy,
    requesting,
    loadingErrorMessage,
  } = useChildChecklistAssessmentModel();

  const removeThisChecklist = () => {
    window.Swal.fire({
      title: t('Confirm') + '?',
      icon: 'warning',
      text: t('Are you sure you want to remove this checklist') + '?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then((result) => {
      if (result.value) {
        deleteCurrentChecklistAssessment();
      }
    });
  };

  const evalBtnOutlineOrNot = (scoreEval, targetScore, className) => {
    if (!scoreEval || scoreEval === 'NOT_EVALUATED') {
      if (editMode) {
        return 'btn-outline-secondary';
      }
      return 'btn-outline-light';
    }
    if (scoreEval === targetScore) {
      return 'btn-' + className;
    }
    return 'btn-outline-' + className;
  };

  let [dateTime, setDateTime] = useState({ m: null });
  const onSaveDateTime = () => {
    let newDate = moment(checklistAssessment.date);
    if (dateTime.m !== null) {
      newDate = moment(dateTime.m, t('MM/DD/YYYY-'));
    }
    setChecklistAssessmentDate(checklistAssessment.id, newDate);
  };

  // Search
  const [query, updateQuery] = useState('');
  const options = {
    // minMatchCharLength: 3,
    keys: ['skill.title', 'skill.description'],
    distance: 100,
    threshold: 0.3,
  };
  const fuse = new Fuse(checklistItemsFiltered, options);
  function onSearch({ currentTarget }) {
    updateQuery(currentTarget.value);
  }
  const results = fuse.search(query);
  const filterFuse = (el) => {
    if (results.length > 0) {
      for (let rs of results) {
        if (rs.item.skill.id === el.skill.id) return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                id="search"
                name="search"
                value={query}
                onChange={onSearch}
                required
                type="text"
                className="form-control form-control-sm"
                autoComplete="off"
                placeholder={t('Search here')}
              />
            </div>
          </form>
          {child && checklistId !== 'new' && (
            <AccessControl
              requiredPermissions={[
                'child:*:createChildChecklistAssessment',
                'child:' + child.id + ':createChildChecklistAssessment',
              ]}
            >
              <div className="flex-ccc">
                <a href={`/children/${child.id}/checklist/new`} className="btn btn-outline-primary mr-2">
                  {t('Register new Checklist Assessment')}
                </a>
              </div>
            </AccessControl>
          )}
          {child && checklistId !== 'new' && (
            <AccessControl
              requiredPermissions={[
                'child:*:createChildIndividualizedPlan',
                'child:' + child.id + ':createChildIndividualizedPlan',
              ]}
            >
              <div className="flex-ccc">
                <a href={`/children/${child.id}/plan/new`} className="btn btn-outline-primary">
                  {t('Register new Intervention Plan')}
                </a>
              </div>
            </AccessControl>
          )}
        </div>
      </nav>

      <div className="page-content">
        {!loadingErrorMessage && busy && <Loading loadingText={t('Fetching Child Assessment Checklist')} />}
        {!loadingErrorMessage && !busy && child && checklistAssessment && (
          <>
            <nav className="page-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="/children">{t('Sidebar.Children')}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href={`/children/${child.id}`}>{child.person.name}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href={`/children/${child.id}/checklist/${checklistId}`}>
                    {t('Checklist')} ({moment(checklistAssessment.date).format(t('MM/DD/YYYY'))})
                  </a>
                </li>
              </ol>
            </nav>

            {/* Checklist date select, buttons for edit, view and delete row */}
            <div className="row">
              <h4 className="page-title col-12 mb-3">
                <div className="d-flex">
                  {child.person.name}
                  <AccessControl
                    requiredPermissions={[
                      'child:*:updateChildChecklistAssessment:*',
                      'child:' + child.id + ':updateChildChecklistAssessment:*',
                      'child:*:updateChildChecklistAssessment:' + checklistAssessment.id,
                      'child:' + child.id + ':updateChildChecklistAssessment:' + checklistAssessment.id,
                    ]}
                  >
                    {/* Edit Checklist date */}
                    {editMode && (
                      <form
                        id="dateTimeForm"
                        className="forms-child-info"
                        onSubmit={(e) => {
                          e.preventDefault();
                          onSaveDateTime();
                        }}
                      >
                        <div className="d-flex col-sm-12">
                          <input
                            className="form-control mb-4 mb-md-0"
                            id="dateTime"
                            name="dateTime"
                            defaultValue={moment(checklistAssessment.date).format(t('MM/DD/YYYY-'))}
                            data-inputmask-placeholder="*"
                            im-insert="false"
                            onBlur={(e) => setDateTime({ m: e.target.value })}
                            data-inputmask="'alias': 'datetime'"
                            data-inputmask-inputformat={t('MM/DD/YYYY+')}
                          />
                          {dateTime.m !== null && (
                            <button type="submit" className="btn btn-secondary btn-sm mr-2">
                              {t('Save')}
                            </button>
                          )}
                        </div>
                      </form>
                    )}
                  </AccessControl>

                  {/* Choose another checklist by date */}
                  {!editMode && (
                    <>
                      <button
                        className="btn btn-outline-secondary ml-2 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {moment(checklistAssessment.date).format(t('MM/DD/YYYY'))}
                      </button>

                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {childChecklists.map((ca, idx) => (
                          <a
                            key={ca.id}
                            className="dropdown-item"
                            href={`/children/${child.id}/checklist/${ca.id}/?level=1&domain=CRE&score=${filterScore}`}
                          >
                            {moment(ca.date).format(t('MM/DD/YYYY'))} {idx === 0 && '(' + t('current') + ')'}
                          </a>
                        ))}
                      </div>
                    </>
                  )}

                  {/* Checklist Edit button  */}
                  <div className="d-flex justify-content-end" style={{ flex: '1' }}>
                    {!editMode && (
                      <AccessControl
                        requiredPermissions={[
                          'child:*:updateChildChecklistAssessment:*',
                          'child:' + child.id + ':updateChildChecklistAssessment:*',
                          'child:*:updateChildChecklistAssessment:' + checklistAssessment.id,
                          'child:' + child.id + ':updateChildChecklistAssessment:' + checklistAssessment.id,
                        ]}
                      >
                        <a
                          href={`/children/${child.id}/checklist/${checklistAssessment.id}?mode=edit`}
                          className="btn btn-outline-info ml-2"
                        >
                          {t('Edit this Checklist')}
                        </a>
                      </AccessControl>
                    )}

                    {/* Checklist View button  */}
                    {editMode && (
                      <AccessControl
                        requiredPermissions={[
                          'child:*:updateChildChecklistAssessment:*',
                          'child:' + child.id + ':updateChildChecklistAssessment:*',
                          'child:*:updateChildChecklistAssessment:' + checklistAssessment.id,
                          'child:' + child.id + ':updateChildChecklistAssessment:' + checklistAssessment.id,
                        ]}
                      >
                        <a
                          href={`/children/${child.id}/checklist/${checklistAssessment.id}`}
                          className="btn btn-outline-info ml-2"
                        >
                          {t('View this Checklist')}
                        </a>
                      </AccessControl>
                    )}

                    {/* Checklist Delete button  */}
                    <AccessControl
                      requiredPermissions={[
                        'child:*:deleteChildChecklistAssessment:*',
                        'child:' + child.id + ':deleteChildChecklistAssessment:*',
                        'child:*:deleteChildChecklistAssessment:' + checklistAssessment.id,
                        'child:' + child.id + ':deleteChildChecklistAssessment:' + checklistAssessment.id,
                      ]}
                    >
                      <button className="btn btn-outline-info ml-2" onClick={removeThisChecklist}>
                        {t('Delete this Checklist')}
                      </button>
                    </AccessControl>
                  </div>
                </div>
              </h4>
            </div>

            {!editMode && false && (
              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body main-content">
                      <ChildChecklistAssessmentSummary child={child} checklistId={checklistId} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body main-content">
                    <div className="mb-4 d-flex justify-content-between">
                      <ChecklistFilter
                        availableLevels={availableLevels}
                        denverDevelopmentalDomains={denverDevelopmentalDomains}
                        currentDevelopmentalDomainFilter={currentDevelopmentalDomainFilter}
                        setCurrentDevelopmentalDomainFilter={setCurrentDevelopmentalDomainFilter}
                        currentLevel={currentLevel}
                        setCurrentLevel={setCurrentLevel}
                      >
                        <div className="btn-group btn-group-sm" role="group">
                          <ButtonMinus
                            onClick={(_) => setFilterScore(filterScore === 'FAIL' ? '' : 'FAIL')}
                            className={'btn ' + evalBtnOutlineOrNot(filterScore, 'FAIL', 'primary')}
                          />
                          <ButtonPlusMinus
                            onClick={(_) => setFilterScore(filterScore === 'PASS_FAIL' ? '' : 'PASS_FAIL')}
                            className={'btn ' + evalBtnOutlineOrNot(filterScore, 'PASS_FAIL', 'primary')}
                          />
                          <ButtonPlus
                            onClick={(_) => setFilterScore(filterScore === 'PASS' ? '' : 'PASS')}
                            className={'btn ' + evalBtnOutlineOrNot(filterScore, 'PASS', 'primary')}
                          />
                        </div>
                      </ChecklistFilter>
                    </div>

                    {/* Summary card */}
                    <div className="row">
                      <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                          <div className="card-body main-content">
                            {developmentScore && (
                              <>
                                <div>
                                  {t('Age')}:{' '}
                                  <strong>
                                    {getIntervalInMonths(child.person.dateOfBirth, new Date())} {t('months')}
                                  </strong>{' '}
                                  | {t('Level')}:{' '}
                                  <strong>
                                    {getTypicalLevelByAge(getIntervalInMonths(child.person.dateOfBirth, new Date()))}
                                  </strong>
                                </div>
                                <div>
                                  {t('Maximum Score')}: <strong>{developmentScore.maximumScore}</strong>
                                </div>
                                <div>
                                  {t('Maximum Evaluated Score')}:{' '}
                                  <strong>{developmentScore.maximumEvaluatedScore}</strong>
                                </div>
                                <div>
                                  {t('Score')}: <strong>{developmentScore.score}</strong>
                                </div>
                                <div>
                                  {t('Percentage Score')}:{' '}
                                  <strong>
                                    {(
                                      (developmentScore.score * 100) /
                                      (developmentScore.maximumEvaluatedScore
                                        ? developmentScore.maximumEvaluatedScore
                                        : 1)
                                    ).toFixed(2)}
                                    %
                                  </strong>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Mark all button to set all itens in the checklist with the selected score */}
                    {editMode && (
                      <AccessControl
                        requiredPermissions={[
                          'child:*:updateChildChecklistAssessment:*',
                          'child:' + child.id + ':updateChildChecklistAssessment:*',
                          'child:*:updateChildChecklistAssessment:' + checklistAssessment.id,
                          'child:' + child.id + ':updateChildChecklistAssessment:' + checklistAssessment.id,
                        ]}
                      >
                        <div className="row">
                          <div className="col-md-12 grid-margin stretch-card">
                            <div className="card">
                              <div className="card-body main-content content-start">
                                <div className="d-flex flex-wrap justify-content-between">
                                  {/* Mark all items of current level */}
                                  <div className="p-2">
                                    <div>{t('checkListAssessment.updateCurrentLevelSkills')}</div>
                                    <div className="btn-group child-checklist-item-assessment">
                                      <ButtonMinus
                                        disabled={!editMode}
                                        onClick={(_) => setAllLevelScore(currentLevel, 'FAIL')}
                                        className={
                                          'btn ' + evalBtnOutlineOrNot('', 'FAIL', 'primary') + ' filled hover-ghost'
                                        }
                                      />

                                      <ButtonPlusMinus
                                        disabled={!editMode}
                                        onClick={(_) => setAllLevelScore(currentLevel, 'PASS_FAIL')}
                                        className={
                                          'btn ' +
                                          evalBtnOutlineOrNot('', 'PASS_FAIL', 'primary') +
                                          ' filled hover-ghost'
                                        }
                                      />

                                      <ButtonPlus
                                        disabled={!editMode}
                                        onClick={(_) => setAllLevelScore(currentLevel, 'PASS')}
                                        className={
                                          'btn ' + evalBtnOutlineOrNot('', 'PASS', 'primary') + ' filled hover-ghost'
                                        }
                                      />
                                    </div>
                                  </div>

                                  {/* Mark current view items */}
                                  <div className="d-flex flex-column">
                                    <div className="d-flex justify-content-between">
                                      <span>{t('checkListAssessment.checkAllBelow')}:</span>
                                      <button
                                        className="btn p-0"
                                        onClick={(_) => setAllSkillScore(checklistItemsFiltered, 'NOT_EVALUATED')}
                                      >
                                        {t('Clear')}
                                      </button>
                                    </div>
                                    <div className="btn-group child-checklist-item-assessment">
                                      <ButtonMinus
                                        disabled={!editMode}
                                        onClick={(_) => setAllSkillScore(checklistItemsFiltered, 'FAIL')}
                                        className={
                                          'btn ' + evalBtnOutlineOrNot('', 'FAIL', 'primary') + ' filled hover-ghost'
                                        }
                                      />

                                      <ButtonPlusMinus
                                        disabled={!editMode}
                                        onClick={(_) => setAllSkillScore(checklistItemsFiltered, 'PASS_FAIL')}
                                        className={
                                          'btn ' +
                                          evalBtnOutlineOrNot('', 'PASS_FAIL', 'primary') +
                                          ' filled hover-ghost'
                                        }
                                      />

                                      <ButtonPlus
                                        disabled={!editMode}
                                        onClick={(_) => setAllSkillScore(checklistItemsFiltered, 'PASS')}
                                        className={
                                          'btn ' + evalBtnOutlineOrNot('', 'PASS', 'primary') + ' filled hover-ghost'
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccessControl>
                    )}

                    {/*  Checklist itens list */}
                    {checklistItemsFiltered.filter(filterFuse).map((item) => (
                      <div key={item.skill.code} className="col-12 row">
                        <div className="col-11 card rounded-0">
                          <div className="card-body">
                            <h4
                              className="card-title"
                              data-toggle="collapse"
                              data-target={'#skillDetails-' + item.id}
                              aria-expanded="false"
                              aria-controls={'skillDetails-' + item.id}
                            >
                              {getNumberFromSkillCode(item.skill.code)}. &nbsp;
                              {item.skill.title}
                            </h4>
                            <div
                              style={{ color: '#AAA' }}
                              data-toggle="collapse"
                              data-target={'#skillDetails-' + item.id}
                              aria-expanded="false"
                              aria-controls={'skillDetails-' + item.id}
                            >
                              {item.skill.description}
                            </div>
                            <AccessControl
                              requiredPermissions={[
                                'child:*:updateChildChecklistAssessment:*',
                                'child:' + child.id + ':updateChildChecklistAssessment:*',
                                'child:*:updateChildChecklistAssessment:' + checklistAssessment.id,
                                'child:' + child.id + ':updateChildChecklistAssessment:' + checklistAssessment.id,
                              ]}
                            >
                              {editMode && (
                                <div className="btn-group child-checklist-item-assessment">
                                  <button
                                    disabled={!editMode || requesting}
                                    onClick={(_) => setSkillScore(item, 'FAIL')}
                                    className={
                                      'btn ' +
                                      evalBtnOutlineOrNot(item.score, 'FAIL', 'primary') +
                                      ' filled hover-ghost'
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-minus"
                                    >
                                      <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                  </button>
                                  <button
                                    disabled={!editMode || requesting}
                                    onClick={(_) => setSkillScore(item, 'PASS_FAIL')}
                                    className={
                                      'btn ' +
                                      evalBtnOutlineOrNot(item.score, 'PASS_FAIL', 'primary') +
                                      ' filled hover-ghost'
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-plus"
                                    >
                                      <line x1="12" y1="5" x2="12" y2="19"></line>
                                      <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-minus"
                                    >
                                      <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                  </button>
                                  <button
                                    disabled={!editMode || requesting}
                                    onClick={(_) => setSkillScore(item, 'PASS')}
                                    className={
                                      'btn ' +
                                      evalBtnOutlineOrNot(item.score, 'PASS', 'primary') +
                                      ' filled hover-ghost'
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-plus"
                                    >
                                      <line x1="12" y1="5" x2="12" y2="19"></line>
                                      <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                  </button>
                                </div>
                              )}
                            </AccessControl>
                          </div>
                        </div>

                        {item.score === 'FAIL' && (
                          <div
                            className={`card d-flex justify-content-center align-items-center bg-danger col-1 rounded-0`}
                            style={{ fontSize: '1.5rem' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-minus"
                            >
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </div>
                        )}
                        {item.score === 'PASS_FAIL' && (
                          <div
                            className={`card d-flex justify-content-center align-items-center bg-warning col-1 rounded-0`}
                            style={{ fontSize: '1.5rem' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-plus"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-minus"
                            >
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </div>
                        )}
                        {item.score === 'PASS' && (
                          <div
                            className={`card d-flex justify-content-center align-items-center bg-success col-1 rounded-0`}
                            style={{ fontSize: '1.5rem' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-plus"
                            >
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default withChildrenPermission(ChildChecklistAssessment);
