import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import useChildChecklistAssessmentSummary from '../../routes/children/components/childChecklistAssessmentSummary/useChildChecklistAssessmentSummary';

function ChecklistLevelStatsRadial({ childId, childDateOfBirth, checklistId }) {
  const { levelsDevelopmentRatios, developmentFactor, checklistState } = useChildChecklistAssessmentSummary({
    childId,
    childDateOfBirth,
    checklistId,
  });
  const { t } = useTranslation();
  const timer = useRef();

  if (checklistState === 'EMPTY') {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        <Trans i18nKey="ZZLongTexts.EmptyChecklistsOnStats">
          No items evaluated in the last checklist.
          <a href={`/children/${childId}/checklist/${checklistId}`} rel="noreferrer" target="_blank">
            Click here to view the checklist
          </a>
        </Trans>
      </div>
    );
  }

  if (!levelsDevelopmentRatios) {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        {t('Loading')}
      </div>
    );
  }

  clearTimeout(timer.current);
  timer.current = setTimeout(() => {
    var options = {
      chart: {
        height: 300,
        type: 'radialBar',
      },
      series: levelsDevelopmentRatios.map((a) => a.toFixed(2) * 100),
      plotOptions: {
        radialBar: {
          inverseOrder: true,
          dataLabels: {
            value: {
              show: true,
              formatter: (val) => (val ? parseFloat(val).toFixed(0) : 0) + '%',
            },
            total: {
              show: true,
              label: t('Aggregated'),
              color: '#373d3f',
              fontSize: '14px',
              fontWeight: 600,
              formatter: () => (developmentFactor * 100).toFixed(0) + '%',
            },
          },
        },
      },

      labels: levelsDevelopmentRatios.map((_, idx) => t('Level') + ' ' + (idx + 1)),
    };

    if (window.ApexCharts) {
      var chart = new window.ApexCharts(document.querySelector(`#ChecklistLevelStatsRadial-${childId}`), options);
      if (chart) {
        chart.render();
      }
    }
  }, 1000);

  return <div id={`ChecklistLevelStatsRadial-${childId}`} style={{ minHeight: '300px' }}></div>;
}

export default ChecklistLevelStatsRadial;
