export const unifyChecklistsByDomain = (developmentalDomainScores) => {
  const socialDomains = ['CSA', 'CSP', 'CAP', 'CSO'];
  const gameDomains = ['JOG', 'JOR', 'JOI'];
  const independencyDomains = ['IPA', 'IPV', 'IPH', 'IPT', 'IPE'];

  function unifyChecklistsByDevelopmentDomains(checklists, newDomainName) {
    if (checklists.length < 1) return;

    function average(elements) {
      const reduction = elements.reduce((a, b) => a + b);
      const result = reduction / elements.length;
      return result;
    }

    // for each domain, check if there are 2 different checklists.
    // If there aren't, duplicate one of them, so the graph renderer won't break.
    // The checking for a single checklist is done on the return clause of this function.
    const expandedChecklists = checklists.map((checklist) => {
      let developmentalDomain = checklist.developmentalDomain;

      let scoreTotal =
        checklist.scoreTotal.length > 1 ? checklist.scoreTotal : [checklist.scoreTotal[0], checklist.scoreTotal[0]];

      let developmentFactor =
        checklist.developmentFactor.length > 1
          ? checklist.developmentFactor
          : [checklist.developmentFactor[0], checklist.developmentFactor[0]];

      let typicalDevelopmentTotalPoints =
        checklist.typicalDevelopmentTotalPoints.length > 1
          ? checklist.typicalDevelopmentTotalPoints
          : [checklist.typicalDevelopmentTotalPoints[0], checklist.typicalDevelopmentTotalPoints[0]];

      let typicalDevelopmentWeightingTotalPoints =
        checklist.typicalDevelopmentWeightingTotalPoints.length > 1
          ? checklist.typicalDevelopmentWeightingTotalPoints
          : [checklist.typicalDevelopmentWeightingTotalPoints[0], checklist.typicalDevelopmentWeightingTotalPoints[0]];

      return {
        developmentalDomain: developmentalDomain,
        scoreTotal: scoreTotal,
        developmentFactor: developmentFactor,
        typicalDevelopmentTotalPoints: typicalDevelopmentTotalPoints,
        typicalDevelopmentWeightingTotalPoints: typicalDevelopmentWeightingTotalPoints,
      };
    });

    return {
      developmentalDomain: {
        title: newDomainName,
      },

      scoreTotal: [
        {
          checklistDate: expandedChecklists[0].scoreTotal[0].checklistDate,
          value: Math.floor(average(expandedChecklists.map((item) => item.scoreTotal[0].value))),
        },
        expandedChecklists.some((checklist) => checklist.scoreTotal[1]) && {
          checklistDate: expandedChecklists[0].scoreTotal[1].checklistDate,
          value: Math.floor(
            average(expandedChecklists.map((item) => (item.scoreTotal[1].value ? item.scoreTotal[1].value : 0))),
          ),
        },
      ].filter((exists) => exists),

      developmentFactor: [
        {
          checklistDate: expandedChecklists[0].developmentFactor[0].checklistDate,
          value: average(expandedChecklists.map((item) => item.developmentFactor[0].value)),
        },
        expandedChecklists.some((checklist) => checklist.developmentFactor[1]) && {
          checklistDate: expandedChecklists[0].developmentFactor[1].checklistDate,
          value: average(expandedChecklists.map((item) => item.developmentFactor[1].value)),
        },
      ].filter((exists) => exists),

      typicalDevelopmentTotalPoints: [
        {
          checklistDate: expandedChecklists[0].typicalDevelopmentTotalPoints[0].checklistDate,
          value: average(expandedChecklists.map((item) => item.typicalDevelopmentTotalPoints[0].value)),
        },
        expandedChecklists.some((checklist) => checklist.typicalDevelopmentTotalPoints[1]) && {
          checklistDate: expandedChecklists[0].typicalDevelopmentTotalPoints[1].checklistDate,
          value: average(expandedChecklists.map((item) => item.typicalDevelopmentTotalPoints[1].value)),
        },
      ].filter((exists) => exists),

      typicalDevelopmentWeightingTotalPoints: [
        {
          checklistDate: expandedChecklists[0].typicalDevelopmentWeightingTotalPoints[0].checklistDate,
          value: average(expandedChecklists.map((item) => item.typicalDevelopmentWeightingTotalPoints[0].value)),
        },
        expandedChecklists.some((checklist) => checklist.typicalDevelopmentWeightingTotalPoints[1]) && {
          checklistDate: expandedChecklists[0].typicalDevelopmentWeightingTotalPoints[1].checklistDate,
          value: average(expandedChecklists.map((item) => item.typicalDevelopmentWeightingTotalPoints[1].value)),
        },
      ].filter((exists) => exists),
    };
  }

  const socialCompetences = unifyChecklistsByDevelopmentDomains(
    developmentalDomainScores.filter((domain) => socialDomains.includes(domain.developmentalDomain.code)),
    'Competências Sociais',
  );

  const gameCompetences = unifyChecklistsByDevelopmentDomains(
    developmentalDomainScores.filter((domain) => gameDomains.includes(domain.developmentalDomain.code)),
    'Jogo',
  );

  const independencyCompetences = unifyChecklistsByDevelopmentDomains(
    developmentalDomainScores.filter((domain) => independencyDomains.includes(domain.developmentalDomain.code)),
    'Independência',
  );

  //Return an array with unified domains
  const unifiedChecklists = developmentalDomainScores.filter(
    (checklist) =>
      // filter out domains that won't be unified
      !socialDomains.includes(checklist.developmentalDomain.code) &&
      !gameDomains.includes(checklist.developmentalDomain.code) &&
      !independencyDomains.includes(checklist.developmentalDomain.code),
  );

  // Pushing the unified domains back to the array
  socialCompetences && unifiedChecklists.push(socialCompetences);
  gameCompetences && unifiedChecklists.push(gameCompetences);
  independencyCompetences && unifiedChecklists.push(independencyCompetences);

  return unifiedChecklists;
};
