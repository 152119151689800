import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import {
  getAllSkillsFiltered,
  getAllDevelopmentalDomains,
  getDevelopmentalDomainsByLevel,
  getAvailableLevelsByDevelopmentalDomain,
} from '../../services/denver';
import { useHistory } from '../../utils/history';

export default ({ shouldUpdateChecklistURL }) => {
  const { t } = useTranslation();
  const { updateChecklistURL } = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(true);
  const [denverDevelopmentalDomains, setDenverDevelopmentalDomains] = useState([]);
  const [availableLevels, setAvailableLevels] = useState([1, 2, 3, 4]);
  const [currentDevelopmentalDomainFilter, setCurrentDevelopmentalDomainFilter] = useState(
    query.get('domain') ? { code: query.get('domain') } : { code: 'CRE' },
  );
  const [currentLevel, setCurrentLevel] = useState(query.get('level') ? parseInt(query.get('level')) : 1);
  const [checklistItems, setChecklistItems] = useState([]);

  useEffect(
    (_) => {
      setBusy(true);
      setLoadingErrorMessage('');
      getAllSkillsFiltered(
        {
          developmentDomainCode: currentDevelopmentalDomainFilter.code,
          level: currentLevel,
          removeCustomObjectives: true,
        },
        (result, err) => {
          if (!err) {
            setChecklistItems(result);
            getAvailableLevelsByDevelopmentalDomain(
              currentDevelopmentalDomainFilter.code,
              (resultGetLevelByDevDomain, errorResponseGetLevelByDevDomain) =>
                !errorResponseGetLevelByDevDomain && setAvailableLevels(resultGetLevelByDevDomain),
            );

            if (shouldUpdateChecklistURL) {
              updateChecklistURL(currentLevel, currentDevelopmentalDomainFilter.code);
            }
          } else {
            setLoadingErrorMessage(t('There was a problem fetching Denver Checklist'));
          }
          setBusy(false);
        },
      );
    },
    [t, currentDevelopmentalDomainFilter, currentLevel],
  );

  useEffect(
    (_) => {
      setBusy(true);
      setLoadingErrorMessage('');

      getAllDevelopmentalDomains((resultDevelopmental, err2) => {
        if (!err2) {
          setDenverDevelopmentalDomains(resultDevelopmental);
          let curr = resultDevelopmental.find((rd) => rd.code === query.get('domain'));
          if (curr) {
            setCurrentDevelopmentalDomainFilter(curr);
          } else {
            setCurrentDevelopmentalDomainFilter(resultDevelopmental[0]);
          }
        } else {
          setLoadingErrorMessage(t('There was a problem fetching Denver Checklist'));
          setBusy(false);
        }
      });
    },
    [t],
  );

  useEffect(
    (_) => {
      getDevelopmentalDomainsByLevel(currentLevel, (resultDevelopmental, err) => {
        if (!err) {
          setDenverDevelopmentalDomains(resultDevelopmental);
        } else {
          setLoadingErrorMessage(t('There was a problem fetching Denver Checklist'));
        }
      });
    },
    [currentLevel],
  );

  return {
    checklistItems,
    denverDevelopmentalDomains,
    currentDevelopmentalDomainFilter,
    setCurrentDevelopmentalDomainFilter,
    currentLevel,
    setCurrentLevel,
    loadingErrorMessage,
    busy,
    availableLevels,
  };
};
