import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import config from './config';
import { getAdmin, createAdmin } from './services/admin';
import { loginPasswordClick } from './services/auth';
import { getRelativeByEmail } from './services/children';
import { getByEmail } from './services/staff';

export default () => {
  const { t } = useTranslation();

  const [loggedUser, setLoggedUser] = useState(JSON.parse(window.localStorage.getItem('loggedUserData')));

  const authenticate = async (email, password, errorCallback) => {
    await loginPasswordClick(email, password, (resultToken, err) => {
      if (err) {
        errorCallback(t('Error authenticating user'));
      } else if (email === config.adminUser) {
        //admin
        var adminPermissions = [
          'denver',

          'behavior',

          'staffMember',
          'createStaffMember',
          'deleteStaffMember:*',
          'updateStaffMember:*',

          'child',
          'createChild',
          'deleteChild:*',
          'updateChild:*',

          'child:*:childChecklistAssessment',
          'child:*:createChildChecklistAssessment',
          'child:*:deleteChildChecklistAssessment:*',
          'child:*:updateChildChecklistAssessment:*',

          'child:*:childChildIndividualizedPlan',
          'child:*:createChildIndividualizedPlan',
          'child:*:deleteChildIndividualizedPlan:*',
          'child:*:updateChildIndividualizedPlan:*',

          'child:*:childIndividualizedPlanSession',
          'child:*:createChildIndividualizedPlanSession',
          'child:*:deleteChildIndividualizedPlanSession:*',
          'child:*:updateChildIndividualizedPlanSession:*',
          'child:*:updateChildIndividualizedPlanSessionStep:*',
        ];

        getAdmin(email, password, (result, resultToken, err) => {
          if (err === 'admin not found') {
            createAdmin('EarlyDenver Admin', email, password, (result, err) => {
              if (!err) {
                errorCallback(t('Successfully registered password'));
              } else {
                var errText = t('Error authenticating user');
                if (err.status === 450) {
                  errText = t('Error registering (Invalid name)');
                } else if (err.status === 455) {
                  errText = t('Error registering (Invalid email)');
                } else if (err.status === 460) {
                  errText = t('Error registering (Invalid password)');
                } else if (err.status === 465) {
                  errText = t('Error registering (Email already exists)');
                }
                errorCallback(errText);
              }
            });
          } else if (err) {
            errorCallback(t('Error authenticating user'));
          } else {
            resultToken.role = 'admin';
            resultToken.adminId = result.person.id;
            resultToken.id = result.id;
            resultToken.permissions = adminPermissions;
            setLoggedUser(resultToken);
            window.localStorage.setItem('loggedUserData', JSON.stringify(resultToken));
            window.location.reload();
          }
        });
        // no Admin
      } else {
        getByEmail(email, password, (result, resultToken, err) => {
          if (err) {
            errorCallback(t('Error authenticating user'));
          } else if (result) {
            // childStaffAssignments
            resultToken.permissions = ['denver', 'behavior', 'staffMember', 'child'];
            resultToken.role = 'staffMember';
            if (result[0]) {
              resultToken.id = result[0].staffMember.id;
              resultToken.staffMember = result[0].staffMember.id;
              resultToken.children = [];
              resultToken.permissions.push('updateStaffMember:' + result[0].staffMember.id);
              for (let item of result) {
                if (item.child) {
                  resultToken.children.push(item.child.id);
                  resultToken.permissions.push(
                    'child:' + item.child.id + ':childChecklistAssessment',
                    'child:' + item.child.id + ':childChildIndividualizedPlan',
                    'child:' + item.child.id + ':childIndividualizedPlanSession',
                    'child:' + item.child.id + ':createChildIndividualizedPlanSession',
                    'child:' + item.child.id + ':deleteChildIndividualizedPlanSession:*',
                    'child:' + item.child.id + ':updateChildIndividualizedPlanSession:*',
                  );
                  if (item.staffAttribution) {
                    // Supervisors
                    if (
                      item.staffAttribution.title === 'Main Supervisor' ||
                      item.staffAttribution.title === 'Supervisor'
                    ) {
                      resultToken.permissions.push(
                        'staffMember',
                        'createStaffMember',
                        'updateStaffMember:*',
                        'child',
                        'createChild',
                        'updateChild:*',
                        'child:' + item.child.id + ':createChildChecklistAssessment',
                        'child:' + item.child.id + ':deleteChildChecklistAssessment:*',
                        'child:' + item.child.id + ':updateChildChecklistAssessment:*',
                        'child:' + item.child.id + ':createChildIndividualizedPlan',
                        'child:' + item.child.id + ':deleteChildIndividualizedPlan:*',
                        'child:' + item.child.id + ':updateChildIndividualizedPlan:*',
                        'child:' + item.child.id + ':updateChildIndividualizedPlanSessionSteps',
                      );
                    }
                  }
                }
              }
            } else {
              resultToken.children = [];
              resultToken.staffMember = result.id;
              resultToken.permissions.push('updateStaffMember:' + result.id);
            }
            setLoggedUser(resultToken);
            window.localStorage.setItem('loggedUserData', JSON.stringify(resultToken));
            window.location.reload();
          } else {
            getRelativeByEmail(email, password, (result, resultToken, err) => {
              if (err) {
                errorCallback(t('Error authenticating user'));
              } else if (result) {
                // relatives
                resultToken.permissions = ['child'];
                resultToken.role = 'relatives';
                resultToken.id = result[0].person.id;
                resultToken.children = [];
                for (let item of result) {
                  resultToken.children.push(item.child.id);
                  if (item.child) {
                    resultToken.permissions.push(
                      'child:' + item.child.id + ':childChecklistAssessment',
                      'child:' + item.child.id + ':childChildIndividualizedPlan',
                      'child:' + item.child.id + ':childIndividualizedPlanSession',
                    );
                  }
                }
                setLoggedUser(resultToken);
                window.localStorage.setItem('loggedUserData', JSON.stringify(resultToken));
                window.location.reload();
              } else {
                errorCallback(t('User not found for e-mail') + ': ' + email);
              }
            });
          }
        });
      }
    });
  };
  return {
    loggedUser,
    authenticate,
  };
};
