import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { getInterventionSessions } from '../services/interventionSession';
import useUserContext from '../useUserContext';
import { CalendarForSessionButton } from './CalendarForSessionButton';

export function NewSessionButton({ childId, leftAlign = false, btnStyleSecondary = false, onNavbar = false }) {
  const [shouldRender, setShouldRender] = useState(false);
  const [sessionsList, setSessionsList] = useState([]);
  const { t } = useTranslation();
  const { loggedUser } = useUserContext();
  const editModeURL = loggedUser.role !== 'relatives' ? '?mode=edit' : '';

  const showCalendar = () => {
    if (shouldRender) return;
    setShouldRender(true);
    return;
  };

  const formatDate = (date) => moment(date).format(t('MM/DD/YYYY'));

  const createNewSessionModal = (selectedDate) =>
    window.Swal.fire({
      title: `${t('Create new session on day')} ${formatDate(selectedDate)}?`,
      html: `<input type="time" id="time" value="${new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}" class="swal2-input">
      `,
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const time = window.Swal.getPopup().querySelector('#time').value;
        return { time };
      },
      cancelButtonText: t('Cancel'),
      confirmButtonText: t('Create new session'),
    }).then((res) => {
      if (res.dismiss) return;
      if (res.value) {
        if (!res.value.time)
          return window.Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: t('Invalid time'),
          });
        const { time } = res.value;
        const newSessionDate = new Date(selectedDate);
        newSessionDate.setHours(time.split(':')[0], time.split(':')[1]);
        window.location.href = `/children/${childId}/session/new?date=${new Date(newSessionDate).toISOString()}`;
      }
    });

  const loadInterventions = () => {
    if (sessionsList.length !== 0) return;
    getInterventionSessions(childId, (result, err) => {
      if (sessionsList.length > 0) return;
      if (err) {
        console.error(err);
        return;
      }
      setSessionsList(result);
    });
  };

  const sessionMap = {};
  sessionsList.forEach((s) => {
    const dateString = formatDate(s.checkinAt);
    s.dataSessao = new Date(s.checkinAt);
    if (!sessionMap[dateString]) {
      sessionMap[dateString] = [s];
    } else {
      sessionMap[dateString].push(s);
    }
  });

  const openSelectModal = (dateString, listForDay, selectedDate) => {
    const list = listForDay.map(
      (s, idx) =>
        `<li><a href="/children/${childId}/session/${s.id}${editModeURL}"><h4>${t('Session')} ${idx + 1}</h4></a></li>`,
    );
    window.Swal.fire({
      title: '<strong>' + t('Which Session of') + ' ' + dateString + '?</strong>',
      icon: 'info',
      html: '<ul> ' + list.join('') + '</ul>',
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      focusConfirm: false,
      confirmButtonText: t('Create new session'),
    }).then((res) => {
      if (res.value) {
        createNewSessionModal(selectedDate);
      }
    });
  };

  const handleSelectDate = (selectedDate) => {
    const dateString = formatDate(selectedDate);
    if (sessionMap[dateString]) {
      openSelectModal(
        dateString,
        sessionMap[dateString].sort((a, b) => a.dataSessao.getTime() - b.dataSessao.getTime()),
        selectedDate,
      );
    } else {
      createNewSessionModal(selectedDate);
    }
  };

  return (
    <div className="position-relative w-100" onMouseEnter={() => showCalendar()} style={{ maxWidth: '280px' }}>
      <button
        className={`btn d-flex btn-icon-text align-items-center ${
          btnStyleSecondary ? 'btn-outline-secondary' : 'btn-primary'
        }`}
        type="button"
        data-toggle="collapse"
        data-target={`#newSessionCalendar-${childId}`}
        aria-expanded="false"
        aria-controls="collapseExample"
        onClick={() => loadInterventions()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-briefcase btn-icon-prepend"
        >
          <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
          <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
        </svg>
        {t('New intervention session')}
      </button>
      {shouldRender ? (
        <CalendarForSessionButton
          onNavbar={onNavbar}
          childId={childId}
          handleSelectDate={handleSelectDate}
          leftAlign={leftAlign}
        />
      ) : null}
    </div>
  );
}
