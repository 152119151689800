import React from 'react';
import { useTranslation } from 'react-i18next';

import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';

import logo from '../../../logo.png';
import { getNumberFromSkillCode, getNumberFromStepCode } from '../../../utils/legacy';

const styles = StyleSheet.create({
  page: { padding: 30 },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 100,
  },
  logo: { width: 70, height: 70 },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  infoCointaner: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  infoDef: {
    fontSize: 14,
  },
  logoContainer: { display: 'flex', flexDirection: 'column' },
  infoValue: { fontSize: 14 },
  skillDef: { fontSize: 14 },
  titleContainer: { fontSize: 21, marginTop: 10, marginBottom: 10 },
  skillsWrapper: { marginTop: -100 },
  skillContainer: { padding: 5 },
  stepContainer: { paddingLeft: 15, paddingVertical: 2 },
  step: { fontSize: 12, color: 'red' },
  devDomain: { fontSize: 8 },
  domainContainer: { paddingVertical: 5 },
  url: { fontSize: 9, color: 'grey', marginBottom: 8 },
});

export const ChildResumePdf = ({ data, resolveSessionsObjectiveSteps, individualizedPlan }) => {
  const { individualPlans, childName, date } = data;
  const { t } = useTranslation();
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.headerContent}>
          <View style={styles.logoContainer}>
            <Image src={logo} alt="images" style={styles.logo} />
          </View>
          <View>
            <View style={styles.infoCointaner}>
              <Text style={styles.infoDef}>Data: </Text>
              <Text style={styles.infoValue}>{date} </Text>
            </View>
            <View style={styles.infoCointaner}>
              <Text style={styles.infoDef}>Criança: </Text>
              <Text style={styles.infoValue}>{childName} </Text>
            </View>
          </View>
        </View>
        <View style={styles.skillsWrapper}>
          <Text style={styles.url}>www.earlydenver.com.br</Text>
          <Text style={styles.titleContainer}>Plano de Ensino Individualizado</Text>

          {individualPlans.map((item) => {
            const steps = resolveSessionsObjectiveSteps(
              item,
              individualizedPlan.sessions,
              individualizedPlan.skillSteps,
            );
            const currentStep = steps.find((step) => !step.achievementDate);
            const currentStepIndex = currentStep?.id ?? null;

            return (
              <View key={item.id} style={styles.skillContainer}>
                <View style={styles.domainContainer}>
                  <Text style={styles.devDomain}>{item.developmentalDomain.title.toUpperCase()}</Text>
                </View>

                <Text style={styles.skillDef}>
                  {t('LEVEL_SINGLE_LETTER')}
                  {item.level}.{getNumberFromSkillCode(item.code)}&nbsp;—&nbsp;
                  {item.title}
                </Text>
                {steps.map((step) => (
                  <View key={step.id} style={styles.stepContainer}>
                    <Text
                      style={[
                        styles.step,
                        {
                          color: !!currentStepIndex && currentStepIndex === step.id ? 'red' : 'grey',
                        },
                      ]}
                    >
                      {item.level}.{getNumberFromSkillCode(item.code)}.
                      {getNumberFromStepCode(
                        step.denverChecklistSkillAssessmentStep
                          ? step.denverChecklistSkillAssessmentStep.code
                          : step.code,
                      )}
                      &nbsp;—&nbsp;
                      {step.denverChecklistSkillAssessmentStep
                        ? step.denverChecklistSkillAssessmentStep.description
                        : step.description}
                    </Text>
                  </View>
                ))}
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};
