import config from '../config';

export const createNewAccount = async (name, email, password, responseCallback) => {
  const response = await fetch(config.shieldURL + '/user/' + email, {
    method: 'PUT',
    body: JSON.stringify({ name: name, password: password }),
    headers: { 'Content-Type': 'application/json' },
  });
  responseCallback(response);
};

export const accountActivate = async (token, responseCallback) => {
  const response = await fetch(config.shieldURL + '/token', {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token },
  });
  if (response.status !== 200) {
    responseCallback(response);
  }
  const responseJson = await response.json();
  //console.log(JSON.stringify(responseJson))
  const response1 = await fetch(config.shieldURL + '/user/' + responseJson.sub + '/activate', {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + token },
  });
  responseCallback(response1);
};

export const loginPasswordClick = async (email, password, responseCallback) => {
  var err = await createTokenPassword(email, password);
  const token = getToken();
  if (err == null && token !== null) {
    responseCallback(token, err);
  } else {
    responseCallback(null, err);
  }
};
export const createTokenPassword = async (email, password) => {
  const response = await fetch(config.shieldURL + '/token', {
    method: 'POST',
    body: JSON.stringify({ email: email, password: password }),
    headers: { 'Content-Type': 'application/json' },
  });
  if (response.status !== 200) {
    return 'Invalid user/password combination';
  } else {
    const responseJson = await response.json();
    window.localStorage.setItem('token', JSON.stringify(responseJson));
    return null;
  }
};
export function getToken() {
  const t = localStorage.getItem('token');
  if (t !== null) {
    return JSON.parse(localStorage.getItem('token'));
  }
  return null;
}

export const passwordReset = async (email, responseCallback) => {
  const response = await fetch(config.shieldURL + '/user/' + email + '/password-reset-request', {
    method: 'POST',
  });
  responseCallback(response);
};
export const changePassword = async (token, newPassword, responseCallback) => {
  const response = await fetch(config.shieldURL + '/token', {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token },
  });
  if (response.status !== 200) {
    responseCallback(response);
  }
  const responseJson = await response.json();
  const response1 = await fetch(config.shieldURL + '/user/' + responseJson.sub + '/password-reset-change', {
    method: 'POST',
    body: JSON.stringify({ password: newPassword }),
    headers: { Authorization: 'Bearer ' + token },
  });
  responseCallback(response1);
};

export const refreshToken = async () => {
  const loggedUser = JSON.parse(window.localStorage.getItem('loggedUserData'));

  const token = loggedUser;
  if (token == null) console.log('Token not found');
  const response = await fetch(config.shieldURL + '/token/refresh', {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + token.refreshToken },
  });
  if (response.status !== 200) {
    delete window.localStorage['loggedUserData'];
    console.error('Could not refresh token. err=' + (await response.status));
    return (window.location.href = '/');
  }
  const responseJson = await response.json();

  if (loggedUser.children) responseJson.children = loggedUser.children;
  if (loggedUser.permissions) responseJson.permissions = loggedUser.permissions;
  if (loggedUser.staffMember) responseJson.staffMember = loggedUser.staffMember;
  if (loggedUser.id) responseJson.id = loggedUser.id;
  if (loggedUser.adminId) responseJson.adminId = loggedUser.adminId;
  if (loggedUser.role) responseJson.role = loggedUser.role;

  window.localStorage.setItem('loggedUserData', JSON.stringify(responseJson));
  window.localStorage.setItem('token', JSON.stringify(responseJson));
};

export const changePasswordForm = async (email, newPassword, responseCallback) => {
  // const loggedUser = JSON.parse(window.localStorage.getItem("loggedUserData"));
  const response = await fetch(config.shieldURL + '/user/' + email + '/password-change-form', {
    method: 'POST',
    body: JSON.stringify({ password: newPassword }),
  });
  responseCallback(response);
};

export const deleteAccount = async (email, responseCallback) => {
  const response = await fetch(config.shieldURL + '/user/' + email + '/delete', {
    method: 'POST',
    body: JSON.stringify({ email: email }),
  });
  responseCallback(response);
};

// -------------------------------------------------------------------------

/*
function removeToken() {
    return localStorage.removeItem("token")
}

function keepTokenRefreshed() {
    const t = getToken()
    if(t==null || !t.accessTokenExpiration) {
        console.log('Could not get token for auto refresh')
        return
    }
    const diff = Date.parse(t.accessTokenExpiration) - new Date().getTime()
    // const diff = 120000 + 10000
    console.log("Next token refresh scheduled in " + (diff-120000) + "ms")
    window.setTimeout(doRefresh, Math.max(diff-120000, 0))
}

async function doRefresh() {
    console.log("Refreshing token")
    const err = await refreshToken()
    if(err!==null) {
        console.log("Error refreshing token. err=" + err)
    }
    console.log("Token refreshed successfully")

    const t = getToken()
    const diff = Date.parse(t.accessTokenExpiration) - new Date().getTime()
    // const diff = 120000 + 10000
    console.log("Next token refresh scheduled in " + (diff-120000) + "ms")
    window.setTimeout(doRefresh, Math.max(diff-120000, 0))
}
*/

/*
export const createTokenGoogle = async(googleAuthCode) => {
    const response = await fetch(config.shieldURL + '/token',
        {
            method: 'POST',
            body: JSON.stringify({ 'googleAuthCode': googleAuthCode }),
            headers: { 'Content-Type': 'application/json' }
        }
    );
    if (response.status !== 200) {
        return "Invalid Google token"
    } else {
        const responseJson = await response.json();
        localStorage.setItem('token', JSON.stringify(responseJson));
        return null
    }
}
export const createTokenFacebook = async(facebookToken) => {
    const response = await fetch(config.shieldURL + '/token',
        {
            method: 'POST',
            body: JSON.stringify({ 'facebookToken': facebookToken }),
            headers: { 'Content-Type': 'application/json' }
        }
    );
    if (response.status !== 200) {
        return "Invalid Facebook token"
    } else {
        const responseJson = await response.json();
        localStorage.setItem('token', JSON.stringify(responseJson));
        return null
    }
}
*/
