import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { getAllDevelopmentalDomains } from '../../services/denver';
import { convertFromHtmlToDoc } from '../../utils/convertFromHtmlToDoc';
import LazyDownloadDocButtonModel from './LazyDownloadDocButtonModel';

export const LazyDownloadDocButton = ({ child, checklistAssessments, annotations }) => {
  const { buildingDOCX, setReportParams } = LazyDownloadDocButtonModel(child);
  const { t } = useTranslation();
  const checklists = checklistAssessments.reduce((result, item) => {
    result[item.id] = moment(item.date).format(t('MM/DD/YYYY'));
    return result;
  }, {});

  const showChooseModal = () => {
    const annotationsHtml = annotations
      .map((item) => {
        return `
          <div class="card mt-1 annotation" key=${item.id}>
            <blockquote>
              <div class="pt-3 pl-3 pr-3 pb-1 text-justify"> 
                <input type="checkbox" class="annotation-checkbox" id="${item.id}" />
                ${convertFromHtmlToDoc(item.anottation).slice(0, 200)}
              </div>
              <footer class="blockquote-footer text-justify ml-3">
                <small>
                  ${t('created-at')} ${' '}
                  <span>
                    ${moment(item.createdAt).format('HH:mm:ss')} ${t('of')} ${' '}
                    ${moment(item.createdAt).format('DD/MM/YYYY')}
                  </span> ${' '}
                  ${t('by')} <strong style={{ color: 'black' }}> ${item.attendant.person.name} </strong> ${' '}
                </small>
              </footer>
            </blockquote>
          </div>`;
      })
      .join('');

    const annotationsContainer = `
      <div class="annotations-container">
        ${annotationsHtml}
      </div>`;

    window.Swal.fire({
      width: '800px',
      showCloseButton: true,
      showCancelButton: true,
      title: t('report-generator-modal-title-annotations'),
      html: annotationsContainer,
      preConfirm: () => {
        const checked = [...document.querySelectorAll('.annotation-checkbox')]
          .filter((item) => item.checked)
          .map((item) => item.id);
        const annotationsToReport = annotations.filter((item) => checked.includes(item.id));
        setReportParams((prevParams) => ({
          ...prevParams,
          annotations: annotationsToReport,
        }));
      },
    }).then((_result) => {
      window.Swal.fire({
        showCloseButton: true,
        showCancelButton: true,
        title: t('Choose the first checklist'),
        input: 'select',
        inputValue: Object.keys(checklists)[0],
        inputOptions: checklists,
        inputPlaceholder: t('Choose the first checklist'),
      }).then((value) => {
        const isCancel = value?.dismiss === 'cancel';

        getAllDevelopmentalDomains((dds) => {
          const tableRows = dds
            .map(
              (dd) => `<tr>
              <td class="text-right"><span class="cursor-pointer" onclick='const isChkd = [...document.querySelectorAll(".input-${dd.code}")].map(e => e.checked).reduce((a,b) => a && b);document.querySelectorAll(".input-${dd.code}").forEach(x => x.checked=!isChkd)'>${dd.title}</span></td>
              <td><input type="checkbox" class="transform-scale-2 input-level1 input-${dd.code}" name="level1"></td>
              <td><input type="checkbox" class="transform-scale-2 input-level2 input-${dd.code}" name="level2"></td>
              <td><input type="checkbox" class="transform-scale-2 input-level3 input-${dd.code}" name="level3"></td>
              <td><input type="checkbox" class="transform-scale-2 input-level4 input-${dd.code}" name="level4"></td>
            </tr>`,
            )
            .join('');

          const headerLevel = [1, 2, 3, 4]
            .map(
              (h) =>
                `<th><span class="cursor-pointer" onclick='const isChkd = [...document.querySelectorAll(".input-level${h}")].map(e => e.checked).reduce((a,b) => a && b);document.querySelectorAll(".input-level${h}").forEach(x => x.checked=!isChkd)'>${t(
                  'Level',
                )} ${h}</span></th>`,
            )
            .join('');

          const tableSelectLevelDomain = `
          <table class="table">
            <thead>
              <tr>
                <th></th>
                ${headerLevel}
              </tr>
            </thead>
            <tbody>
              ${tableRows}
            </tbody>
          </table>`;

          if (!isCancel && Object.keys(checklists).length === 1) {
            window.Swal.fire({
              width: '800px',
              title: t('Choose levels'),
              showCancelButton: true,
              html: tableSelectLevelDomain,
              focusConfirm: false,
              preConfirm: () => {
                let selectedDomainsAndLevels = {};
                dds.forEach((dd) =>
                  Object.assign(selectedDomainsAndLevels, {
                    [dd.code]: [1, 2, 3, 4].map(
                      (k) => document.querySelector(`.input-level${k}.input-${dd.code}`).checked,
                    ),
                  }),
                );

                setReportParams((prevParams) => ({
                  ...prevParams,
                  selectedDomainsAndLevels,
                  selectedChecklists: [checklistAssessments.find((ca) => ca.id === value.value)],
                }));
              },
            });
          } else {
            if (!isCancel) {
              const reportChecklistSecondChoose = { ...checklists };
              delete reportChecklistSecondChoose[value.value];

              window.Swal.fire({
                showCancelButton: true,
                title: t('Choose the second checklist'),
                input: 'select',
                inputValue: Object.keys(reportChecklistSecondChoose)[0],
                inputOptions: reportChecklistSecondChoose,
                inputPlaceholder: t('Choose the second checklist'),
              }).then((secondValue) => {
                const isCancelSecond = secondValue?.dismiss === 'cancel';

                if (!isCancelSecond) {
                  window.Swal.fire({
                    width: '800px',
                    title: t('Choose levels'),
                    showCancelButton: true,
                    html: tableSelectLevelDomain,
                    focusConfirm: false,
                    preConfirm: () => {
                      let selectedDomainsAndLevels = {};
                      dds.forEach((dd) =>
                        Object.assign(selectedDomainsAndLevels, {
                          [dd.code]: [1, 2, 3, 4].map(
                            (k) => document.querySelector(`.input-level${k}.input-${dd.code}`).checked,
                          ),
                        }),
                      );
                      setReportParams((prevParams) => ({
                        ...prevParams,
                        selectedDomainsAndLevels,
                        selectedChecklists: [
                          checklistAssessments.find((ca) => ca.id === value.value),
                          checklistAssessments.find((ca) => ca.id === secondValue.value),
                        ],
                      }));
                    },
                  });
                }
              });
            }
          }
        });
      });
    });
  };

  const showAlertInfoModal = () => {
    return window.Swal.fire({
      title: t('Error!'),
      text: t('There`s no checklist'),
      icon: 'error',
      confirmButtonText: 'OK',
    });
  };

  return (
    <>
      <button
        onClick={async () => {
          if (Object.keys(checklists).length > 0) {
            showChooseModal();
          } else {
            showAlertInfoModal();
          }
        }}
        className="btn btn-secondary"
        type="button"
        data-toggle="collapse"
        data-target="#comment"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        {buildingDOCX ? t('Building DOCX') + '...' : t('Export DOCX')}
      </button>
    </>
  );
};
