import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { getChecklists } from '../../../services/checklistAssessment';
import {
  createStaffAssignment,
  get,
  getAllAnotattionFromChildren,
  getFilteredAnotattionFromChildren,
  removeStaffAssignment,
} from '../../../services/children';
import { graphQLRequest } from '../../../services/common';
import { getIndividualizedPlans } from '../../../services/individualizedPlan';
import { getInterventionSessions } from '../../../services/interventionSession';
import { getAllStaffMembers, getStaffAttributions } from '../../../services/staff';
import useUserContext from '../../../useUserContext';

export default () => {
  const { id } = useParams();

  const [currentId] = useState(id && id !== 'new' ? id : null);
  const [child, setChild] = useState('');
  const [childDateOfBirth, setChildDateOfBirth] = useState('');

  const [staffAssignments, setStaffAssignments] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [fixedAnnotations, setFixedAnnotations] = useState([]);
  const [checklistAssessments, setChecklistAssessments] = useState([]);
  const [individualizedPlans, setIndividualizedPlans] = useState([]);
  const [interventionSessions, setInterventionSessions] = useState([]);

  const [staffAttributions, setStaffAttributions] = useState([]);
  const [currentStaffAttribution, setCurrentStaffAttribution] = useState({ id: '', title: '' });

  const [staffMembers, setStaffMembers] = useState([]);
  const [currentStaffMember, setCurrentStaffMember] = useState({ id: '', name: '' });
  const { loggedUser } = useUserContext();

  const { t } = useTranslation();

  useEffect(() => {
    if (!currentId) {
      getAllStaffMembers((result, err) => {
        if (!err) {
          setStaffMembers(result);
        }
      });
    } else {
      get(currentId, (child) => {
        if (child) {
          const { role } = loggedUser;
          const staffProf = child.staffAssignments.find((sa) => sa.staffMember.id === loggedUser.id);

          setChild(child);
          setChildDateOfBirth(child.person.dateOfBirth);
          setStaffAssignments(child.staffAssignments);

          if (role === 'admin' || staffProf?.staffAttribution?.title === 'Supervisor') {
            getAllAnotattionFromChildren(currentId, (res) => {
              setAnnotations(res?.data?.childAnotattionses ?? []);
              setFixedAnnotations(res?.data?.childAnotattionses.filter((annotation) => annotation.fixed));
            });
          } else if (role === 'relatives') {
            getFilteredAnotattionFromChildren({ id: child.id, domainReached: role }, (res) => {
              setAnnotations(res?.data?.childAnotattionses ?? []);
              setFixedAnnotations(res?.data?.childAnotattionses.filter((annotation) => annotation.fixed));
            });
          } else {
            getFilteredAnotattionFromChildren(
              { id: child.id, domainReached: staffProf?.staffAttribution?.title },
              (res) => {
                setAnnotations(res?.data?.childAnotattionses ?? []);
                setFixedAnnotations(res?.data?.childAnotattionses.filter((annotation) => annotation.fixed));
              },
            );
          }
          getAllStaffMembers((result, err) => {
            if (!err) {
              // filter only not yet assigned professionals
              setStaffMembers(
                result.filter(
                  (smr) => !child.staffAssignments.some((sa) => sa.staffMember.person.id === smr.person.id),
                ),
              );
              setCurrentStaffMember(
                result.filter(
                  (smr) => !child.staffAssignments.some((sa) => sa.staffMember.person.id === smr.person.id),
                )[0],
              );
            } else {
              console.error('Error fetching Staff Members:', err);
            }
          });
          getChecklists(currentId, (result, err) => {
            if (!err) {
              setChecklistAssessments(result);
            } else {
              console.error('Error fetching Child Checklists:', err);
            }
          });

          getIndividualizedPlans(currentId, (result, err) => {
            if (!err) {
              setIndividualizedPlans(result);
            } else {
              console.error('Error fetching Child Individualized Plans:', err);
            }
          });
          getInterventionSessions(currentId, (result, err) => {
            if (!err) {
              setInterventionSessions(result);
            } else {
              console.error('Error fetching Child Intervention Sessions:', err);
            }
          });
        }
      });
    }
    getStaffAttributions((staffAttributions, err) => {
      if (!err) {
        setStaffAttributions(staffAttributions);
        setCurrentStaffAttribution(staffAttributions[0]);
      } else {
        console.error('Error fetching Staff Attributions:', err);
      }
    });
  }, [currentId, loggedUser]);

  const assignStaff = () => {
    createStaffAssignment(currentId, currentStaffMember.id, currentStaffAttribution.id, (_, err) => {
      if (err) {
        console.error(err);
        return;
      }

      // refresh the current child form
      get(currentId, (child, err2) => {
        if (err2) {
          console.error(err2);
          return;
        }

        setChild(child);
        setStaffAssignments(child.staffAssignments);
        getAllStaffMembers((result, err) => {
          if (err) {
            console.error(err);
            return;
          }

          // filter only not yet assigned professionals
          setStaffMembers(
            result.filter((smr) => !child.staffAssignments.some((sa) => sa.staffMember.person.id === smr.person.id)),
          );
        });
      });
    });
  };

  const unassignStaff = (assignmentId, callback) => {
    removeStaffAssignment(assignmentId, (_, err) => {
      if (!err) {
        callback();
        // refresh the current child form
        get(currentId, (child, err2) => {
          if (!err2) {
            setChild(child);
            setStaffAssignments(child.staffAssignments);
            getAllStaffMembers((result, err) => {
              if (!err) {
                // filter only not yet assigned professionals
                setStaffMembers(
                  result.filter(
                    (smr) => !child.staffAssignments.some((sa) => sa.staffMember.person.id === smr.person.id),
                  ),
                );
              }
            });
          }
        });
      } else {
        callback(err);
      }
    });
  };

  const unpinAnnotation = (anottation) => {
    const fixed = anottation.fixed;
    const graphQL = {
      query: ` mutation {
        updateChildAnotattions (
            data: {
                fixed: ${!fixed}
            }
            where: {
                id: "${anottation.id}"
            }
        ){
            id
        }
    }`,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error('error fixing note', err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('fix-anottation-error'),
        });
      } else {
        setAnnotations((ann) => ann.filter((a) => a.id !== result.data.updateChildAnotattions.id));
        setFixedAnnotations((ann) => ann.filter((a) => a.id !== result.data.updateChildAnotattions.id));
      }
    });
  };

  return {
    currentId,
    child,
    childDateOfBirth,
    staffAssignments,
    checklistAssessments,
    individualizedPlans,
    interventionSessions,

    staffAttributions,
    currentStaffAttribution,
    setCurrentStaffAttribution,

    staffMembers,
    currentStaffMember,
    setCurrentStaffMember,

    assignStaff,
    unassignStaff,
    annotations,
    fixedAnnotations,
    unpinAnnotation,
  };
};
