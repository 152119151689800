import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccessControl from '../../../../AccessControl';
import useChildRelativesFormModel from './useChildRelativesFormModel';

export function ChildRelativesForm() {
  const { t } = useTranslation();

  const {
    id,
    name,
    setName,
    password,
    setPassword,
    phone,
    setPhone,
    email,
    setEmail,
    gender,
    setGender,
    relationship,
    setRelationship,
    familyRelationshipsTypes,

    familyMembers,

    save,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    removeRelative,

    currentRelativeId,
    setCurrentRelativeId,
  } = useChildRelativesFormModel();

  // component did mount
  useEffect(() => {
    window.jQuery(':input').inputmask();
    window.$('[data-toggle="tooltip]').tooltip();
    window.jQuery.validator.addMethod(
      'oneChoiceRequired',
      (_) => {
        return gender !== 'NotInformed';
      },
      t('Please choose one of the options'),
    );

    window.jQuery('#relativeRegisterForm').validate({
      rules: {
        fullNameRelative: {
          required: true,
          minlength: 3,
        },
        phoneRelative: {
          required: true,
        },
        emailRelative: {
          email: true,
        },
        passwordRelative: {
          required: false,
        },
        genderRelative: {
          oneChoiceRequired: true,
        },
      },
      messages: {
        fullNameRelative: {
          required: t('Please enter a name'),
          minlength: t('X must consist of at least Y characters', {
            x: 'Name',
            y: '3',
          }),
        },
        phoneRelative: {
          required: t('Please enter a phone number'),
        },
        emailRelative: {
          required: t('Please enter an email'),
        },
        passwordRelative: {
          required: t('Please enter an password'),
        },
        genderRelative: {
          oneChoiceRequired: t('Please choose one of the options'),
        },
      },
      errorPlacement: function (label, element) {
        label.addClass('mt-2 text-danger');
        label.insertAfter(element);
      },
      highlight: function (element, errorClass) {
        window.jQuery(element).parent().addClass('has-danger');
        window.jQuery(element).addClass('form-control-danger');
      },
    });
  }, [t, gender, currentRelativeId]);

  return (
    <>
      <h6 className="card-title">{t('Family Members')}</h6>
      {familyMembers && (
        <ul className="carded-list mb-4 mt-3">
          {familyMembers.map((f, idx) => (
            <li key={idx}>
              <div className="carded-list-group">
                <span className="fake-link actionable mr-1" onClick={(_) => setCurrentRelativeId(f.id)}>
                  {f.person.name}
                </span>
                / {f.person.phone}{' '}
                <span style={{ color: '#999' }} className="ml-1">
                  / ({t(f.relationship.title)})
                </span>
                <AccessControl requiredPermissions={['updateChild:*', 'updateChild:' + id]}>
                  <i
                    className="mdi mdi-delete actionable"
                    onClick={(_) => removeRelative(f.id, f.person.email)}
                    title={t('Remove')}
                  ></i>
                </AccessControl>
              </div>
            </li>
          ))}
        </ul>
      )}

      <AccessControl requiredPermissions={['updateChild:*', 'updateChild:' + id]}>
        {!currentRelativeId && (
          <button
            className={`btn btn-primary mt-2`}
            type="button"
            data-toggle={'collapse'}
            data-target="#addNewFamilyMember"
            aria-expanded="false"
            aria-controls="addNewFamilyMember"
          >
            {t('Add new family number')}
          </button>
        )}
        <div className={(!currentRelativeId ? 'collapse' : '') + ' mt-4'} id="addNewFamilyMember">
          <form
            id="relativeRegisterForm"
            className="forms-child-info"
            onSubmit={(e) => {
              e.preventDefault();
              window.jQuery('#relativeRegisterForm').valid() && save();
            }}
          >
            <div className="form-group">
              <label htmlFor="fullNameRelative">{t('Full name')}</label>
              <input
                id="fullNameRelative"
                name="fullNameRelative"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                required
                type="text"
                className="form-control"
                autoComplete="off"
                placeholder={t('placeholder.Barbara Russel')}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phoneRelative">{t('Phone number')}</label>
              {!phone && (
                <input
                  id="phoneRelative"
                  name="phoneRelative"
                  defaultValue={phone}
                  onBlur={(e) => setPhone(e.target.value)}
                  className="form-control mb-4 mb-md-0"
                  data-inputmask-alias={t('(999) 9999-9999')}
                  im-insert="true"
                />
              )}
              {phone && (
                <input
                  id="phoneRelative"
                  name="phoneRelative"
                  defaultValue={phone}
                  onBlur={(e) => setPhone(e.target.value)}
                  className="form-control mb-4 mb-md-0"
                  data-inputmask-alias={t('(999) 9999-9999')}
                  im-insert="false"
                />
              )}
            </div>

            <div className="form-group">
              <label htmlFor="emailRelative">E-mail</label>
              {!currentRelativeId && (
                <input
                  id="emailRelative"
                  name="emailRelative"
                  type="email"
                  defaultValue={email}
                  onBlur={(e) => setEmail(e.target.value)}
                  className="form-control"
                  autoComplete="off"
                  placeholder="_@_._"
                  im-insert="true"
                />
              )}
              {currentRelativeId && (
                <input
                  id="emailRelative"
                  name="emailRelative"
                  type="text"
                  className="form-control"
                  disabled
                  value={email}
                />
              )}
            </div>

            <div className="form-group">
              {!currentRelativeId && <label htmlFor="passwordRelative">{t('Password')}</label>}
              {currentRelativeId && <label htmlFor="passwordRelative">{t('New Password')}</label>}
              <input
                id="passwordRelative"
                name="passwordRelative"
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control"
                autoComplete="off"
                placeholder={t('Password')}
              />
            </div>

            <div className="form-group">
              <label htmlFor="genderRelative">{t('Man or Woman?')}</label>
              <div>
                <button
                  type="button"
                  onClick={(_) => setGender('MALE')}
                  className={'btn ' + (gender === 'MALE' ? 'btn-info' : 'btn-light') + ' mr-2 boy-button '}
                >
                  <i className="mdi mdi-human-male side-icon"></i> {t('Man')}
                </button>
                <button
                  type="button"
                  onClick={(_) => setGender('FEMALE')}
                  className={'btn ' + (gender === 'FEMALE' ? 'btn-danger' : 'btn-light') + ' mr-2 girl-button '}
                >
                  <i className="mdi mdi-human-female side-icon"></i> {t('Woman')}
                </button>
              </div>

              {/** This input is just to anchor the jQuery validation message  */}
              <input
                id="genderRelative"
                defaultValue={gender}
                name="genderRelative"
                style={{ width: 0, height: 0, opacity: 0, display: 'block' }}
              />
            </div>

            <div className="form-group">
              <label htmlFor="familyRelationship">{t('family.Relationship')}</label>
              <div>
                <div className="btn-group">
                  <button
                    className="btn btn-light dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t(relationship.title)}
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {familyRelationshipsTypes &&
                      familyRelationshipsTypes.length > 0 &&
                      familyRelationshipsTypes.map((fr) => (
                        <span onClick={() => setRelationship(fr)} key={fr.id} className="dropdown-item actionable">
                          {' '}
                          {t(fr.title)}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {formSubmissionMessage && (
              <div className="form-group">
                {formSubmissionMessageType === 'success' && (
                  <div className="alert alert-icon-success flex-rss" role="alert">
                    <i className="mdi mdi-check-circle-outline mr-1"></i>
                    {formSubmissionMessage}
                  </div>
                )}

                {formSubmissionMessageType === 'fail' && (
                  <div className="alert alert-icon-danger flex-rss" role="alert">
                    <i className="mdi mdi-check-circle-outline mr-1"></i>
                    {formSubmissionMessage}
                  </div>
                )}
              </div>
            )}

            {!submitting && (
              <button type="submit" className="btn btn-primary mr-2">
                {t('Save')}
              </button>
            )}
            {submitting && (
              <button type="submit" disabled className="btn btn-primary mr-2">
                <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                {t('Submitting') + '...'}
              </button>
            )}
            <button
              type="button"
              className="btn btn-light"
              onClick={(_) => {
                window.jQuery('#addNewFamilyMember').collapse('hide');
                setCurrentRelativeId(null);
              }}
            >
              {t('Cancel')}
            </button>
          </form>
        </div>
      </AccessControl>
    </>
  );
}
