import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  denverDevelopmentalDomains,
  currentDevelopmentalDomainFilter,
  setCurrentDevelopmentalDomainFilter,
  currentLevel,
  setCurrentLevel,
  availableLevels,
  levelMode = 'inline',
  children,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {levelMode === 'inline' && (
        <div className="btn-group btn-group-sm" role="group">
          <button
            onClick={(_) => setCurrentLevel(1)}
            type="button"
            disabled={availableLevels.indexOf(1) === -1}
            className={`btn btn-${currentLevel !== 1 ? 'outline-' : ''}${
              availableLevels.indexOf(1) === -1 ? 'secondary' : 'primary'
            }`}
          >
            {t('Level')} 1
          </button>

          <button
            disabled={availableLevels.indexOf(2) === -1}
            onClick={(_) => setCurrentLevel(2)}
            type="button"
            className={`btn btn-${currentLevel !== 2 ? 'outline-' : ''}${
              availableLevels.indexOf(2) === -1 ? 'secondary' : 'primary'
            }`}
          >
            {t('Level')} 2
          </button>

          <button
            onClick={(_) => setCurrentLevel(3)}
            type="button"
            disabled={availableLevels.indexOf(3) === -1}
            className={`btn btn-${currentLevel !== 3 ? 'outline-' : ''}${
              availableLevels.indexOf(3) === -1 ? 'secondary' : 'primary'
            }`}
          >
            {t('Level')} 3
          </button>

          <button
            disabled={availableLevels.indexOf(4) === -1}
            onClick={(_) => setCurrentLevel(4)}
            type="button"
            className={`btn btn-${currentLevel !== 4 ? 'outline-' : ''}${
              availableLevels.indexOf(4) === -1 ? 'secondary' : 'primary'
            }`}
          >
            {t('Level')} 4
          </button>
        </div>
      )}
      {levelMode === 'dropdown' && (
        <div className={levelMode ? levelMode : 'visible-1024-plus'}>
          <button
            id="btnGroupDrop2"
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('Level')} {currentLevel}
          </button>
          <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
            <div>
              <button
                onClick={(_) => setCurrentLevel(1)}
                disabled={availableLevels.indexOf(1) === -1}
                type="button"
                className={`dropdown-item`}
              >
                {t('Level')} 1
              </button>
            </div>
            <div>
              <button
                onClick={(_) => setCurrentLevel(2)}
                disabled={availableLevels.indexOf(2) === -1}
                type="button"
                className={`dropdown-item`}
              >
                {t('Level')} 2
              </button>
            </div>
            <div>
              <button
                onClick={(_) => setCurrentLevel(3)}
                disabled={availableLevels.indexOf(3) === -1}
                type="button"
                className={`dropdown-item`}
              >
                {t('Level')} 3
              </button>
            </div>
            <div>
              <button
                onClick={(_) => setCurrentLevel(4)}
                disabled={availableLevels.indexOf(4) === -1}
                type="button"
                className={`dropdown-item`}
              >
                {t('Level')} 4
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="btn-group" role="group">
        <button
          id="btnGroupDrop1"
          type="button"
          className="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {t(currentDevelopmentalDomainFilter.title)}
        </button>
        <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
          {denverDevelopmentalDomains.map((dd) => {
            return (
              <button key={dd.id} className="dropdown-item" onClick={(_) => setCurrentDevelopmentalDomainFilter(dd)}>
                {dd.title}
              </button>
            );
          })}
        </div>
      </div>
      {children}
    </>
  );
};
