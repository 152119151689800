export const colors = {
  CRE: '#F94144',
  CEX: '#F8961E',
  CAC: '#3a86ff',
  IMI: '#577590',
  COG: '#ff006e',
  MFI: '#4D908E',
  MGR: '#3c096c',
  COM: '#0077B6',
  CSO: '#9d4edd',
  CSA: '#9d4edd',
  CSP: '#9d4edd',
  CAP: '#9d4edd',
  JOG: '#17c3b2',
  JOI: '#17c3b2',
  JOR: '#17c3b2',
  IND: '#F3722C',
  IPA: '#F3722C',
  IPV: '#F3722C',
  IPH: '#F3722C',
  IPT: '#F3722C',
  IPE: '#F3722C',
};
