import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { evaluateAgeInMonthsByLevelsScore, getTypicalLevelByAge } from '../../../services/denver';

function ChecklistLevelAggregatedStatsRadial({ childId, lastChecklistData }) {
  const { t } = useTranslation();
  const timer = useRef();

  if (lastChecklistData?.checklistState === 'EMPTY') {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        <Trans i18nKey="ZZLongTexts.EmptyChecklistsOnStats">
          No items evaluated in the last checklist.
          <a href={`/children/${childId}/checklist/${lastChecklistData.id}`} rel="noreferrer" target="_blank">
            Click here to view the checklist
          </a>
        </Trans>
      </div>
    );
  }
  if (!lastChecklistData?.developmentFactor) {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        {t('Loading')}
      </div>
    );
  }

  clearTimeout(timer.current);
  timer.current = setTimeout(() => {
    var options = {
      chart: {
        height: 300,
        type: 'radialBar',
      },
      series: [(lastChecklistData.developmentFactor * 100).toFixed(0)],
      plotOptions: {
        radialBar: {
          startAngle: -120,
          endAngle: 120,
          track: {
            background: '#e7e7e7',
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: '22px',
            },
          },
        },
      },
    };

    var chart = new window.ApexCharts(
      document.querySelector(`#ChecklistLevelAggregatedStatsRadial-${childId}`),
      options,
    );
    if (chart) {
      chart.render();
    }
  }, 400 + Math.random() * 1000);

  return (
    <div>
      <div className="d-flex justify-content-center mt-4" style={{ marginBottom: '-20px' }}>
        {t('Current child level')}:
        <strong className="ml-1">
          {getTypicalLevelByAge(evaluateAgeInMonthsByLevelsScore(lastChecklistData.levelsDevelopmentRatios))}
        </strong>
      </div>
      <div id={`ChecklistLevelAggregatedStatsRadial-${childId}`} style={{ minHeight: '300px' }}></div>
      <div className="d-flex justify-content-center" style={{ marginTop: '-30px' }}>
        {t('Aggregated')}
      </div>
    </div>
  );
}

export default ChecklistLevelAggregatedStatsRadial;
