import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import 'react-calendar/dist/Calendar.css';
import useUserContext from '../../../useUserContext';

export const ChildInterventionSessionsList = ({ interventionSessions, childId }) => {
  const { t } = useTranslation();
  const { loggedUser } = useUserContext();
  const [editModeURL] = useState(loggedUser.role === 'staffMember' ? '?mode=edit' : '');

  if (!interventionSessions || interventionSessions.length === 0) {
    return <div></div>;
  }

  const dates = interventionSessions.map((is) => moment(is.checkinAt).format(t('MM/DD/YYYY')));
  const sessionsSorted = interventionSessions
    .map((id) => new Date(id.checkinAt))
    .sort((a, b) => b.getTime() - a.getTime());
  let sessionMap = {};
  interventionSessions.forEach((is) => {
    const dateString = moment(is.checkinAt).format(t('MM/DD/YYYY'));
    is.dataSessao = new Date(is.checkinAt);
    if (!sessionMap[dateString]) {
      sessionMap[dateString] = [is];
    } else {
      sessionMap[dateString].push(is);
    }
  });

  const openSelectModal = (dateString, sessions) => {
    let list = sessions.map(
      (s, idx) =>
        `<li><a href="/children/${childId}/session/${s.id}${editModeURL}"><h4>${t('Session')} ${idx + 1}</h4></a></li>`,
    );
    window.Swal.fire({
      title: '<strong>' + t('Which Session of') + ' ' + dateString + '?</strong>',
      icon: 'info',
      html: '<ul> ' + list.join('') + '</ul>',
      showCloseButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      cancelButtonText: t('Close'),
    });
  };

  const onSelectDate = (date) => {
    const dateString = moment(date).format(t('MM/DD/YYYY'));
    if (sessionMap[dateString]) {
      if (sessionMap[dateString].length === 1) {
        window.location.href = `/children/${childId}/session/${sessionMap[dateString][0].id}${editModeURL}`;
      } else {
        openSelectModal(
          dateString,
          sessionMap[dateString].sort((a, b) => a.dataSessao.getTime() - b.dataSessao.getTime()),
        );
      }
    }
  };

  const lastSessionDate = sessionsSorted[0];
  return (
    <>
      <Calendar
        onClickDay={onSelectDate}
        locale="pt-BR"
        defaultValue={lastSessionDate}
        // eslint-disable-next-line no-unused-vars
        tileDisabled={({ activeStartDate, date, view }) =>
          !dates.some((dt) => dt === moment(date).format(t('MM/DD/YYYY')))
        }
      />
    </>
  );
};
