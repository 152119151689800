import {
  AlignmentType,
  BorderStyle,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
  HeadingLevel,
} from 'docx';
import moment from 'moment';

import { getChildChecklistAssessments } from '../../services/checklistAssessment';
import { getAllSkillsFiltered } from '../../services/denver';
import { margins } from './LazyButtonStyles';
import { scoreForColorsCell } from './mapValuesScore';

/**
 * Generates the punchCards for the report
 * @param child - The child ID you wish to generate the punchCards to
 * @param checkListAssessments - The checklist IDs you wish to generate the punchCards to
 * @returns All of the docx data needed to include the punchCards in the report
 */
export default async (child, checkListAssessments) => {
  // Listing all domains per level
  const levelDomains = {
    1: ['CRE', 'CEX', 'CSO', 'IMI', 'COG', 'JOG', 'MFI', 'MGR', 'COM', 'IPA', 'IPV', 'IPH', 'IPT'],
    2: ['CRE', 'CEX', 'CAC', 'CSA', 'CSP', 'IMI', 'COG', 'JOR', 'JOI', 'MFI', 'MGR', 'IPA', 'IPV', 'IPH', 'IPT'],
    3: ['CRE', 'CEX', 'CAP', 'COG', 'JOG', 'MFI', 'MGR', 'IPH', 'IPT', 'IPE'],
    4: ['CRE', 'CEX', 'CSO', 'COG', 'JOG', 'MFI', 'MGR', 'IPE'],
  };

  /**
   * Generates a single column of the punchCard, which corresponds to a domain
   * @param checklistId - the id of the checklist you want to get the data for
   * @param developmentDomainList - Gets the development Domain, in array format
   * @param levelList - Gets the level you wish to generate the punchCard to, in array format
   * @returns An array of containing the scores of the skills of a given domain and level.
   */
  async function generateColumnData(checklistId, developmentDomainList, levelList) {
    const evaluatedSkills = await getChildChecklistAssessments({
      childId: child.id,
      checklistId,
      developmentDomainList,
      levelList,
    });

    const allSkills = await getAllSkillsFiltered({
      developmentDomainCode: developmentDomainList[0],
      level: levelList,
    });

    const mergedList = await allSkills
      .map((sk) => {
        const evs = evaluatedSkills.find((es) => es.skill.code === sk.code);
        if (evs) {
          return {
            code: evs.skill.code,
            score: evs.score,
          };
        } else {
          return {
            code: sk.code,
            score: 'NOT_EVALUATED',
          };
        }
      })
      .filter((p) => !p.code.match(/CTM/));

    return mergedList;
  }

  /**
   * Generates the whole table data, which corresponds to a single level with all of its domains, in a single date.
   * @param level - the level of the punchCard
   * @returns An array containing the scores of all development domains
   */
  async function generateTabularData(domainLevels, assessment) {
    const domains = levelDomains[domainLevels];
    const data = await Promise.all(
      domains.map(async (dom) => {
        return { [dom]: await generateColumnData(assessment.id, [dom], [domainLevels]) };
      }),
    );

    // Calculate the longest array (it will be used to determine the number of rows of the table)
    const biggestDomain = Object.values(
      data.reduce((acc, curr) => {
        return Object.values(acc)[0].length > Object.values(curr)[0].length ? acc : curr;
      }),
    )[0].length;

    // Get each row of the table
    const tableData = [];
    for (let row = 0; row < biggestDomain; row++) {
      let row_data = [];
      // iterate through each column
      for (let col = 0; col < data.length; col++) {
        row_data.push(Object.values(Object.values(data)[col])[0][row]);
      }
      tableData.push(row_data);
    }

    // get the headers
    const domainHeaders = [];
    for (let keys of data) {
      domainHeaders.push(...Object.keys(keys));
    }

    const assessmentDate = assessment.date;

    return { tableData, domainHeaders, assessmentDate };
  }

  // Renders the docx table for a given tabularData
  function generateDocxTable(tabData, headers, assessmentDate, level) {
    return [
      new Paragraph(''),
      new Paragraph({
        children: [
          new TextRun({
            text: `Nível ${level} - `,
            font: 'Arial',
          }),
          new TextRun({
            text: moment(assessmentDate).format('DD/MM/YYYY'),
            bold: true,
            font: 'Arial',
          }),
        ],
      }),
      new Paragraph(''),
      new Table({
        rows: [
          new TableRow({
            children: [
              ...headers.map(
                (header) =>
                  new TableCell({
                    width: { size: 50, type: WidthType.PERCENTAGE },
                    verticalAlign: VerticalAlign.CENTER,
                    margins,
                    borders: {
                      top: { style: BorderStyle.THICK, color: 'FFFFFF' },
                      bottom: { style: BorderStyle.THICK, color: 'FFFFFF' },
                      left: { style: BorderStyle.THICK, color: 'FFFFFF' },
                      right: { style: BorderStyle.THICK, color: 'FFFFFF' },
                    },
                    shading: {
                      val: ShadingType.SOLID,
                      color: '#4DA3FB',
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [new TextRun({ text: header, font: 'Arial', bold: true, color: 'ffffff' })],
                      }),
                    ],
                  }),
              ),
            ],
          }),
          ...tabData.map(
            (row) =>
              new TableRow({
                children: [
                  ...row.map((cell) =>
                    cell
                      ? new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          margins,
                          borders: {
                            top: { style: BorderStyle.THICK, color: 'FFFFFF' },
                            bottom: { style: BorderStyle.THICK, color: 'FFFFFF' },
                            left: { style: BorderStyle.THICK, color: 'FFFFFF' },
                            right: { style: BorderStyle.THICK, color: 'FFFFFF' },
                          },
                          shading: {
                            val: ShadingType.SOLID,
                            color: scoreForColorsCell(cell.score),
                          },
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({ text: cell.code.slice(1), font: 'Arial', color: 'ffffff', size: 18 }),
                              ],
                            }),
                          ],
                        })
                      : new TableCell({
                          verticalAlign: VerticalAlign.CENTER,
                          margins,
                          borders: {
                            top: { style: BorderStyle.THICK, color: 'FFFFFF' },
                            bottom: { style: BorderStyle.THICK, color: 'FFFFFF' },
                            left: { style: BorderStyle.THICK, color: 'FFFFFF' },
                            right: { style: BorderStyle.THICK, color: 'FFFFFF' },
                          },
                          shading: {
                            val: ShadingType.SOLID,
                            color: 'f8f8f8',
                          },
                          children: [new Paragraph('')],
                        }),
                  ),
                ],
              }),
          ),
        ],
      }),
      new Paragraph(''),
      new Paragraph(''),
    ];
  }

  // Loop through the checklistAssessments, generating the tables for each one
  const punchcards = await Promise.all(
    checkListAssessments.map(async (ca) => {
      const tables = await Promise.all(
        [1, 2, 3, 4].map(async (lvl) => {
          const { tableData, domainHeaders, assessmentDate } = await generateTabularData(lvl, ca);
          const docxTable = generateDocxTable(tableData, domainHeaders, assessmentDate, lvl);
          return docxTable;
        }),
      );

      return [
        {
          properties: {
            page: {
              margin: {
                top: 1000,
                right: 800,
                bottom: 800,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              heading: HeadingLevel.HEADING_1,
              children: [
                new TextRun({
                  text: 'Visão geral do checklist de ',
                  font: 'Arial',
                  color: '000000',
                }),
                new TextRun({
                  text: moment(ca.date).format('DD/MM/YYYY'),
                  bold: true,
                  font: 'Arial',
                  color: '000000',
                }),
              ],
            }),
            new Paragraph(''),
            ...tables[0],
            ...tables[1],
          ],
        },
        {
          properties: {
            page: {
              margin: {
                top: 1000,
                right: 800,
                bottom: 800,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              heading: HeadingLevel.HEADING_1,
              children: [
                new TextRun({
                  text: 'Visão geral do checklist de ',
                  font: 'Arial',
                  color: '000000',
                }),
                new TextRun({
                  text: moment(ca.date).format('DD/MM/YYYY'),
                  bold: true,
                  font: 'Arial',
                  color: '000000',
                }),
              ],
            }),
            new Paragraph(''),
            ...tables[2],
          ],
        },
        {
          properties: {
            page: {
              margin: {
                top: 1000,
                right: 800,
                bottom: 800,
                left: 1000,
              },
            },
          },
          children: [
            new Paragraph({
              heading: HeadingLevel.HEADING_1,
              children: [
                new TextRun({
                  text: 'Visão geral do checklist de ',
                  font: 'Arial',
                  color: '000000',
                }),
                new TextRun({
                  text: moment(ca.date).format('DD/MM/YYYY'),
                  bold: true,
                  font: 'Arial',
                  color: '000000',
                }),
              ],
            }),
            new Paragraph(''),
            ...tables[3],
          ],
        },
      ];
    }),
  );

  return punchcards.flatMap((punchcard) => [...punchcard]);
};
