import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import manuh from 'manuh';

import topics from '../../../manuh-topics';
import { getAvailableLevelsByDevelopmentalDomain } from '../../../services/denver';

export function LevelSelect({ level = 1, levelMode, className, onSelectLevel }) {
  const query = new URLSearchParams(window.location.search);

  const [availableLevels, setAvailableLevels] = useState([1, 2, 3, 4]);
  const [currentLevel, setCurrentLevel] = useState(
    level ? level : query.get('level') ? parseInt(query.get('level')) : 1,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (onSelectLevel) {
      onSelectLevel(currentLevel);
    }
  }, [currentLevel]);

  manuh.subscribe(
    topics.components.developmentalDomainSelector.select,
    'LevelSelect',
    (selectedDevelopmentalDomain) => {
      getAvailableLevelsByDevelopmentalDomain(selectedDevelopmentalDomain.code, (res, err) => {
        if (!err) {
          setAvailableLevels(res);
        }
      });
    },
  );

  if (!levelMode || levelMode === 'dropdown') {
    return (
      <div className={`btn-group btn-group-sm ${levelMode ? levelMode : 'visible-1024-plus'}`}>
        <button
          id="btnGroupDrop2"
          type="button"
          className={`btn ${className} dropdown-toggle`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {t('Level')} {currentLevel}
        </button>
        <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
          <div>
            <button
              onClick={() => setCurrentLevel(1)}
              disabled={availableLevels.indexOf(1) === -1}
              type="button"
              className={`dropdown-item`}
            >
              {t('Level')} 1
            </button>
          </div>
          <div>
            <button
              onClick={() => setCurrentLevel(2)}
              disabled={availableLevels.indexOf(2) === -1}
              type="button"
              className={`dropdown-item`}
            >
              {t('Level')} 2
            </button>
          </div>
          <div>
            <button
              onClick={() => setCurrentLevel(3)}
              disabled={availableLevels.indexOf(3) === -1}
              type="button"
              className={`dropdown-item`}
            >
              {t('Level')} 3
            </button>
          </div>
          <div>
            <button
              onClick={() => setCurrentLevel(4)}
              disabled={availableLevels.indexOf(4) === -1}
              type="button"
              className={`dropdown-item`}
            >
              {t('Level')} 4
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={levelMode ? levelMode : 'visible-1024-less'}>
        <button
          onClick={() => setCurrentLevel(1)}
          disabled={availableLevels.indexOf(1) === -1}
          type="button"
          className={`btn btn-${currentLevel !== 1 ? 'outline-' : ''}${
            availableLevels.indexOf(1) === -1 ? 'secondary' : 'primary'
          }`}
        >
          {t('Level')} 1
        </button>
        <button
          onClick={() => setCurrentLevel(2)}
          disabled={availableLevels.indexOf(2) === -1}
          type="button"
          className={`btn btn-${currentLevel !== 2 ? 'outline-' : ''}${
            availableLevels.indexOf(2) === -1 ? 'secondary' : 'primary'
          }`}
        >
          {t('Level')} 2
        </button>
        <button
          onClick={() => setCurrentLevel(3)}
          disabled={availableLevels.indexOf(3) === -1}
          type="button"
          className={`btn btn-${currentLevel !== 3 ? 'outline-' : ''}${
            availableLevels.indexOf(3) === -1 ? 'secondary' : 'primary'
          }`}
        >
          {t('Level')} 3
        </button>
        <button
          onClick={() => setCurrentLevel(4)}
          disabled={availableLevels.indexOf(4) === -1}
          type="button"
          className={`btn btn-${currentLevel !== 4 ? 'outline-' : ''}${
            availableLevels.indexOf(4) === -1 ? 'secondary' : 'primary'
          }`}
        >
          {t('Level')} 4
        </button>
      </div>
    );
  }
}
