import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {EditableSteps} from './EditableSteps';

export const EditableStepsGroup = ({
  item,
  title,
  assessmentSteps,
  stepCreated,
  stepDeleted,
  codePrefix,
  saveStep,
  deleteStep,
}) => {
  const { t } = useTranslation();

  const [modifiedItems, setModifiedItems] = useState([]);

  useEffect(() => {
    setModifiedItems(assessmentSteps);
  }, [assessmentSteps]);

  const stepAdded = (newStep) => {
    if (stepCreated) {
      stepCreated(newStep);
    }
    setModifiedItems(modifiedItems.concat([newStep]).sort((a, b) => a.order - b.order));
  };

  const onStepAdded = (newStep) => {
    window.$('#addNewStepForm').removeClass('show');
    stepAdded(newStep);
    if (stepCreated) {
      stepCreated(newStep);
    }
  };

  return (
    <>
      <div>
        <h6 className="card-title mb-3">{title}</h6>
        {modifiedItems.length > 0 &&
          modifiedItems.map((as) => (
            <div key={as.id}>
              <EditableSteps
                idSkill={item.id}
                skill={item}
                assessmentStep={as}
                onStepRemoved={stepDeleted}
                deleteStep={deleteStep}
                saveStep={saveStep}
              />
            </div>
          ))}
        <button
          className="btn btn-secondary mt-2"
          type="button"
          data-toggle="collapse"
          data-target="#addNewStepForm"
          aria-expanded="false"
          aria-controls="addNewStepForm"
        >
          {t('Add new Step')}
        </button>
        <div id="addNewStepForm" className="collapse mt-3">
          <EditableSteps
            skill={item}
            key={Math.random() + new Date().getTime()}
            idSkill={item.id}
            assessmentStep={{
              code: codePrefix + 'E' + new Date().getTime(),
              order: modifiedItems.length + 1,
              achievementMininumAttempts: null,
              achievementMinimumScore: null,
            }}
            onStepAdded={onStepAdded}
            saveStep={saveStep}
          />
        </div>
      </div>
    </>
  );
};
