import moment from 'moment';

export default async ({ selectedChecklistsData, t }) =>
  new Promise((resolve, reject) => {
    const idBarChart = 'radarChart' + parseInt((Math.random() * 1000).toFixed(0));
    let containerDiv = window.document.createElement('div');
    containerDiv.id = 'container-' + idBarChart;
    containerDiv.innerHTML = `<div id="${idBarChart}" style={{ position: 'absolute', left: -9999, top: -9999 }} />`;

    const checklistDates = selectedChecklistsData.checklistDates;

    let factorSeries = [];
    for (let date in checklistDates) {
      const checkDate = checklistDates[date];
      const values = selectedChecklistsData.unifiedChecklistsEvaluated
        .filter((x) => x.developmentFactor)
        .map((k) => k.developmentFactor && k.developmentFactor.filter((d) => d.checklistDate === checkDate))
        .map((ce) => ((ce && ce[0] ? ce[0].value : 0.0) * 100).toFixed(0));

      checklistDates.length === 1 && factorSeries.push({ name: '', data: [] });
      factorSeries.push({
        name: moment(new Date(checkDate)).format('MM/YYYY'),
        data: values,
      });
    }

    var options = {
      chart: {
        animations: { enabled: false },
        type: 'radar',
        height: 350,
        width: 670,
      },
      series: factorSeries,
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: '#e9e9e9',
            fill: {
              colors: ['#f8f8f8', '#fff'],
            },
          },
        },
      },
      colors: ['#b6c5d0', '#008ffb'],
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        categories: selectedChecklistsData.unifiedChecklistsEvaluated
          .filter((x) => x.typicalDevelopmentWeightingTotalPoints)
          .map((dd) => t(dd.developmentalDomain.title)),
      },
      yaxis: {
        tickAmount: 5,
        max: 100,
        labels: {
          formatter: function (val, _i) {
            return val.toFixed(0);
          },
        },
      },
    };

    var chartRadar = new window.ApexCharts(containerDiv.lastChild, options);
    chartRadar.render().then(() => {
      chartRadar.dataURI().then(({ imgURI }) => {
        const chartImage = imgURI.split(',')[1];
        if (!chartImage) {
          return reject('ERROR');
        }
        containerDiv.remove();
        resolve(chartImage);
      });
    });
  });
