import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import moment from 'moment';

import { getAllChildren, del } from '../../services/children';
import useUserContext from '../../useUserContext';

export default () => {
  const { t } = useTranslation();

  const [childrenList, setChildrenList] = useState([]);
  const [childrenListFiltered, setChildrenListFiltered] = useState([]);
  const [showOnlyActives, setShowOnlyActives] = useState(true);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(true);

  const { loggedUser } = useUserContext();

  useEffect(
    (_) => {
      countToast();
      setBusy(true);
      setLoadingErrorMessage('');
      getAllChildren(async (result, err) => {
        if (!err) {
          const modifiedStaffAssignments = await Promise.all(
            result.map(async (r) => {
              r.staffAssignments = r.staffAssignments.sort((a, b) => {
                if (a.staffAttribution.id === '1' && b.staffAttribution.id !== '1') {
                  return -1;
                }
                if (b.staffAttribution.id === '1' && a.staffAttribution.id !== '1') {
                  return 1;
                }

                if (!a.staffMember) {
                  console.log(r);
                }

                return a.staffMember.person.name.localeCompare(b.staffMember.person.name);
              });

              const lastCheck = r.checklistAssessments[0];
              r.lc = lastCheck ? lastCheck.date : null;

              const lastSession = r.lastSessions[0]?.sessions[0];
              r.lis = lastSession ? lastSession.checkinAt : null;

              return r;
            }),
          );
          setChildrenList(
            modifiedStaffAssignments.filter((f) => {
              // staffMember can only see your children record and admin can see non-active
              if (loggedUser.children) {
                for (var child of loggedUser.children) {
                  if (child === f.id && f.active) return true;
                }
              } else {
                return true;
              }
            }),
          );
        } else {
          setLoadingErrorMessage(t('There was a problem fetching children records'));
        }
        setBusy(false);
      });
    },
    [t],
  );

  useEffect(() => {
    const childrenListwithNormName = childrenList.map((child) => {
      let newChild = { ...child };
      // this strange .normalize basically get all letters with accents and remove the accents
      newChild.person.normName = newChild.person.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      return newChild;
    });
    setChildrenListFiltered(childrenListwithNormName.filter((c) => !showOnlyActives || c.active));
  }, [showOnlyActives, childrenList]);

  const delet = (event) => {
    var delName = event.currentTarget.dataset.name;
    var delId = event.currentTarget.dataset.id;

    window.Swal.fire({
      title: t('Confirm') + '?',
      type: 'warning',
      text: t('Are you sure you want to remove') + ' ' + delName + '?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then((result) => {
      if (result.value) {
        del(delId, (_, err) => {
          if (err) {
            return window.Swal.fire({
              type: 'error',
              title: t('Error'),
              text: t('There was an error removing'),
            });
          }

          return window.Swal.fire({
            type: 'success',
            title: t('Removed'),
            text: delName + ' ' + t('successfully removed') + '!',
          }).then((_) => {
            window.location.reload();
          });
        });
      }
    });
  };

  const isBirthdayDay = (someDate) => {
    var qtd = localStorage.getItem(moment().format('MM/DD/YYYY'));
    if (qtd > 5) {
      return null;
    } else {
      var now = moment();
      var input = moment(someDate);
      return now.format('M') === input.format('M') && now.format('D') == input.format('D');
    }
  };

  const countToast = () => {
    var qtd = localStorage.getItem(moment().format('MM/DD/YYYY'));
    if (qtd === null) qtd = 0;
    localStorage.setItem(moment().format('MM/DD/YYYY'), Number(qtd) + 1);
  };

  const toastBirthday = (child) => {
    var txt = '🥳 ' + child.person.name + '\n';
    const lastToastTime = window.localStorage['lastToast'] && parseInt(window.localStorage['lastToast']);
    if (!lastToastTime || new Date().getTime() - lastToastTime > 60 * 60 * 1000) {
      toast.success(txt, {
        position: 'top-right',
        hideProgressBar: true,
        autoClose: 15000,
        closeOnClick: true,
      });
      window.localStorage['lastToast'] = new Date().getTime();
    }
  };

  // 3 months
  const isInterventionSessionLate = (someDate) => {
    if (someDate == null) {
      return null;
    } else {
      const now = new Date();
      return moment(someDate).isBefore(new Date(now.setMonth(now.getMonth() - 3)));
    }
  };
  // 3 months
  const isChecklistLate = (someDate) => {
    if (someDate == null) {
      return null;
    } else {
      const now = new Date();
      return moment(someDate).isBefore(new Date(now.setMonth(now.getMonth() - 3)));
    }
  };

  return {
    childrenListFiltered,
    childrenList,
    showOnlyActives,
    setShowOnlyActives,
    delet,
    isBirthdayDay,
    toastBirthday,
    isInterventionSessionLate,
    isChecklistLate,
    loadingErrorMessage,
    busy,
  };
};
