import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import moment from 'moment';

import { create, createStaffAssignment, get, update, verifyRegistered } from '../../../../services/children';
import { updateChildrenList } from '../../../../services/staff';
import useProfileModel from '../../../profile/useProfileModel';

export default () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [active, setActive] = useState(true);
  const [currentId, setCurrentId] = useState(id !== 'new' ? id : null);
  const [name, setName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [dateOfBirthComplete, setDateOfBirthComplete] = useState('');
  const [gender, setGender] = useState('NOT_INFORMED');
  const [videoURL, setVideoURL] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState('');
  const [formSubmissionMessageType, setFormSubmissionMessageType] = useState('');

  const { loggedUser } = useProfileModel();

  useEffect(() => {
    if (id === 'new' || id === null) return;
    get(currentId, (child) => {
      if (child) {
        setActive(child.active);
        setName(child.person.name);
        setDateOfBirth(moment(child.person.dateOfBirth).format(t('MM/DD/YYYY')));
        setDateOfBirthComplete(child.person.dateOfBirth);
        setGender(child.person.gender);
        setVideoURL(child.videoURL);
      }
    });
  }, [currentId, t]);

  const save = async () => {
    setSubmitting(true);

    // Creating a new child
    if (!currentId) {
      // Checking if there are any other children already registered with similar data
      const registerCheck = await verifyRegistered(name, moment(dateOfBirth, t('MM/DD/YYYY')).toISOString());

      // Error checking
      if (registerCheck.error) {
        console.error(registerCheck.error);
        setFormSubmissionMessageType('fail');
        setFormSubmissionMessage(t('Error'));
        setSubmitting(false);
        return;
      }

      // If there is a similar register, warn the user before creating a new child
      if (registerCheck.data.children && registerCheck.data.children.length > 0) {
        const registerCheckModalList = registerCheck.data.children.map(
          (child) => `
            <tr>
              <td>
                <a href="/children/${child.id}" target="_blank">${child.person.name}</a>
              </td>
              <td>${moment(child.person.dateOfBirth).format(t('MM/DD/YYYY'))}</td>
            </tr>`,
        );

        const registerCheckModalHtml = `
          <table class="table table-hover mt-2 mb-4">
            <thead> 
              <tr> 
                <th scope="col">${t('Full name')}</th>
                <th scope="col">${t('Date of birth')}</th>
              </tr>
            </thead>
            <tbody>
              ${registerCheckModalList}
            </tbody>
          </table>
          <h4>${t('child-register-form-dialogs.confirmation')}</h4>`;

        const continueRegistering = await window.Swal.fire({
          title: t('child-register-form-dialogs.similar-register-warning-title'),
          type: 'warning',
          width: '800px',
          html: registerCheckModalHtml,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: t('Yes'),
          cancelButtonText: t('No'),
        }).then((result) => result.value);

        if (!continueRegistering) {
          setFormSubmissionMessageType('fail');
          setFormSubmissionMessage(t('Child already registered!'));
          setSubmitting(false);
          return;
        }
      }

      // If there are no other children already registered with similar data, or if the user decides to
      // continue registering a child with similar data, create a new child.
      create(
        active,
        name,
        moment(dateOfBirth, t('MM/DD/YYYY')).toISOString(),
        gender,
        videoURL,
        async (result, err) => {
          if (!err) {
            setCurrentId(result.id);
            setFormSubmissionMessageType('success');
            setFormSubmissionMessage(t('Successfully registered Child!'));
            setSubmitting(false);
            try {
              await createStaffAssignment(result.data.createChild.id, loggedUser.id, 'ckrtlhsyt005f0h36fxo01myh');
              updateChildrenList();
            } catch (e) {
              window.Swal.fire({
                type: 'error',
                title: t('Error'),
                text: t('Callback error in createStaffAssignment'),
              });
            }
          } else {
            console.error(err);
            setFormSubmissionMessageType('fail');
            setFormSubmissionMessage(t('Error registering this new child'));
          }
        },
      );

      // Updating an existing child
    } else {
      update(id, active, name, moment(dateOfBirth, t('MM/DD/YYYY')).toISOString(), gender, videoURL, (_, err) => {
        if (!err) {
          setFormSubmissionMessageType('success');
          setFormSubmissionMessage(t('Successfully updated Child information!'));
          setSubmitting(false);
        } else {
          setFormSubmissionMessageType('fail');
          setFormSubmissionMessage(t('Error updating child information'));
        }
      });
    }
    setSubmitting(false);
  };

  return {
    active,
    setActive,
    id,
    name,
    setName,
    dateOfBirth,
    dateOfBirthComplete,
    setDateOfBirth,
    gender,
    setGender,
    videoURL,
    setVideoURL,
    save,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
  };
};
