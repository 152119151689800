import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { getAllSkills } from '../../../services/denver';
import { resolvePlanUniqueSkills, getLastIndividualizedPlan } from '../../../services/individualizedPlan';
import {
  getInterventionSession,
  getLastInterventionSessions,
  getInterventionSessions,
  createInterventionSession,
  deleteInterventionSession,
  updateInterventionSession,
} from '../../../services/interventionSession';

export default () => {
  const { t } = useTranslation();

  const { sessionId, childId } = useParams();
  const query = new URLSearchParams(window.location.search);

  const date = query.get('date');

  const [editMode] = useState(query.get('mode') === 'edit');
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(true);

  const [interventionSession, setInterventionSession] = useState(null);
  const [interventionSessions, setInterventionSessions] = useState([]);

  const setInterventionSessionDate = (id, newDate) => {
    const date = new Date(newDate);
    updateInterventionSession(id, date.toISOString(), (_, err) => {
      if (err) {
        return window.Swal.fire({
          title: t('Error'),
          text: t(err.message),
        });
      }
      if (!err) {
        window.location.reload();
      }
    });
  };

  useEffect(
    (_) => {
      setBusy(true);
      setLoadingErrorMessage('');
      if (sessionId === 'new') {
        //creating new session
        getLastIndividualizedPlan(childId, (result, err) => {
          if (!err) {
            const loggedUser = JSON.parse(window.localStorage['loggedUserData']);
            getLastInterventionSessions(childId).then((lastSession) => {
              if (!err) {
                var sessionNumber = 1;
                if (lastSession) {
                  if (lastSession.mig__sessionNumber > 0) {
                    sessionNumber = lastSession.mig__sessionNumber + 1;
                  }
                }
                createInterventionSession(
                  {
                    checkinAt: date ? new Date(date) : new Date(),
                    attendant: loggedUser,
                    individualizedPlan: result,
                    mig__sessionNumber: sessionNumber,
                  },
                  (result, err) => {
                    if (err || !result) {
                      setInterventionSession(null);
                      setLoadingErrorMessage(t('There was a problem fetching Child Intervention Session'));
                      setBusy(false);
                    } else {
                      window.location.href = `/children/${childId}/session/${result.id}?mode=edit`;
                    }
                  },
                );
              } else {
                setLoadingErrorMessage(t('There was a problem fetching Child Intervention Session'));
                setInterventionSession(null);
                setBusy(false);
              }
            });
          } else {
            setLoadingErrorMessage(t('There was a problem fetching Child Intervention Session'));
            setInterventionSession(null);
            setBusy(false);
          }
        });
      } else {
        //retrieving and existent session

        getInterventionSession(sessionId, (session, err) => {
          if (!session) {
            return;
          }
          let interventionSessionMix = Object.assign({}, session, {
            skillSteps: [],
          });
          const skills = resolvePlanUniqueSkills(session.childIndividualizedPlan);

          // group in each skill its steps and results
          skills.forEach((s) => {
            interventionSessionMix.skillSteps.push({
              plan: true,
              skill: s,
              sessionDate: session.checkinAt,
              stepsResult: session.skillAssessmentStepScore.filter(
                (sss) =>
                  sss.childIndividualizedPlanSkillStep &&
                  ((sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep &&
                    sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.skill.code === s.code) ||
                    (sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill &&
                      sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill.skill.code === s.code)),
              ),
            });
          });

          interventionSessionMix.skillSteps = interventionSessionMix.skillSteps
            .filter((im) => im.stepsResult.length > 0)
            .sort((a, b) => {
              // group skills by code
              if (a.skill.developmentalDomain.order === b.skill.developmentalDomain.order) {
                // group skills by level
                if (a.skill.level === b.skill.level) {
                  // order customized skills: if a skill has the skillFrom property, it goes first
                  if (a.skill.code.match(/CTM/) && !a.skill.skillFrom && !b.skill.code.match(/CTM/)) return 1;
                  if (b.skill.code.match(/CTM/) && !b.skill.skillFrom && !a.skill.code.match(/CTM/)) return -1;
                  if (a.skill.code.match(/CTM/) && b.skill.code.match(/CTM/)) {
                    if (a.skill.skillFrom && !b.skill.skillFrom) return -1;
                    if (!a.skill.skillFrom && b.skill.skillFrom) return 1;
                  }
                  // order skills that have the skillFrom property
                  if (a.skill.skillFrom && b.skill.skillFrom) return a.skill.skillFrom.order - b.skill.skillFrom.order;
                  if (a.skill.skillFrom && !b.skill.skillFrom) return a.skill.skillFrom.order - b.skill.order;
                  if (!a.skill.skillFrom && b.skill.skillFrom) return a.skill.order - b.skill.skillFrom.order;

                  return a.skill.order - b.skill.order;
                }
                if (a.skill.skillFrom && b.skill.skillFrom) return b.skill.skillFrom.order - b.skill.skillFrom.order;
                if (a.skill.skillFrom && !b.skill.skillFrom) return a.skill.skillFrom.order - a.skill.order;
                if (!a.skill.skillFrom && b.skill.skillFrom) return b.skill.order - b.skill.skillFrom.order;
                return b.skill.level - a.skill.level;
              }
              return a.skill.developmentalDomain.order - b.skill.developmentalDomain.order;
            });

          getAllSkills((skillsAll, err) => {
            skillsAll
              .filter((fff) => (skills.some((r) => r.id === fff.id) ? false : true))
              .forEach((s) => {
                if (s.assessmentSteps.length > 0) {
                  interventionSessionMix.skillSteps.push({
                    plan: false,
                    skill: s,
                    stepsResult: session.skillAssessmentStepScore.filter(
                      (sss) =>
                        sss.childIndividualizedPlanSkillStep &&
                        ((sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep &&
                          sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.skill.code ===
                            s.code) ||
                          (sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill &&
                            sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill.skill.code === s.code)),
                    ),
                  });
                }
              });
          });

          setInterventionSession(interventionSessionMix);

          getInterventionSessions(session.childIndividualizedPlan.child.id, (result, err) => {
            if (!err) {
              setInterventionSessions(result);
            } else {
              console.error('Error fetching Child Intervention Sessions:', err);
            }
            setBusy(false);
          });
        });
      }
    },
    [t, sessionId, childId],
  );

  const deleteCurrentInterventionSession = () => {
    deleteInterventionSession(interventionSession, (_, err) => {
      if (err) {
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('There was an error removing this session'),
        });
      }
      return window.Swal.fire({
        icon: 'success',
        title: t('Removed'),
        text: t('Intervention session successfully removed') + '!',
      }).then((_) => {
        if (interventionSessions.length === 1) {
          //if this was the last plan
          window.location.href = `/children/${interventionSession.childIndividualizedPlan.child.id}`;
        } else {
          window.location.href = `/children/${interventionSession.childIndividualizedPlan.child.id}`;
        }
      });
    });
  };

  return {
    busy,
    loadingErrorMessage,
    editMode,
    deleteCurrentInterventionSession,
    setInterventionSessionDate,
    interventionSessions,
    interventionSession,
  };
};
