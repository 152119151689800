import { BorderStyle, convertInchesToTwip } from 'docx';

export const margins = {
  top: convertInchesToTwip(0.04),
  bottom: convertInchesToTwip(0.04),
  right: convertInchesToTwip(0.04),
  left: convertInchesToTwip(0.04),
};

export const borders = {
  top: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#000000',
  },
  bottom: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#000000',
  },
  left: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#000000',
  },
  right: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#000000',
  },
};
