import React from 'react';

import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';

import logo from '../../../logo.png';

const styles = StyleSheet.create({
  page: { padding: 30, fontFamily: 'Helvetica' },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  logo: { width: 70, height: 70 },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2c2c2c',
    paddingVertical: 5,
  },
  infoTitle: {
    fontSize: 12,
  },
  infoValue: {
    fontSize: 12,
    color: '#000000',
  },
  url: { fontSize: 9, color: 'grey', marginBottom: 8 },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: { display: 'flex', flexDirection: 'column', marginTop: 10 },
  contentTitle: { fontSize: 18, paddingVertical: 10 },
  tableHeaderMain: {
    fontSize: 12,
    color: 'black',
    backgroundColor: '#f0f0f0',
    fontFamily: 'Helvetica-Bold',
    width: '1px',
    padding: 3,
  },
  tableHeader: {
    textAlign: 'center',
    fontSize: 9,
    color: 'black',
    backgroundColor: '#f0f0f0',
    fontFamily: 'Helvetica-Bold',
  },
  centerCell: { textAlign: 'center', alignContent: 'center' },
  domain: { fontSize: 9, color: 'black', fontFamily: 'Helvetica-Bold' },
  text: { fontSize: 10, color: 'black' },
  spacer: { paddingBottom: 5 },
  attempts: { fontSize: 9 },
  paddingCell: { padding: 3 },
  signatureContainer: { marginTop: 50, textAlign: 'center' },
  italicText: { fontFamily: 'Helvetica-Oblique', fontSize: 9 },
});

export const SessionReportPDF = (session) => {
  const tableData = session.items.map((item) => ({
    objective: (
      <View style={styles.paddingCell}>
        <View style={styles.spacer}>
          <Text style={styles.domain}>{item.domain}</Text>
          <Text style={styles.text}>{item.title}</Text>
        </View>
        <Text style={styles.text}>{item.description}</Text>
        <Text style={styles.attempts}>
          Mínimo de {item.minimumAttempts} em {item.minimumSuccess} oportunidades
        </Text>
      </View>
    ),
    success: (
      <View>
        <Text style={styles.centerCell}>{item.success}</Text>
      </View>
    ),
    failure: (
      <View>
        <Text style={styles.centerCell}>{item.failure}</Text>
      </View>
    ),
    notEvaluated: (
      <View>
        <Text style={styles.centerCell}>{item.notEvaluated ? 'X' : null}</Text>
      </View>
    ),
  }));

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.headerContent}>
          <View style={styles.infoContainer}>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Nome: </Text>
              <Text style={styles.infoValue}>{session.child} </Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Data Nascimento: </Text>
              <Text style={styles.infoValue}>{session.dob}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Profissional: </Text>
              <Text style={styles.infoValue}>{session.attendant}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Inicio da sessão: </Text>
              <Text style={styles.infoValue}>{session.checkinAt} ____:____</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Fim da sessão: </Text>
              <Text style={styles.infoValue}>____/____/______ ____:____</Text>
            </View>
          </View>
          <View style={styles.logoContainer}>
            <Image src={logo} alt="images" style={styles.logo} />
            <Text style={styles.url}>app.earlydenver.com.br</Text>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <Table data={tableData}>
            <TableHeader>
              <TableCell style={styles.tableHeaderMain}>Objetivo da sessão</TableCell>
              <TableCell weighting={0.1} style={styles.tableHeader}>
                Sucesso
              </TableCell>
              <TableCell weighting={0.1} style={styles.tableHeader}>
                Falha
              </TableCell>
              <TableCell weighting={0.1} style={styles.tableHeader}>
                N/A
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell getContent={(r) => r.objective} />
              <DataTableCell textAlign="center" weighting={0.1} getContent={(r) => r.success} />
              <DataTableCell textAlign="center" weighting={0.1} getContent={(r) => r.failure} />
              <DataTableCell textAlign="center" weighting={0.1} getContent={(r) => r.notEvaluated} />
            </TableBody>
          </Table>
        </View>
        <View style={styles.signatureContainer}>
          <Text>________________________________________</Text>
          <Text style={styles.italicText}>Assinatura/Carimbo do profissional responsável</Text>
        </View>
      </Page>
    </Document>
  );
};
