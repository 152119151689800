import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import 'react-quill/dist/quill.snow.css';

import Inputmask from 'inputmask';
import moment from 'moment';

import AccessControl from '../../../AccessControl';
import { Loading } from '../../../components';
import { withChildrenPermission } from '../../../withChildrenPermission';
import { ChildWeeklyCommentModel } from './ChildWeeklyCommentModel';

const ChildWeeklyComment = () => {
  const $ = window.$;
  $(document).ready(function () {
    Inputmask().mask(document.querySelectorAll('input'));
  });

  const { t } = useTranslation();

  const {
    child,
    busy,
    comment,
    updateComment,
    annotationDate,
    setAnnotationDate,
    saveAnottation,
    fixAnottation,
    showSuccess,
    isNew,
    filteredAnnotations,
    loggedUser,
    removeThisAnottation,
    selectedAnottation,
    setSelectedAnottation,
    textAreaValue,
    setTextAreaValue,
    updateAnottation,
    listAll,
    destinationAnottation,
    setDestinationAnottation,
    levelUser,
    dateFilter,
    setDateFilter,
    generateAnnotationsDocx,
  } = ChildWeeklyCommentModel();

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                id="search"
                name="search"
                required
                type="text"
                className="form-control form-control-sm"
                autoComplete="off"
                placeholder={t('Search here')}
              />
            </div>
          </form>
        </div>
      </nav>

      <div className="page-content">
        {busy && <Loading loadingText={t('Fetching Child Assessment Checklist')} />}
        {!busy && (
          <>
            <nav className="page-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="/children">{t('Sidebar.Overview')}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href={`/children/${child.id}`}>{child.person.name}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href={`/children/${child.id}/comment/listAll`}>
                    {listAll ? t('Annotations') : `${moment(new Date()).format('DD/MM/YYYY')}`}
                  </a>
                </li>
              </ol>
            </nav>

            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body main-content">
                    <h4 className="page-title">{t('Annotations')}</h4>
                    {!isNew && (
                      <div className="d-flex justify-content-between form-inline mb-2">
                        <div className="form-group">
                          <label htmlFor="date-picker" className="col-form-label">
                            {t('annotations-select-date-filter')}
                          </label>
                          <DateRangePicker
                            onChange={setDateFilter}
                            value={dateFilter}
                            locale={t('locale-string')}
                            minDetail="year"
                            className="mr-1 ml-1"
                            id="date-picker"
                          />
                        </div>

                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary btn-icon-text"
                            onClick={() => generateAnnotationsDocx(child, filteredAnnotations)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-file-text btn-icon-prepend"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                              <polyline points="14 2 14 8 20 8"></polyline>
                              <line x1="16" y1="13" x2="8" y2="13"></line>
                              <line x1="16" y1="17" x2="8" y2="17"></line>
                              <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                            Exportar Docx
                          </button>

                          <AccessControl
                            allowedRole={['staffMember', 'admin']}
                            requiredPermissions={[
                              'child:*:childIndividualizedPlanSession',
                              'child:' + child.id + ':childIndividualizedPlanSession',
                            ]}
                          >
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                window.location.href = `/children/${child.id}/comment/new`;
                              }}
                              type="button"
                              className="btn btn-primary btn-icon-text ml-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-briefcase btn-icon-prepend"
                              >
                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                              </svg>
                              {t('New annotation')}
                            </button>
                          </AccessControl>
                        </div>
                      </div>
                    )}

                    {isNew ? (
                      <form
                        id="comment-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          saveAnottation();
                        }}
                      >
                        <div className="form-group">
                          <label htmlFor="annotation-text">{t('New annotation')}</label>
                          <ReactQuill
                            id="annotation-text"
                            placeholder={t('Description')}
                            value={comment}
                            onChange={updateComment}
                            required
                          />
                        </div>

                        <div className="mt-2 form-group row">
                          <label className="col-sm-3 col-md-2 col-form-label" htmlFor="personSelectDD">
                            {t('Visible to')}
                          </label>
                          <select
                            value={destinationAnottation}
                            onChange={(event) => setDestinationAnottation(event.target.value)}
                            id="personSelectDD"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="custom-select custom-select-sm col-sm-9 col-md-10"
                          >
                            <option value="All" key={0}>
                              {t('All')}
                            </option>
                            <option value="Family" key={1}>
                              {t('Family')}
                            </option>
                            {(levelUser === 1 || levelUser === 2) && (
                              <option value="Therapist Assistant" key={2}>
                                {t('Therapist Assistant')}
                              </option>
                            )}
                            {levelUser === 2 && (
                              <option value="Supervisor" key={3}>
                                {t('Supervisor')}
                              </option>
                            )}
                          </select>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-3 col-md-2 col-form-label" htmlFor="dateTime">
                            {t('Annotation date')}
                          </label>
                          <input
                            className="form-control col-sm-9 col-md-10"
                            id="dateTime"
                            name="dateTime"
                            defaultValue={annotationDate}
                            onBlur={(e) => setAnnotationDate(moment(e.target.value, t('MM/DD/YYYY')))}
                            data-inputmask-placeholder="*"
                            im-insert="false"
                            data-inputmask-alias="datetime"
                            data-inputmask-inputformat={t('mm/dd/yyyy')}
                          />
                        </div>

                        {showSuccess && (
                          <div className="form-group mt-3">
                            <div className="alert alert-icon-success flex-rss" role="alert">
                              <i className="mdi mdi-check-circle-outline mr-1" />
                              Anotação cadastrada com sucesso!
                            </div>
                          </div>
                        )}
                        <button type="submit" className="btn btn-secondary btn-sm mr-2 mt-3">
                          {t('Save')}
                        </button>
                      </form>
                    ) : (
                      <div>
                        {(!filteredAnnotations || filteredAnnotations.length === 0) && (
                          <div>{t('No notes added yet')}</div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {filteredAnnotations.map((each, index) => {
                            return (
                              <div
                                key={index}
                                className="card child-annotation ql-editor"
                                style={{ marginTop: index !== 0 ? 20 : '0' }}
                              >
                                <div>
                                  <blockquote className="p-3 mb-0">
                                    {selectedAnottation?.id !== each.id && (
                                      <div
                                        onDoubleClick={(event) => {
                                          if (loggedUser.id === each.attendant.id) {
                                            event.stopPropagation();
                                            if (selectedAnottation?.id !== each.id) {
                                              const { anottation } = each;
                                              setTextAreaValue(anottation);
                                              setSelectedAnottation(each);
                                            } else {
                                              setSelectedAnottation(null);
                                            }
                                          }
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: each.anottation.replace(/\n/g, '<br />'),
                                        }}
                                      />
                                    )}
                                    {selectedAnottation?.id === each.id && (
                                      <>
                                        <ReactQuill
                                          placeholder={t('Description')}
                                          value={textAreaValue}
                                          onChange={setTextAreaValue}
                                          required
                                        />

                                        <div className="form-group row">
                                          <label className="col-sm-3 col-md-2 col-form-label" htmlFor="dateTime">
                                            {t('Annotation date')}
                                          </label>
                                          <input
                                            className="form-control col-sm-9 col-md-10"
                                            id="dateTime"
                                            name="dateTime"
                                            defaultValue={moment(
                                              selectedAnottation.annotationDate ?? selectedAnottation.createdAt,
                                            ).format(t('MM/DD/YYYY'))}
                                            onBlur={(e) =>
                                              setAnnotationDate(moment(e.target.value, t('MM/DD/YYYY')).toISOString())
                                            }
                                            data-inputmask-placeholder="*"
                                            im-insert="false"
                                            data-inputmask="'alias': 'datetime'"
                                            data-inputmask-inputformat={t('mm/dd/yyyy')}
                                          />
                                        </div>
                                      </>
                                    )}

                                    <footer className="blockquote-footer mt-3">
                                      <strong>
                                        {t('Annotation date')}
                                        {': '}
                                      </strong>
                                      <span>
                                        {moment(each.annotationDate ?? each.createdAt).format('DD/MM/YYYY')}
                                        {' | '}
                                      </span>
                                      {t('created-by')}{' '}
                                      <strong style={{ color: 'black' }}> {each.attendant.person.name}</strong>{' '}
                                      {t('to')} <strong style={{ color: 'black' }}>{t(each.visibility)}</strong>
                                    </footer>
                                  </blockquote>
                                </div>

                                <div className="card-header border-top d-flex justify-content-end">
                                  <div className="d-flex form-inline justify-content-end">
                                    {' '}
                                    <div className="form-group">
                                      {loggedUser.id === each.attendant.id && (
                                        <>
                                          <button
                                            className="btn btn-outline-info mt-1 mr-1"
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              if (selectedAnottation?.id !== each.id) {
                                                const { anottation } = each;
                                                setTextAreaValue(anottation);
                                                setSelectedAnottation(each);
                                              } else {
                                                setSelectedAnottation(null);
                                              }
                                            }}
                                          >
                                            {selectedAnottation?.id !== each.id ? 'Editar Anotação' : 'Cancelar Edição'}
                                          </button>

                                          <button
                                            className="btn btn-outline-info mt-1 mr-1"
                                            onClick={() =>
                                              selectedAnottation?.id !== each.id
                                                ? removeThisAnottation(each)
                                                : updateAnottation(each)
                                            }
                                          >
                                            {selectedAnottation?.id !== each.id ? 'Excluir' : 'Salvar Anotação'}
                                          </button>
                                        </>
                                      )}

                                      <button className="btn btn-outline-info mt-1" onClick={() => fixAnottation(each)}>
                                        {each.fixed ? t('remove-fixed-annotation') : t('fix-annotation')}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withChildrenPermission(ChildWeeklyComment);
