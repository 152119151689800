import React from 'react';
import { useTranslation } from 'react-i18next';

import { Document, Packer, Paragraph, HeadingLevel, ImageRun, TextRun, AlignmentType } from 'docx';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { getChildChecklistAssessmentsByDomainWithLevels } from '../../services/checklistAssessment';
import { checklistsAssessmentsDevelopmentalDomainProgress, generateStatsData } from '../../services/childStats';
import convertHtmlToParagraphs from '../../utils/convertFromHtmlToDoc';
import docxTemplate from '../../utils/docxTemplate';
import renderChecklistPunchcard from './renderChecklistPunchcard';
import resolveChecklistResultTables from './resolveChecklistResultTables';
import resolveRadarChartConsolidatedDevelopmentRatio from './resolveRadarChartConsolidatedDevelopmentRatio';
import resolveTableConsolidatedDevelopmentRatio from './resolveTableConsolidatedDevelopmentRatio';

export default (child) => {
  const { t } = useTranslation();

  const [reportParams, setReportParams] = React.useState({});
  const [buildingDOCX, setBuildingDOCX] = React.useState(false);
  const [checkListAssessments, setCheckListAssessments] = React.useState([]);

  React.useEffect(() => {
    if (!reportParams || !reportParams.selectedDomainsAndLevels) {
      return;
    }
    // map the DevelopmentalDomain/Level matrix selection to a filtering object
    // to bring just the assessments of the selected levels related to the respective developmental domain
    // and don't bring the developmental domains that doesn't have any level selected
    const developmentDomainLevelList = Object.keys(reportParams.selectedDomainsAndLevels)
      .filter((dd) => reportParams.selectedDomainsAndLevels[dd]?.reduce((a, b) => a || b)) //filter just the DDs that has at least one level checked
      .map((dd) => ({
        //map to the filtering object expected by `getChildChecklistAssessmentsByDomainWithLevels` interface
        developmentalDomainCode: dd,
        levels: reportParams.selectedDomainsAndLevels[dd]
          .map((v, index) => (v ? index + 1 : -1))
          .filter((x) => x !== -1),
      }));

    const fetchChecklistAsync = async () => {
      const checkListAssessmentsVal = await getChildChecklistAssessmentsByDomainWithLevels({
        childId: child.id,
        checklistIDs: reportParams.selectedChecklists.map((sd) => sd.id),
        developmentDomainLevelList,
      });

      setCheckListAssessments(checkListAssessmentsVal);
    };
    fetchChecklistAsync();
  }, [child.id, reportParams]);

  React.useEffect(
    (_) => {
      const buildReportDOCX = async () => {
        try {
          if (!child || !child.person || !reportParams || !reportParams.selectedDomainsAndLevels) {
            return;
          }
          setBuildingDOCX(true);
          // Convert the child age in terms of years and months
          const dateOfBirth = moment(new Date(child.person.dateOfBirth));
          const today = moment(new Date());
          const years = today.diff(dateOfBirth, 'year');
          dateOfBirth.add(years, 'years');
          const months = today.diff(dateOfBirth, 'months');

          const [olderChecklist, newerChecklist] = reportParams.selectedChecklists.sort((a, b) =>
            moment.utc(a.date).diff(b.date),
          );

          // eslint-disable-next-line no-unused-vars
          const olderChecklistChildAge = moment(new Date(olderChecklist.date)).diff(
            moment(child.person.dateOfBirth),
            'months',
          );
          // eslint-disable-next-line no-unused-vars
          const newerChecklistChildAge =
            newerChecklist && moment(new Date(newerChecklist.date)).diff(moment(child.person.dateOfBirth), 'months');

          const olderChecklistData = await generateStatsData({
            childId: child.id,
            checklistId: olderChecklist.id,
            childDateOfBirth: child.person.dateOfBirth,
          });
          const newerChecklistData =
            newerChecklist &&
            (await generateStatsData({
              childId: child.id,
              checklistId: newerChecklist.id,
              childDateOfBirth: child.person.dateOfBirth,
            }));

          const checklistsArray = [olderChecklistData];
          if (newerChecklist) checklistsArray.push(newerChecklistData);

          const filterOnlyLoaded = checklistsArray.filter((cl) => cl.checklistState === 'LOADED');

          if (filterOnlyLoaded.length !== checklistsArray.length) {
            const selectedCL = reportParams.selectedChecklists;
            const errorCL = selectedCL.filter((scl) => scl.date !== filterOnlyLoaded[0].checklistDate);

            const errorDate = moment(errorCL[0].date).format(t('MM/DD/YYYY'));
            throw t('Please verify the checklist') + ': ' + errorDate;
          }

          const selectedChecklistsData = await checklistsAssessmentsDevelopmentalDomainProgress(checklistsArray);

          const tableConsolidatedDevelopmentRatio = await resolveTableConsolidatedDevelopmentRatio({
            checklistScores: selectedChecklistsData.rawChecklists,
            t,
          });

          const radarChartConsolidatedDevelopmentRatio = await resolveRadarChartConsolidatedDevelopmentRatio({
            selectedChecklistsData,
            t,
          });

          const checklistResultTables = await resolveChecklistResultTables({
            checkListAssessments,
            selectedChecklists: reportParams.selectedChecklists,
            t,
          });
          if (!checklistResultTables) {
            return null;
          }

          const annotationsText =
            reportParams.annotations && reportParams.annotations.length > 0
              ? await Promise.all(
                  reportParams.annotations.map(async (annotation) => [
                    await convertHtmlToParagraphs(annotation.anottation),
                    new Paragraph(''),
                  ]),
                )
              : '';

          const punchCards = await renderChecklistPunchcard(child, reportParams.selectedChecklists);

          // build the DOCX Document content
          const doc = new Document({
            ...docxTemplate.defaultListsProperties,
            sections: [
              {
                properties: { ...docxTemplate.defaultPageProperties },
                children: [
                  new Paragraph({
                    heading: HeadingLevel.TITLE,
                    children: [
                      new TextRun({
                        text: child.person.name,
                        font: 'Arial',
                      }),
                    ],
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'Data de Nascimento: ',
                        bold: true,
                        font: 'Arial',
                      }),
                      new TextRun({
                        font: 'Arial',
                        text: `${moment(new Date(child.person.dateOfBirth)).format(
                          'DD/MM/YYYY',
                        )} | Idade: ${years} anos e ${months} meses`,
                      }),
                    ],
                  }),
                  new Paragraph(''),

                  tableConsolidatedDevelopmentRatio,

                  new Paragraph(''),
                  new Paragraph(''),
                  new Paragraph(''),
                  new Paragraph(''),

                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: Uint8Array.from(Buffer(radarChartConsolidatedDevelopmentRatio, 'base64')),
                        transformation: {
                          width: 670,
                          height: 350,
                        },
                      }),
                    ],
                  }),
                  new Paragraph(''),
                  new Paragraph(''),
                  ...checklistResultTables,
                  new Paragraph(''),
                  annotationsText?.length > 0 &&
                    new Paragraph({
                      heading: HeadingLevel.HEADING_1,
                      children: [
                        new TextRun({
                          text: t('report-professional-opinion-title'),
                          bold: true,
                          font: 'Arial',
                          color: '000000',
                        }),
                      ],
                    }),
                  new Paragraph(''),
                ].concat(annotationsText?.length > 0 && annotationsText.flat(2)),
              },
              ...punchCards,
            ],
          });

          // Generate the DOCX file

          Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `${child.person.name} - ${moment(new Date()).format('DD/MM/YYYY')}`);
          });
        } catch (error) {
          return window.Swal.fire({
            type: 'error',
            title: t('There was an error generating the DOCX report'),
            text: error,
          });
        } finally {
          setBuildingDOCX(false);
        }
      };

      buildReportDOCX();
      return () => setReportParams({});
    },
    [checkListAssessments],
  );

  return {
    buildingDOCX,
    setReportParams,
  };
};
