import moment from 'moment';

export const isBirthdayWeek = (birthDate) => {
  var now = moment();
  var input = moment(birthDate);
  input.set('year', now.get('year'));
  return now.isoWeek() === input.isoWeek();
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getIntervalInMonths = (dateFrom, dateTo) => {
  let momTo = moment(dateTo);
  if (typeof dateTo === 'string') {
    momTo = moment(new Date(dateTo));
  }
  let momFrom = moment(dateFrom);
  if (typeof dateFrom === 'string') {
    if (dateFrom.indexOf('/') !== -1) {
      momFrom = moment(dateFrom, 'DD/MM/YYYY');
    } else {
      momFrom = moment(dateFrom, 'YYYY-MM-DD');
    }
  }
  return momTo.diff(moment(momFrom), 'months');
};

export const getSaoPauloTime = async () => {
  try {
    const response = await fetch('http://worldtimeapi.org/api/timezone/America/Sao_Paulo').then((data) => data.json());
    if (!response.datetime) return { data: null, error: 'error fetching date' };
    return { data: response.datetime };
  } catch {
    return { data: null, error: 'error fetching date' };
  }
};
