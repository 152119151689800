import React from 'react';
import { useTranslation } from 'react-i18next';

import { getNumberFromSkillCode } from '../../../../../utils/legacy';
import { ButtonMinus, ButtonPlus, ButtonPlusMinus, SummaryItem } from '../../../components';
import useChildIndividualizedPlanEditChecklistSelectModel from './useChildIndividualizedPlanEditChecklistSelectModel';

function ChildIndividualizedPlanEditChecklistSelect({
  filterFuseResults,
  childId,
  checklistId,
  levelMode,
  itemAdded,
  itemCustomized,
  setPlanItems,
}) {
  const { t } = useTranslation();

  const {
    checklistItemsFiltered,
    loadingErrorMessage,
    denverDevelopmentalDomains,
    currentDevelopmentalDomainFilter,
    setCurrentDevelopmentalDomainFilter,
    currentLevel,
    setCurrentLevel,
    availableLevels,
    filterScore,
    setFilterScore,
  } = useChildIndividualizedPlanEditChecklistSelectModel({ checklistId, childId });

  const evalBtnOutlineOrNot = (scoreEval, targetScore, className) => {
    if (!scoreEval || scoreEval === 'NOT_EVALUATED') {
      return 'btn-outline-primary';
    }
    if (scoreEval === targetScore) {
      return 'btn-' + className;
    }
    return 'btn-outline-' + className;
  };

  const fuseFilterChecklist = (el) => {
    if (filterFuseResults.length > 0) {
      for (let rs of filterFuseResults) {
        if (rs.item.skill.id === el.id) return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      {!loadingErrorMessage && currentDevelopmentalDomainFilter && (
        <>
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body main-content">
                  <h4>{t('Therapeutic Goals')}</h4>
                  {loadingErrorMessage && (
                    <div className="alert alert-primary" role="alert">
                      {loadingErrorMessage}
                    </div>
                  )}
                  <div className="mb-4 checklist-inline-filter-group">
                    {/* By Level Filter */}
                    <div className="btn-group btn-group-sm" role="group">
                      {levelMode && levelMode === 'inline' && (
                        <div className={levelMode ? levelMode : 'visible-1024-less'}>
                          <button
                            onClick={(_) => setCurrentLevel(1)}
                            disabled={availableLevels.indexOf(1) === -1}
                            type="button"
                            className={`btn btn-${currentLevel !== 1 ? 'outline-' : ''}${
                              availableLevels.indexOf(1) === -1 ? 'secondary' : 'primary'
                            }`}
                          >
                            {t('Level')} 1
                          </button>
                          <button
                            onClick={(_) => setCurrentLevel(2)}
                            disabled={availableLevels.indexOf(2) === -1}
                            type="button"
                            className={`btn btn-${currentLevel !== 2 ? 'outline-' : ''}${
                              availableLevels.indexOf(2) === -1 ? 'secondary' : 'primary'
                            }`}
                          >
                            {t('Level')} 2
                          </button>
                          <button
                            onClick={(_) => setCurrentLevel(3)}
                            disabled={availableLevels.indexOf(3) === -1}
                            type="button"
                            className={`btn btn-${currentLevel !== 3 ? 'outline-' : ''}${
                              availableLevels.indexOf(3) === -1 ? 'secondary' : 'primary'
                            }`}
                          >
                            {t('Level')} 3
                          </button>
                          <button
                            onClick={(_) => setCurrentLevel(4)}
                            disabled={availableLevels.indexOf(4) === -1}
                            type="button"
                            className={`btn btn-${currentLevel !== 4 ? 'outline-' : ''}${
                              availableLevels.indexOf(4) === -1 ? 'secondary' : 'primary'
                            }`}
                          >
                            {t('Level')} 4
                          </button>
                        </div>
                      )}
                      {(!levelMode || levelMode === 'dropdown') && (
                        <div className={levelMode ? levelMode : 'visible-1024-plus'}>
                          <button
                            id="btnGroupDrop2"
                            type="button"
                            className="btn btn-primary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {t('Level')} {currentLevel}
                          </button>
                          <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <div>
                              <button
                                onClick={(_) => setCurrentLevel(1)}
                                disabled={availableLevels.indexOf(1) === -1}
                                type="button"
                                className={`dropdown-item`}
                              >
                                {t('Level')} 1
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={(_) => setCurrentLevel(2)}
                                disabled={availableLevels.indexOf(2) === -1}
                                type="button"
                                className={`dropdown-item`}
                              >
                                {t('Level')} 2
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={(_) => setCurrentLevel(3)}
                                disabled={availableLevels.indexOf(3) === -1}
                                type="button"
                                className={`dropdown-item`}
                              >
                                {t('Level')} 3
                              </button>
                            </div>
                            <div>
                              <button
                                onClick={(_) => setCurrentLevel(4)}
                                disabled={availableLevels.indexOf(4) === -1}
                                type="button"
                                className={`dropdown-item`}
                              >
                                {t('Level')} 4
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* By Development Domain Filter */}
                    <div className="btn-group btn-group-sm" role="group">
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {t(currentDevelopmentalDomainFilter.title)}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        {denverDevelopmentalDomains.map((dd) => (
                          <button
                            key={dd.id}
                            className="dropdown-item"
                            onClick={(_) => setCurrentDevelopmentalDomainFilter(dd)}
                          >
                            {dd.title}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* By +, +- and - Filter */}
                    <div className="btn-group btn-group-sm" role="group">
                      <ButtonMinus
                        onClick={(_) => setFilterScore(filterScore === 'FAIL' ? '' : 'FAIL')}
                        className={'btn ' + evalBtnOutlineOrNot(filterScore, 'FAIL', 'primary')}
                      />
                      <ButtonPlusMinus
                        onClick={(_) => setFilterScore(filterScore === 'PASS_FAIL' ? '' : 'PASS_FAIL')}
                        className={'btn ' + evalBtnOutlineOrNot(filterScore, 'PASS_FAIL', 'primary')}
                      />
                      <ButtonPlus
                        onClick={(_) => setFilterScore(filterScore === 'PASS' ? '' : 'PASS')}
                        className={'btn ' + evalBtnOutlineOrNot(filterScore, 'PASS', 'primary')}
                      />
                    </div>
                  </div>

                  <div className="row">
                    {(!checklistItemsFiltered || checklistItemsFiltered.length === 0) && (
                      <div className="col-12 grid-margin">
                        {t(
                          'No items from {{developmentalDomain}} developmental domain in Level {{level}} was evaluated in the last Checklist',
                          {
                            developmentalDomain: currentDevelopmentalDomainFilter.title,
                            level: currentLevel,
                          },
                        )}
                      </div>
                    )}

                    {/* List of Checklist itens to be selected */}
                    {checklistItemsFiltered
                      .filter(fuseFilterChecklist)
                      .sort((a, b) => {
                        const valueA = `${a.level}.${getNumberFromSkillCode(a.code)}`;
                        const valueB = `${b.level}.${getNumberFromSkillCode(b.code)}`;

                        return valueA - valueB;
                      })
                      .map((item) => (
                        <div key={item.code} className="col-12">
                          <SummaryItem
                            item={item}
                            itemAdded={itemAdded(item)}
                            itemCustomized={itemCustomized(item)}
                            key={item.code}
                            setPlanItems={setPlanItems}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ChildIndividualizedPlanEditChecklistSelect;
