import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EditableStepsGroup } from '../../../../../components';
import { DevelopmentalDomainSelect, LevelSelect } from '../../../components';
import useChildIndividualizedPlanItemCustomSkillEditModel from './useChildIndividualizedPlanItemCustomSkillEditModel';

function ChildIndividualizedPlanItemCustomSkillEdit({
  artificialKey,
  individualizedPlan,
  individualizedPlanSkill,
  onDelete,
}) {
  const { t } = useTranslation();

  const {
    busyStatus,
    deleted,
    planItem,
    assessmentSteps,
    savePlanItem,
    deletePlanItem,
    saveIndividualizedPlanSkillStep,
    removeIndividualizedPlanSkillStep,
  } = useChildIndividualizedPlanItemCustomSkillEditModel(individualizedPlan, individualizedPlanSkill);

  // component did mount
  useLayoutEffect(() => {
    window.jQuery('#addObjectiveForm_' + artificialKey).validate({
      rules: {
        objectiveTitle: {
          required: true,
          minlength: 5,
        },
        objectiveInstruction: {
          required: true,
          minlength: 10,
        },
      },
      messages: {
        objectiveTitle: {
          required: t('Please enter the objective title'),
          minlength: t('X must consist of at least Y characters', {
            x: t('Title'),
            y: '5',
          }),
        },
        objectiveInstruction: {
          required: t('Please enter an objective description'),
          minlength: t('X must consist of at least Y characters', {
            x: t('Objective instructions'),
            y: '10',
          }),
        },
      },
      errorPlacement: function (label, element) {
        label.addClass('mt-2 text-danger');
        label.insertAfter(element);
      },
      highlight: function (element, errorClass) {
        window.jQuery(element).parent().addClass('has-danger');
        window.jQuery(element).addClass('form-control-danger');
      },
    });
  }, [artificialKey, t]);

  if (deleted) {
    return <></>;
  }

  const removeFromPlan = () => {
    const swalWithBootstrapButtons = window.Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t('Are you sure?'),
        text: t('You will lose all the changes made to this skill'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'ml-2',
        confirmButtonText: t('Confirm'),
        cancelButtonText: t('Cancel'),
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          deletePlanItem((result, err) => {
            if (result) {
              onDelete(individualizedPlanSkill);
            } else {
              swalWithBootstrapButtons.fire(t('Error'), t('Error removing Skill from this Plan'), 'error');
            }
          });
        }
      });
  };

  let saveButtonLabel = t('Save changes');
  let saveButtonClassName = 'btn-primary';
  if (busyStatus === 'loading') {
    saveButtonLabel = t('Saving') + '...';
    saveButtonClassName = 'btn-info';
  }
  if (busyStatus === 'error') {
    saveButtonLabel = t('Error');
    saveButtonClassName = 'btn-danger';
  }
  if (busyStatus === 'success') {
    saveButtonLabel = t('Successfully saved') + '!';
    saveButtonClassName = 'btn-success';
  }

  return (
    <>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            {planItem && (
              <form
                id={'addObjectiveForm_' + artificialKey}
                onSubmit={(e) => {
                  e.preventDefault();
                  window.jQuery('#addObjectiveForm_' + artificialKey).valid() && savePlanItem();
                }}
              >
                <div className="p-2 bg-lighter">
                  <div className="mb-2">
                    <DevelopmentalDomainSelect
                      selectedDevelopmentalDomain={planItem.skill.developmentalDomain}
                      className="btn-outline-secondary mr-2"
                      onInit={(sd) =>
                        planItem.skill.developmentalDomain ? (planItem.skill.developmentalDomain = sd) : ''
                      }
                      onSelectDevelopmentalDomain={(sd) => (planItem.skill.developmentalDomain = sd)}
                    />
                    <LevelSelect
                      level={planItem.skill.level}
                      levelMode="dropdown"
                      className="btn-outline-secondary"
                      onSelectLevel={(lv) => (planItem.skill.level = lv)}
                    />
                  </div>
                  <h4 className="card-title title-form">
                    <input
                      name="objectiveTitle"
                      style={{ fontSize: '1.2rem' }}
                      type="text"
                      required
                      className="form-control no-border"
                      placeholder={t('Objective title')}
                      defaultValue={planItem.skill.title}
                      onChange={(e) => (planItem.skill.title = e.target.value.replace(/"/g, "'"))}
                    />
                  </h4>
                  <div className="mt-2">
                    <textarea
                      name="objectiveInstruction"
                      className="form-control no-border"
                      required
                      placeholder={t('Description')}
                      rows={3}
                      defaultValue={planItem.skill.description}
                      onChange={(e) => (planItem.skill.description = e.target.value.replace(/"/g, "'"))}
                    ></textarea>
                  </div>
                  <div className="mt-2">
                    <textarea
                      name="objectiveInstruction"
                      className="form-control no-border"
                      required
                      placeholder={t('Session Tips')}
                      rows={3}
                      defaultValue={planItem.skill.objectiveInstruction}
                      onChange={(e) => (planItem.skill.objectiveInstruction = e.target.value.replace(/"/g, "'"))}
                    ></textarea>
                  </div>
                  <div className="mt-4 d-flex flex-row justify-content-between">
                    <button type="submit" className={'btn ' + saveButtonClassName} disabled={busyStatus === 'loading'}>
                      {saveButtonLabel}
                    </button>
                  </div>
                </div>
                {planItem.id && (
                  <div className="mt-2 mb-2 p-4 bg-lighter">
                    <EditableStepsGroup
                      item={planItem}
                      title={t('Objective Assessment Steps')}
                      codePrefix={planItem.skill.code}
                      assessmentSteps={assessmentSteps}
                      saveStep={saveIndividualizedPlanSkillStep}
                      deleteStep={removeIndividualizedPlanSkillStep}
                    />
                  </div>
                )}
                <div className="d-flex flex-row justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    disabled={!planItem.id}
                    onClick={(_) => removeFromPlan()}
                  >
                    {t('Remove from Plan')}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChildIndividualizedPlanItemCustomSkillEdit;
