import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAllDevelopmentalDomains } from '../../../services/denver';

export function DevelopmentalDomainSelect({
  selectedDevelopmentalDomain,
  className,
  onSelectDevelopmentalDomain,
  onInit,
}) {
  const [busy, setBusy] = useState(false);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState();
  const [currentDevelopmentalDomainFilter, setCurrentDevelopmentalDomainFilter] = useState(selectedDevelopmentalDomain);
  const [denverDevelopmentalDomains, setDenverDevelopmentalDomains] = useState([]);

  const { t } = useTranslation();

  useEffect(
    (_) => {
      setBusy(true);

      getAllDevelopmentalDomains((denverDevelopmentalDomains, err) => {
        if (!err) {
          setDenverDevelopmentalDomains(denverDevelopmentalDomains);
          if (!selectedDevelopmentalDomain) {
            setCurrentDevelopmentalDomainFilter(denverDevelopmentalDomains[0]);
            onInit(denverDevelopmentalDomains[0]);
          } else {
            onInit(selectedDevelopmentalDomain);
          }
        } else {
          setLoadingErrorMessage(t('There was a problem fetching Denver Developmental Domains'));
        }
        setBusy(false);
      });
    },
    [t],
  );

  useEffect(() => {
    if (onSelectDevelopmentalDomain) {
      onSelectDevelopmentalDomain(currentDevelopmentalDomainFilter);
    }
  }, [currentDevelopmentalDomainFilter, onSelectDevelopmentalDomain]);

  return (
    <div className="btn-group btn-group-sm" role="group">
      <button
        id="btnGroupDrop1"
        type="button"
        className={`btn ${className} dropdown-toggle`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {!busy && t(currentDevelopmentalDomainFilter ? currentDevelopmentalDomainFilter.title : '')}
        {busy && t('loading')}
        {loadingErrorMessage && '' + loadingErrorMessage}
      </button>
      <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
        {denverDevelopmentalDomains.map((dd) => (
          <button key={dd.id} className="dropdown-item" onClick={(_) => setCurrentDevelopmentalDomainFilter(dd)}>
            {dd.title}
          </button>
        ))}
      </div>
    </div>
  );
}
