import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAdminForm, updateAdmin } from '../../services/admin';
import useUserContext from '../../useUserContext';

export default () => {
  const { t } = useTranslation();
  const { loggedUser } = useUserContext();
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [busy, setBusy] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState('');
  const [formSubmissionMessageType, setFormSubmissionMessageType] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');

  useEffect(() => {
    setBusy(true);
    if (loggedUser.role === 'admin' || loggedUser.role === 'relatives') {
      getAdminForm(loggedUser.email, (result, err) => {
        if (!err) {
          if (!phone) setPhone(result.phone);
          if (!name) setName(result.name);
        } else {
          console.error(err);
        }
      });
    }
    setBusy(false);
  }, [loggedUser.role, loggedUser.email, phone, name]);

  const update = () => {
    setSubmitting(true);
    var id = loggedUser.id;
    if (loggedUser.adminId) id = loggedUser.adminId;
    updateAdmin(id, name, loggedUser.email, password, phone, (_, err) => {
      if (!err) {
        setFormSubmissionMessageType('success');
        setFormSubmissionMessage(t('Successfully updated information!'));
        setSubmitting(false);
      } else {
        var errText = t('Error updating this information');
        if (err.status === 450) {
          errText = t('Error registering (Invalid name)');
        } else if (err.status === 455) {
          errText = t('Error registering (Invalid email)');
        } else if (err.status === 460) {
          errText = t('Error registering (Invalid password)');
        } else if (err.status === 465) {
          errText = t('Error registering (Email already exists)');
        }
        setFormSubmissionMessageType('fail');
        setFormSubmissionMessage(errText);
        setSubmitting(false);
      }
    });
  };

  return {
    loggedUser,
    password,
    setPassword,
    phone,
    setPhone,
    name,
    setName,
    update,

    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    loadingErrorMessage,
    busy,
  };
};
