import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

const disableSentry = process.env.REACT_APP_SENTRY_DISABLE;

if (!disableSentry) {
  Sentry.init({
    dsn: 'https://4a8d49c6830547ca88b55a013b687de5@o4504728457117696.ingest.us.sentry.io/4504728488771584',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error', 'debug'],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
