import React from 'react';
import { useTranslation } from 'react-i18next';

import AccessControl from '../AccessControl';

export default ({ onLogout }) => {
  const { t } = useTranslation();

  return (
    <nav className="sidebar">
      <div className="sidebar-header">
        <a href="/" className="sidebar-brand">
          Early<span>Denver</span>
        </a>
        <div className="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="sidebar-body">
        <ul className="nav">
          <li className="nav-item nav-category">{t('Therapeutic Work')}</li>
          <li className="nav-item">
            <a href="/dashboard" className="nav-link">
              <i className="link-icon" data-feather="activity"></i>
              <span className="link-title">{t('Dashboard')}</span>
            </a>
          </li>
          <AccessControl requiredPermissions={['child']}>
            <li className="nav-item">
              <a href="/children/" className="nav-link">
                <i className="link-icon" data-feather="sun"></i>
                <span className="link-title">{t('Sidebar.Children')}</span>
              </a>
            </li>
          </AccessControl>
          <AccessControl requiredPermissions={['staffMember']}>
            <li className="nav-item">
              <a href="/staff/" className="nav-link">
                <i className="link-icon" data-feather="users"></i>
                <span className="link-title">{t('Staff members')}</span>
              </a>
            </li>
          </AccessControl>
          <AccessControl requiredPermissions={['denver']} allowedRole={['admin']}>
            <li className="nav-item">
              <a href="/reports/" className="nav-link">
                <i className="link-icon" data-feather="folder"></i>
                <span className="link-title">{t('Reports')}</span>
              </a>
            </li>
          </AccessControl>
          <li className="nav-item nav-category">{t('Sidebar.Settings')}</li>
          <AccessControl requiredPermissions={['denver']}>
            <li className="nav-item">
              <a href="/denver/" className="nav-link">
                <i className="link-icon" data-feather="check-circle"></i>
                <span className="link-title">{t('ESDM Skills')}</span>
              </a>
            </li>
          </AccessControl>
          {/* <AccessControl requiredPermissions={["behavior"]}>
            <li className="nav-item">
              <a href="/behavior/" className="nav-link">
                <i className="link-icon" data-feather="shuffle"></i>
                <span className="link-title">{t("Behaviors")}</span>
              </a>
            </li>
          </AccessControl> */}
          <li className="nav-item nav-category">{t('User')}</li>
          <li className="nav-item">
            <a href="/profile/" className="nav-link">
              <i className="link-icon" data-feather="user"></i>
              <span className="link-title">{t('My Profile')}</span>
            </a>
          </li>
          <li className="nav-item">
            <span className="nav-link actionable" onClick={(_) => onLogout()}>
              <i className="link-icon" data-feather="log-out"></i>
              <span className="link-title">{t('Log Out')}</span>
            </span>
          </li>
        </ul>
      </div>
    </nav>
  );
};
