import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccessControl from '../../AccessControl';
import { FormSubmissionMessage, Loading } from '../../components';
import useStaffMemberRegisterFormModel from './useStaffMemberRegisterFormModel';

function StaffMemberRegisterForm(idStaffMember) {
  const { t } = useTranslation();
  const {
    active,
    setActive,
    name,
    setName,
    password,
    setPassword,
    phone,
    setPhone,
    email,
    setEmail,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    profession,
    setProfession,
    professionTypes,

    save,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    busy,
    loadingErrorMessage,
    currentId,
  } = useStaffMemberRegisterFormModel(idStaffMember);

  // component did mount
  useEffect(() => {
    window.jQuery(':input').inputmask();

    window.jQuery.validator.addMethod(
      'oneChoiceRequired',
      (_) => {
        return gender !== 'NotInformed';
      },
      t('Please choose one of the options'),
    );

    window.jQuery('#staffMemberRegisterForm').validate({
      rules: {
        fullNameRelative: {
          required: true,
          minlength: 3,
        },
        emailRelative: {
          email: true,
        },
        passwordRelative: {
          required: currentId ? false : true,
        },
        genderRelative: {
          oneChoiceRequired: true,
        },
      },
      messages: {
        fullNameRelative: {
          required: t('Please enter a name'),
          minlength: t('X must consist of at least Y characters', {
            x: 'Name',
            y: '3',
          }),
        },
        emailRelative: {
          required: t('Please enter an email'),
        },
        passwordRelative: {
          required: t('Please enter an password'),
        },
        genderRelative: {
          oneChoiceRequired: t('Please choose one of the options'),
        },
      },
      errorPlacement: function (label, element) {
        label.addClass('mt-2 text-danger');
        label.insertAfter(element);
      },
      highlight: function (element, errorClass) {
        window.jQuery(element).parent().addClass('has-danger');
        window.jQuery(element).addClass('form-control-danger');
      },
    });
  }, [t, gender, currentId, busy]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          {loadingErrorMessage && (
            <div className="alert alert-primary" role="alert">
              {loadingErrorMessage}
            </div>
          )}
          {!loadingErrorMessage && busy && <Loading loadingText={t('Fetching staff member details')} />}
          {!busy && !loadingErrorMessage && (
            <>
              <h6 className="card-title">{t('Personal Info')}</h6>
              <form
                id="staffMemberRegisterForm"
                className="forms-child-info"
                onSubmit={(e) => {
                  e.preventDefault();
                  window.jQuery('#staffMemberRegisterForm').valid() && save();
                }}
              >
                <div className="form-group">
                  <label htmlFor="fullNameRelative">
                    {t('Activated')}
                    <input
                      id="active"
                      name="active"
                      onChange={() => setActive(!active)}
                      type="checkbox"
                      className="form-control"
                      checked={active}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label htmlFor="fullNameRelative">{t('Full name')}</label>

                  <input
                    id="fullNameRelative"
                    name="fullNameRelative"
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    placeholder={t('placeholder.Barbara Russel')}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="phoneRelative">{t('Phone number')}</label>
                  {!phone && (
                    <input
                      id="phoneRelative"
                      name="phoneRelative"
                      defaultValue={phone}
                      onBlur={(e) => setPhone(e.target.value)}
                      className="form-control mb-4 mb-md-0"
                      data-inputmask-alias={t('(999) 9999-9999')}
                      im-insert="true"
                    />
                  )}
                  {phone && (
                    <input
                      id="phoneRelative"
                      name="phoneRelative"
                      defaultValue={phone}
                      onBlur={(e) => setPhone(e.target.value)}
                      className="form-control mb-4 mb-md-0"
                      data-inputmask-alias={t('(999) 9999-9999')}
                      im-insert="false"
                    />
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="emailRelative">E-mail</label>
                  {!currentId && (
                    <input
                      id="emailRelative"
                      name="emailRelative"
                      type="email"
                      defaultValue={email}
                      onBlur={(e) => setEmail(e.target.value)}
                      className="form-control"
                      autoComplete="off"
                      placeholder="_@_._"
                      im-insert="true"
                    />
                  )}
                  {currentId && (
                    <input
                      id="emailRelative"
                      name="emailRelative"
                      type="text"
                      disabled
                      className="form-control"
                      value={email}
                    />
                  )}
                </div>

                <div className="form-group">
                  {!currentId && <label htmlFor="passwordRelative">{t('Password')}</label>}
                  {currentId && <label htmlFor="passwordRelative">{t('New Password')}</label>}
                  <input
                    id="passwordRelative"
                    name="passwordRelative"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    autoComplete="off"
                    placeholder={t('Password')}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="dateOfBirthRelative">{t('Date of birth')}</label>
                  {!dateOfBirth && (
                    <input
                      id="dateOfBirthRelative"
                      name="dateOfBirthRelative"
                      defaultValue={dateOfBirth}
                      onBlur={(e) => setDateOfBirth(e.target.value)}
                      className="form-control mb-4 mb-md-0"
                      data-inputmask="'alias': 'datetime'"
                      data-inputmask-inputformat={t('MM/DD/YYYY').toLowerCase()}
                      data-inputmask-placeholder="*"
                      im-insert="false"
                    />
                  )}
                  {dateOfBirth && (
                    <input
                      id="dateOfBirthRelative"
                      name="dateOfBirthRelative"
                      defaultValue={dateOfBirth}
                      onBlur={(e) => setDateOfBirth(e.target.value)}
                      className="form-control mb-4 mb-md-0"
                      data-inputmask="'alias': 'datetime'"
                      data-inputmask-inputformat={t('MM/DD/YYYY').toLowerCase()}
                      im-insert="false"
                    />
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="genderRelative">{t('Man or Woman?')}</label>
                  <div>
                    <button
                      type="button"
                      onClick={(_) => setGender('MALE')}
                      className={'btn ' + (gender === 'MALE' ? 'btn-info' : 'btn-light') + ' mr-2 boy-button '}
                    >
                      <i className="mdi mdi-human-male side-icon"></i> {t('Man')}
                    </button>
                    <button
                      type="button"
                      onClick={(_) => setGender('FEMALE')}
                      className={'btn ' + (gender === 'FEMALE' ? 'btn-danger' : 'btn-light') + ' mr-2 girl-button '}
                    >
                      <i className="mdi mdi-human-female side-icon"></i> {t('Woman')}
                    </button>
                  </div>

                  {/** This input is just to anchor the jQuery validation message  */}
                  <input
                    id="genderRelative"
                    defaultValue={gender}
                    name="genderRelative"
                    style={{
                      width: 0,
                      height: 0,
                      opacity: 0,
                      display: 'block',
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="profession">{t('Profession')}</label>
                  <div>
                    <div className="btn-group">
                      <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {profession && t(profession.title)}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {professionTypes.map((fr) => (
                          <span onClick={() => setProfession(fr)} key={fr.id} className="dropdown-item actionable">
                            {' '}
                            {t(fr.title)}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <FormSubmissionMessage message={formSubmissionMessage} type={formSubmissionMessageType} />

                {!submitting && (
                  <>
                    {currentId && currentId !== 'new' && (
                      <AccessControl requiredPermissions={['updateStaffMember:*', 'updateStaffMember:' + currentId]}>
                        <button type="submit" className="btn btn-primary mr-2">
                          {t('Save')}
                        </button>
                      </AccessControl>
                    )}
                    {(!currentId || currentId === 'new') && (
                      <AccessControl requiredPermissions={['createStaffMember']}>
                        <button type="submit" className="btn btn-primary mr-2">
                          {t('Save')}
                        </button>
                      </AccessControl>
                    )}
                    <a href="/staff/" className="btn btn-light">
                      {t('Back')}
                    </a>
                  </>
                )}
                {submitting && (
                  <button type="submit" disabled className="btn btn-primary mr-2">
                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    {t('Submitting') + '...'}
                  </button>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StaffMemberRegisterForm;
