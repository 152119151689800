import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Fuse from 'fuse.js';
import Inputmask from 'inputmask';
import moment from 'moment';

import AccessControl from '../../../AccessControl';
import { useChildIndividualizedPlanContext } from './ChildIndividualizedPlanContext';
import {
  ChildIndividualizedPlanEditChecklistSelect,
  ChildIndividualizedPlanItemCustomSkillEdit,
  ChildIndividualizedPlanItemEdit,
  SelectedObjectives,
} from './components';
import { ChildIndividualizedPlanReadOnly } from './contents';

function ChildIndividualizedPlan() {
  const { t } = useTranslation();
  const { childId, planId } = useParams();

  const {
    checklistId,
    editMode,
    planData,
    editStep,
    setEditStep,
    setIndividualizedPlanDate,
    onPlanItemRemoved,
    onCustomPlanItemRemoved,
    isSkillAdded,
    isSkillCustomized,
    createNewCustomSkill,
    updatePlanItems,
  } = useChildIndividualizedPlanContext();

  const { planItems, customPlanItems, individualizedPlan } = planData;

  useLayoutEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  });

  // edit plan date
  const $ = window.$;
  $(document).ready(function () {
    Inputmask().mask(document.querySelectorAll('input'));
  });
  let [dateTime, setDateTime] = useState({ m: null });
  const onSaveDateTime = () => {
    if (dateTime.m !== null) {
      individualizedPlan.date = moment(dateTime.m, t('MM/DD/YYYY-')).toISOString();
    }
    setIndividualizedPlanDate(individualizedPlan);
  };

  // Search
  const [query] = useState('');
  const options = {
    // minMatchCharLength: 3,
    keys: ['skill.title', 'skill.description'],
    distance: 100,
    threshold: 0.3,
  };
  var fs = individualizedPlan ? individualizedPlan.skills : '';
  const fuse = new Fuse(fs, options);
  // function onSearch({ currentTarget }) {
  //   updateQuery(currentTarget.value);
  // }
  const results = fuse.search(query);
  const filterFuseSkillSteps = (el) => {
    if (results.length > 0) {
      for (let rs of results) {
        if (rs.item.skill.id === el.denverChecklistSkillAssessmentStep.skill.id) return true;
      }
    } else {
      return true;
    }
  };
  const filterFuseFun = (individualizedPlan) => {
    var individualizedPlanFiltered = {};
    if (individualizedPlan != null) {
      individualizedPlanFiltered.child = individualizedPlan.child;
      individualizedPlanFiltered.createdBy = individualizedPlan.createdBy;
      individualizedPlanFiltered.date = individualizedPlan.date;
      individualizedPlanFiltered.id = individualizedPlan.id;
      individualizedPlanFiltered.sessions = individualizedPlan.sessions;
      individualizedPlanFiltered.skillSteps = individualizedPlan.skillSteps.filter(filterFuseSkillSteps);
      individualizedPlanFiltered.skills = individualizedPlan.skills;
    }
    return individualizedPlanFiltered;
  };
  const filterFuseItems = (el) => {
    if (results.length > 0) {
      for (let rs of results) {
        if (rs.item.skill.id === el.skill.id) return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <SelectedObjectives />
                </div>
              </div>
            </div>
          </form>
          {childId && (
            <div className="d-flex flex-row">
              <AccessControl
                requiredPermissions={[
                  'child:*:createChildIndividualizedPlan',
                  'child:' + childId + ':createChildIndividualizedPlan',
                ]}
              >
                <div className="flex-ccc">
                  <a href={`/children/${childId}/plan/new`}>
                    <button type="button" className="btn btn-outline-primary mr-2 mobile-hidden">
                      {t('Register new Intervention Plan')}
                    </button>
                  </a>
                </div>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  'child:*:createChildIndividualizedPlanSession',
                  'child:' + childId + ':createChildIndividualizedPlanSession',
                ]}
              >
                <div className="flex-ccc">
                  <a href={`/children/${childId}/session/new`}>
                    <button type="button" className="btn btn-outline-primary">
                      {t('Register new Intervention Session')}
                    </button>
                  </a>
                </div>
              </AccessControl>
            </div>
          )}
        </div>
      </nav>

      <div className="page-content">
        {individualizedPlan && (
          <>
            <nav className="page-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="/children">{t('Sidebar.Children')}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href={`/children/${individualizedPlan.child.id}`}>{individualizedPlan.child.person.name}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {individualizedPlan && (
                    <a href={`/children/${individualizedPlan.child.id}/plan/${planId}`}>
                      {t('Individualized Plan')} ({moment(individualizedPlan.date).format(t('MM/DD/YYYY'))})
                    </a>
                  )}
                  {!individualizedPlan && t('Register new Intervention Plan')}
                </li>
              </ol>
            </nav>
          </>
        )}

        {/* View Plan */}
        {!editMode && (
          <ChildIndividualizedPlanReadOnly
            individualizedPlan={filterFuseFun(individualizedPlan)}
            childId={childId}
            planId={planId}
            showChildName={true}
            showPlanSelection={true}
          />
        )}

        {/* Edit Plan */}
        {editMode && checklistId && individualizedPlan && individualizedPlan.child && (
          <>
            <AccessControl
              requiredPermissions={[
                'child:*:updateChildIndividualizedPlan:*',
                'child:' + childId + ':updateChildIndividualizedPlan:*',
                'child:*:updateChildIndividualizedPlan:' + individualizedPlan.id,
                'child:' + childId + ':updateChildIndividualizedPlan:' + individualizedPlan.id,
              ]}
            >
              <div className="row">
                <h4 className="page-title col-lg-6 mb-2">{t('Therapeutic Goals')} </h4>
                <div className="col-lg-6 row justify-content-end align-items-start mb-2">
                  <form
                    id="dateTimeForm"
                    className="forms-child-info"
                    onSubmit={(e) => {
                      e.preventDefault();
                      onSaveDateTime();
                    }}
                  >
                    <div className="d-flex  align-items-center">
                      <input
                        className="form-control mr-2"
                        id="dateTime"
                        name="dateTime"
                        defaultValue={moment(individualizedPlan.date).format(t('MM/DD/YYYY-'))}
                        data-inputmask-placeholder="*"
                        im-insert="false"
                        onBlur={(e) => setDateTime({ m: e.target.value })}
                        data-inputmask="'alias': 'datetime'"
                        data-inputmask-inputformat={t('MM/DD/YYYY+')}
                      />
                      {dateTime.m !== null && (
                        <button type="submit" className="btn btn-secondary btn-sm mr-2">
                          {t('Save')}
                        </button>
                      )}
                    </div>
                  </form>
                  <div className="d-flex justify-content-end align-items-center">
                    <a
                      href={`/children/${individualizedPlan.child.id}/plan/${individualizedPlan.id}?mode=readonly`}
                      className="btn btn-outline-info ml-2"
                    >
                      {t('View this Plan')}
                    </a>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <ul className="nav nav-tabs">
                    <li className="nav-item" onClick={(_) => setEditStep(1)}>
                      <div className={'actionable nav-link ' + (editStep === 1 ? 'active' : '')}>
                        1. {t('Select Checklist Items')}
                      </div>
                    </li>
                    {/* <li className="nav-item" onClick={(_) => setEditStep(2)}>
                      <div className={'actionable nav-link ' + (editStep === 2 ? 'active' : '')}>
                        2. {t('Detail Plan Items')}
                        <span className="ml-1 text-primary">({planItems.length})</span>
                      </div>
                    </li> */}
                    <li className="nav-item" onClick={(_) => setEditStep(3)}>
                      <div className={'actionable nav-link ' + (editStep === 3 ? 'active' : '')}>
                        2. {t('Custom objectives')}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Select PLan Itens Tab */}
              {editStep === 1 && (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <ChildIndividualizedPlanEditChecklistSelect
                      filterFuseResults={results}
                      individualizedPlan={individualizedPlan}
                      childId={childId}
                      checklistId={checklistId}
                      levelMode="dropdown"
                      setPlanItems={updatePlanItems}
                      planItems={planItems}
                      itemAdded={(skill) => isSkillAdded(skill)}
                      itemCustomized={(skill) => isSkillCustomized(skill)}
                    />
                  </div>
                </div>
              )}
              {/* Detail Plan Itens Tab */}
              {editStep === 2 && (
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body main-content">
                        {planItems.filter(filterFuseItems).map((planItem) => (
                          <ChildIndividualizedPlanItemEdit
                            individualizedPlan={individualizedPlan}
                            key={planItem.skill.id}
                            planItem={planItem}
                            onDelete={(_) => onPlanItemRemoved(planItem)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Custom Plan Objectives Tab */}
              {editStep === 3 && (
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body main-content">
                        {customPlanItems.filter(filterFuseItems).map((planItem, idx) => (
                          <ChildIndividualizedPlanItemCustomSkillEdit
                            key={'CustomSkill-' + planItem.id + '_' + idx}
                            artificialKey={'CustomSkill-' + planItem.id}
                            individualizedPlan={individualizedPlan}
                            individualizedPlanSkill={planItem}
                            onDelete={(_) => onCustomPlanItemRemoved(planItem)}
                          />
                        ))}
                        {(!customPlanItems || customPlanItems.length === 0) && (
                          <div style={{ color: '#9999' }}>{t('No custom objectives created yet')}</div>
                        )}
                        <div className="mt-4">
                          <button className={'btn btn-success'} onClick={(_) => createNewCustomSkill()}>
                            {t('Add new custom objective')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </AccessControl>
          </>
        )}
      </div>
    </>
  );
}

export default ChildIndividualizedPlan;
