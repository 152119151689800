import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

function ChecklistDevelopmentalDomainStatsRadar({ childId, lastChecklistData }) {
  const { t } = useTranslation();
  const timer = useRef();

  if (lastChecklistData.checklistState === 'EMPTY') {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        <Trans i18nKey="ZZLongTexts.EmptyChecklistsOnStats">
          No items evaluated in the last checklist.
          <a href={`/children/${childId}/checklist/${lastChecklistData.id}`} target="_blank" rel="noopener noreferrer">
            Click here to view the checklist
          </a>
        </Trans>
      </div>
    );
  }

  if (!lastChecklistData.unifiedDevelopmentDomainsRatios) {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        {t('Loading')}
      </div>
    );
  }

  const values = lastChecklistData.unifiedDevelopmentDomainsRatios
    .filter((x) => x.typicalDevelopmentWeightingTotalPoints)
    .map((a) => (a.developmentFactor * 100).toFixed(2));

  clearTimeout(timer.current);
  timer.current = setTimeout(() => {
    var options = {
      chart: {
        animations: { enabled: false },
        type: 'radar',
        height: 350,
      },
      series: [
        {
          name: t('Development Score'),
          data: values,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: '#e9e9e9',
            fill: {
              colors: ['#f8f8f8', '#fff'],
            },
          },
        },
      },

      colors: ['#d53734'],
      markers: {
        size: 3,
        colors: ['#fff'],
        strokeColor: '#d53734',
        strokeWidth: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        categories: lastChecklistData.unifiedDevelopmentDomainsRatios
          .filter((x) => x.typicalDevelopmentWeightingTotalPoints)
          .map((dd) => t(dd.developmentalDomain.title)),
      },
      yaxis: {
        tickAmount: 5,
        max: 100,
        labels: {
          formatter: function (val, _i) {
            return val.toFixed(0);
          },
        },
      },
    };

    var chart = new window.ApexCharts(
      document.querySelector(`#ChecklistDevelopmentalDomainStatsRadar-${childId}`),
      options,
    );
    if (chart) {
      chart.render();
    }
  }, 400);

  return <div id={`ChecklistDevelopmentalDomainStatsRadar-${childId}`} style={{ minHeight: '300px' }}></div>;
}

export default ChecklistDevelopmentalDomainStatsRadar;
