import React from 'react';
import { useTranslation } from 'react-i18next';

import { createIndividualizedPlanSkill, deleteIndividualizedPlanSkill } from '../../../../services/individualizedPlan';
import { useChildIndividualizedPlanContext } from '../../childIndividualizedPlan/ChildIndividualizedPlanContext';

export default () => {
  const { planData, planDataDispatcher, onPlanItemRemoved, onCustomPlanItemRemoved } =
    useChildIndividualizedPlanContext();
  const { individualizedPlan, planItems, customPlanItems } = planData;
  const [isLoading, setIsLoading] = React.useState(false);

  const { t } = useTranslation();

  /**
   * It takes in a skill and adds it to the plan
   * @param skill - The skill that you want to add to the plan.
   */
  const addSkillToPlan = async (skill) => {
    setIsLoading(true);

    await createIndividualizedPlanSkill({ individualizedPlan, skill })
      .then((newSkill) => {
        planDataDispatcher({
          type: 'addPlanItem',
          payload: { id: newSkill.id, skill: skill, objectiveInstruction: skill.objectiveInstruction },
        });

        setIsLoading(false);
      })
      .catch(() => {
        window.Swal.fire({
          type: 'error',
          title: t('Error'),
          text: t('There was an error adding the skills and skill steps. Please unselect and select again'),
        });
        setIsLoading(false);
      });
  };

  /**
   * Removes an non-customized skill from a plan.
   * @param planSkill - The skill that is being removed from the plan.
   */
  const removeSkillFromPlan = async (planSkill) => {
    const planItem = planItems.find((pi) => pi.skill.id === planSkill.id);
    setIsLoading(true);

    const deletionQuery = await deleteIndividualizedPlanSkill(individualizedPlan, planItem);

    if (deletionQuery.error) {
      console.error(deletionQuery);
      setIsLoading(false);
      return window.Swal.fire({
        icon: 'error',
        title: t('Error'),
        text: t('There was an error making this evaluation'),
      });
    }

    onPlanItemRemoved(planItem);
    setIsLoading(false);
  };

  /**
   * It removes a customized skill from a plan
   * @param customizedPlanSkill - The skill that the user wants to remove from the
   * plan.
   */
  const removeCustomizedSkillFromPlan = (customizedPlanSkill) => {
    const customPlanItem = customPlanItems.find((pi) => pi.skill.skillFrom.id === customizedPlanSkill.id);
    setIsLoading(true);

    deleteIndividualizedPlanSkill(individualizedPlan, customPlanItem, (result, err) => {
      setIsLoading(false);

      if (!err) {
        onCustomPlanItemRemoved(customPlanItem);
      } else {
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('There was an error making this evaluation'),
        });
      }
    });
  };

  return {
    isLoading,
    addSkillToPlan,
    removeSkillFromPlan,
    removeCustomizedSkillFromPlan,
  };
};
