import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import moment from 'moment';

import { FormSubmissionMessage } from '../../../../components';
import { getIntervalInMonths, isBirthdayWeek } from '../../../../utils/time';
import useChildRegisterFormModel from './useChildRegisterFormModel';

export function ChildRegisterForm() {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    active,
    setActive,
    name,
    setName,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    videoURL,
    setVideoURL,
    dateOfBirthComplete,
    save,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
  } = useChildRegisterFormModel();

  // component did mount
  useEffect(() => {
    window.jQuery(':input').inputmask();
    window.jQuery.validator.addMethod(
      'oneChoiceRequired',
      (_) => {
        return gender !== 'NotInformed';
      },
      t('Please choose one of the options'),
    );

    window.jQuery('#registerForm').validate({
      rules: {
        fullName: {
          required: true,
          minlength: 3,
        },
        dateOfBirth: {
          required: true,
        },
        gender: {
          oneChoiceRequired: true,
        },
      },
      messages: {
        fullName: {
          required: t('Please enter a name'),
          minlength: t('X must consist of at least Y characters', {
            x: 'Name',
            y: '3',
          }),
        },
        dateOfBirth: {
          required: t('Please enter a date of birth'),
        },
        gender: {
          oneChoiceRequired: t('Please choose one of the options'),
        },
      },
      errorPlacement: function (label, element) {
        label.addClass('mt-2 text-danger');
        label.insertAfter(element);
      },
      highlight: function (element, errorClass) {
        window.jQuery(element).parent().addClass('has-danger');
        window.jQuery(element).addClass('form-control-danger');
      },
    });
  }, [t, gender]);

  return (
    <>
      {isBirthdayWeek(dateOfBirthComplete) === true && (
        <div>
          <small className="text-primary">
            {' '}
            <span className="mr-1" role="img" aria-labelledby="birthday party emoji">
              🥳
            </span>
            {t('birthday on')} {moment(dateOfBirthComplete).format(t('MM/DD'))}
            <span className="ml-1" role="img" aria-labelledby="party popper emoji">
              🎉
            </span>
          </small>
        </div>
      )}
      <h6 className="card-title">{t('Personal Info')}</h6>
      <form
        id="registerForm"
        className="forms-child-info"
        onSubmit={(e) => {
          e.preventDefault();
          window.jQuery('#registerForm').valid() && save();
        }}
      >
        <div className="form-check form-check-primary">
          <label className="form-check-label">
            <input
              id="active"
              name="active"
              onChange={() => setActive(!active)}
              type="checkbox"
              className="form-check-input"
              checked={active}
            />
            {t('Active')}
          </label>
        </div>

        <div className="form-group">
          <label htmlFor="fullName">{t('Full name')}</label>
          {(!id || id === 'new') && (
            <input
              id="fullName"
              name="fullName"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              required
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder={t('placeholder.Barbara Russel')}
            />
          )}
          {id && id !== 'new' && (
            <input
              id="fullName"
              name="fullName"
              type="text"
              className="form-control"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          )}
        </div>

        <div className="form-group">
          <label htmlFor="gender">{t('Boy or Girl?')}</label>
          <div>
            <button
              type="button"
              onClick={(_) => setGender('MALE')}
              className={'btn ' + (gender === 'MALE' ? 'btn-info' : 'btn-light') + ' mr-2 boy-button '}
            >
              <i className="mdi mdi-human-male side-icon"></i> {t('Boy')}
            </button>
            <button
              type="button"
              onClick={(_) => setGender('FEMALE')}
              className={'btn ' + (gender === 'FEMALE' ? 'btn-danger' : 'btn-light') + ' mr-2 girl-button '}
            >
              <i className="mdi mdi-human-female side-icon"></i> {t('Girl')}
            </button>
          </div>

          {/** This input is just to anchor the jQuery validation message  */}
          <input
            id="gender"
            defaultValue={gender}
            name="gender"
            style={{ width: 0, height: 0, opacity: 0, display: 'block' }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="dateOfBirth">{t('Date of birth')}</label>
          {!dateOfBirth && (
            <input
              id="dateOfBirth"
              name="dateOfBirth"
              defaultValue={dateOfBirth}
              onBlur={(e) => setDateOfBirth(e.target.value)}
              className="form-control mb-4 mb-md-0"
              data-inputmask="'alias': 'datetime'"
              data-inputmask-inputformat={t('MM/DD/YYYY').toLowerCase()}
              data-inputmask-placeholder="*"
              im-insert="false"
            />
          )}
          {dateOfBirth && (
            <div>
              <input
                id="dateOfBirth"
                name="dateOfBirth"
                defaultValue={dateOfBirth}
                onBlur={(e) => setDateOfBirth(e.target.value)}
                className="form-control mb-4 mb-md-0"
                data-inputmask="'alias': 'datetime'"
                data-inputmask-inputformat={t('MM/DD/YYYY').toLowerCase()}
                im-insert="false"
              />
              <div className="mt-1 text-small">
                {getIntervalInMonths(dateOfBirth, new Date())} {t('months')}
              </div>
            </div>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="videos">{t('Session Videos')}</label>
          <input
            id="videos"
            defaultValue={videoURL}
            onChange={(e) => setVideoURL(e.target.value)}
            type="text"
            className="form-control"
            autoComplete="off"
            placeholder={t('placeholder.Facebook group URL')}
          />
        </div>

        <FormSubmissionMessage message={formSubmissionMessage} type={formSubmissionMessageType} />

        {!submitting && (
          <>
            {id && id !== 'new' && (
              <button type="submit" className={`btn btn-primary mr-2`}>
                {t('Save')}
              </button>
            )}
            {(!id || id === 'new') && (
              <button type="submit" className="btn btn-primary mr-2">
                {t('Save')}
              </button>
            )}
          </>
        )}
        {submitting && (
          <button type="submit" disabled className="btn btn-primary mr-2">
            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
            {t('Submitting') + '...'}
          </button>
        )}
        <a href="/children/" className="btn btn-light">
          {t('Back')}
        </a>
      </form>
    </>
  );
}
