let loadingTimerTimeout = null;
export const showLoading = (t, messageKey) => {
  clearTimeout(loadingTimerTimeout);
  loadingTimerTimeout = setTimeout(() => {
    window.Swal.fire({
      title: t('Loading'),
      text: t(messageKey),
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }, 800);
};

export const hideLoading = () => {
  clearTimeout(loadingTimerTimeout);
  window.Swal.close();
};
