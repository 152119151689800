import moment from 'moment';
import stats from 'stats-lite';

import { graphQLRequest } from './common';

export const getAllSkills = (responseCallback) => {
  const graphQL = {
    query: `{
              denverChecklistSkills (orderBy: order_ASC) {
                id
                title
                code
                level
                description
                objectiveInstruction
                assessmentSteps {
                    id
                    order
                    description
                    achievementMininumAttempts
                    achievementMinimumScore
                }
                developmentalDomain {
                    id
                    code
                    title
                }
              }
            }
        
      `,
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.denverChecklistSkills);
    } else {
      console.error(err);
      responseCallback(null, err);
    }
  });
};

export const getAllSkillsFiltered = async (
  { developmentDomainCode, level, removeCustomObjectives },
  responseCallback,
) =>
  new Promise((resolve, reject) => {
    // console.log("<<++====", developmentDomainCode);
    let where = '';
    if (developmentDomainCode || level) {
      const developmentDomainFilter = developmentDomainCode
        ? `developmentalDomain:{ code_contains:"${developmentDomainCode}" }`
        : '';
      const levelFilter = level ? `level:${level}` : '';
      where = `where: {
                AND:{
                    ${developmentDomainFilter}        
                    ${levelFilter}
                }        
            }`;
    }
    const graphQL = {
      query: `{
            denverChecklistSkills (orderBy:order_ASC, ${where})
              {
                id
                code
                level
                title
                objectiveInstruction
                description
                developmentalDomain {
                  id
                  order
                  title
                }
                skillFrom{
                  id
                }
              }
          }
      `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        let filteredResult = result.data.denverChecklistSkills;
        if (removeCustomObjectives) {
          filteredResult = filteredResult.filter((ob) => !ob.code.match(/^CTM/g));
        }
        if (responseCallback) responseCallback(filteredResult);
        resolve(filteredResult);
      } else {
        console.error(err);
        if (responseCallback) responseCallback(null, err);
        reject(err);
      }
    });
  });

export const getSkillCodesForLevel = async ({ level, removeCustomObjectives = true }, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
              denverChecklistSkills (orderBy:order_ASC, where: { level: ${level} })
                {
                  id
                  code
                  level
                }
            }
        `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        let filteredResult = result.data.denverChecklistSkills;
        if (removeCustomObjectives) {
          filteredResult = filteredResult.filter((ob) => !ob.code.match(/^CTM/g));
        }
        if (responseCallback) responseCallback(filteredResult);
        resolve(filteredResult);
      } else {
        console.error(err);
        if (responseCallback) responseCallback(null, err);
        reject(err);
      }
    });
  });

export const getAllDenverChecklistSkillsBelowLevel = async ({ maxLevel }) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
        denverDevelopmentalDomains {
          id
          code
          checklistSkills(
            where: { AND: { level_lte: ${maxLevel}, code_not_contains: """CTM""" } }
          ) {
            id
            code
            level
          }
        }
      }`,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        return resolve(result.data.denverDevelopmentalDomains);
      } else {
        console.error(err);
        return reject(err);
      }
    });
  });

export const getAllDevelopmentalDomains = async (responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
            denverDevelopmentalDomains(orderBy: order_ASC) {
              id
              code
              order
              title
            }
          }        
      `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        if (responseCallback) {
          responseCallback(result.data.denverDevelopmentalDomains);
        }
        return resolve(result.data.denverDevelopmentalDomains);
      } else {
        console.error(err);
        if (responseCallback) {
          responseCallback(null, err);
        }
        return reject(err);
      }
    });
  });

export const getAllDevelopmentalDomainsWithSkill = (responseCallback) => {
  const graphQL = {
    query: `{
              denverDevelopmentalDomains(orderBy: order_ASC) {
                id
                code
                order
                title
                checklistSkills {
                  id
                  code
                  level
                  title
                  description
                  objectiveInstruction
                }
              }
            }
        
      `,
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.denverDevelopmentalDomains);
    } else {
      console.error(err);
      responseCallback(null, err);
    }
  });
};

export const getDevelopmentalDomainsByLevel = async (level, responseCallback) =>
  new Promise((resolve, reject) => {
    if (!level) {
      return getDevelopmentalDomainsByLevel(responseCallback);
    }
    const graphQL = {
      query: `{
              denverChecklistSkills (
                where: {
                  level: ${level}
                  NOT: [{developmentalDomain:null}]
                }
              ){
                developmentalDomain {
                  id
                  order
                  code
                  title
                }
              }
            }
        `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        if (responseCallback) {
          responseCallback(
            result.data.denverChecklistSkills
              .map((dcs) => JSON.stringify(dcs.developmentalDomain))
              .filter((v, i, a) => a.indexOf(v) === i) //devolver somente os únicos
              .map((x) => JSON.parse(x))
              .sort((a, b) => a.order - b.order),
          );
        }
        return resolve(
          result.data.denverChecklistSkills
            .map((dcs) => JSON.stringify(dcs.developmentalDomain))
            .filter((v, i, a) => a.indexOf(v) === i) //devolver somente os únicos
            .map((x) => JSON.parse(x))
            .sort((a, b) => a.order - b.order),
        );
      } else {
        console.error(err);
        if (responseCallback) responseCallback(null, err);
        return reject(err);
      }
    });
  });

export const getDevelopmentalDomainsAndSkillsByLevel = async (level, responseCallback) =>
  new Promise((resolve, reject) => {
    if (!level) return getDevelopmentalDomainsAndSkillsByLevel(responseCallback);

    const graphQL = {
      query: `{
        denverChecklistSkills(
          where: { level: ${level}, NOT: [{ developmentalDomain: null }] }
        ) {
          developmentalDomain {
            id
            order
            code
            title
            checklistSkills (
              where: {
                level: ${level}
              }
            ){
              id
              code
              order
            }
          }
        }
      }`,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        // Order by Developmental Domain and Remove Duplicates
        const data = result.data.denverChecklistSkills
          .map((dcs) => JSON.stringify(dcs.developmentalDomain))
          .filter((v, i, a) => a.indexOf(v) === i) //devolver somente os únicos
          .map((x) => {
            const denverItems = JSON.parse(x);
            const uniqueSkills = denverItems.checklistSkills
              // Remove Customized Skills
              .filter((skill) => !skill.code.match(/CTM/))
              // Remove Duplicates
              .filter((s, i, a) => a.indexOf(s) === i)
              // Order by Skill
              .sort((a, b) => a.order - b.order);
            denverItems.checklistSkills = uniqueSkills;
            return denverItems;
          })
          .sort((a, b) => a.order - b.order);
        // Order by Skill and Remove Duplicates
        if (responseCallback) responseCallback(data);
        resolve(data);
      } else {
        console.error(err);
        if (responseCallback) responseCallback(null, err);
        reject(err);
      }
    });
  });

export const getAvailableLevelsByDevelopmentalDomain = (code, responseCallback) => {
  return new Promise((resolve, reject) => {
    if (!code) {
      return [1, 2, 3, 4];
    }
    const graphQL = {
      query: `{
              denverChecklistSkills (
                where: {
                  developmentalDomain: {
                    code:"""${code}"""
                  }
                }
              ){
                level
              }
            }
        `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        const uniqueValues = result.data.denverChecklistSkills
          .map((d) => d.level)
          .filter((v, i, a) => a.indexOf(v) === i); //devolver somente os únicos
        if (responseCallback) {
          responseCallback(uniqueValues);
        } else {
          resolve(uniqueValues);
        }
      } else {
        console.error(err);
        if (responseCallback) {
          responseCallback(null, err);
        }
        reject(err);
      }
    });
  });
};

export const getSkill = async (id, responseCallback) =>
  new Promise((resolve) => {
    const graphQL = {
      query: `{
            denverChecklistSkill(where: { id: "${id}"}) {
                id
                title      
                description
                objectiveInstruction
                level
                developmentalDomain {
                    id 
                    order
                    title
                }
                assessmentSteps {
                    id
                    description
                    order
                    achievementMinimumScore
                    achievementMininumAttempts
                }
              }
          }
        `,
      variables: {},
    };
    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        if (responseCallback) return responseCallback(null, err);
        resolve({ data: null, error: err });
      }
      if (responseCallback) return responseCallback(result.data.denverChecklistSkill);
      resolve(result.data.denverChecklistSkill);
    });
  });

export const createSkill = (
  { code, level, title, description, objectiveInstruction, developmentalDomain },
  responseCallback,
) => {
  const graphQL = {
    query: `
            mutation {
                createDenverChecklistSkill (
                    data: {
                        code: """${code}"""
                        level: ${level}
                        developmentalDomain: {
                          connect: {
                            id: """${developmentalDomain.id}"""
                          }
                        }
                        title: """${title}"""
                        description: """${description}"""
                        objectiveInstruction: """${objectiveInstruction}"""
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };
  graphQLRequest(graphQL, responseCallback);
};

export const updateSkill = (
  { id, title, description, objectiveInstruction, developmentalDomain, level },
  responseCallback,
) => {
  const graphQL = {
    query: `
            mutation {
                updateDenverChecklistSkill (
                    data: {
                        title: """${title}"""
                        level: ${level}
                        developmentalDomain: {
                          connect: {
                            id: """${developmentalDomain.id}"""
                          }
                        }
                        description: """${description}"""
                        objectiveInstruction: """${objectiveInstruction}"""
                    }
                    where: {
                        id: "${id}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };
  graphQLRequest(graphQL, responseCallback);
};

export const createAssessmentStep = (
  idSkill,
  code,
  description,
  order,
  achievementMininumAttempts,
  achievementMinimumScore,
  responseCallback,
) => {
  const graphQL = {
    query: `
                mutation {
                    createDenverChecklistSkillAssessmentStep (
                        data: {
                            code: """${code}"""
                            description: """${description}"""
                            order: ${order}
                            achievementMininumAttempts: ${achievementMininumAttempts}
                            achievementMinimumScore: ${achievementMinimumScore}                            
                            skill: {
                              connect: {
                                id: "${idSkill}"
                              }
                            }
                        }
                    ){
                        id
                        createdAt
                        updatedAt
                    }
                }
            `,
    variables: {},
  };
  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.createDenverChecklistSkillAssessmentStep);
    } else {
      responseCallback(null, err);
    }
  });
};

export const updateAssessmentStep = (
  id,
  description,
  achievementMininumAttempts,
  achievementMinimumScore,
  responseCallback,
) => {
  const graphQL = {
    query: `
                mutation {
                    updateDenverChecklistSkillAssessmentStep (
                        data: {
                            description: """${description}"""
                            achievementMininumAttempts: ${achievementMininumAttempts}
                            achievementMinimumScore: ${achievementMinimumScore}                            
                        }
                        where: {
                            id: "${id}"
                        }
                    ){
                        id
                        createdAt
                        updatedAt
                    }
                }
            `,
    variables: {},
  };
  graphQLRequest(graphQL, responseCallback);
};

export const removeAssessmentStep = (stepId, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                deleteDenverChecklistSkillAssessmentStep (
                    where: {
                        id: "${stepId}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const getScoreByAssessment = (assessment) => {
  if (assessment === 'PASS') {
    return 2;
  }
  if (assessment === 'PASS_FAIL') {
    return 1;
  }
  if (assessment === 'FAIL') {
    return 0;
  }
};

export const getAgeInMonthsByLevel = (level) => {
  if (level === 0) {
    return 9;
  }
  if (level === 1) {
    return 18;
  }
  if (level === 2) {
    return 24;
  }
  if (level === 3) {
    return 36;
  }
  if (level === 4) {
    return 48;
  }
  throw new Error('Invalid Level: ' + level);
};

export const getDevelopmentIntervalByLevel = (level) => {
  if (level === 1) {
    return 9;
  }
  if (level === 2) {
    return 6;
  }
  if (level === 3) {
    return 12;
  }
  if (level === 4) {
    return 12;
  }
  throw new Error('Invalid Level: ' + level);
};

export const getTypicalLevelByAge = (childAgeInMonths) => {
  // Level 1: 9-18
  // Level 2: 18-24
  // Level 3: 24-36
  // Level 4: 36-48
  if (childAgeInMonths <= 18) {
    return 1;
  }
  if (childAgeInMonths <= 24) {
    return 2;
  }
  if (childAgeInMonths <= 36) {
    return 3;
  }
  return 4;
};

export const evaluateDevelopmentScoreByLevel = (checklistItems, level, childAgeInMonths) => {
  if (!checklistItems || checklistItems.length === 0 || !childAgeInMonths) {
    return null;
  }

  // first, calculates the proportion by level that the child can achieve in a typical development
  // e.g.: if the child has 20 months of age, and by Denver model the development starts at 9 months
  // there's a window of 11 months. So, the expected behavior would be:
  // 9 months in Level 1 [9-18]
  // 2 months in Level 2 [18-24]
  // So in the `levelDevelopmentFit` we are calculating this proportion by level
  let levelDevelopmentFit = {};
  for (let lvl = 4; lvl >= 1; lvl--) {
    if (childAgeInMonths > getAgeInMonthsByLevel(lvl)) {
      levelDevelopmentFit[lvl] = getDevelopmentIntervalByLevel(lvl);
    } else if (getAgeInMonthsByLevel(lvl) - childAgeInMonths < getDevelopmentIntervalByLevel(lvl)) {
      levelDevelopmentFit[lvl] = childAgeInMonths - getAgeInMonthsByLevel(lvl - 1);
    } else {
      levelDevelopmentFit[lvl] = 0;
    }
  }

  // To calculate the development score we use: the checklist gross score, the score normalized by
  // the items effectively used in the assessment and the `levelDevelopmentFit` to
  // bring the result to a proportional bias, according to the child age in months
  let developmentScore = {};
  const itemsInLevel = checklistItems.filter((itm) => itm.skill.level === level);
  const evaluatedItems = itemsInLevel.filter((it1) => it1.score !== 'NOT_EVALUATED');

  // maximum score -> all itens that were evaluated == PASS
  const maximumScore =
    itemsInLevel.length > 0 ? itemsInLevel.map((_) => getScoreByAssessment('PASS')).reduce((a, b) => a + b) : 0;

  const maximumEvaluatedScore =
    evaluatedItems.length > 0 ? evaluatedItems.map((_) => getScoreByAssessment('PASS')).reduce((a, b) => a + b) : 0;

  const score =
    evaluatedItems.length > 0 ? evaluatedItems.map((it) => getScoreByAssessment(it.score)).reduce((a, b) => a + b) : 0;

  developmentScore.code = itemsInLevel[0]?.skill.developmentalDomain.code;
  developmentScore.itensCount = itemsInLevel.length;
  developmentScore.evaluatedItensCount = evaluatedItems.length;

  // we use the typical development score proportionalized by the `levelDevelopmentFit`
  // to put the results of the patient in perspective
  // the development factor will be the score achieved by the patient, proportional to a typical development child weighted
  // in function of how many items were indeed evaluated and proportional of the age the patient has in that level interval, i.e., levelDevelopmentFit
  developmentScore.maximumScore = maximumScore;
  developmentScore.maximumEvaluatedScore = maximumEvaluatedScore;
  developmentScore.weightingFactor = maximumEvaluatedScore / maximumScore;
  developmentScore.score = score;
  developmentScore.levelDevelopmentFit =
    levelDevelopmentFit[level] > 0 ? levelDevelopmentFit[level] : levelDevelopmentFit[level] === 0 && score > 0 ? 1 : 0;
  developmentScore.percentualScore =
    maximumEvaluatedScore === 0 ? -1 : parseFloat((score / maximumEvaluatedScore).toFixed(2));
  developmentScore.levelDevelopmentFitWeight =
    developmentScore.levelDevelopmentFit / getDevelopmentIntervalByLevel(level); // weight of this Level based on the child age
  developmentScore.typicalDevelopmentScoreFit =
    developmentScore.levelDevelopmentFitWeight * developmentScore.maximumScore;
  developmentScore.typicalDevelopmentScoreFitWighting =
    developmentScore.typicalDevelopmentScoreFit * developmentScore.weightingFactor;

  if (developmentScore.score > 0) {
    developmentScore.scoreProportionalFitWeighting =
      developmentScore.score / developmentScore.typicalDevelopmentScoreFit; // score related to a typical child score with the same age. The "fit" here is because the child age can be in the middle of the level boundaries
    if (developmentScore.scoreProportionalFitWeighting > 1) {
      developmentScore.scoreProportionalFitWeighting = 1;
    }
  } else {
    developmentScore.scoreProportionalFitWeighting = 0;
  }
  developmentScore.developmentGrowth =
    developmentScore.scoreProportionalFitWeighting * developmentScore.levelDevelopmentFit; // how much of the development window in that level the child achieved?
  return developmentScore;
};

export const evaluateDevelopmentScoreWithoutFilters = (checklistItems) => {
  const evaluatedItems = checklistItems.map((it) => {
    if (it.score === 'NOT_EVALUATED') return { ...it, score: 'FAIL' };
    return it;
  });

  const itemsCount = evaluatedItems.length;

  const maximumScore = itemsCount * getScoreByAssessment('PASS');

  const score = evaluatedItems.map((it) => getScoreByAssessment(it.score)).reduce((a, b) => a + b);

  const percentualScore = parseFloat((score / maximumScore).toFixed(2));

  const returnObj = {
    itemsCount,
    score,
    maximumScore,
    percentualScore,
  };

  return returnObj;
};

export const evaluateDevelopmentalMatrixScore = async (
  childChecklistAssessments,
  childDateOfBirth,
  denverChecklistDomains,
) => {
  let ret = {
    levelsDevelopmentRatios: [],
    developmentDomainsRatios: [],
    levelsScore: [],
    assessments: childChecklistAssessments,
  };

  const childAgeInMonths = moment(childChecklistAssessments[0].childChecklistAssessment.date).diff(
    moment(childDateOfBirth),
    'months',
  );

  let developmentalMatrixScore = {};
  const developmentalDomains = [...new Set(childChecklistAssessments.map((ass) => ass.skill.developmentalDomain.code))];

  let levelDevelopmentScore = {};

  // Get total scores by level and not by domain
  for (let level = 1; level <= 4; level++) {
    const devScore = evaluateDevelopmentScoreByLevel(childChecklistAssessments, level, childAgeInMonths);
    if (devScore.evaluatedItensCount === 0) continue;
    ret.levelsScore.push({ [level]: devScore.score });
  }

  await Promise.all(
    developmentalDomains.map(async (dd) => {
      developmentalMatrixScore[dd] = {
        typicalDevelopmentTotalPoints: 0,
        typicalDevelopmentWeightingTotalPoints: 0,
        scoreTotal: 0,
        developmentWindow: 0,
        developmentGrowth: 0,
        percentualGrowth: 0,
        developmentFactor: 0,
        itensCount: 0,
        evaluatedItensCount: 0,
        maximumEvaluatedScore: 0,
        itemsCountNotPondered: 0,
        scoreNotPondered: 0,
        maximumScoreNotPondered: 0,
        percentualScoreNotPondered: 0,
      };

      // Get data to calculate pondered average of the item.
      // let allItemsPonderedAverageNumerator = 0;
      // let allItemsPonderedAverageDenominator = 0;
      let evaluatedItemsPonderedAverageNumerator = 0;
      let evaluatedItemsPonderedAverageDenominator = 0;

      if (denverChecklistDomains) {
        const currentDenverDomain = denverChecklistDomains.find((d) => d.code === dd);

        const currentDomainSkills = childChecklistAssessments.filter(
          (items) => items.skill.developmentalDomain.code === dd,
        );

        currentDenverDomain.checklistSkills.forEach((skill) => {
          if (!currentDomainSkills.find((s) => s.skill.id === skill.id)) {
            currentDomainSkills.push({
              score: 'FAIL',
              skill: skill,
            });
          }
        });

        const itemsNotPondered = evaluateDevelopmentScoreWithoutFilters(currentDomainSkills);

        developmentalMatrixScore[dd].itemsCountNotPondered = itemsNotPondered.itemsCount;
        developmentalMatrixScore[dd].scoreNotPondered = itemsNotPondered.score;
        developmentalMatrixScore[dd].maximumScoreNotPondered = itemsNotPondered.maximumScore;
        developmentalMatrixScore[dd].percentualScoreNotPondered = itemsNotPondered.percentualScore;
      }

      // Calculus considering level
      for (let level = 1; level <= 4; level++) {
        const items = childChecklistAssessments.filter(
          (r) => r.skill.developmentalDomain.code === dd && r.skill.level === level,
        );
        developmentalMatrixScore[dd].itensCount += items.length;

        // enrich the item with developmentalDomain data
        if (items.length > 0) {
          developmentalMatrixScore[dd].developmentalDomain = items[0].skill.developmentalDomain;
        }
        const devScore = evaluateDevelopmentScoreByLevel(items, level, childAgeInMonths);

        if (!devScore || devScore.percentualScore === -1) {
          //means that no items were evaluated
          continue;
        }

        // allItemsPonderedAverageNumerator += devScore.percentualScore * devScore.maxPossibleScore;
        // allItemsPonderedAverageDenominator += devScore.maxPossibleScore;
        evaluatedItemsPonderedAverageNumerator += devScore.percentualScore * devScore.evaluatedItensCount;
        evaluatedItemsPonderedAverageDenominator += devScore.evaluatedItensCount;

        if (!levelDevelopmentScore[level]) {
          levelDevelopmentScore[level] = [];
        }
        // when the skill was not evaluated, the percentualScore is eq -1. Remove those from the returning array
        // because it would break the mean calc
        if (devScore.percentualScore >= 0) {
          levelDevelopmentScore[level].push({
            developmentalDomain: dd,
            percentualScore: devScore.percentualScore,
            weight: devScore.levelDevelopmentFitWeight,
          });
        }

        developmentalMatrixScore[dd].scoreTotal += devScore.score;
        developmentalMatrixScore[dd].typicalDevelopmentTotalPoints += devScore.typicalDevelopmentScoreFit;
        developmentalMatrixScore[dd].typicalDevelopmentWeightingTotalPoints +=
          devScore.typicalDevelopmentScoreFitWighting;
        developmentalMatrixScore[dd].developmentWindow += devScore.levelDevelopmentFit;
        developmentalMatrixScore[dd].developmentGrowth += devScore.developmentGrowth;
        developmentalMatrixScore[dd].percentualGrowth += devScore.percentualScore;
        developmentalMatrixScore[dd].evaluatedItensCount += devScore.evaluatedItensCount;
        developmentalMatrixScore[dd].maximumEvaluatedScore += devScore.maximumEvaluatedScore;

        if (
          developmentalMatrixScore[dd].scoreTotal > developmentalMatrixScore[dd].typicalDevelopmentWeightingTotalPoints
        ) {
          developmentalMatrixScore[dd].scoreTotal = developmentalMatrixScore[dd].typicalDevelopmentWeightingTotalPoints;
        }
      }
      try {
        // evaluates the developmentFactor by calculating the percentualGrowth and
        // pondering by how many levels there of the developmentalDomain and the
        // age of the child

        // const typicalLevelByAge = getTypicalLevelByAge(childAgeInMonths);
        // const levelsAvailable = await getAvailableLevelsByDevelopmentalDomain(dd).then((availableLevels) =>
        //   availableLevels.filter((level) => level <= typicalLevelByAge),
        // );
        // const levelAgeInMonths = getAgeInMonthsByLevel(typicalLevelByAge);
        // const levelsAvailableForAge = levelsAvailable.filter((l) => l <= typicalLevelByAge).length;

        // Make calculations only if the percentualGrowth is greater than 0
        // If this is not checked, there's a chance of returning 'NaN', breaking the graphs
        if (developmentalMatrixScore[dd].percentualGrowth > 0) {
          /* 
          //  The following lines are related to another strategy to calculate a pondered growth, which was not in 
          //  use but has some considerations about using the child's age as a parameter. For now, it's here as a 
          //  part of the app's documentation, and some of this code can be reused in future implementations.
            
          // the Age Factor evaluates the child based on its actual age, not the maximum of the level.
          let ageFactor = childAgeInMonths / levelAgeInMonths;
          if (ageFactor > 1) ageFactor = 1;

          // By multiplying the ageFactor to the levelsAvailable, we ponder the growth of children who have
          // been evaluated in several levels.
          let growthPondered = developmentalMatrixScore[dd].percentualGrowth / (ageFactor * levelsAvailableForAge);

          // if the child has a score more than 100% because he is doing things over his age, push back to 100%
          // for analysis purpose
          if (growthPondered > 1) growthPondered = 1;

          developmentalMatrixScore[dd].percentualGrowth = growthPondered;           
          */

          // The following calculus does not use pondered average.
          // developmentalMatrixScore[dd].percentualGrowth =
          //   developmentalMatrixScore[dd].percentualGrowth / levelsAvailable.length;

          // Here, we have have a pondered growth where we consider every possible item in each level,
          // including the ones which were not evaluated
          // developmentalMatrixScore[dd].allItemsPonderedGrowth =
          //   allItemsPonderedAverageNumerator / allItemsPonderedAverageDenominator;

          // We're returning a pondered growth where we consider only the items which were evaluated
          developmentalMatrixScore[dd].percentualGrowth = parseFloat(
            (evaluatedItemsPonderedAverageNumerator / evaluatedItemsPonderedAverageDenominator).toFixed(2),
          );
        }

        developmentalMatrixScore[dd].developmentFactor = developmentalMatrixScore[dd].percentualGrowth;
        // developmentalMatrixScore[dd].developmentFactor =
        //   developmentalMatrixScore[dd].developmentGrowth /
        //   developmentalMatrixScore[dd].developmentWindow;
      } catch (error) {
        console.error(
          'Error fetching available levels for developmental domain for evaluateDevelopmentalMatrixScore and Developmental Domain ' +
            dd +
            '. Error:',
          error,
        );
        return error;
      }
    }),
  );

  let developmentRatios = [];
  for (let level = 1; level <= getTypicalLevelByAge(childAgeInMonths); level++) {
    if (!levelDevelopmentScore[level]) {
      // if this level wasn't evaluated, go to next
      continue;
    }
    if (levelDevelopmentScore[level].length === 0) {
      developmentRatios.push([{ percentualScore: 0, weight: 1 }]);
      continue;
    }

    developmentRatios.push(levelDevelopmentScore[level]);
  }
  ret.levelsDevelopmentRatios = developmentRatios;

  ret.developmentTotal = ret.assessments.length * getScoreByAssessment('PASS');
  ret.developmentAcc = stats.sum(ret.assessments.map((a) => getScoreByAssessment(a.score)));

  ret.levelDevelopmentWeightedMeans = ret.levelsDevelopmentRatios.map(
    (arr) =>
      stats.sum(
        arr.map((aro) => {
          const tmpVal = aro.percentualScore / aro.weight;
          if (tmpVal > 1) {
            return 1;
          }
          return tmpVal;
        }),
      ) / arr.length,
  );
  ret.developmentFactor = stats.mean(ret.levelDevelopmentWeightedMeans);

  let auxSummary = [];
  Object.keys(developmentalMatrixScore).forEach((key) => {
    auxSummary.push(Object.assign({}, developmentalMatrixScore[key], { code: key }));
  });

  ret.developmentDomainsRatios = auxSummary.sort((a, b) => a.developmentalDomain.order - b.developmentalDomain.order);

  return ret;
};

/**
 * This method evaluates what is the child age based on the level score achievement in the checklist
 * @param {array of floats} levelsScore : ratio of checklist development score for levels 1 to 4
 */
export const evaluateAgeInMonthsByLevelsScore = (levelsScore) =>
  getAgeInMonthsByLevel(0) +
  levelsScore.map((x, idx) => getDevelopmentIntervalByLevel(idx + 1) * x).reduce((a, b) => a + b);

/**
 * Skill order function
 *
 * @param {skill} a
 * @param {skill} b
 * @returns which skill comes first
 */
export const orderSkills = (s1, s2) => {
  if (s1.skill.code.match(/CTM/) && !s2.skill.code.match(/CTM/)) {
    return 1;
  }
  if (s2.skill.code.match(/CTM/) && !s1.skill.code.match(/CTM/)) {
    return -1;
  }
  if (s1.developmentalDomain.order === s2.developmentalDomain.order) {
    if (s1.level === s2.level) {
      return s1.order - s2.order;
    }
    return s2.level - s1.level;
  }
  return s1.developmentalDomain.order - s2.developmentalDomain.order;
};
