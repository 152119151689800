import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

export default function LevelsChecklistScoreProgress({ activeSeries }) {
  const { t } = useTranslation();

  const series = [];

  for (const item in activeSeries.levelSeries) {
    series.push({
      name: `${t('Level')} ${item}`,
      data: activeSeries.levelSeries[item],
    });
  }

  const chartOptions = {
    title: {
      text: t('child-stats-page.levels-score'),
    },
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    dataLabels: { enabled: true },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: activeSeries.categories.map((c) => moment(c).format(t('MM/DD/YYYY'))),
    },
    yaxis: {
      max: activeSeries.levelsYMax,
      min: 0,
      labels: {
        formatter: function (val, _i) {
          return val.toFixed(0);
        },
      },
    },
  };

  return (
    <div className="container-fluid border rounded mb-2">
      <Chart options={chartOptions} type="line" series={series} height={350} />
    </div>
  );
}
