import { useEffect, useState } from 'react';

import { getAllChildren } from '../../services/children';

export const useChildrenList = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [staffList, setStaffList] = useState([]);

  useEffect((_) => {
    getAllChildren((result, err) => {
      if (!err) {
        const nameAndIdOnly = result.map((child) => ({
          id: child.id,
          name: child.person.name,
        }));
        setStaffList(nameAndIdOnly);
      } else {
        setError(err);
      }
      setLoading(false);
    });
  }, []);

  return { loading, error, staffList };
};
