import { graphQLRequest } from '../../services/common';

export const getStaffChildrenRelated = async (staffMemberId, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
        staffMember(where: { id: "${staffMemberId}" }) {
          id
          assignments {
            child {
              id
              createdAt
              person {
                name
              }
            }
          }
        }
      }
          `,
      variables: {},
    };

    if (responseCallback) return graphQLRequest(graphQL, responseCallback);

    graphQLRequest(graphQL, (res, err) => {
      if (!err) return resolve(res);
      return reject(err);
    });
  });
