import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import manuh from 'manuh';

import topics from '../../../../manuh-topics';
import { getNumberFromSkillCode } from '../../../../utils/legacy';
import ChildIndividualizedPlanSkillStepSession from './childIndividualizedPlanSkillStepSession/ChildIndividualizedPlanSkillStepSession';

export default function ChildIndividualizedPlanSkillCard({ number, skill, individualizedPlan, substeps }) {
  const { t } = useTranslation();
  const [showingSessions, setShowingSessions] = React.useState(false);
  const [openSubsteps, setOpenSubsteps] = useState(false);

  const skillSessionResults = {
    sessionCount: substeps.length,
    achievements: substeps.filter((s) => s.achievementDate).length,
  };

  const handleMouseOver = () => {
    if (!openSubsteps) setOpenSubsteps(true);
  };

  manuh.subscribe(
    topics.components.interventionPlan.openSkillSessionDetails,
    'ChildIndividualizedPlanSkillStepSessionList-' + skill.id,
    (selectedSkill) => {
      if (skill && selectedSkill.id === skill.id) {
        setShowingSessions(true);
        setTimeout(() => {
          const element = window.document.getElementById('skillCard-' + skill.id);
          var topPos = element.getBoundingClientRect().top + window.scrollY - 60;
          window.scrollTo({
            top: topPos,
            behavior: 'smooth',
          });
        }, 400);
      } else {
        setShowingSessions(false);
      }
    },
  );

  const resolveStepResultsBadge = () => {
    const achievementRatio = skillSessionResults.achievements / skillSessionResults.sessionCount;

    return (
      <span
        className={`p-1 border-bottom-2 border-${
          achievementRatio >= 1.0 ? 'success' : achievementRatio >= 0.6 ? 'warning' : 'danger'
        } text-black bg-light`}
        data-toggle="collapse"
        data-target={'#sessions-' + skill.id}
        aria-expanded="false"
        aria-controls={'skillDetails-' + skill.id}
      >
        {achievementRatio >= 1.0
          ? t('totally achieved')
          : achievementRatio >= 0.6
          ? t('partially achieved')
          : t('not achieved')}{' '}
        ({skillSessionResults.achievements}/{skillSessionResults.sessionCount})
      </span>
    );
  };

  const showSessionsFromSkill = () => {
    manuh.publish(topics.components.interventionPlan.openSkillSessionDetails, skill);
  };

  let skillTitle = t('Custom objective');
  if (skill.developmentalDomain) {
    skillTitle = skill.developmentalDomain.title;
  }

  return (
    <div id={'skillCard-' + skill.id} className="card" style={{ border: showingSessions ? '3px solid black' : 'none' }}>
      <div className="card-body" onMouseOver={() => handleMouseOver()}>
        <div className="d-flex flex-row">
          <div className="border-right border-light pr-3 mr-3 d-flex justify-content-center align-items-center">
            <h5 className="text-secondary font-weight-normal">{number + 1}</h5>
          </div>
          <div className="w-100">
            <div className="mb-1 d-flex justify-content-between">
              <span id={`card-${skill.id}`} className="badge badge-info mr-2 mb-2">
                {skillTitle}
              </span>
            </div>
            <h4
              className="actionable font-weight-normal"
              data-toggle="collapse"
              data-target={'#skillDetails-' + skill.id}
              aria-expanded="false"
              aria-controls={'skillDetails-' + skill.id}
            >
              <strong className="text-primary">
                {t('LEVEL_SINGLE_LETTER')}
                {skill.level}.{getNumberFromSkillCode(skill.code)}
                &nbsp;
              </strong>
              —&nbsp;{skill.title}
            </h4>
            <div
              className="actionable"
              style={{ color: '#AAA' }}
              data-toggle="collapse"
              data-target={'#skillDetails-' + skill.id}
              aria-expanded="false"
              aria-controls={'skillDetails-' + skill.id}
            >
              {skill.objectiveInstruction}
            </div>
            <div id={'skillDetails-' + skill.id} className="collapse m-1 p-2" style={{ background: '#F9F9F9' }}>
              {skill.description}
            </div>

            <div className="mt-2">
              {resolveStepResultsBadge()}
              <button
                className={`btn btn-light ml-2`}
                data-toggle="collapse"
                data-target={'#sessions-' + skill.id}
                aria-expanded="false"
                aria-controls={'skillDetails-' + skill.id}
                onClick={() => showSessionsFromSkill(skill)}
              >
                {t('Check sessions')}
              </button>
            </div>
            {openSubsteps && (
              <div className="accordion-item">
                <div id={'sessions-' + skill.id} className={`collapse`} data-parent="#accordionSkills">
                  {substeps.map((s) => (
                    <div key={skill.id + '-' + s.id}>
                      <hr style={{ borderWidth: '3px' }} />
                      <div className="ml-2">
                        <ChildIndividualizedPlanSkillStepSession
                          skill={skill}
                          skillStep={s}
                          childId={individualizedPlan.child.id}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
