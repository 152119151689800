import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from './logo.png';
import { accountActivate } from './services/auth';

function AccountActivate() {
  const { t } = useTranslation();

  const doActivate = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    const tokenAccountActivate = urlParams.get('AccountActivate');
    accountActivate(tokenAccountActivate, (response) => {
      var textError = '';
      if (response.status === 202) {
        return window.Swal.fire({
          type: 'success',
          title: t('Activated'),
          text: t('Account activated successfully') + '!',
        }).then((_) => {
          window.location.replace('./');
        });
      } else if (response.status === 450) {
        textError = t('Invalid activation token') + '!';
      } else if (response.status === 455) {
        textError = t('Account already activated') + '!';
      } else if (response.status === 460) {
        textError = t('Account disabled') + '!';
      } else {
        textError = t('Server error') + '!';
      }
      return window.Swal.fire({
        type: 'error',
        title: t('Error'),
        text: textError,
      }).then((_) => {
        window.location.replace('./');
      });
    });
  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-6 mx-auto">
              <div className="card">
                <div className="auth-form-wrapper px-4 py-5">
                  <div className="noble-ui-logo d-block mb-2">
                    <img src={logo} width="50px" className="mr-2" alt="logo-denver" />
                    Early<span>Denver</span>
                  </div>
                  <h5 className="text-muted font-weight-normal mb-4 mt-4">{t('Account Activation')}</h5>
                  <form className="forms-sample" onSubmit={(e) => doActivate(e)}>
                    <div className="mt-3">
                      <button type="submit" className="btn btn-primary mr-2 mb-2 mb-md-0 text-white">
                        {t('Activate')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountActivate;
