import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getAllStaffMembers, del } from '../../services/staff';
import useUserContext from '../../useUserContext';

export default () => {
  const { t } = useTranslation();

  const [staffList, setStaffList] = useState([]);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(true);

  const { loggedUser } = useUserContext();

  useEffect(
    (_) => {
      setBusy(true);
      setLoadingErrorMessage('');
      getAllStaffMembers((result, err) => {
        if (!err) {
          // staffMember can only see your own record and admin can see non-active
          if (loggedUser.staffMember && !loggedUser.permissions.includes('updateStaffMember:*'))
            result = result.filter((f) => loggedUser.staffMember === f.id).filter((f) => f.active);
          setStaffList(result);
        } else {
          setLoadingErrorMessage(t('There was a problem fetching staff members'));
        }
        setBusy(false);
      });
    },
    [t],
  );

  const delet = (event) => {
    var delName = event.currentTarget.dataset.name;
    var delId = event.currentTarget.dataset.id;
    var delEmail = event.currentTarget.dataset.email;
    window.Swal.fire({
      title: t('Confirm') + '?',
      type: 'warning',
      text: t('Are you sure you want to remove') + ' ' + delName + '?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then((result) => {
      if (result.value) {
        del(delId, delEmail, (_, err) => {
          if (err) {
            return window.Swal.fire({
              type: 'error',
              title: t('Error'),
              text: t('There was an error removing'),
            });
          }

          return window.Swal.fire({
            type: 'success',
            title: t('Removed'),
            text: delName + ' ' + t('successfully removed') + '!',
          }).then((_) => {
            window.location.reload();
          });
        });
      }
    });
  };

  return {
    staffList,
    loadingErrorMessage,
    delet,
    busy,
  };
};
