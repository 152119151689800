import React from 'react';

import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';

import moment from 'moment/moment';

import logo from '../../../logo.png';

const styles = StyleSheet.create({
  page: { padding: 30 },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  logo: { width: 70, height: 70 },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'row',
    color: '#2c2c2c',
    paddingVertical: 5,
  },
  infoTitle: {
    fontSize: 12,
  },
  infoValue: {
    fontSize: 12,
    color: '#000000',
  },
  url: { fontSize: 9, color: 'grey', marginBottom: 8 },
  logoContainer: { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
  contentContainer: { display: 'flex', flexDirection: 'column' },
  contentTitle: { fontSize: 18, paddingVertical: 10 },
  sectionWrapper: { display: 'flex', flexDirection: 'column', paddingVertical: 10 },
  sectionTitle: { fontSize: 14, paddingBottom: 10 },
  sectionContainer: { display: 'flex', flexDirection: 'column' },
  itemContainer: { display: 'flex', flexDirection: 'column', paddingVertical: 3 },
  itemContainerRow: { display: 'flex', flexDirection: 'row', paddingVertical: 3 },
  itemTitle: { fontSize: 12, color: '#a31b1b' },
  itemValue: { fontSize: 12, color: '#2c2c2c', paddingVertical: 2 },
  itemValueRow: { fontSize: 12, color: '#2c2c2c' },
});

export const AnamneseReportPDF = (ana) => {
  const mappedAnamnese = [
    {
      section: 'Dados na gestação',
      itemsSection: [
        { title: 'idadeGestacional', value: ana.idadeGestacional, text: 'Idade da mãe na concepção' },
        { title: 'gestacaoPlanejada', value: ana.gestacaoPlanejada, text: 'A gestação foi planejada?' },
        { title: 'acompanhamentoPreNatal', value: ana.acompanhamentoPreNatal, text: 'Fez acompanhamento pré-natal?' },
        {
          title: 'maeMedicamentosDuranteGravidez',
          value: ana.maeMedicamentosDuranteGravidez,
          text: 'Fez o uso de medicamento durante a gravidez? Se sim, qual motivo e frequência?',
        },
        {
          title: 'problemaSaudeDuranteGravidez',
          value: ana.problemaSaudeDuranteGravidez,
          text: 'Problemas de saúde durante a gestação? Se sim, quais?',
        },
        {
          title: 'drogasDuranteAGravidez',
          value: ana.drogasDuranteAGravidez,
          text: 'Fez uso de drogas durante a gestação?',
        },
        {
          title: 'frequenciaUsoDroga',
          value: ana.frequenciaUsoDroga,
          text: 'Se a reposta anterior for sim, informe a frequência',
        },
      ],
    },
    {
      section: 'Dados sobre o nascimento',
      itemsSection: [
        { title: 'partoMode', value: ana.partoMode, text: 'Condições do parto' },
        { title: 'idadeMaeNascimentoCrianca', value: ana.idadeMaeNascimentoCrianca, text: 'Idade gestacional' },
        { title: 'peso', value: ana.peso, text: 'Peso' },
        {
          title: 'posPartoTratamento',
          value: ana.posPartoTratamento,
          text: 'Após o parto, a criança precisou de algum tratamento específico?',
        },
        { title: 'complicacoesParto', value: ana.complicacoesParto, text: 'Teve alguma complicação?' },
        {
          title: 'hospitalizadoMotivo',
          value: ana.hospitalizadoMotivo,
          text: 'Ficou hospitalizado? Se sim, qual o motivo?',
        },
      ],
    },
    {
      section: 'Dados sobre a alimentação',
      itemsSection: [
        {
          title: 'dificuldadeAmamentacao',
          value: ana.dificuldadeAmamentacao,
          text: 'Houve alguma dificuldade na amamentação?',
        },
        {
          title: 'aleitamentoMaternoExclusivo',
          value: ana.aleitamentoMaternoExclusivo,
          text: 'Aleitamento materno exclusivo?',
        },
        {
          title: 'periodoAleitamento',
          value: ana.periodoAleitamento,
          text: 'Se a resposta anterior for sim, informe o período',
        },
        { title: 'introducaoAlimentar', value: ana.introducaoAlimentar, text: 'Como ocorreu a introdução alimentar?' },
        { title: 'alimentacaoAtual', value: ana.alimentacaoAtual, text: 'Como é a alimentação atual?' },
        { title: 'restricoesAlimentares', value: ana.restricoesAlimentares, text: 'Possui restrições alimentares?' },
        {
          title: 'eletronicosNaAlimentacao',
          value: ana.eletronicosNaAlimentacao,
          text: 'Usa eletrônicos no momento da alimentação?',
        },
      ],
    },
    {
      section: 'Dados sobre histórico médico',
      itemsSection: [
        {
          title: 'historicoMedicoCrianca',
          value: ana.historicoMedicoCrianca,
          text: 'Dados presentes na história médica da criança',
        },
        {
          title: 'frequenciaHistoricoMedico',
          value: ana.frequenciaHistoricoMedico,
          text: 'Se houver presença de algum dos dados citados acima, descreva os motivos e a frequência.',
        },
        { title: 'examesAuditivos', value: ana.examesAuditivos, text: 'Já foram realizados exames auditivos?' },
        {
          title: 'criancaMedicamento',
          value: ana.criancaMedicamento,
          text: 'Faz o uso de medicação? Se sim, informe os nomes dos medicamentos e a frequência.',
        },
        {
          title: 'profissionaisAcompanhamento',
          value: ana.profissionaisAcompanhamento,
          text: 'Quais os profissionais que realizam acompanhamento atualmente?',
        },
        {
          title: 'regrecaoDesenvolvimento',
          value: ana.regrecaoDesenvolvimento,
          text: 'Houve alguma regressão no desenvolvimento?',
        },
      ],
    },
    {
      section: 'Dados sobre desenvolvimento motor',
      itemsSection: [
        {
          title: 'sustentouACabeca',
          value: ana.sustentouACabeca,
          text: 'Com qual idade a criança sustentou a cabeça?',
        },
        { title: 'sentou', value: ana.sentou, text: 'Com qual idade a criança sentou?' },
        { title: 'engatinhou', value: ana.engatinhou, text: 'Com qual idade a criança engatinhou?' },
        { title: 'andou', value: ana.andou, text: 'Com qual idade a criança andou? ' },
        { title: 'correu', value: ana.correu, text: 'Com qual idade a criança correu?' },
        {
          title: 'utensiliosAlimentacao',
          value: ana.utensiliosAlimentacao,
          text: 'Usa utensílios para alimentação?',
        },
        { title: 'controleXixiCoco', value: ana.controleXixiCoco, text: 'Possui controle de urina e fezes?' },
      ],
    },
    {
      section: 'Dados sobre linguagem',
      itemsSection: [
        { title: 'respondeAoSerChamado', value: ana.respondeAoSerChamado, text: 'A criança responde quando a chamam?' },
        { title: 'comecouBalbuciar', value: ana.comecouBalbuciar, text: 'Quando começou a balbuciar?' },
        { title: 'primeirasPalavras', value: ana.primeirasPalavras, text: 'Quando falou suas primeiras palavras?' },
        { title: 'entendeFilho', value: ana.entendeFilho, text: 'Compreende a comunicação do seu filho?' },
        { title: 'obedeceVerbal', value: ana.obedeceVerbal, text: 'A criança obedece à comandos/pedidos verbais?' },
        {
          title: 'ecoliase',
          value: ana.ecoliase,
          text: 'A criança tem ecolalia?',
        },
        {
          title: 'movimentoCorporalRepetitivoEmpolgado',
          value: ana.movimentoCorporalRepetitivoEmpolgado,
          text: 'A faz movimentos corporais repetitivos quando está empolgado?',
        },
        { title: 'expressaDesejos', value: ana.expressaDesejos, text: 'Como a criança expressa seus desejos?' },
        {
          title: 'principaisDificuldadeComunicacao',
          value: ana.principaisDificuldadeComunicacao,
          text: 'Quais as principais dificuldades na comunicação da criança?',
        },
      ],
    },
    {
      section: 'Dados da rotina e comportamento social',
      itemsSection: [
        {
          title: 'quemConviveDiariamente',
          value: ana.quemConviveDiariamente,
          text: 'Quem convive diariamente com a criança?',
        },
        {
          title: 'relacionamentoFamiliares',
          value: ana.relacionamentoFamiliares,
          text: 'Como é o relacionamento com os familiares?',
        },
        {
          title: 'relacionamentoCriancas',
          value: ana.relacionamentoCriancas,
          text: 'Como se comporta diante de frustrações?',
        },
        {
          title: 'comportamentoFrustrado',
          value: ana.comportamentoFrustrado,
          text: 'Como se comporta diante de frustrações?',
        },
        {
          title: 'reacaoDianteEstranhos',
          value: ana.reacaoDianteEstranhos,
          text: 'Como reage diante de pessoas estranhas?',
        },
        {
          title: 'atividadePreferida',
          value: ana.atividadePreferida,
          text: 'Quais são as atividades preferidas da criança?',
        },
        {
          title: 'brinquedoPreferido',
          value: ana.brinquedoPreferido,
          text: 'Quais os brinquedos preferidos da criança?',
        },
        {
          title: 'frequenciaUsoEletronicos',
          value: ana.frequenciaUsoEletronicos,
          text: 'Qual a frequência do uso de aparelhos eletrônicos?',
        },
        {
          title: 'comportamentoPrimeiroAno',
          value: ana.comportamentoPrimeiroAno,
          text: 'Comportamentos presente durante o priameiro ano de vida da criança?',
        },
        {
          title: 'comportamentoCotidiano',
          value: ana.comportamentoCotidiano,
          text: 'Quais dessas características são presentes no seu cotidiano?',
        },
      ],
    },
    {
      section: 'Dados sobre escolaridade',
      itemsSection: [
        { title: 'idadeIrEscola', value: ana.idadeIrEscola, text: 'Com quantos anos a criança foi para a escola?' },
        { title: 'nomeDaEscola', value: ana.nomeDaEscola, text: 'Nome da escola atual' },
        { title: 'horarioEscola', value: ana.horarioEscola, text: 'Qual o horário da escola?' },
        {
          title: 'outrasAtividades',
          value: ana.outrasAtividades,
          text: 'Tem outras atividades extracurriculares?',
        },
        { title: 'gostaDaEscola', value: ana.gostaDaEscola, text: 'Gosta da escola?' },
      ],
    },
    {
      section: 'Dados sobre histórico familiar',
      itemsSection: [
        {
          title: 'histFamiliarDificuldadeComunicacao',
          value: ana.histFamiliarDificuldadeComunicacao,
          text: 'Algum membro da família apresenta ou apresentou dificuldade na comunicação?',
        },
        {
          title: 'histFamiliarNeuro',
          value: ana.histFamiliarNeuro,
          text: 'Algum membro da família apresenta ou apresentou problemas neurológicos?',
        },
        {
          title: 'histFamiliarDificuldadeAprendizado',
          value: ana.histFamiliarDificuldadeAprendizado,
          text: 'Algum membro da família apresenta ou apresentou dificuldade no aprendizado?',
        },
      ],
    },
    {
      section: 'Outras Informações',
      itemsSection: [
        {
          title: 'outrasInformacoes',
          value: ana.outrasInformacoes,
          text: 'Há alguma outra informação que você acredita que possa auxiliar no acompanhamento da criança?',
        },
      ],
    },
  ];

  // const notMappedData = {
  //   metadata: [
  //     { title: 'id', value: ana.id, text: 'id' },
  //     { title: 'updatedAt', value: ana.updatedAt, text: 'Last updated' },
  //     { title: 'dataEnvio', value: ana.dataEnvio, text: 'Anamnesis sent' },
  //     { title: 'email', value: ana.email, text: 'Email' },
  //   ],
  //   generalData: [
  //     { title: 'endereço', value: ana.endereco, text: 'Endereço' },
  //     { title: 'telefone', value: ana.telefone, text: 'Telefone' },
  //   ],
  //   relatives: [
  //     { title: 'nomeMae', value: ana.nomeMae, text: 'Nome da mãe' },
  //     { title: 'idadeMae', value: ana.idadeMae, text: 'Idade da mãe' },
  //     { title: 'profissaoMae', value: ana.profissaoMae, text: 'Profissão da mãe' },
  //     { title: 'nomePai', value: ana.nomePai, text: 'Nome do pai' },
  //     { title: 'idadeDoPai', value: ana.idadeDoPai, text: 'Idade do pai' },
  //     { title: 'profissaoDoPai', value: ana.profissaoDoPai, text: 'Profissão do pai' },
  //   ],
  // };

  function formatAge(age) {
    const number = Number(age.replace(/\D/g, ''));
    if (!isNaN(number)) {
      return number + ' anos';
    }
    return null;
  }

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.headerContent}>
          <View style={styles.infoContainer}>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Data da coleta: </Text>
              <Text style={styles.infoValue}>{moment(ana.dataEnvio).format('DD/MM/YYYY')}</Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Nome: </Text>
              <Text style={styles.infoValue}>{ana.nomeCrianca} </Text>
            </View>
            <View style={styles.infoItem}>
              <Text style={styles.infoTitle}>Data Nascimento: </Text>
              <Text style={styles.infoValue}>{moment(ana.dataNascimentoCrianca).format('DD/MM/YYYY')}</Text>
            </View>
          </View>
          <View style={styles.logoContainer}>
            <Image src={logo} alt="images" style={styles.logo} />
            <Text style={styles.url}>app.earlydenver.com.br</Text>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View>
            <Text style={styles.sectionTitle}>Dados dos Pais</Text>
            <View style={styles.sectionContainer}>
              <View style={styles.itemContainerRow}>
                <Text style={styles.itemTitle}>Nome da Mãe: </Text>
                <Text style={styles.itemValueRow}>
                  {ana.nomeMae}
                  {formatAge(ana.idadeMae) && ' - ' + formatAge(ana.idadeMae)}
                </Text>
              </View>
              <View style={styles.itemContainerRow}>
                <Text style={styles.itemTitle}>Nome do Pai: </Text>
                <Text style={styles.itemValueRow}>
                  {ana.nomePai}
                  {formatAge(ana.idadeDoPai) && ' - ' + formatAge(ana.idadeDoPai)}
                </Text>
              </View>
            </View>
          </View>
          <Text style={styles.contentTitle}>Anamnese</Text>
          {mappedAnamnese.map((section) => (
            <View key={section.section}>
              <View style={styles.sectionWrapper}>
                <Text style={styles.sectionTitle}>{section.section}</Text>
                <View style={styles.sectionContainer}>
                  {section.itemsSection.map((item) => (
                    <View key={item.title} wrap break={false} style={styles.itemContainer}>
                      <Text style={styles.itemTitle}>{item.text}</Text>
                      <Text style={styles.itemValue}>{item.value === '' ? 'Sem resposta' : item.value}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
