import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import Fuse from 'fuse.js';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

import { Loading, LastChecklistStatsLevel } from '../../components';
import { isBirthdayWeek } from '../../utils/time';
import useChildrenModel from '../children/useChildrenModel';

export function ChildrenDashBoard() {
  const { t } = useTranslation();

  const {
    busy,
    loadingErrorMessage,
    childrenListFiltered,
    isBirthdayDay,
    toastBirthday,
    isInterventionSessionLate,
    isChecklistLate,
  } = useChildrenModel();

  // Search
  const [query, updateQuery] = useState('');
  const fuse = new Fuse(childrenListFiltered, {
    // minMatchCharLength: 3,
    keys: ['person.name'],
    distance: 100,
    threshold: 0.3,
  });
  function onSearch({ currentTarget }) {
    updateQuery(currentTarget.value);
  }
  const results = fuse.search(query);
  const filterFuse = (el) => {
    if (results.length > 0) {
      for (let rs of results) {
        if (rs.item.id === el.id) return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                id="search"
                name="search"
                value={query}
                onChange={onSearch}
                required
                type="text"
                className="form-control form-control-sm"
                autoComplete="off"
                placeholder={t('Search here')}
              />
            </div>
          </form>
        </div>
      </nav>

      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/children">{t('Sidebar.Overview')}</a>
            </li>
          </ol>
        </nav>

        <div className="row">
          {loadingErrorMessage && (
            <div className="col-md-12 grid-margin">
              <div className="alert alert-primary" role="alert">
                {loadingErrorMessage}
              </div>
            </div>
          )}
          {!loadingErrorMessage && busy && (
            <div className="col-md-12 grid-margin">
              <Loading loadingText={t('Fetching children records')} />
            </div>
          )}
          {!loadingErrorMessage &&
            !busy &&
            childrenListFiltered.filter(filterFuse).map((child) => (
              <div className="col-12 col-md-6 col-lg-4 grid-margin" key={child.id}>
                <div className="card child-card h-100">
                  <div className="card-body">
                    <div className="card-title-group">
                      <h4 className="card-title actionable">
                        {child.active && (
                          <a href={`/stats/${child.id}`} style={{ color: 'black' }}>
                            {child.person.name}
                          </a>
                        )}
                        {!child.active && (
                          <a href={`/stats/${child.id}`} style={{ color: 'red' }}>
                            {child.person.name}
                          </a>
                        )}
                        <i
                          className={
                            'mdi mdi-human-' + (child.person.gender === 'FEMALE' ? 'female' : 'male') + ' side-icon'
                          }
                        ></i>
                      </h4>
                    </div>

                    <div className="card-internal-group mt-4">
                      <LastChecklistStatsLevel child={child} fetchChecklists />
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          {isInterventionSessionLate(child.lis) === false && (
                            <div>
                              <small className="text-muted">
                                {t('Last intervention session in')} {moment(child.lis).format(t('MM/DD'))}
                              </small>
                            </div>
                          )}
                          {isInterventionSessionLate(child.lis) === true && (
                            <div>
                              <small className="text-danger d-flex flex-row">
                                <i className="mdi mdi-alert mr-1"></i>
                                {t('Last intervention session in')}{' '}
                                <strong className="ml-1">{moment(child.lis).format(t('MM/DD'))}</strong>
                              </small>
                            </div>
                          )}

                          {isChecklistLate(child.lc) === false && (
                            <div>
                              <small className="text-muted">
                                {t('Last checklist in')} {moment(child.lc).format(t('MM/DD'))}
                              </small>
                            </div>
                          )}
                          {isChecklistLate(child.lc) === true && (
                            <div>
                              <small className="text-danger d-flex flex-row">
                                <i className="mdi mdi-alert mr-1"></i>
                                {t('Last checklist in')}{' '}
                                <strong className="ml-1">{moment(child.lc).format(t('MM/DD'))}</strong>
                              </small>
                            </div>
                          )}
                        </div>
                        {isBirthdayWeek(child.person.dateOfBirth) === true && (
                          <div>
                            <small className="text-primary">
                              {' '}
                              <span className="mr-1" role="img" aria-labelledby="birthday party emoji">
                                🥳
                              </span>
                              {t('birthday on')} {moment(child.person.dateOfBirth).format(t('MM/DD'))}
                              <span className="ml-1" role="img" aria-labelledby="party popper emoji">
                                🎉
                              </span>
                            </small>
                          </div>
                        )}
                        {isBirthdayDay(child.person.dateOfBirth) === true && toastBirthday(child)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
