import { changePasswordForm, createNewAccount, deleteAccount, loginPasswordClick } from './auth';
import { graphQLRequest } from './common';

export const create = async (
  active,
  name,
  password,
  phoneNumber,
  email,
  dateOfBirth,
  gender,
  professionId,
  responseCallback,
) =>
  // Checagem da presença da data de nascimento. Caso não ocorra, a query do graphql
  // envia a string 'null' ao  invés do valor null, gerando erro
  new Promise((resolve, reject) => {
    const birthDate = dateOfBirth ? `dateOfBirth: "${dateOfBirth}"` : '';
    const graphQL = {
      query: `
            mutation {
                createStaffMember (
                    data: {
                        active: ${active}
                        person: {
                            create: {
                                name: "${name}"
                                phone: "${phoneNumber}"
                                email: "${email}"
                                gender: ${gender}
                                ${birthDate}
                            }
                        }
                        profession: {
                          connect: {
                            id: "${professionId}"
                          }
                        }
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
      variables: {},
    };

    createNewAccount(name, email, password, (response) => {
      if (response.status === 201 || response.status === 250) {
        graphQLRequest(graphQL, (result, err) => {
          if (!err) {
            if (responseCallback) return responseCallback(result);
            return resolve(result);
          } else {
            if (responseCallback) return responseCallback(null, err);
            return reject(null, err);
          }
        });
      } else {
        if (responseCallback) return responseCallback(null, response);
        return resolve(null, response);
      }
    });
  });

export const verifyRegistered = (name, dateOfBirth, responseCallback) => {
  const graphQL = {
    query: `
    {
          staffMembers(
            where:{
              person:{
                name: """${name}"""
			  }
            }) 
		{  
			id
			person {
			  name   
			  gender
			  email
			  phone
			  dateOfBirth
			}      
			profession {
				id
				title
			}
        }
    }
      `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      var exist = false;
      if (result.data.staffMembers && result.data.staffMembers.length > 0) {
        for (let staff of result.data.staffMembers) {
          var d = new Date(staff.person.dateOfBirth);
          var date = ('0' + d.getDate()).slice(-2);
          var month = ('0' + (d.getMonth() + 1)).slice(-2);
          var year = d.getFullYear();
          var dateStr = date + '/' + month + '/' + year;
          if (dateStr === dateOfBirth) {
            exist = true;
          }
        }
      }
      responseCallback(exist);
    } else {
      responseCallback(null, err);
    }
  });
};

export const update = (
  id,
  active,
  name,
  password,
  phone,
  email,
  dateOfBirth,
  gender,
  professionId,
  responseCallback,
) => {
  const dateOfBirthQuery = dateOfBirth ? `dateOfBirth: "${dateOfBirth}"` : '';
  const phoneQuery = phone ? `phone: "${phone}"` : '';
  const queryProfessionQuery = professionId ? `profession: { connect: { id: "${professionId}" } }` : '';
  const graphQL = {
    query: `
          mutation {
              updateStaffMember (
                  data: {
                      active: ${active} 
                      person: {
                          update: {
                              name: "${name}"
                              ${phoneQuery}
                              email: "${email}"
                              ${dateOfBirthQuery}
                              gender: ${gender}
                          }
                      }
                      ${queryProfessionQuery}
                  }
                  where: {
                      id: "${id}"
                  }
              ){
                  id
                  createdAt
                  updatedAt
              }
          }
      `,
    variables: {},
  };

  if (password.length !== 0) {
    changePasswordForm(email, password, (response, err) => {
      if (response.status === 455) {
        createNewAccount(name, email, password, (response) => {
          if (response.status === 201 || response.status === 250) {
            graphQLRequest(graphQL, (result, err) => {
              if (!err) {
                graphQLRequest(graphQL, responseCallback);
              } else {
                responseCallback(null, err);
              }
            });
          } else {
            responseCallback(null, response);
          }
        });
      } else if (response.status !== 200) {
        responseCallback(null, response);
      } else {
        graphQLRequest(graphQL, responseCallback);
      }
    });
  } else {
    graphQLRequest(graphQL, responseCallback);
  }
};

export const del = (id, email, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `mutation { deleteStaffMember ( where: {id: "${id}"} ) {id createdAt updatedAt} }`,
      variables: {},
    };

    if (responseCallback) {
      graphQLRequest(graphQL, responseCallback);
      deleteAccount(email, (_response) => {});
      return;
    }

    graphQLRequest(graphQL, (res, err) => {
      if (!err) return resolve(res);
      return reject(err);
    });

    // TODO: trocar após uso de base de testes
    deleteAccount(email, (_response) => {
      // if (response.status !== 200) {
      // responseCallback(null, response)
      // } else {
      // graphQLRequest(graphQL, responseCallback);
      // }
    });
  });

export const get = (id, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
      staffMember(where: { id: "${id}"}) {
        id
		active
        person {
          id
          name	  
          gender
          email
          phone
          dateOfBirth
        }      
        profession {
            id
            title
        }
      }
    }
      `,
      variables: {},
    };
    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        if (responseCallback) return responseCallback(result.data.staffMember);
        return resolve(result.data.staffMember);
      } else {
        if (responseCallback) return responseCallback(null, err);
        return reject(null, err);
      }
    });
  });

export const getByEmail = (email, password, responseCallback) => {
  const graphQL = {
    query: `
		{
            childStaffAssignments(
              where: { 
                staffMember: { 
                  person:{email:"""${email}"""} 
                } 
              }
            ) {
              id
              child {
                id                  
              }
              staffAttribution {
                id
                title
              }
              staffMember {
                id
                active
                person {
                  id
                }
                profession {
                  id
                }
                mig__personId                               
              }
            }
        } 
      `,
    variables: {},
  };

  const graphQLStaff = {
    query: `
		{
            staffMembers(
              where: { 
                person:{email:"""${email}"""} 
              }
            ) {
                id
                active
                person {
                  id
                }
                profession {
                  id
                }
                mig__personId                          
            }
        } 
      `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, resultToken, err) => {
    if (!err && result.data.childStaffAssignments && result.data.childStaffAssignments.length === 0) {
      graphQLRequest(graphQLStaff, (resultstaff, resultToken, err) => {
        if (err) {
          responseCallback(null, null, err);
        } else {
          loginPasswordClick(email, password, (resultToken, err) => {
            if (err) {
              responseCallback(null, null, err);
            } else {
              responseCallback(resultstaff.data.staffMembers[0], resultToken);
            }
          });
        }
      });
    } else if (!err && result.data.childStaffAssignments && result.data.childStaffAssignments.length > 0) {
      loginPasswordClick(email, password, (resultToken, err) => {
        if (err) {
          responseCallback(null, null, err);
        } else {
          responseCallback(result.data.childStaffAssignments, resultToken);
        }
      });
    } else if (!err) {
      responseCallback(null);
    } else {
      responseCallback(null, null, err);
    }
  });
};

export const getAllStaffMembers = (responseCallback) => {
  const graphQL = {
    query: `{
      staffMembers{
        id
		active
        person {
          id
          name
          gender
          phone
          mobilePhone
          email
        }
        profession {
          id
          title
    
        }
      }
    }
    `,
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.staffMembers.sort((a, b) => a.person.name.localeCompare(b.person.name)));
    } else {
      responseCallback(null, err);
    }
  });
};

export const getStaffProfessionTypes = (responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
            staffProfessions {
              id
              title
            }
          }
        `,
      variables: {},
    };
    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        if (responseCallback) return responseCallback(result.data.staffProfessions.sort((a, b) => a.title - b.title));
        return resolve(result.data.staffProfessions.sort((a, b) => a.title - b.title));
      } else {
        if (responseCallback) return responseCallback(null, err);
        return reject(null, err);
      }
    });
  });

export const getStaffAttributions = (responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
            staffAttributions {
              id
              title
            }
          }
        `,
      variables: {},
    };
    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        if (responseCallback) return responseCallback(result.data.staffAttributions.sort((a, b) => a.title - b.title));
        return resolve(result.data.staffAttributions.sort((a, b) => a.title - b.title));
      } else {
        if (responseCallback) return responseCallback(null, err);
        return reject(null, err);
      }
    });
  });

export const getStaffMemberAssignments = (id) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
              childStaffAssignments(
                where: {
                  staffMember: {
                    id:"${id}"
                  }
                }
              ) {
                id
                child {
                  id
                }
              }
      }`,
      variables: {},
    };
    return graphQLRequest(graphQL, (res, err) => {
      if (!err) return resolve(res.data.childStaffAssignments);
      return reject(err);
    });
  });

export const updateChildrenList = (_, responseCallback) => {
  const oldUserData = JSON.parse(window.localStorage.getItem('loggedUserData'));
  const { email, id } = oldUserData;

  if (!email) throw new Error('Falha ao refazer sessao');

  const updateChildren = (list) => {
    const permissions = ['denver', 'behavior', 'staffMember', 'child'];
    const childrenId = list.map((child) => child.child.id);

    permissions.push('updateStaffMember:' + id);
    for (let item of list) {
      if (item.child) {
        permissions.push(
          'child:' + item.child.id + ':childChecklistAssessment',
          'child:' + item.child.id + ':childChildIndividualizedPlan',
          'child:' + item.child.id + ':childIndividualizedPlanSession',
          'child:' + item.child.id + ':createChildIndividualizedPlanSession',
          'child:' + item.child.id + ':deleteChildIndividualizedPlanSession:*',
          'child:' + item.child.id + ':updateChildIndividualizedPlanSession:*',
        );
        if (item.staffAttribution) {
          // Supervisors
          if (item.staffAttribution.title === 'Main Supervisor' || item.staffAttribution.title === 'Supervisor') {
            permissions.push(
              'staffMember',
              'createStaffMember',
              'updateStaffMember:*',
              'child',
              'createChild',
              'updateChild:*',
              'child:' + item.child.id + ':createChildChecklistAssessment',
              'child:' + item.child.id + ':deleteChildChecklistAssessment:*',
              'child:' + item.child.id + ':updateChildChecklistAssessment:*',
              'child:' + item.child.id + ':createChildIndividualizedPlan',
              'child:' + item.child.id + ':deleteChildIndividualizedPlan:*',
              'child:' + item.child.id + ':updateChildIndividualizedPlan:*',
              'child:' + item.child.id + ':updateChildIndividualizedPlanSessionSteps',
            );
          }
        }
      }
    }

    oldUserData.children = childrenId;
    oldUserData.permissions = permissions;
    window.localStorage.setItem('loggedUserData', JSON.stringify(oldUserData));
    window.location.href = '/';
  };

  new Promise((resolve, reject) => {
    const graphQL = {
      query: `
      {
        childStaffAssignments(
          where: { staffMember: { person: { email: "${email}" } } }
        ) {
          child {
            id
          }
          staffAttribution {
            title
          }
        }
      }
          `,
      variables: {},
    };

    if (responseCallback) return graphQLRequest(graphQL, responseCallback);

    graphQLRequest(graphQL, (res, err) => {
      if (!err) return resolve(res.data.childStaffAssignments);
      return reject(err);
    });
  }).then((list) => updateChildren(list));
};
