import React from 'react';

import { LoadingCircle } from '../../../../../components/LoadingCircle';
import { useSessionReport } from './useSessionReport';

export function ExportSessionPdf({ sessionId, children }) {
  const { downloadPDFHandle, error, loading } = useSessionReport();

  if (loading || error)
    return (
      <span disabled style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        {error ? 'Error' : children}
        {loading && !error && <LoadingCircle />}
      </span>
    );
  return <span onClick={() => downloadPDFHandle(sessionId)}>{children}</span>;
}
