import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { getDomainCalculusDetails } from '../../../../../services/childStats';

export default function CalculusDescription({ child, allChecklists }) {
  const { t } = useTranslation();

  const [selectedChecklist, setSelectedChecklist] = useState(allChecklists[0]);
  const [selectedChecklistData, setSelectedChecklistData] = useState();
  const [availableDomains, setAvailableDomains] = useState();
  const [selectedDomain, setSelectedDomain] = useState();

  useEffect(() => {
    const fetchData = async () => {
      // Clear data before fetching new one. This prevents the user from seeing old data
      // while new data is being fetched
      setSelectedChecklistData();

      const [, evaluatedDomainsByLevels] = await getDomainCalculusDetails(
        child.id,
        selectedChecklist.id,
        child.person.dateOfBirth,
      );

      const evaluatedDomains = Object.keys(evaluatedDomainsByLevels)
        .map((key) => ({
          code: key,
          title: Object.values(evaluatedDomainsByLevels[key][0])[0].developmentalDomain.title,
          order: Object.values(evaluatedDomainsByLevels[key][0])[0].developmentalDomain.order,
        }))
        .sort((a, b) => a.order - b.order);

      setSelectedChecklistData(evaluatedDomainsByLevels);
      setAvailableDomains(evaluatedDomains);
      setSelectedDomain(evaluatedDomains[0].code);
    };

    fetchData();
  }, [selectedChecklist]);

  const basicText = (
    <>
      <p className="mb-2">{t('calculus-description-modal.introductory-text')}</p>
      <p className="mb-3">{t('calculus-description-modal.formula-description')}</p>
    </>
  );

  const loading = (
    <div className="container-fluid">
      {basicText}
      <br />
      <h4>{t('Loading')}</h4>
      <br />
      <br />
      <br />
      <div className="swal2-actions swal2-loading" style={{ display: 'flex' }}>
        <span
          className="swal2-confirm swal2-styled"
          style={{ display: 'flex', borderLeftColor: '#D53734', borderRightColor: '#D53734' }}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );

  if (!selectedChecklist || !selectedChecklistData || !selectedDomain) return loading;

  const selectedData = selectedChecklistData[selectedDomain] || [];

  const parsedData = selectedData.map((item) => {
    const level = Object.keys(item)[0];
    const percentualScore = Object.values(item)[0].percentualScore;
    const evaluatedItensCount = Object.values(item)[0].evaluatedItensCount;
    return { level, percentualScore, evaluatedItensCount };
  });

  const evaluatedCountTotal = parsedData.reduce((a, b) => a + b.evaluatedItensCount, 0);
  const weightedScoreTotal = parsedData.reduce((a, b) => a + b.evaluatedItensCount * b.percentualScore, 0).toFixed(2);
  const finalScore = ((weightedScoreTotal / evaluatedCountTotal) * 100).toFixed(0);

  const handleChecklistChange = (input) => {
    const selected = allChecklists.find((item) => item.id === input.target.value);
    setSelectedChecklist(selected);
  };

  const handleDomainChange = (input) => {
    setSelectedDomain(input.target.value);
  };

  return (
    <div className="container-fluid">
      {basicText}
      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="checklistSelect" className="mb-0">
            {t('calculus-description-modal.checklist-select-label')}
          </label>
          <select
            className="form-control"
            name="checklistSelect"
            id="checklistSelect"
            value={selectedChecklist.id}
            onChange={handleChecklistChange}
          >
            {allChecklists.map((item) => (
              <option key={item.id} value={item.id}>
                {moment(item.date).format(t('MM/DD/YYYY'))}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col">
          <label htmlFor="domainSelect" className="mb-0">
            {t('calculus-description-modal.domain-select-label')}
          </label>
          <select
            className="form-control"
            name="domainSelect"
            id="domainSelect"
            value={selectedDomain}
            onChange={handleDomainChange}
          >
            {availableDomains.map((item) => (
              <option key={item.code} value={item.code}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">{t('Level')}</th>
            <th scope="col">{t('Percentage Score')}</th>
            <th scope="col">{t('calculus-description-modal.evaluated-items')}</th>
            <th scope="col">{t('calculus-description-modal.weighted-score')}</th>
          </tr>
        </thead>
        <tbody>
          {parsedData.map((item) => (
            <tr key={Math.random()}>
              <td>{item.level}</td>
              <td>{(item.percentualScore * 100).toFixed(0)}%</td>
              <td>{item.evaluatedItensCount}</td>
              <td>{(item.percentualScore * item.evaluatedItensCount).toFixed(2)}</td>
            </tr>
          ))}

          <tr>
            <td colSpan="2">
              <strong>TOTAL</strong>
            </td>
            <td>
              <strong>{evaluatedCountTotal}</strong>
            </td>
            <td>
              <strong>{weightedScoreTotal}</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <p className="mt-3">{t('calculus-description-modal.conclusion-text')}</p>
      <p className="lead">
        {weightedScoreTotal} &divide; {evaluatedCountTotal} = <strong>{finalScore}%</strong>
      </p>
    </div>
  );
}
