import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import ConsolidatedChecklistScoreProgress from './ConsolidatedChecklistScoreProgress';
import LevelsChecklistScoreProgress from './LevelsChecklistScoreProgress';

export default function ChecklistScoreProgress({ child, allChecklistsData }) {
  const { t } = useTranslation();

  const [allGraphsData, setAllGraphsData] = useState();
  const [activeSeries, setActiveSeries] = useState();
  const [loading, setLoading] = useState(true);

  function parseLevelsData(levelsData) {
    const result = {};
    const numArrays = levelsData.length;
    const numObjects = Math.max(...levelsData.map((l) => l.length));

    for (let i = 1; i <= numObjects; i++) {
      let currentKey = levelsData[0][i - 1] ? Object.keys(levelsData[0][i - 1])[0] : i;
      result[currentKey] = [];

      for (let j = 0; j < numArrays; j++) {
        let currentValue = levelsData[j][i - 1] ? levelsData[j][i - 1][currentKey] : null;
        result[currentKey].push(currentValue);
      }
    }

    return result;
  }

  const getChartData = (checklists) => {
    const consolidatedSeries = checklists.map((c) => parseFloat(c.developmentAcc.toFixed(2)));
    const levelSeries = parseLevelsData(checklists.map((c) => c.levelsScore));
    const categories = checklists.map((c) => c.checklistDate);
    const yMax = Math.max(...checklists.map((c) => c.developmentTotal));
    const levelsYMax = Math.max(...Object.values(levelSeries).flat());
    const ids = checklists.map((c) => c.id);
    return { consolidatedSeries, levelSeries, categories, yMax, levelsYMax, ids };
  };

  const orderSeries = (series) => {
    const indices = series.categories
      .map((date, index) => index)
      .sort((a, b) => moment(series.categories[a]).diff(moment(series.categories[b]), 'days'));

    const sortedSeries = {
      ...series,
      consolidatedSeries: indices.map((index) => series.consolidatedSeries[index]),
      levelSeries: {},
      categories: indices.map((index) => series.categories[index]),
      ids: indices.map((index) => series.ids[index]),
    };

    for (const key in series.levelSeries) {
      if (key in series.levelSeries) {
        sortedSeries.levelSeries[key] = indices.map((index) => series.levelSeries[key][index]);
      }
    }

    return sortedSeries;
  };

  const handleActiveChecklists = (item) => {
    const itemIndex = activeSeries.ids.indexOf(item.id);
    if (itemIndex !== -1) {
      const filteredChecklists = {
        ...activeSeries,
        consolidatedSeries: [...activeSeries.consolidatedSeries.filter((_, i) => i !== itemIndex)],
        levelSeries: { ...activeSeries.levelSeries },
        categories: [...activeSeries.categories.filter((_, i) => i !== itemIndex)],
        ids: [...activeSeries.ids.filter((_, i) => i !== itemIndex)],
      };
      Object.keys(filteredChecklists.levelSeries).forEach((key) => {
        filteredChecklists.levelSeries[key] = [
          ...filteredChecklists.levelSeries[key].filter((_, i) => i !== itemIndex),
        ];
      });
      setActiveSeries(orderSeries(filteredChecklists));
    } else {
      const addedItemIndex = allGraphsData.ids.indexOf(item.id);
      const newSeries = {
        ...activeSeries,
        consolidatedSeries: [...activeSeries.consolidatedSeries, allGraphsData.consolidatedSeries[addedItemIndex]],
        levelSeries: { ...activeSeries.levelSeries },
        categories: [...activeSeries.categories, allGraphsData.categories[addedItemIndex]],
        ids: [...activeSeries.ids, allGraphsData.ids[addedItemIndex]],
      };
      Object.keys(newSeries.levelSeries).forEach((key) => {
        newSeries.levelSeries[key] = [...newSeries.levelSeries[key], allGraphsData.levelSeries[key][addedItemIndex]];
      });
      const orderedNewSeries = orderSeries(newSeries);
      setActiveSeries(orderedNewSeries);
    }
  };

  useEffect(() => {
    const allGraphs = getChartData(allChecklistsData);

    // Start up with the last 4 checklists (if available)
    const initialSeries = {
      ...allGraphs,
      consolidatedSeries: allGraphs.consolidatedSeries
        .slice(0, 4)
        .filter((item) => item !== null && item !== undefined),
      categories: allGraphs.categories.slice(0, 4).filter((item) => item !== null && item !== undefined),
      ids: allGraphs.ids.slice(0, 4).filter((item) => item !== null && item !== undefined),
    };
    const sortedSeries = orderSeries(initialSeries);

    setAllGraphsData(allGraphs);
    setActiveSeries(sortedSeries);

    setLoading(false);
  }, [allChecklistsData]);

  if (loading || !activeSeries) return <></>;

  return (
    <div className="row">
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h5 className="d-flex justify-content-center">{t('Development Growth')}</h5>
            <div className="row">
              <div className="col-lg-2">
                <div className="container-fluid mt-4">
                  <strong>Checklists</strong>
                  <ul>
                    {allChecklistsData.map((item) => (
                      <li key={item.id} className="d-flex" style={{ marginBottom: '-5px', gap: '0.25rem' }}>
                        <label className="d-flex align-items-center" style={{ gap: '0.25rem' }}>
                          <input
                            type="checkbox"
                            checked={activeSeries.ids.includes(item.id)}
                            onChange={() => handleActiveChecklists(item)}
                            style={{ marginBottom: '3px' }}
                          />
                          {moment(item.checklistDate).format(t('MM/DD/YYYY'))}
                        </label>
                        {item.id && (
                          <a
                            title={`${t('Open checklist')}: ${moment(item.checklistDate).format(t('MM/DD/YYYY'))}`}
                            className="d-inline"
                            href={`/children/${child.id}/checklist/${item.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            &#x2197;
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="container-fluid mt-4">
                  <ConsolidatedChecklistScoreProgress activeSeries={activeSeries} className="mb-4" />
                </div>
                <div className="container-fluid mt-4">
                  <LevelsChecklistScoreProgress activeSeries={activeSeries} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
