import React from 'react';

import ChecklistDevelopmentalDomainStatsBar from '../../charts/ChecklistDevelopmentalDomainStatsBar';
import ChecklistDevelopmentalDomainStatsPolarArea from '../../charts/ChecklistDevelopmentalDomainStatsPolarArea';
import ChecklistDevelopmentalDomainStatsRadar from '../../charts/ChecklistDevelopmentalDomainStatsRadar';

function LastChecklistStatsDevelopmentalDomain({ child, lastChecklistData, type = 'column' }) {
  if (!lastChecklistData) {
    return <></>;
  }

  if (type === 'column') {
    return (
      <ChecklistDevelopmentalDomainStatsBar sortBy="max" childId={child.id} lastChecklistData={lastChecklistData} />
    );
  }

  if (type === 'radar') {
    return <ChecklistDevelopmentalDomainStatsRadar childId={child.id} lastChecklistData={lastChecklistData} />;
  }

  if (type === 'polarArea') {
    return <ChecklistDevelopmentalDomainStatsPolarArea childId={child.id} lastChecklistData={lastChecklistData} />;
  }
}

export default LastChecklistStatsDevelopmentalDomain;
