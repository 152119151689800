import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

export default ({ skillStep, childId }) => {
  const { t } = useTranslation();

  const [showAll, setShowAll] = useState(false);

  const filterSession = skillStep.sessions.filter((item) => item.positiveAchievement + item.negativeAchievement > 0);

  // fill session number
  const memSessionNumber = {};
  skillStep.sessions = filterSession.map((ses) => {
    const k = moment(ses.date).format(t('MM/DD'));
    if (!memSessionNumber[k]) {
      memSessionNumber[k] = 1;
    } else {
      memSessionNumber[k] += 1;
    }
    ses.sessionNumber = parseInt(memSessionNumber[k]);
    return ses;
  });

  const skillStepAchievementMininumAttempts =
    skillStep.achievementMininumAttempts ?? skillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts;

  const skillStepAchievementMinimumScore =
    skillStep.achievementMinimumScore ?? skillStep.denverChecklistSkillAssessmentStep.achievementMinimumScore;

  if (filterSession.length === 0) {
    return <div>{t('no intervention sessions yet')}</div>;
  }

  const filteredSession = filterSession
    .sort((a, b) => moment(b.date) - moment(a.date))
    .slice(0, showAll || skillStep.sessions.length < 6 ? skillStep.sessions.length : 5);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>{t('Session Date')}</th>
          <th className="mobile-hidden">{t('Session number')}</th>
          <th className="mobile-hidden">{t('Achievements')}</th>
          <th>{t('Result')}</th>
        </tr>
      </thead>
      <tbody>
        {filteredSession.map((ss, idx) => (
          <tr key={ss.id + '_sess_' + idx}>
            <th>
              <a href={`/children/${childId}/session/${ss.idSession}`} rel="noreferrer" target="_blank">
                {moment(ss.date).format(t('MM/DD'))}
              </a>
            </th>

            <td className="mobile-hidden">
              <a href={`/children/${childId}/session/${ss.idSession}`} rel="noreferrer" target="_blank">
                {ss.sessionNumber}
              </a>
            </td>

            <td className="mobile-hidden">
              {ss.positiveAchievement}/{ss.positiveAchievement + ss.negativeAchievement}
            </td>
            <td>
              {ss.positiveAchievement + ss.negativeAchievement < skillStepAchievementMininumAttempts && (
                <div>{t('not enough attempts')}</div>
              )}
              {ss.positiveAchievement + ss.negativeAchievement >= skillStepAchievementMininumAttempts && (
                <div className="progress">
                  <div
                    className={`progress-bar progress-bar-striped progress-bar-animated bg-${
                      ss.positiveAchievement / (ss.positiveAchievement + ss.negativeAchievement) <
                      skillStepAchievementMinimumScore
                        ? 'danger'
                        : 'success'
                    }`}
                    role="progressbar"
                    style={{
                      width: `${(ss.positiveAchievement / (ss.positiveAchievement + ss.negativeAchievement)) * 100}%`,
                    }}
                    aria-valuenow={(ss.positiveAchievement / (ss.positiveAchievement + ss.negativeAchievement)) * 100}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <span className="mobile-visible-only">
                      {ss.positiveAchievement}/{ss.positiveAchievement + ss.negativeAchievement}
                    </span>
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      {!showAll && filterSession.length > 5 && (
        <tfoot>
          <tr>
            <td colSpan="4">
              <button onClick={() => setShowAll(true)} className="btn btn-outline-secondary">
                {t('View all {{X}} sessions', { X: filterSession.length })}
              </button>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};
