import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { pdf } from '@react-pdf/renderer';

import { saveAs } from 'file-saver';
import moment from 'moment';

import { Templates } from '../../../../../components';
import { getAllSkills } from '../../../../../services/denver';
import { resolvePlanUniqueSkills } from '../../../../../services/individualizedPlan';
import { getInterventionSession } from '../../../../../services/interventionSession';

export const useSessionReport = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const getSessionReportData = (sessionId, callback) => {
    getInterventionSession(sessionId, (session, err) => {
      if (!session) {
        return;
      }
      let interventionSessionMix = Object.assign({}, session, {
        skillSteps: [],
      });
      const skills = resolvePlanUniqueSkills(session.childIndividualizedPlan);

      // group in each skill its steps and results
      skills.forEach((s) => {
        interventionSessionMix.skillSteps.push({
          plan: true,
          skill: s,
          sessionDate: session.checkinAt,
          stepsResult: session.skillAssessmentStepScore.filter(
            (sss) =>
              sss.childIndividualizedPlanSkillStep &&
              ((sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep &&
                sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.skill.code === s.code) ||
                (sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill &&
                  sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill.skill.code === s.code)),
          ),
        });
      });

      interventionSessionMix.skillSteps = interventionSessionMix.skillSteps
        .filter((im) => im.stepsResult.length > 0)
        .sort((a, b) => {
          // group skills by code
          if (a.skill.developmentalDomain.order === b.skill.developmentalDomain.order) {
            // group skills by level
            if (a.skill.level === b.skill.level) {
              // order customized skills: if a skill has the skillFrom property, it goes first
              if (a.skill.code.match(/CTM/) && !a.skill.skillFrom && !b.skill.code.match(/CTM/)) return 1;
              if (b.skill.code.match(/CTM/) && !b.skill.skillFrom && !a.skill.code.match(/CTM/)) return -1;
              if (a.skill.code.match(/CTM/) && b.skill.code.match(/CTM/)) {
                if (a.skill.skillFrom && !b.skill.skillFrom) return -1;
                if (!a.skill.skillFrom && b.skill.skillFrom) return 1;
              }
              // order skills that have the skillFrom property
              if (a.skill.skillFrom && b.skill.skillFrom) return a.skill.skillFrom.order - b.skill.skillFrom.order;
              if (a.skill.skillFrom && !b.skill.skillFrom) return a.skill.skillFrom.order - b.skill.order;
              if (!a.skill.skillFrom && b.skill.skillFrom) return a.skill.order - b.skill.skillFrom.order;

              return a.skill.order - b.skill.order;
            }
            if (a.skill.skillFrom && b.skill.skillFrom) return b.skill.skillFrom.order - b.skill.skillFrom.order;
            if (a.skill.skillFrom && !b.skill.skillFrom) return a.skill.skillFrom.order - a.skill.order;
            if (!a.skill.skillFrom && b.skill.skillFrom) return b.skill.order - b.skill.skillFrom.order;
            return b.skill.level - a.skill.level;
          }
          return a.skill.developmentalDomain.order - b.skill.developmentalDomain.order;
        });

      getAllSkills((skillsAll, err) => {
        skillsAll
          .filter((fff) => (skills.some((r) => r.id === fff.id) ? false : true))
          .forEach((s) => {
            if (s.assessmentSteps.length > 0) {
              interventionSessionMix.skillSteps.push({
                plan: false,
                skill: s,
                stepsResult: session.skillAssessmentStepScore.filter(
                  (sss) =>
                    sss.childIndividualizedPlanSkillStep &&
                    ((sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep &&
                      sss.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.skill.code === s.code) ||
                      (sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill &&
                        sss.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill.skill.code === s.code)),
                ),
              });
            }
          });
      });

      const activeSkillSteps = interventionSessionMix.skillSteps
        .filter((i) => i.plan)
        .map((skillStep) => getCurrentStep(skillStep));

      const data = {
        attendant: interventionSessionMix.attendant.person.name,
        child: interventionSessionMix.childIndividualizedPlan.child.person.name,
        dob: new Date(interventionSessionMix.childIndividualizedPlan.child.person.dateOfBirth).toLocaleDateString(),
        checkinAt: new Date(interventionSessionMix.checkinAt).toLocaleDateString(),
        items: activeSkillSteps.map((step) => ({
          domain:
            step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.skill.developmentalDomain.title,
          title: step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.skill.title,
          description:
            step.childIndividualizedPlanSkillStep.description ??
            step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.description,
          minimumAttempts:
            step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts ??
            step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts,
          minimumSuccess: Math.floor(
            (step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts ??
              step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts) /
              (step.childIndividualizedPlanSkillStep.achievementMinimumScore ??
                step.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMinimumScore),
          ),
          success: step.positiveAchievement,
          failure: step.negativeAchievement,
          notEvaluated: !step.positiveAchievement && !step.negativeAchievement,
        })),
      };

      callback(data, null);
    });
  };

  const getFileName = (sess) => {
    const childName = sess.child;
    const sessionDate = sess.checkinAt;
    const fileNameSpace = `${childName}-${sessionDate}`.replace(/\s/g, '_');
    const fileNameDash = fileNameSpace.replace(/\//g, '-');
    return fileNameDash;
  };

  const downloadPDFHandle = (sessionId) => {
    if (!sessionId) return;
    setLoading(true);
    try {
      getSessionReportData(sessionId, (session, err) => {
        if (err) {
          setError(err);
          setLoading(false);
          return;
        } else {
          const fileName = getFileName(session);
          saveAsPDF(session, fileName);
        }
      });
    } catch (error) {
      console.error(error);
      return window.Swal.fire({
        type: 'error',
        title: t('There was an error generating the DOCX report'),
        text: error,
      });
    }
  };

  function getCurrentStep(skillSteps) {
    let currentStep = skillSteps.stepsResult
      .sort((a, b) => {
        const aOrder = a.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep
          ? a.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.order
          : a.childIndividualizedPlanSkillStep.order;
        const bOrder = b.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep
          ? b.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.order
          : b.childIndividualizedPlanSkillStep.order;
        return aOrder - bOrder;
      })
      .find(
        (s) =>
          !s.childIndividualizedPlanSkillStep.achievementDate ||
          moment(s.childIndividualizedPlanSkillStep.achievementDate) > moment(skillSteps.sessionDate),
      );

    return currentStep;
  }

  const saveAsPDF = async (session, fileName) => {
    const doc = Templates.sessionReport(session);
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, fileName);
    setLoading(false);
  };

  return { downloadPDFHandle, loading, error };
};
