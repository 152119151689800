import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import moment from 'moment';

import { getStaffProfessionTypes, create, verifyRegistered, get, update } from '../../services/staff';

export default (idStaffMember) => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [active, setActive] = useState(true);
  const [currentId, setCurrentId] = useState(id !== 'new' ? id : null);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [gender, setGender] = useState('NOT_INFORMED');
  const [profession, setProfession] = useState({ id: '', title: '' });

  const [professionTypes, setProfessionTypes] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState('');
  const [formSubmissionMessageType, setFormSubmissionMessageType] = useState('');

  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    setBusy(true);

    if (idStaffMember.id) setCurrentId(idStaffMember.id);

    getStaffProfessionTypes((professionTypes, err) => {
      if (!err) {
        setProfessionTypes(professionTypes);
        if (!currentId) {
          setProfession(professionTypes[0]);
        }
      } else {
        setProfessionTypes([]);
        console.error(err);
      }
      setBusy(false);
    });

    if (currentId) {
      setLoadingErrorMessage('');
      get(currentId, (result, err) => {
        if (!err) {
          setActive(result.active);
          setName(result.person.name);
          setPhone(result.person.phone);
          setEmail(result.person.email);
          setDateOfBirth(moment(result.person.dateOfBirth).format(t('MM/DD/YYYY')));
          setGender(result.person.gender);
          setProfession(result.profession);
        } else {
          setLoadingErrorMessage(t('There was a problem fetching staff member details'));
        }
        setBusy(false);
      });
    } else {
      setBusy(false);
    }
  }, [currentId, t]);

  const save = () => {
    setSubmitting(true);

    if (!currentId) {
      //create
      verifyRegistered(name, dateOfBirth, (registered, err) => {
        if (!err) {
          if (registered) {
            setFormSubmissionMessageType('fail');
            setFormSubmissionMessage(t('Staff already registered!'));
            setSubmitting(false);
          } else {
            create(
              active,
              name,
              password,
              phone,
              email,
              dateOfBirth ? moment(dateOfBirth, t('MM/DD/YYYY')).toISOString() : null,
              gender,
              profession.id,
              (result, err) => {
                if (!err) {
                  setCurrentId(result.id);
                  setFormSubmissionMessageType('success');
                  setFormSubmissionMessage(t('Successfully registered staff member'));
                  setSubmitting(false);
                } else {
                  var errText = t('Error registering this new staff member');
                  if (err.status === 450) {
                    errText = t('Error registering (Invalid name)');
                  } else if (err.status === 455) {
                    errText = t('Error registering (Invalid email)');
                  } else if (err.status === 460) {
                    errText = t('Error registering (Invalid password)');
                  } else if (err.status === 465) {
                    errText = t('Error registering (Email already exists)');
                  }
                  setFormSubmissionMessageType('fail');
                  setFormSubmissionMessage(errText);
                  setSubmitting(false);
                }
              },
            );
          }
        } else {
          setFormSubmissionMessageType('fail');
          setFormSubmissionMessage(t('Error'));
          setSubmitting(false);
        }
      });
    } else {
      //update
      update(
        currentId,
        active,
        name,
        password,
        phone,
        email,
        dateOfBirth ? moment(dateOfBirth, t('MM/DD/YYYY')).toISOString() : null,
        gender,
        profession ? profession.id : null,
        (_, err) => {
          if (!err) {
            setFormSubmissionMessageType('success');
            setFormSubmissionMessage(t('Successfully updated staff member information!'));
            setSubmitting(false);
          } else {
            setFormSubmissionMessageType('fail');
            setFormSubmissionMessage(t('Error updating this staff member info'));
            setSubmitting(false);
          }
        },
      );
    }
  };

  return {
    active,
    setActive,
    currentId,
    name,
    setName,
    password,
    setPassword,
    email,
    setEmail,
    phone,
    setPhone,
    dateOfBirth,
    setDateOfBirth,
    gender,
    setGender,
    profession,
    setProfession,

    professionTypes,

    save,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    loadingErrorMessage,
    busy,
  };
};
