import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import AccessControl from '../../../../../AccessControl';
import { getNumberFromStepCode, getNumberFromSkillCode } from '../../../../../utils/legacy';
import ChildInterventionSessionSummaryTable from '../../../components/ChildInterventionSessionSummaryTable';
import useChildIndividualizedPlanSkillStepSessionModel from './useChildIndividualizedPlanSkillStepSessionModel';

function ChildIndividualizedPlanSkillStepSession({ skill, skillStep, childId, onStepAchievedChange }) {
  const { t } = useTranslation();

  const { toggleStepAchieved, achievementDate } = useChildIndividualizedPlanSkillStepSessionModel(
    skillStep.achievementDate,
    onStepAchievedChange,
  );

  const skillStepResolved = {
    code: skillStep.code ?? skillStep.denverChecklistSkillAssessmentStep.code,
    description: skillStep.description ?? skillStep.denverChecklistSkillAssessmentStep.description,
    achievementMininumAttempts:
      skillStep.achievementMininumAttempts ?? skillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts,
    achievementMinimumScore:
      skillStep.achievementMinimumScore ?? skillStep.denverChecklistSkillAssessmentStep.achievementMinimumScore,
  };

  return (
    <>
      <div className="bg-white">
        <div className="text-uppercase font-weight-bold">
          <div>
            <span className="text-secondary">
              {t('LEVEL_SINGLE_LETTER')}
              {skill.level}.{getNumberFromSkillCode(skill.code)}.{getNumberFromStepCode(skillStepResolved.code)}
            </span>
            &nbsp;—&nbsp;
            {skillStepResolved.description}
          </div>
        </div>
        <div>
          {t('Minimum attempts')}: <strong>{skillStepResolved.achievementMininumAttempts}</strong> |{' '}
          {t('Minimum score')}:{' '}
          <strong>
            {skillStepResolved.achievementMinimumScore ? skillStepResolved.achievementMinimumScore * 100 + '%' : '-'}
          </strong>
        </div>
        <div className="mt-1">
          {achievementDate && (
            <>
              <span className="badge badge-success mr-2 mb-2">
                {t('Achieved on')}: <strong>{moment(skillStep.achievementDate).format(t('MM/DD/YYYY'))} </strong>
              </span>
              <AccessControl
                requiredPermissions={[
                  'child:*:updateChildIndividualizedPlanSessionStep:*',
                  'child:' + childId + ':updateChildIndividualizedPlanSessionSteps',
                ]}
              >
                <button onClick={() => toggleStepAchieved(skillStep)} className="btn btn-outline-danger btn-xs">
                  {t('Revert')}
                </button>
              </AccessControl>
            </>
          )}
          {!achievementDate && (
            <>
              <span className="badge badge-warning mr-2 mb-2">{t('Not yet Achieved')}</span>
              <AccessControl
                requiredPermissions={[
                  'child:*:updateChildIndividualizedPlanSessionStep:*',
                  'child:' + childId + ':updateChildIndividualizedPlanSessionSteps',
                ]}
              >
                <button onClick={() => toggleStepAchieved(skillStep)} className="btn btn-outline-success btn-xs">
                  {t('Mark as achieved')}
                </button>
              </AccessControl>
            </>
          )}
        </div>
      </div>
      <ChildInterventionSessionSummaryTable skillStep={skillStep} childId={childId} />
    </>
  );
}

export default ChildIndividualizedPlanSkillStepSession;
