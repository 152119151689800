export const mapValuesScore = (score) => {
  if (score) return valuesScore[score];
  return 'NA';
};

const valuesScore = {
  PASS: '+',
  PASS_FAIL: '+-',
  FAIL: '-',
  NOT_EVALUATED: 'NA',
};

export const scoreForColorsCell = (score) => {
  if (score) return mapValues[score];
  return 'dddddd';
};

const mapValues = {
  PASS: '26e7a6',
  PASS_FAIL: 'febc3b',
  FAIL: 'ff6178',
  NOT_EVALUATED: 'dddddd',
};
