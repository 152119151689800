import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  increasePositiveSkillAssessmentStepScore,
  increaseNegativeSkillAssessmentStepScore,
  getSkillAssessmentStepScore,
} from '../../../../../services/interventionSession';

let intervalTimePositive = null;
let intervalTimeNegative = null;
const DEBOUNCE_TIME = 1000;

// eslint-disable-next-line no-unused-vars
export default (stepResult, interventionSession) => {
  const { t } = useTranslation();

  const [stepAssessmentId, setStepAssessmentId] = useState();
  const [positiveAchievement, setPositiveAchievement] = useState();
  const [negativeAchievement, setNegativeAchievement] = useState();
  const [loadingPositiveButton, setLoadingPositiveButton] = useState(true);
  const [loadingNegativeButton, setLoadingNegativeButton] = useState(true);

  useEffect(() => {
    setStepAssessmentId(stepResult.id);

    getSkillAssessmentStepScore(stepResult.id, (result, err) => {
      setLoadingNegativeButton(false);
      setLoadingPositiveButton(false);
      if (err) {
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('There was an error'),
        });
      }

      setPositiveAchievement(result.positiveAchievement);
      setNegativeAchievement(result.negativeAchievement);
    });
  }, [t, stepResult]);

  const updatePositiveResult = (type) => {
    clearInterval(intervalTimePositive);
    const amount = type === 'increase' ? 1 : -1;
    setPositiveAchievement(positiveAchievement + amount);

    intervalTimePositive = setTimeout(() => {
      setLoadingPositiveButton(true);
      increasePositiveSkillAssessmentStepScore(stepAssessmentId, positiveAchievement + amount, (_, err) => {
        setLoadingPositiveButton(false);
        if (err) {
          return window.Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('There was an error making this evaluation'),
          });
        }
      });
    }, DEBOUNCE_TIME);
  };

  const updateNegativeResult = (type) => {
    clearInterval(intervalTimeNegative);
    const amount = type === 'increase' ? 1 : -1;
    setNegativeAchievement(negativeAchievement + amount);

    intervalTimeNegative = setTimeout(() => {
      setLoadingNegativeButton(true);
      increaseNegativeSkillAssessmentStepScore(stepAssessmentId, negativeAchievement + amount, (_, err) => {
        setLoadingNegativeButton(false);
        if (err) {
          return window.Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('There was an error making this evaluation'),
          });
        }
      });
    }, DEBOUNCE_TIME);
  };

  // set `achievementDate` when the child completes a step - issue #22
  // const updateAchievementDate = () => {
  // let date = new Date();
  // getLastSkillAssessmentStepScores(
  //   interventionSession.childIndividualizedPlan.id,
  //   stepResult.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.id,
  //   new Date(date.setMonth(date.getMonth() - 3)).toISOString(),
  //   (score, err) => {
  //     if (score.length > 2) {
  //       let lastScores = score.slice(score.length - 3, score.length);
  //       // Mínimo de tentativas
  //       if (
  //         lastScores[0].positiveAchievement / (lastScores[0].positiveAchievement + lastScores[0].negativeAchievement) >=
  //           lastScores[0].childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMinimumScore &&
  //         lastScores[1].positiveAchievement / (lastScores[1].positiveAchievement + lastScores[1].negativeAchievement) >=
  //           lastScores[1].childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMinimumScore &&
  //         lastScores[2].positiveAchievement / (lastScores[2].positiveAchievement + lastScores[2].negativeAchievement) >=
  //           lastScores[2].childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMinimumScore &&
  //         // Score mínimmo:
  //         lastScores[0].positiveAchievement + lastScores[0].negativeAchievement >=
  //           lastScores[0].childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts &&
  //         lastScores[1].positiveAchievement + lastScores[1].negativeAchievement >=
  //           lastScores[1].childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts &&
  //         lastScores[2].positiveAchievement + lastScores[2].negativeAchievement >=
  //           lastScores[2].childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts
  //       ) {
  //         if (lastScores[2].childIndividualizedPlanSkillStep.achievementDate === null) {
  //           updateChildIndividualizedPlanSkillStep(interventionSession.checkinAt, lastScores[2].childIndividualizedPlanSkillStep.id);
  //           // Comentado para permitir acerto caso terapeuta marque positivo errado pq se atualizar a tela, some o item...
  //           // window.location.reload();
  //         }
  //       } else if (lastScores[2].childIndividualizedPlanSkillStep.achievementDate !== null) {
  //         updateChildIndividualizedPlanSkillStep(null, lastScores[2].childIndividualizedPlanSkillStep.id);
  //       }
  //     }
  //   }
  // );
  // };

  return {
    positiveAchievement,
    negativeAchievement,
    updatePositiveResult,
    updateNegativeResult,
    loadingPositiveButton,
    loadingNegativeButton,
  };
};
