import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSubmissionMessage, Loading } from '../../components';
import Navbar from '../../partials/Navbar';
import StaffMemberRegisterForm from '../staff/StaffMemberRegisterForm';
import useProfileModel from './useProfileModel';

function Profile() {
  const { t } = useTranslation();
  const {
    loggedUser,
    password,
    setPassword,
    phone,
    setPhone,
    name,
    setName,
    update,

    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    loadingErrorMessage,
    busy,
  } = useProfileModel();

  // component did mount
  useEffect(() => {
    window.jQuery(':input').inputmask();
  });

  return (
    <>
      <Navbar />
      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('My Profile')}</li>
          </ol>
        </nav>

        <div className="row">
          {loggedUser.role === 'staffMember' && (
            <div className="col-md-12 grid-margin stretch-card">
              <StaffMemberRegisterForm id={loggedUser.staffMember} />
            </div>
          )}

          {(loggedUser.role === 'admin' || loggedUser.role === 'relatives') && (
            <div className="card col-12">
              <div className="card-body">
                {loadingErrorMessage && (
                  <div className="alert alert-primary" role="alert">
                    {loadingErrorMessage}
                  </div>
                )}
                {!loadingErrorMessage && busy && <Loading loadingText={t('Fetching admin details')} />}
                {!busy && !loadingErrorMessage && (
                  <>
                    {loggedUser.role === 'admin' && <h6 className="card-title">{t('Admin')}</h6>}
                    <form
                      id="adminForm"
                      className="forms-admin-info"
                      onSubmit={(e) => {
                        e.preventDefault();
                        update();
                      }}
                    >
                      <div className="form-group">
                        <label htmlFor="emailRelative">E-mail</label>
                        <input
                          id="emailRelative"
                          name="emailRelative"
                          type="text"
                          className="form-control"
                          disabled
                          value={loggedUser.email}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="fullNameRelative">{t('Full name')}</label>
                        <input
                          id="fullNameRelative"
                          name="fullNameRelative"
                          defaultValue={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder={t('placeholder.Barbara Russel')}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="phoneRelative">{t('Phone number')}</label>
                        {!phone && (
                          <input
                            id="phoneRelative"
                            name="phoneRelative"
                            defaultValue={phone}
                            onBlur={(e) => setPhone(e.target.value)}
                            className="form-control mb-4 mb-md-0"
                            data-inputmask-alias={t('(999) 9999-9999')}
                            im-insert="true"
                          />
                        )}
                        {phone && (
                          <input
                            id="phoneRelative"
                            name="phoneRelative"
                            defaultValue={phone}
                            onBlur={(e) => setPhone(e.target.value)}
                            className="form-control mb-4 mb-md-0"
                            data-inputmask-alias={t('(999) 9999-9999')}
                            im-insert="false"
                          />
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="passwordRelative">{t('New Password')}</label>
                        <input
                          id="passwordRelative"
                          name="passwordRelative"
                          defaultValue={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="form-control"
                          autoComplete="off"
                          placeholder={t('Password')}
                        />
                      </div>

                      <FormSubmissionMessage message={formSubmissionMessage} type={formSubmissionMessageType} />

                      {!submitting && (
                        <>
                          <button type="submit" className="btn btn-primary mr-2">
                            {t('Save')}
                          </button>
                        </>
                      )}
                      {submitting && (
                        <button type="submit" disabled className="btn btn-primary mr-2">
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {t('Submitting') + '...'}
                        </button>
                      )}
                    </form>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Profile;
