import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getStaffChildrenRelated } from './getStaffChildrenRelated';

export function StaffChildrenRelated({ id, variant, showAmmount = 4 }) {
  const { t } = useTranslation();

  const fullVariant = variant === 'full';

  const [children, setChildren] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showMore, setShowMore] = useState([]);

  const fetchChildren = useCallback(async () => {
    setIsLoading(true);
    if (id === 'new') {
      setChildren([]);
      setIsLoading(false);
      return;
    }
    await getStaffChildrenRelated(id)
      .then((res) => res.data.staffMember.assignments)
      .then((data) => {
        if (!fullVariant) {
          const cardList = data.splice(showAmmount);
          setChildren(data);
          if (cardList.length) setShowMore(cardList);
          return [...cardList];
        } else {
          setChildren(data);
        }
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    fetchChildren();
  }, [fetchChildren]);

  const cardStyle = fullVariant
    ? {
        maxHeight: '42rem',
        overflowY: 'auto',
      }
    : {
        maxHeight: '7rem',
        overflowY: 'auto', //todo: change to hidden before go to production
      };

  return (
    <div className="card-internal-group">
      {!fullVariant && <h6 className="card-internal-group-title d-block">{t('Children')}</h6>}
      <div style={cardStyle}>
        {isLoading ? (
          <p>
            <em>{t('Loading')}</em>
          </p>
        ) : children.length ? (
          children.map(({ child }, index) => {
            return (
              <Link key={child.id + index} to={`/children/${child.id}`}>
                <p className="card-text">
                  <strong>{child.person.name}</strong>
                </p>
              </Link>
            );
          })
        ) : (
          <p className="card-text">
            <em>{t('No children')}</em>
          </p>
        )}
      </div>
      {!!showMore.length && (
        <div
          className="btn btn-secondary mt-2"
          onClick={() => {
            setChildren([...children, ...showMore]);
            setShowMore([]);
          }}
        >
          {t('Show more children')}
        </div>
      )}
    </div>
  );
}
