import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import Fuse from 'fuse.js';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

import AccessControl from '../../AccessControl';
import { Loading, NewSessionButton } from '../../components';
import { updateChildrenList } from '../../services/staff';
import { getIntervalInMonths, isBirthdayWeek } from '../../utils/time';
import useChildrenModel from './useChildrenModel';

export function Children() {
  const { t } = useTranslation();

  const {
    busy,
    loadingErrorMessage,
    childrenListFiltered,
    setShowOnlyActives,
    delet,
    isBirthdayDay,
    toastBirthday,
    isInterventionSessionLate,
    isChecklistLate,
  } = useChildrenModel();

  // Search
  const [query, setQuery] = useState('');
  const [normalizedQuery, setNormalizedQuery] = useState('');
  // const fuseIndex = Fuse.createIndex(['person.name'], childrenListFiltered);
  const fuse = new Fuse(childrenListFiltered, {
    // minMatchCharLength: 3,
    keys: ['person.normName'],
    threshold: 0.3,
  });

  function onSearch({ currentTarget }) {
    setQuery(currentTarget.value);
    // this strange .normalize basically get all letters with accents and remove the accents
    setNormalizedQuery(currentTarget.value.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z0-9\s])/g, ''));
  }

  const results = fuse.search(normalizedQuery);

  const filterFuse = (el) => {
    if (results.length > 0) {
      for (let rs of results) {
        if (rs.item.id === el.id) return true;
      }
    } else {
      return true;
    }
  };

  function handleUpdateChildren() {
    // TODO: Refresh page without full reloading
    updateChildrenList();
  }

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                id="search"
                name="search"
                value={query}
                onChange={onSearch}
                required
                type="text"
                className="form-control form-control-sm"
                autoComplete="off"
                placeholder={t('Search here')}
              />
            </div>
          </form>
          <div className="d-flex" style={{ gap: '.25rem' }}>
            <div className="flex-ccc">
              <button onClick={handleUpdateChildren} className="btn btn-outline-primary">
                {t('Update children')}
              </button>
            </div>
            <AccessControl requiredPermissions={['createChild']}>
              <div className="flex-ccc">
                <a href="/children/new">
                  <button type="button" className="btn btn-outline-primary">
                    {t('Register new Child')}
                  </button>
                </a>
              </div>
            </AccessControl>
          </div>
        </div>
      </nav>

      <div className="page-content">
        <nav className="page-breadcrumb">
          <div className="d-flex justify-content-between">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href="/children">
                  {t('Sidebar.Children')} ({childrenListFiltered.length})
                </a>
              </li>
            </ol>
            <div>
              <select
                onChange={(e) => setShowOnlyActives(e.target.value !== 'all')}
                className="mr-1 border-light text-secondary"
              >
                <option value="only-actives">{t('only actives')}</option>
                <option value="all">{t('show all')}</option>
              </select>
            </div>
          </div>
        </nav>

        <div className="row">
          {loadingErrorMessage && (
            <div className="col-md-12 grid-margin">
              <div className="alert alert-primary" role="alert">
                {loadingErrorMessage}
              </div>
            </div>
          )}
          {!loadingErrorMessage && busy && (
            <div className="col-md-12 grid-margin">
              <Loading loadingText={t('Fetching children records')} />
            </div>
          )}
          {!loadingErrorMessage &&
            !busy &&
            childrenListFiltered.filter(filterFuse).map((child) => (
              <div className="col-12 col-md-6 col-lg-4 grid-margin" key={child.id}>
                <div className="card child-card h-100">
                  <div className="card-body">
                    <div className="card-title-group">
                      <h4 className="card-title actionable">
                        <a href={`/children/${child.id}`} style={{ color: child.active ? 'black' : 'red' }}>
                          {child.person.name}
                        </a>
                        <i
                          className={
                            'mdi mdi-human-' + (child.person.gender === 'FEMALE' ? 'female' : 'male') + ' side-icon'
                          }
                        ></i>
                      </h4>
                      <div className="text-small">
                        {getIntervalInMonths(child.person.dateOfBirth, new Date())} {t('months')}
                      </div>
                    </div>
                    <div className="card-internal-group">
                      <h6 className="card-internal-group-title">{t('Therapists and Assistants')}</h6>
                      {child.staffAssignments.map((csa, idx) => (
                        <p key={csa.staffMember + '-' + idx} className="card-text">
                          {csa.staffMember.person.name}: {csa.staffMember.person.phone}
                          {csa.staffAttribution.title === 'Main Supervisor' && <i className="mdi mdi-crown"></i>}
                        </p>
                      ))}
                    </div>
                    <div className="card-internal-group">
                      <h6 className="card-internal-group-title">{t('Family')}</h6>
                      {child.relatives
                        .filter((c) => c.person)
                        .map((r, idx) => (
                          <p key={r.id + '-' + idx} className="card-text">
                            {r.person.name.split(/\s+/)[0]} - {r.person.phone}
                          </p>
                        ))}
                    </div>
                    <div className="card-internal-group mt-4">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {t('Go to')}
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href={`/children/${child.id}`}>
                          {t('Child Profile')}
                        </a>
                        <AccessControl
                          requiredPermissions={[
                            'child:*:createChildChecklistAssessment',
                            'child:' + child.id + ':createChildChecklistAssessment',
                          ]}
                        >
                          <a className="dropdown-item" href={`/children/${child.id}/checklist/new`}>
                            {t('New Checklist')}
                          </a>
                        </AccessControl>
                        <AccessControl
                          requiredPermissions={[
                            'child:*:createChildIndividualizedPlan',
                            'child:' + child.id + ':createChildIndividualizedPlan',
                          ]}
                        >
                          <a className="dropdown-item" href={`/children/${child.id}/plan/new`}>
                            {t('New Intervention Plan')}
                          </a>
                        </AccessControl>
                      </div>
                    </div>
                    <AccessControl
                      requiredPermissions={[
                        'child:*:createChildIndividualizedPlanSession',
                        'child:' + child.id + ':createChildIndividualizedPlanSession',
                      ]}
                    >
                      <div className="card-internal-group">
                        <NewSessionButton calendarHover={false} childId={child.id} />
                      </div>
                    </AccessControl>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          {isInterventionSessionLate(child.lis) === false && (
                            <div>
                              <small className="text-muted">
                                {t('Last intervention session in')} {moment(child.lis).format(t('MM/DD'))}
                              </small>
                            </div>
                          )}
                          {isInterventionSessionLate(child.lis) === true && (
                            <div>
                              <small className="text-danger d-flex flex-row">
                                <i className="mdi mdi-alert mr-1"></i>
                                {t('Last intervention session in')}{' '}
                                <strong className="ml-1">{moment(child.lis).format(t('MM/DD'))}</strong>
                              </small>
                            </div>
                          )}

                          {isChecklistLate(child.lc) === false && (
                            <div>
                              <small className="text-muted">
                                {t('Last checklist in')} {moment(child.lc).format(t('MM/DD'))}
                              </small>
                            </div>
                          )}
                          {isChecklistLate(child.lc) === true && (
                            <div>
                              <small className="text-danger d-flex flex-row">
                                <i className="mdi mdi-alert mr-1"></i>
                                {t('Last checklist in')}{' '}
                                <strong className="ml-1">{moment(child.lc).format(t('MM/DD'))}</strong>
                              </small>
                            </div>
                          )}
                        </div>
                        {isBirthdayWeek(child.person.dateOfBirth) === true && (
                          <div>
                            <small className="text-primary">
                              {' '}
                              <span className="mr-1" role="img" aria-labelledby="birthday party emoji">
                                🥳
                              </span>
                              {t('birthday on')} {moment(child.person.dateOfBirth).format(t('MM/DD'))}
                              <span className="ml-1" role="img" aria-labelledby="party popper emoji">
                                🎉
                              </span>
                            </small>
                          </div>
                        )}
                        {isBirthdayDay(child.person.dateOfBirth) === true && toastBirthday(child)}
                      </div>

                      <AccessControl requiredPermissions={['deleteChild:*', 'deleteChild:' + child.id]}>
                        <div>
                          <i
                            title={t('Delete')}
                            onClick={delet}
                            data-id={child.id}
                            data-name={child.person.name}
                            className={'mdi mdi-delete side-icon text-danger actionable'}
                          ></i>
                        </div>
                      </AccessControl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
