import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Navbar from '../../partials/Navbar';
import { get } from '../../services/children';
import { getAllChecklists } from '../../services/childStats';
import { withChildrenPermission } from '../../withChildrenPermission';
import AssessmentsOverview from './assessmentsOverview/AssessmentsOverview';
import ChecklistPerformanceDetails from './checklistPerformanceDetails/ChecklistPerformanceDetails';

function ChildStats() {
  const { t } = useTranslation();
  const { id } = useParams();

  const [child, setChild] = useState();

  const [allChecklists, setAllChecklists] = useState();
  const [maxEvaluatedLevel, setMaxEvaluatedLevel] = useState();
  const [tab, setTab] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      // Get child data
      const ch = await get(id);
      setChild(ch);

      // Get all checklist ids so we can provide them to the respective getters
      const checklists = await getAllChecklists(ch.id).then((res) => res?.data?.childChecklistAssessments);
      const maxLevel = checklists.reduce((max, checklist) => {
        const maxLevel = checklist.childChecklistAssessmentSkills.reduce(
          (max, skill) => Math.max(max, skill.skill.level),
          0,
        );
        return Math.max(max, maxLevel);
      }, 0);
      setAllChecklists(checklists);
      setMaxEvaluatedLevel(maxLevel);
    };

    fetchData();
  }, [id]);

  if (!child || !allChecklists || !maxEvaluatedLevel) {
    return (
      <>
        <Navbar />
      </>
    );
  }

  return (
    <>
      <Navbar>
        <div>
          <a className="btn btn-outline-primary" href={`/children/${child.id}`}>
            {t('View child data')}
          </a>
        </div>
      </Navbar>

      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
            <li className="breadcrumb-item">
              <a href="/stats">{t('Sidebar.Overview')}</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href={`/children/${child.id}`}>{child.person.name}</a>
            </li>
          </ol>
        </nav>

        <>
          <ul className="nav nav-tabs mb-3" role="tablist">
            <li className="nav-item">
              <button
                className={tab === 1 ? 'nav-link active' : 'nav-link'}
                data-toggle="tab"
                role="tab"
                aria-selected={tab === 1 ? 'true' : 'false'}
                onClick={() => setTab(1)}
              >
                <div className="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                  <span className="d-none d-sm-block">{t('Progress Overview')}</span>
                </div>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={tab === 2 ? 'nav-link active' : 'nav-link'}
                data-toggle="tab"
                role="tab"
                aria-selected={tab === 2 ? 'true' : 'false'}
                onClick={() => setTab(2)}
              >
                <div className="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                  <span className="d-none d-sm-block">{t('Checklist Assessment Details')}</span>
                </div>
              </button>
            </li>
          </ul>

          {tab === 1 && (
            <AssessmentsOverview child={child} allChecklists={allChecklists} maxEvaluatedLevel={maxEvaluatedLevel} />
          )}

          {tab === 2 && (
            <ChecklistPerformanceDetails
              allChecklists={allChecklists}
              childId={child.id}
              childDateOfBirth={child.person.dateOfBirth}
            />
          )}
        </>
      </div>
    </>
  );
}

export default withChildrenPermission(ChildStats);
