import React from 'react';

export const ButtonPlusMinus = ({ className = '', disabled = false, size = 'small', onClick }) => {
  return (
    <button onClick={onClick} disabled={disabled} className={'btn ' + className + ' hover-ghost'}>
      <svg
        style={{ height: size === 'small' ? '12px' : '18px' }}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-plus"
      >
        <line x1="12" y1="5" x2="12" y2="19"></line>
        <line x1="5" y1="12" x2="19" y2="12"></line>
      </svg>
      <svg
        style={{ height: size === 'small' ? '12px' : '18px' }}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-minus"
      >
        <line x1="5" y1="12" x2="19" y2="12"></line>
      </svg>
    </button>
  );
};
