import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import logo from './logo.png';
import { changePassword } from './services/auth';

function ResetPassword() {
  const { t } = useTranslation();

  const [password, setPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const doChangePassword = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    const tokenResetPassword = urlParams.get('ResetPassword');
    if (!password) {
      setErrorMessage(t('Please enter an password'));
    } else {
      changePassword(tokenResetPassword, password, (response) => {
        if (response.status !== 200) {
          return window.Swal.fire({
            type: 'error',
            title: t('Error'),
            text: t('Invalid password reset token'),
          }).then((_) => {
            window.location.replace('./');
          });
        } else {
          return window.Swal.fire({
            type: 'success',
            title: t('Changed'),
            text: t('Password changed') + '!',
          }).then((_) => {
            window.location.replace('./');
          });
        }
      });
    }
  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-6 mx-auto">
              <div className="card">
                <div className="auth-form-wrapper px-4 py-5">
                  <div className="noble-ui-logo d-block mb-2">
                    <img src={logo} width="50px" className="mr-2" alt="logo-denver" />
                    Early<span>Denver</span>
                  </div>
                  {errorMessage && (
                    <div className="alert alert-fill-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <form className="forms-sample" onSubmit={(e) => doChangePassword(e)}>
                    <div className="form-group">
                      <input
                        type="password"
                        onChange={(ev) => setPassword(ev.target.value)}
                        className="form-control"
                        id="password"
                        autoComplete="current-password"
                        placeholder={t('New Password')}
                      />
                    </div>
                    <div className="mt-3">
                      <button type="submit" className="btn btn-primary mr-2 mb-2 mb-md-0 text-white">
                        {t('Password Reset')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
