import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Fuse from 'fuse.js';
import Inputmask from 'inputmask';
import moment from 'moment';

import AccessControl from '../../../AccessControl';
import { Loading, NewSessionButton } from '../../../components';
import { getNumberFromStepCode, getNumberFromSkillCode } from '../../../utils/legacy';
import { getSaoPauloTime } from '../../../utils/time';
import { withChildrenPermission } from '../../../withChildrenPermission';
import { ChildInterventionSessionsList } from '../components/ChildInterventionSessionsList';
import { ChildInterventionSessionAssessmentButton } from './components/childInterventionSessionAssessmentButton';
import { ExportSessionPdf } from './components/exportSessionPdf/ExportSessionPdf';
import useChildInterventionSessionModel from './useChildInterventionSessionModel';

function getCurrentStep(skillSteps) {
  let currentStep = skillSteps.stepsResult
    .sort((a, b) => {
      const aOrder = a.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep
        ? a.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.order
        : a.childIndividualizedPlanSkillStep.order;
      const bOrder = b.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep
        ? b.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.order
        : b.childIndividualizedPlanSkillStep.order;
      return aOrder - bOrder;
    })
    .find(
      (s) =>
        !s.childIndividualizedPlanSkillStep.achievementDate ||
        moment(s.childIndividualizedPlanSkillStep.achievementDate) > moment(skillSteps.sessionDate),
    );

  return currentStep;
}

function ChildInterventionSession() {
  const $ = window.$;
  $(document).ready(function () {
    Inputmask().mask(document.querySelectorAll('input'));
  });

  const { t } = useTranslation();

  const {
    editMode,
    interventionSession,
    interventionSessions,
    deleteCurrentInterventionSession,
    busy,
    loadingErrorMessage,
    setInterventionSessionDate,
  } = useChildInterventionSessionModel();

  const [tab, setTab] = useState(0);

  const removeThisSession = () => {
    window.Swal.fire({
      title: t('Confirm') + '?',
      icon: 'warning',
      text: t('Are you sure you want to remove this session') + '?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then((result) => {
      if (result.value) {
        deleteCurrentInterventionSession();
      }
    });
  };

  const changeDate = () => {
    window.Swal.fire({
      title: t('Change date for this session to') + ':',
      html: `<input type="date" id="date" max="${new Date().toISOString().split('T')[0]}" class="swal2-input">
      <input type="time" id="time" value="12:00" class="swal2-input">
      `,
      showCloseButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const date = window.Swal.getPopup().querySelector('#date').value;
        const time = window.Swal.getPopup().querySelector('#time').value;
        return { date, time };
      },
    }).then(async (result) => {
      if (result.dismiss) return;
      const { date, time } = result.value;
      const err = date.length === 0 || time.length === 0;
      if (err) {
        return window.Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: t('Invalid date'),
        });
      }
      const dateString = date + 'T' + time;
      const newSessionDate = new Date(dateString);

      // Calculate the offset between server time and client time
      const saoPauloTime = await getSaoPauloTime();
      // if we cant use the server to calculate the difference we will trust in the client
      if (saoPauloTime.error) {
        console.error(saoPauloTime.error);
        return setInterventionSessionDate(interventionSession.id, newSessionDate);
      }

      const serverTime = new Date(saoPauloTime.data);
      const clientTime = new Date();
      const timeOffset = serverTime - clientTime;

      // Add the offset to the selected date from the input field
      const selectedDate = new Date(date);
      selectedDate.setTime(selectedDate.getTime() + timeOffset);
      selectedDate.setHours(time.split(':')[0], time.split(':')[1]);

      setInterventionSessionDate(interventionSession.id, newSessionDate);
    });
  };

  const resolveSkillTitle = (skill) => {
    return skill.developmentalDomain.title;
  };

  const isSkillAchieved = (skillStep) => {
    return (
      skillStep.stepsResult &&
      skillStep.stepsResult.length > 0 &&
      skillStep.stepsResult.filter((st) =>
        moment(st.childIndividualizedPlanSkillStep.achievementDate).isBefore(moment(skillStep.sessionDate)),
      ).length === skillStep.stepsResult.length
    );
  };

  // Search
  const [query, updateQuery] = useState('');
  const options = {
    // minMatchCharLength: 3,
    keys: ['skill.title', 'skill.description'],
    distance: 100,
    threshold: 0.3,
  };
  var fs = interventionSession ? interventionSession.skillSteps : '';
  const fuse = new Fuse(fs, options);
  function onSearch({ currentTarget }) {
    updateQuery(currentTarget.value);
  }
  const results = fuse.search(query);

  const filterSkillType = (el) => {
    var existeAchievement = false;
    var retorno = false;
    if (editMode) {
      if (tab === 0) {
        retorno = el.plan && !isSkillAchieved(el);
      }
      if (tab === 1) {
        retorno = el.plan && isSkillAchieved(el);
      }
      if (tab === 2) {
        retorno = el.plan;
      }
    } else {
      for (let res of el.stepsResult) {
        if (res.negativeAchievement > 0 || res.positiveAchievement > 0) existeAchievement = true;
      }
      if (tab === 0) {
        retorno = el.plan && existeAchievement && !isSkillAchieved(el);
      }
      if (tab === 1) {
        retorno = el.plan && !existeAchievement && !isSkillAchieved(el);
      }
      if (tab === 2 && el.plan) {
        retorno = true;
      }
    }

    if (results.length > 0 && retorno) {
      for (let rs of results) {
        if (rs.item.skill.id === el.skill.id) return true;
      }
      return false;
    }

    return retorno;
  };

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                id="search"
                name="search"
                value={query}
                onChange={onSearch}
                required
                type="text"
                className="form-control form-control-sm"
                autoComplete="off"
                placeholder={t('Search here')}
              />
            </div>
          </form>
          {interventionSession && (
            <div className="d-flex align-items-center position-relative">
              {t('Go to session')}:
              <button
                className="btn btn-outline-primary d-flex align-items-center mx-2 md:font-small"
                type="button"
                data-toggle="collapse"
                data-target="#interventionSessionCalendar"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                {moment(interventionSession.checkinAt).format(t('MM/DD/YYYY'))}
                <i className="mdi mdi mdi-calendar ml-2" />
              </button>
              <AccessControl
                requiredPermissions={[
                  'child:*:createChildIndividualizedPlanSession',
                  'child:' +
                    interventionSession.childIndividualizedPlan.child.id +
                    ':createChildIndividualizedPlanSession',
                ]}
              >
                <div className="">
                  <NewSessionButton
                    onNavbar
                    leftAlign
                    btnStyleSecondary
                    childId={interventionSession.childIndividualizedPlan.child.id}
                  />
                </div>
                <div
                  id="interventionSessionCalendar"
                  style={{ top: '55px' }}
                  className="col-12 mb-2 collapse md:font-small position-absolute"
                >
                  <ChildInterventionSessionsList
                    childId={interventionSession.childIndividualizedPlan.child.id}
                    interventionSessions={interventionSessions}
                  />
                </div>
              </AccessControl>
            </div>
          )}
        </div>
      </nav>
      <div className="page-content">
        {loadingErrorMessage && !busy && (
          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body main-content">
                  {loadingErrorMessage && (
                    <div className="alert alert-primary" role="alert">
                      {loadingErrorMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!loadingErrorMessage && busy && <Loading loadingText={t('Fetching Child Intervention Session')} />}
        {!loadingErrorMessage && !busy && interventionSession.childIndividualizedPlan && (
          <>
            <nav className="page-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="/children">{t('Sidebar.Children')}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href={`/children/${interventionSession.childIndividualizedPlan.child.id}`}>
                    {interventionSession.childIndividualizedPlan.child.person.name}
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a
                    href={`/children/${interventionSession.childIndividualizedPlan.child.id}/session/${interventionSession.id}`}
                  >
                    {t('Intervention Session')} ({moment(interventionSession.checkinAt).format(t('MM/DD/YYYY'))})
                  </a>
                </li>
              </ol>
            </nav>

            <div className="row">
              <h4 className="page-title col-lg-5 mb-2">
                {interventionSession.childIndividualizedPlan.child.person.name}
              </h4>
              {editMode ? (
                <div className="col-lg-7 row mb-2 d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-outline-info dropdown-toggle ml-2 md:font-small"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t('Options')}
                  </button>
                  <div className="dropdown-menu shadow" aria-labelledby="dropdownMenuButton">
                    <div className="d-flex flex-column align-items-center" style={{ gap: '0.5rem' }}>
                      <AccessControl
                        requiredPermissions={[
                          'child:*:updateChildIndividualizedPlanSession:*',
                          'child:' +
                            interventionSession.childIndividualizedPlan.child.id +
                            ':updateChildIndividualizedPlanSession:*',
                          'child:*:updateChildIndividualizedPlanSession:' + interventionSession.id,
                          'child:' +
                            interventionSession.childIndividualizedPlan.child.id +
                            ':updateChildIndividualizedPlanSession:' +
                            interventionSession.id,
                        ]}
                      >
                        <button onClick={() => changeDate()} className="btn btn-outline-info ml-2 md:font-small">
                          {t('Change session date')}
                        </button>
                        <a
                          className="md:font-small"
                          href={`/children/${interventionSession.childIndividualizedPlan.child.id}/session/${interventionSession.id}`}
                        >
                          <button className="btn btn-outline-info ml-2 md:font-small">{t('View this Session')}</button>
                        </a>
                      </AccessControl>
                      <AccessControl
                        requiredPermissions={[
                          'child:*:deleteChildIndividualizedPlanSession:*',
                          'child:' +
                            interventionSession.childIndividualizedPlan.child.id +
                            ':deleteChildIndividualizedPlanSession:*',
                          'child:*:deleteChildIndividualizedPlanSession:' + interventionSession.id,
                          'child:' +
                            interventionSession.childIndividualizedPlan.child.id +
                            ':deleteChildIndividualizedPlanSession:' +
                            interventionSession.id,
                        ]}
                      >
                        <button
                          onClick={removeThisSession}
                          className="btn btn-outline-info ml-2 mobile-hidden md:font-small"
                        >
                          {t('Delete this Session')}
                        </button>
                      </AccessControl>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="d-flex flex-column">
                  <div className="row">
                    <div className="col-12 grid-margin">
                      <div className="card">
                        <div className="card-body main-content">
                          {loadingErrorMessage && (
                            <div className="alert alert-primary" role="alert">
                              {loadingErrorMessage}
                            </div>
                          )}

                          <AccessControl
                            requiredPermissions={[
                              'child:*:childChildIndividualizedPlan',
                              'child:*:childChildIndividualizedPlan:' + interventionSession.childIndividualizedPlan.id,
                              'child:' +
                                interventionSession.childIndividualizedPlan.child.id +
                                ':childChildIndividualizedPlan',
                              'child:' +
                                interventionSession.childIndividualizedPlan.child.id +
                                ':childChildIndividualizedPlan:' +
                                interventionSession.childIndividualizedPlan.id,
                            ]}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              {editMode && (
                                <div className="row">
                                  <div className="col-12 grid-margin d-flex md:flex-column align-items-center justify-content-start">
                                    <AccessControl
                                      requiredPermissions={[
                                        'child:*:updateChildIndividualizedPlanSession:*',
                                        'child:' +
                                          interventionSession.childIndividualizedPlan.child.id +
                                          ':updateChildIndividualizedPlanSession:*',
                                        'child:*:updateChildIndividualizedPlanSession:' + interventionSession.id,
                                        'child:' +
                                          interventionSession.childIndividualizedPlan.child.id +
                                          ':updateChildIndividualizedPlanSession:' +
                                          interventionSession.id,
                                      ]}
                                    >
                                      <div className="d-flex flex-column align-items-center">
                                        {t('Session took place on')}
                                        <h5>{moment(interventionSession.checkinAt).format(t('MM/DD/YYYY'))}</h5>
                                        <h6>{moment(interventionSession.checkinAt).format('HH:mm')}h</h6>
                                      </div>
                                    </AccessControl>
                                  </div>
                                </div>
                              )}
                              <a
                                className="btn btn-outline-info md:font-small"
                                href={`/children/${interventionSession.childIndividualizedPlan.child.id}/plan/${interventionSession.childIndividualizedPlan.id}`}
                              >
                                {t('View Individualized Plan')} {t('of')}
                                &nbsp;
                                {moment(interventionSession.childIndividualizedPlan.date).format(t('MM/DD/YYYY'))}
                              </a>
                              {!editMode ? (
                                <div>
                                  <a
                                    className="md:font-small"
                                    href={`/children/${interventionSession.childIndividualizedPlan.child.id}/session/${interventionSession.id}?mode=edit`}
                                  >
                                    <button className="btn btn-outline-info ml-2 md:font-small">
                                      {t('Edit this Session')}
                                    </button>
                                  </a>

                                  <ExportSessionPdf sessionId={interventionSession.id}>
                                    <button
                                      title={t('Export this Session')}
                                      className="btn btn-outline-info ml-2 md:font-small p-1"
                                    >
                                      <i className="mdi mdi-file-pdf "></i>
                                    </button>
                                  </ExportSessionPdf>
                                </div>
                              ) : null}
                            </div>
                          </AccessControl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2 mobile-hidden">
                    <div className="col-12">
                      <div className="btn-group btn-group-sm">
                        <button
                          className={'btn ' + (tab === 0 ? 'btn-primary' : 'btn-outline-primary')}
                          onClick={(_) => setTab(0)}
                        >
                          {editMode && t('Ongoing skills')}
                          {!editMode && <div>{t('Observed skills')}</div>}
                        </button>
                        <button
                          className={'btn ' + (tab === 1 ? 'btn-primary' : 'btn-outline-primary')}
                          onClick={(_) => setTab(1)}
                        >
                          {editMode && <div>{t('Achieved Skills')}</div>}
                          {!editMode && <div>{t('Not observed skills')}</div>}
                        </button>
                        <button
                          className={'btn ' + (tab === 2 ? 'btn-primary' : 'btn-outline-primary')}
                          onClick={(_) => setTab(2)}
                        >
                          <div>{t('All')}</div>
                        </button>
                      </div>
                    </div>
                  </div>

                  {interventionSession.skillSteps.filter(filterSkillType).map((skillStep, idx) => (
                    <div key={skillStep.skill.id + '-' + idx} className="row">
                      <div className="col-12 grid-margin">
                        <div className="card">
                          <div className="card-body">
                            <div className="mb-2">
                              <span className="badge badge-light mr-2">
                                {skillStep.skill.developmentalDomain
                                  ? resolveSkillTitle(skillStep.skill)
                                  : t('Custom objective')}
                              </span>
                            </div>
                            <h5
                              className="mb-1"
                              data-toggle="collapse"
                              data-target={'#skillDetails-' + skillStep.skill.id}
                              aria-expanded="false"
                              aria-controls={'skillDetails-' + skillStep.skill.id}
                            >
                              {t('LEVEL_SINGLE_LETTER')}
                              {skillStep.skill.level}.{getNumberFromSkillCode(skillStep.skill.code)}
                              &nbsp;—&nbsp;
                              {skillStep.skill.title}
                            </h5>

                            {isSkillAchieved(skillStep) && (
                              <div className="pt-2 pb-3 d-flex">
                                <div className="bg-success btn-icon-text d-flex mr-2 text-white pl-3 pr-3 pt-2 pb-2 justify-content-center align-items-center">
                                  <span role="img" aria-labelledby="party popper emoji">
                                    🎉
                                  </span>
                                  {t('All steps achieved')}
                                </div>
                              </div>
                            )}

                            {!isSkillAchieved(skillStep) &&
                              getCurrentStep(skillStep) &&
                              [getCurrentStep(skillStep)].map((stepResult, idx2) => {
                                return (
                                  <div className="pt-2 pb-3" key={stepResult.id + '--' + idx2}>
                                    <div className="card-title">
                                      {getNumberFromStepCode(
                                        stepResult.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep
                                          ? stepResult.childIndividualizedPlanSkillStep
                                              .denverChecklistSkillAssessmentStep.code
                                          : stepResult.childIndividualizedPlanSkillStep.code,
                                      )}
                                      .&nbsp;
                                      {!stepResult.childIndividualizedPlanSkillStep.description
                                        ? stepResult.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep
                                            .description
                                        : stepResult.childIndividualizedPlanSkillStep.description}
                                    </div>
                                    <div className="d-flex flex-row flex-flow-wrap">
                                      {t('minimum of')}{' '}
                                      <strong className="mr-1 ml-1">
                                        {!stepResult.childIndividualizedPlanSkillStep.achievementMininumAttempts
                                          ? stepResult.childIndividualizedPlanSkillStep
                                              .denverChecklistSkillAssessmentStep?.achievementMininumAttempts ?? 1
                                          : stepResult.childIndividualizedPlanSkillStep?.achievementMininumAttempts ??
                                            1}
                                      </strong>
                                      {t('in')}{' '}
                                      <strong className="ml-1 mr-1">
                                        {!stepResult.childIndividualizedPlanSkillStep.achievementMininumAttempts
                                          ? Math.floor(
                                              (stepResult.childIndividualizedPlanSkillStep
                                                .denverChecklistSkillAssessmentStep?.achievementMininumAttempts ?? 1) /
                                                (stepResult.childIndividualizedPlanSkillStep
                                                  .denverChecklistSkillAssessmentStep?.achievementMinimumScore ?? 1),
                                            )
                                          : Math.floor(
                                              (stepResult.childIndividualizedPlanSkillStep
                                                ?.achievementMininumAttempts ?? 1) /
                                                (stepResult.childIndividualizedPlanSkillStep?.achievementMinimumScore ??
                                                  1),
                                            )}
                                      </strong>
                                      {t('opportunities')}
                                      {
                                        <span className="ml-2" style={{ color: '#AAA' }}>
                                          (
                                          {!stepResult.childIndividualizedPlanSkillStep.achievementMinimumScore
                                            ? stepResult.childIndividualizedPlanSkillStep
                                                .denverChecklistSkillAssessmentStep?.achievementMinimumScore ?? 1 * 100
                                            : (stepResult.childIndividualizedPlanSkillStep?.achievementMinimumScore ??
                                                1) * 100}
                                          %)
                                        </span>
                                      }
                                    </div>
                                    {!editMode && (
                                      <div className="mt-3 d-flex">
                                        <div className="bg-success btn-icon-text d-flex mr-2 text-white pl-3 pr-3 pt-2 pb-2 justify-content-center align-items-center">
                                          <i className="mdi mdi-thumb-up mr-2"></i>
                                          {stepResult.positiveAchievement}
                                        </div>
                                        <div className="bg-danger btn-icon-text d-flex mr-2 text-white pl-3 pr-3 pt-2 pb-2 justify-content-center align-items-center">
                                          <i className="mdi mdi-thumb-down mr-2"></i>
                                          {stepResult.negativeAchievement}
                                        </div>
                                      </div>
                                    )}

                                    {editMode && (
                                      <AccessControl
                                        requiredPermissions={[
                                          'child:*:updateChildIndividualizedPlanSession:*',
                                          'child:' +
                                            interventionSession.childIndividualizedPlan.child.id +
                                            ':updateChildIndividualizedPlanSession:*',
                                          'child:*:updateChildIndividualizedPlanSession:' + interventionSession.id,
                                          'child:' +
                                            interventionSession.childIndividualizedPlan.child.id +
                                            ':updateChildIndividualizedPlanSession:' +
                                            interventionSession.id,
                                        ]}
                                      >
                                        <div className="mt-3 d-flex control-direction">
                                          <ChildInterventionSessionAssessmentButton
                                            stepResult={stepResult}
                                            interventionSession={interventionSession}
                                            type="positive"
                                          />
                                          <ChildInterventionSessionAssessmentButton
                                            stepResult={stepResult}
                                            interventionSession={interventionSession}
                                            type="negative"
                                          />
                                        </div>
                                      </AccessControl>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default withChildrenPermission(ChildInterventionSession);
