import moment from 'moment';

export const orderUnifyCategories = (arrays) => {
  const orderChecklists = [
    'Comunicação Receptiva',
    'Comunicação Expressiva',
    'Comportamentos de Atenção Conjunta',
    'Competências Sociais',
    'Imitação',
    'Cognição',
    'Jogo',
    'Motricidade Fina',
    'Motricidade Grossa',
    'Comportamento',
    'Independência',
  ];
  if (!arrays) return;
  const result = [];
  arrays.forEach((array) => {
    array.forEach((item) => {
      if (!result.includes(item)) result.push(item);
    });
  });

  const ordered = orderChecklists.filter((item) => result.includes(item));

  return ordered;
};

export const evaluateChecklistForCharts = async (arrayOfChecklists, usePonderedScores) => {
  // Filter to only have checklists LOADED(was causing error because some was empty)
  const filterOnlyLoaded = arrayOfChecklists.filter((cl) => cl.checklistState === 'LOADED');

  const checklistsId = [...arrayOfChecklists.map((cl) => ({ id: cl.id, date: cl.checklistDate }))];
  const categories = orderUnifyCategories(
    filterOnlyLoaded.map((x) => x.unifiedDevelopmentDomainsRatios.map((dd) => dd.developmentalDomain.title)),
  );
  const dates = filterOnlyLoaded.map((checklist) => checklist.checklistDate);

  const series = dates.map((checkDate) => {
    const actualCl = filterOnlyLoaded.find((acl) => acl.checklistDate === checkDate);
    const values = categories.map((category) => {
      const filtered = actualCl.unifiedDevelopmentDomainsRatios.reduce((acc, curr) => {
        if (curr.developmentalDomain.title === category) {
          acc = curr;
        }
        return acc;
      }, null);
      const { developmentFactor, percentualScoreNotPondered } = filtered || {};
      let valor = (Number(usePonderedScores ? developmentFactor : percentualScoreNotPondered) * 100).toFixed(0) || 0;
      if (valor === 'NaN') {
        valor = '0';
      }
      return valor;
    });

    const { id } = checklistsId.filter((cl) => cl.date === checkDate)[0];

    return {
      name: moment(new Date(checkDate)).format('DD/MM/YYYY'),
      data: values,
      id: id,
    };
  });

  return {
    categories,
    series,
  };
};

export const verifyPresentDomain = (activeChecklist, allChecklists) => {
  // Verifica quais os valores zero e adiciona ao array
  const notEvaluated = [];
  activeChecklist.forEach((active) => {
    active.data.forEach((value, index) => {
      if (value === '0' || value === 0) {
        notEvaluated.push({
          date: active.name,
          name: allChecklists?.categories[index],
        });
      }
    });
  });

  // Juntar os valores de notEvaluated baseado na data
  const groupedData = {};
  for (const item of notEvaluated) {
    if (!groupedData[item.date]) {
      groupedData[item.date] = [];
    }
    groupedData[item.date].push(item.name);
  }
  const notEvaluatedJoined = Object.entries(groupedData).map(([key, value]) => ({
    date: key,
    name: value.join(', '),
  }));

  return {
    notEvaluated: notEvaluated || [],
    notEvaluatedJoined: notEvaluatedJoined || [],
  };
};
