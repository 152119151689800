import React from 'react';

export const Loading = ({ loadingText }) => {
  const idRand = 'loader-' + Math.random() + new Date().getTime();
  setTimeout(
    (_) => window.document.getElementById(idRand) && (window.document.getElementById(idRand).style.display = 'block'),
    400,
  );
  return (
    <>
      <div id={idRand} className="progress ht-30" style={{ display: 'none' }}>
        <div
          className="progress-bar progress-bar-striped progress-bar-animated bg-info"
          role="progressbar"
          style={{ width: '100%' }}
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {loadingText}
        </div>
      </div>
    </>
  );
};
