import React from 'react';
import { useTranslation } from 'react-i18next';

import { getNumberFromSkillCode } from '../../../../../utils/legacy';
import useChildIndividualizedPlanItemEditModel from './useChildIndividualizedPlanItemEditModel';

function ChildIndividualizedPlanItemEdit({ individualizedPlan, planItem, onDelete }) {
  const { t } = useTranslation();

  const { savePlanItem, deletePlanItem } = useChildIndividualizedPlanItemEditModel(individualizedPlan, planItem);

  const removeFromPlan = () => {
    const swalWithBootstrapButtons = window.Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t('Are you sure?'),
        text: t('You will lose all the changes made to this skill'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'mr-2',
        confirmButtonText: t('Confirm'),
        cancelButtonText: t('Cancel'),
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          deletePlanItem((result, err) => {
            if (result) {
              onDelete(planItem);
            } else {
              swalWithBootstrapButtons.fire(t('Error'), t('Error removing Skill from this Plan'), 'error');
            }
          });
        }
      });
  };

  return (
    <>
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <div className="mb-3">
              <span className="badge badge-light mr-2">{planItem.skill.developmentalDomain.title}</span>
            </div>
            <h4 className="card-title">
              {t('LEVEL_SINGLE_LETTER')}
              {planItem.skill.level}.{getNumberFromSkillCode(planItem.skill.code)}&nbsp;—&nbsp;
              {planItem.skill.title}
            </h4>
            <div className="mt-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <button className="btn btn-secondary" onClick={savePlanItem}>
                {t('Customize')}
              </button>
              <button className="btn btn-outline-danger" onClick={(_) => removeFromPlan()}>
                {t('Remove from Plan')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChildIndividualizedPlanItemEdit;
