import moment from 'moment';

import { unifyDomainAreas, unifyChecklistsByDomain } from '../utils/chartUtils/';
import { graphQLRequest } from './common';
import { getTypicalLevelByAge, evaluateDevelopmentalMatrixScore, evaluateDevelopmentScoreByLevel } from './denver';

const checklistQuery = `
id
childChecklistAssessment {
  id
  date
}
skill {
  id
  code
  level
  developmentalDomain {
    id
    code
    title
    order
  }
  title
  assessmentSteps {
    id
    code
    achievementMinimumScore
    achievementMininumAttempts
  }
  skillFrom {
    id
  }
}
score
`;

/**
 * It gets all the checklist ids for a given child
 * @param childId - The id of the child you want to get the checklists for.
 */
export const getAllChecklists = async (childId) =>
  new Promise((resolve) => {
    const graphQL = {
      query: `
        {
            childChecklistAssessments(
                where: { child: { id: "${childId}" } }
                orderBy: date_DESC
            ) {
                id
                date
                childChecklistAssessmentSkills {
                  skill {
                    level
                  }
                }
            }
        }`,
      variables: {},
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) return resolve(err);
      return resolve(result);
    });
  });

/**
 * It gets the last two checklists for a child
 * @param childId - The id of the child you want to get the checklists for.
 */
export const getLastTwoChecklists = async (childId) =>
  new Promise((resolve) => {
    const graphQL = {
      query: `
        {
            childChecklistAssessments(
                where: { child: { id: "${childId}" } }
                orderBy: date_DESC
                first: 2
            ) {
                id
                date
            }
        }`,
      variables: {},
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) return resolve(err);
      return resolve(result);
    });
  });

/**
 * It gets the last two checklists for a child
 * @param childId - The id of the child you want to get the last checklist for.
 */
export const getLastChecklist = async (childId) =>
  new Promise((resolve) => {
    const graphQL = {
      query: `
        {
            childChecklistAssessments(
                where: { child: { id: "${childId}" } }
                orderBy: date_DESC
                first: 1
            ) {
                id
                date
            }
        }`,
      variables: {},
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) return resolve(err);
      return resolve(result);
    });
  });

/**
 * It gets the skills of a checklist, filtering by the child's age
 * @param childId - The id of the child you want to get the checklist for
 * @param checklistId - The id of the checklist you want to get the data from
 * @param childDateOfBirth - Child's date of birth so you can weight statistics
 * @param calculateLevelByAge (boolean) - if true, the calculus will ponder the child's age when
 * getting levels
 * @param responseCallback - a callback function that will be called with the
 * result of the query.
 */
export const getChecklistData = async (
  { childId, checklistId, childDateOfBirth, calculateLevelByAge },
  responseCallback,
) => {
  if (!checklistId) return;

  // Calculating level by child's age
  const auxQuery = {
    query: `
        {
            childChecklistAssessment(where: {id: "${checklistId}"})
            {date}
        }`,
    variables: {},
  };

  const auxQueryResult = await graphQLRequest(auxQuery);
  const checklistDate = new Date(auxQueryResult.data.childChecklistAssessment.date);

  const childAgeInMonths = moment(checklistDate).diff(moment(new Date(childDateOfBirth)), 'months');

  const typicalLevelByAge = getTypicalLevelByAge(childAgeInMonths);
  const levelsArray = Array(typicalLevelByAge)
    .fill(1)
    .map((i, j) => i + j);

  const whereLevelFilter = calculateLevelByAge
    ? `
        skill: {
            level_in: [${levelsArray}]
        }`
    : '';

  const graphQL = {
    query: `
        {
            childChecklistAssessmentSkills (
                where:{
                    AND:{
                        childChecklistAssessment: {
                            id:"${checklistId}"
                            child: {
                                id: "${childId}"
                            }
                        }
                        ${whereLevelFilter}    
                    }
                }
            ){
                ${checklistQuery}
            }
        }`,
    variables: {},
  };

  // graphQLRequest(graphQL, (result, err) => {
  //   if (err) {
  //     if (responseCallback) return responseCallback(null, err);
  //     return err;
  //   }

  //   const resultOrdered = result.data.childChecklistAssessmentSkills
  //     .sort((a, b) => a.skill.developmentalDomain.order - b.skill.developmentalDomain.order)
  //     .sort((a, b) => a.skill.code.localeCompare(b.skill.code));

  //   if (responseCallback) return responseCallback(resultOrdered);
  //   return resultOrdered;
  // });

  const result = await graphQLRequest(graphQL)
    .then((res) =>
      res.data.childChecklistAssessmentSkills
        .sort((a, b) => a.skill.developmentalDomain.order - b.skill.developmentalDomain.order)
        .sort((a, b) => a.skill.code.localeCompare(b.skill.code)),
    )
    .catch((error) => ({ data: null, err: error }));

  if (responseCallback) return responseCallback(result);
  return result;
};

/**
 * It takes a childId, a checklistId, and a childAge, and returns an object with
 * the child's development data
 * @param childId - the id of the child
 * @param checklistId - the id of the checklist you want to get the data from
 * @param childDateOfBirth - the child's date of birth, in string format
 * @param calculateLevelByAge (boolean) - if true, the calculus will ponder the child's age when getting levels
 * @param denverChecklistDomains - the domains of the Denver Checklist - used to calculate scores without pondering
 */
export const generateStatsData = async ({
  childId,
  checklistId,
  childDateOfBirth,
  calculateLevelByAge,
  denverChecklistDomains,
}) =>
  new Promise((resolve) => {
    getChecklistData({ childId, checklistId, childDateOfBirth, calculateLevelByAge }, async (result, err) => {
      if (result && result.length > 0) {
        const res = await evaluateDevelopmentalMatrixScore(result, childDateOfBirth, denverChecklistDomains);
        return resolve({
          id: checklistId,
          checklistDate: result[0].childChecklistAssessment.date,
          levelsDevelopmentRatios: res.levelDevelopmentWeightedMeans,
          developmentDomainsRatios: res.developmentDomainsRatios,
          unifiedDevelopmentDomainsRatios: unifyDomainAreas(res.developmentDomainsRatios),
          developmentFactor: res.developmentFactor,
          checklistState: 'LOADED',
          developmentTotal: res.developmentTotal,
          developmentAcc: res.developmentAcc,
          levelsScore: res.levelsScore,
        });
      } else {
        console.error(err);
        return resolve({ checklistState: 'EMPTY' });
      }
    });
  });

/**
 * It takes an array of checklists and returns an object with the checklists
 * separated by developmental domain, the dates of the checklists, and the
 * checklists with the developmental domains unified
 * @param checklists - an array of checklists
 */
export const checklistsAssessmentsDevelopmentalDomainProgress = async (checklists) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve) => {
    // separate results by developmental domain
    let developmentalDomain = {};
    checklists.forEach((cl) => {
      cl.developmentDomainsRatios.forEach((ca) => {
        if (!developmentalDomain[ca.developmentalDomain.code]) {
          developmentalDomain[ca.developmentalDomain.code] = {
            developmentalDomain: ca.developmentalDomain,
            scoreTotal: [],
            developmentFactor: [],
            typicalDevelopmentTotalPoints: [],
            typicalDevelopmentWeightingTotalPoints: [],
          };
        }
        developmentalDomain[ca.developmentalDomain.code].scoreTotal.push({
          checklistDate: cl.checklistDate,
          value: ca.scoreTotal,
        });
        developmentalDomain[ca.developmentalDomain.code].developmentFactor.push({
          checklistDate: cl.checklistDate,
          value: ca.developmentFactor,
        });
        developmentalDomain[ca.developmentalDomain.code].typicalDevelopmentTotalPoints.push({
          checklistDate: cl.checklistDate,
          value: ca.typicalDevelopmentTotalPoints,
        });
        developmentalDomain[ca.developmentalDomain.code].typicalDevelopmentWeightingTotalPoints.push({
          checklistDate: cl.checklistDate,
          value: ca.typicalDevelopmentWeightingTotalPoints,
        });
      });
    });

    // get only dates
    const checklistDates = checklists.map((cl) => cl.checklistDate);

    // filter the Developmental Domains that are present in every checklist selected to the graph
    let checklistsEvaluated = Object.values(developmentalDomain).filter((dev) => {
      return checklistDates.every((sd) => dev.developmentFactor.find((df) => sd === df.checklistDate));
    });
    for (let i = 0; i < checklistsEvaluated.length; i++) {
      let dds = checklistsEvaluated[i];
      dds.notEvaluated = [];
      for (let j = 0; j < dds.typicalDevelopmentWeightingTotalPoints.length; j++) {
        const td = dds.typicalDevelopmentWeightingTotalPoints[j];
        if (td === 0) {
          dds.notEvaluated.push(j);
        }
      }
    }

    const rawChecklists = checklists;
    const unifiedChecklistsEvaluated = await unifyChecklistsByDomain(checklistsEvaluated);

    return resolve({
      rawChecklists,
      checklistsEvaluated,
      unifiedChecklistsEvaluated,
      checklistDates,
    });
  });

/**
 * It takes checklist data and returns two objects with evaluated data, one
 * organized by developmental domain and the other by level.
 * @param checklistData - checklist raw data to be evaluated
 * @param childAgeInMonths - the child's age in months
 * @returns an array, containing an object with data organized by domain and another by level
 */
export const evaluateLevelsAndDomains = async (checklistData, childAgeInMonths) => {
  const evaluatedLevels = [...new Set(checklistData.map((ass) => ass.skill.level))];

  const allEvaluatedDomains = [
    ...new Set(
      checklistData.filter((d) => d.score !== 'NOT_EVALUATED').map((ass) => ass.skill.developmentalDomain.code),
    ),
  ];

  // In this object we will save the data organizing by domain. Each domain will contain an array of objects,
  // where the keys are the levels and the values are the results of the calculus.
  const evaluatedDomainsByLevels = allEvaluatedDomains.reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {});

  // Create an array with the evaluations of each level, unifying the domain areas
  const evaluatedLevelData = evaluatedLevels.map((level) => {
    const currentLevelEvaluatedDomains = [
      ...new Set(
        checklistData
          .filter((d) => d.skill.level === level && d.score !== 'NOT_EVALUATED')
          .map((ass) => ass.skill.developmentalDomain.code),
      ),
    ];

    const items = currentLevelEvaluatedDomains.map((dd) =>
      checklistData.filter((r) => r.skill.developmentalDomain.code === dd && r.skill.level === level),
    );

    const scoresByDomain = items.map((i) => {
      const domain = { developmentalDomain: i[0].skill.developmentalDomain };
      const evaluatedScore = evaluateDevelopmentScoreByLevel(i, level, childAgeInMonths);
      return { ...domain, ...evaluatedScore };
    });

    // Organize the variables in a way that the application can understand the objects
    const developmentalScore = scoresByDomain.map((ds) => {
      const mappedScore = {
        ...ds,
        scoreTotal: ds.score,
        developmentFactor: ds.percentualScore,
        developmentWindow: ds.levelDevelopmentFit,
        percentualGrowth: ds.percentualScore,
        typicalDevelopmentTotalPoints: ds.typicalDevelopmentScoreFit,
        typicalDevelopmentWeightingTotalPoints: ds.typicalDevelopmentScoreFitWighting,
      };
      evaluatedDomainsByLevels[ds.code].push({ [level]: mappedScore });
      return mappedScore;
    });

    const finalResult = unifyDomainAreas(developmentalScore);

    return { [level]: finalResult };
  });

  return [evaluatedLevelData, evaluatedDomainsByLevels];
};

/**
 * A function similar to evaluateMatrixScore, but instead of consolidating all data,
 * returns it in a way that we can render different graphs for each level.
 * It's focused in detailing a single checklist.
 * @param childId - Child's ID
 * @param checklistId - Current checklist being detailed
 * @param childDateOfBirth - Child's date of birth
 * @returns An array of matrixes to generate graphics for each level of the checklist
 */
export const checklistSummarizedDetails = async (childId, checklistId, childDateOfBirth) => {
  const checklistRawData = await getChecklistData({ childId, checklistId, childDateOfBirth });

  const childAgeInMonths = moment(checklistRawData[0].childChecklistAssessment.date).diff(
    moment(childDateOfBirth),
    'months',
  );

  const [evaluatedLevelData, evaluatedDomainsByLevels] = await evaluateLevelsAndDomains(
    checklistRawData,
    childAgeInMonths,
  );

  // Create a consolidated object, so we can generate a summary on top of the page
  const defaultMatrixScore = await evaluateDevelopmentalMatrixScore(checklistRawData, childDateOfBirth);
  const parsedMatrix = defaultMatrixScore.developmentDomainsRatios.map((ds) => ({
    ...ds,
    score: parseInt(ds.scoreTotal.toFixed(0)),
    developmentFactor: ds.percentualGrowth,
    developmentWindow: ds.levelDevelopmentFit,
    percentualGrowth: parseFloat(ds.percentualGrowth.toFixed(2)),
    typicalDevelopmentTotalPoints: parseInt(ds.typicalDevelopmentTotalPoints.toFixed(0)),
    typicalDevelopmentWeightingTotalPoints: parseInt(ds.typicalDevelopmentWeightingTotalPoints.toFixed(0)),
    maximumEvaluatedScore: ds.maximumEvaluatedScore,
  }));

  const checklistSummarizedData = unifyDomainAreas(parsedMatrix);

  return [evaluatedLevelData, evaluatedDomainsByLevels, checklistSummarizedData];
};

export const getDomainCalculusDetails = async (childId, checklistId, childDateOfBirth) => {
  const checklistRawData = await getChecklistData({ childId, checklistId, childDateOfBirth });

  const childAgeInMonths = moment(checklistRawData[0].childChecklistAssessment.date).diff(
    moment(childDateOfBirth),
    'months',
  );

  const [evaluatedLevelData, evaluatedDomainsByLevels] = await evaluateLevelsAndDomains(
    checklistRawData,
    childAgeInMonths,
  );

  return [evaluatedLevelData, evaluatedDomainsByLevels];
};
