import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ChecklistDetailsInfoTable({ tableData }) {
  const { t } = useTranslation();

  if (!tableData) return <></>;

  const parsedData = Object.values(tableData);

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>{t('child-stats-page.checklist-info-table.developmental-domain')}</th>
            <th>{t('child-stats-page.checklist-info-table.items-total')}</th>
            <th>{t('child-stats-page.checklist-info-table.items-count')}</th>
            <th>{t('child-stats-page.checklist-info-table.max-evaluated-score')}</th>
            <th>{t('child-stats-page.checklist-info-table.score')}</th>
            <th>{t('child-stats-page.checklist-info-table.percentual-score')}</th>
          </tr>
        </thead>
        <tbody>
          {parsedData.map((row, index) => (
            <tr key={`tr-${index}`}>
              <td>{row.developmentalDomain.title}</td>
              <td>{row.itensCount}</td>
              <td>{row.evaluatedItensCount}</td>
              <td>{row.maximumEvaluatedScore}</td>
              <td>{row.score}</td>
              <td>{(row.percentualGrowth * 100).toFixed(0)}&#37;</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
