import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateIndividualizedPlanSkill, deleteIndividualizedPlanSkill } from '../../../../../services/individualizedPlan';

export default (individualizedPlan, planItem) => {
  const { t } = useTranslation();

  const [deleted, setDeleted] = useState(false);
  const [busyStatus, setBusyStatus] = useState('off');
  const [newObjectiveInstruction, setNewObjectiveInstruction] = useState(
    planItem.objectiveInstruction ? planItem.objectiveInstruction : planItem.skill.objectiveInstruction,
  );

  const savePlanItem = () => {
    setBusyStatus('loading');
    planItem.objectiveInstruction = newObjectiveInstruction;
    updateIndividualizedPlanSkill(planItem, (result, err) => {
      if (err) {
        return window.Swal.fire({
          type: 'error',
          title: t('Error'),
          text: t('There was an error saving') + '!',
        }).then((_) => {
          setBusyStatus('error');
        });
      } else {
        return window.Swal.fire({
          type: 'success',
          title: t('Saved'),
          text: t('Successfully saved') + '!',
        }).then((_) => {
          setBusyStatus('success');
        });
      }
    });
  };

  const deletePlanItem = (done) => {
    setBusyStatus('loading');
    deleteIndividualizedPlanSkill(individualizedPlan, planItem, (result, err) => {
      if (err) {
        setBusyStatus('error');
        done(null, err);
      } else {
        setDeleted(true);
        setBusyStatus('success');
        done(result);
      }
    });
  };

  return {
    deleted,
    busyStatus,
    newObjectiveInstruction,
    setNewObjectiveInstruction,
    savePlanItem,
    deletePlanItem,
  };
};
