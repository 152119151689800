import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import moment from 'moment';

import { LastChecklistStatsLevel } from '../../../components';
import { generateStatsData } from '../../../services/childStats';
import { getAllDenverChecklistSkillsBelowLevel } from '../../../services/denver';
import ChecklistLevelAggregatedStatsRadial from '../charts/ChecklistLevelAggregatedStatsRadial';
import ChecklistScoreProgress from './contents/ChecklistScoreProgress';
import DomainsChecklistScoreProgress from './contents/DomainsChecklistScoreProgress';
import LastChecklistStatsDevelopmentalDomain from './contents/LastChecklistStatsDevelopmentalDomain';
import RadarChecklistDevelopmentalDomains from './contents/RadarChecklistDevelopmentalDomains';

export default function AssessmentsOverview({ child, allChecklists, maxEvaluatedLevel }) {
  const [ageInMonths, setAgeInMonths] = useState();
  const [lastChecklist, setLastChecklist] = useState();
  const [lastChecklistData, setLastChecklistData] = useState();
  const [allChecklistsRawData, setAllChecklistsRawData] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      window.Swal.showLoading();

      if (!allChecklists) {
        setLastChecklist('EMPTY');
        setLastChecklistData('EMPTY');
        window.Swal.close();
        return;
      }

      // Get denver checklist (only items with levels below the max evaluated level)
      const denverChecklistDomains = await getAllDenverChecklistSkillsBelowLevel({
        maxLevel: maxEvaluatedLevel,
      });

      // Create an array of promises
      const statsDataPromises = allChecklists.map(async (cl) => {
        return await generateStatsData({
          childId: child.id,
          checklistId: cl.id,
          childDateOfBirth: child.person.dateOfBirth,
          denverChecklistDomains,
        });
      });

      // Wait for all promises to resolve
      const allChecklistsRawData = await Promise.all(statsDataPromises);

      // Filter to only have checklists LOADED(was causing error because some was empty)
      const filterOnlyLoaded = allChecklistsRawData.filter((cl) => cl.checklistState === 'LOADED');

      // Updating states
      setAgeInMonths(moment(new Date(allChecklists[0].date)).diff(moment(child.person.dateOfBirth), 'months'));
      setLastChecklist(allChecklists[0]);
      setAllChecklistsRawData(filterOnlyLoaded);
      setLastChecklistData(filterOnlyLoaded[0]);

      setTimeout(() => {
        window.Swal.close();
      }, 1000);
    };

    fetchData();
  }, [child]);

  return (
    <>
      {lastChecklist !== 'EMPTY' && lastChecklistData !== 'EMPTY' && allChecklistsRawData && (
        <>
          <div className="row">
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  {lastChecklist === 'EMPTY' && lastChecklistData === 'EMPTY' ? (
                    <p>{t('No checklist assessments found for this child')}</p>
                  ) : (
                    <Trans i18nKey="ZZLongTexts.RadialStatsDescription" ageInMonths={ageInMonths}>
                      All the comparisons and scales uses a <strong>{{ ageInMonths }}</strong> months old child as the
                      typical development as the baseline
                    </Trans>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <h3>{t('Current Stats')}</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="mb-4">
                    <h5 className="d-flex justify-content-center">{t('Levels')}</h5>
                    <ChecklistLevelAggregatedStatsRadial childId={child.id} lastChecklistData={lastChecklistData} />
                  </div>
                  <div>
                    <LastChecklistStatsLevel child={child} lastChecklistData={lastChecklistData} type="bar" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h5 className="d-flex justify-content-center">{t('Developmental Domains')}</h5>
                  <LastChecklistStatsDevelopmentalDomain
                    child={child}
                    lastChecklistData={lastChecklistData}
                    type="column"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <h3>{t('Progress Overview')}</h3>
            </div>
          </div>

          <RadarChecklistDevelopmentalDomains child={child} allChecklistsRawData={allChecklistsRawData} />

          <ChecklistScoreProgress child={child} allChecklistsData={allChecklistsRawData} />

          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body d-flex flex-column justify-content-center align-items-center">
                  <h5 className="d-flex justify-content-center mb-4">{t('child-stats-page.domains-score')}</h5>
                  <DomainsChecklistScoreProgress allChecklistsRawData={allChecklistsRawData} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
