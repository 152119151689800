import React from 'react';

import { PermissibleRender } from '@brainhubeu/react-permissible';

import useUserContext from './useUserContext';

function AccessControl({ children, allowedRole, requiredPermissions }) {
  const { loggedUser } = useUserContext();

  if (!requiredPermissions || requiredPermissions.length === 0) {
    return children;
  }

  // enforcement by role
  if (allowedRole && allowedRole.indexOf(loggedUser?.role) === -1) {
    return <></>;
  }

  return (
    <PermissibleRender userPermissions={loggedUser?.permissions} requiredPermissions={requiredPermissions} oneperm>
      {children}
    </PermissibleRender>
  );
}

export default AccessControl;
