import React from 'react';
import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import CalculusDescription from './CalculusDescription';

export default function CalculusDescriptionModal({ child, allChecklists }) {
  const { t } = useTranslation();

  const MySwal = withReactContent(Swal);

  const openModal = () => {
    MySwal.fire({
      width: '80%',
      heightAuto: true,
      title: t('calculus-description-modal.modal-title'),
      showCloseButton: true,
      showConfirmButton: false,
      html: <CalculusDescription child={child} allChecklists={allChecklists} />,
    });
  };

  return (
    <button className="btn btn-outline-primary" onClick={() => openModal()}>
      {t('calculus-description-modal.button-label')}
    </button>
  );
}
