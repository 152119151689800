import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

function ChecklistDevelopmentalDomainStatsBar({ childId, lastChecklistData, sortBy = 'natural' }) {
  const { t } = useTranslation();
  const timer = useRef();

  if (lastChecklistData.checklistState === 'EMPTY') {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        <Trans i18nKey="ZZLongTexts.EmptyChecklistsOnStats">
          No items evaluated in the last checklist.
          <a href={`/children/${childId}/checklist/${lastChecklistData.id}`} target="_blank" rel="noopener noreferrer">
            Click here to view the checklist
          </a>
        </Trans>
      </div>
    );
  }

  if (!lastChecklistData.unifiedDevelopmentDomainsRatios) {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        {t('Loading')}
      </div>
    );
  }

  let sortedDevelopmentDomainsRatios = lastChecklistData.unifiedDevelopmentDomainsRatios;
  if (sortBy === 'max') {
    sortedDevelopmentDomainsRatios = lastChecklistData.unifiedDevelopmentDomainsRatios.sort(
      (a, b) => b.developmentFactor - a.developmentFactor,
    );
  } else if (sortBy === 'min') {
    sortedDevelopmentDomainsRatios = lastChecklistData.unifiedDevelopmentDomainsRatios.sort(
      (a, b) => a.developmentFactor - b.developmentFactor,
    );
  }

  const values = sortedDevelopmentDomainsRatios
    .filter((x) => x.typicalDevelopmentWeightingTotalPoints)
    .map((a) => (a.developmentFactor * 100).toFixed(2));

  clearTimeout(timer.current);
  timer.current = setTimeout(() => {
    var options = {
      chart: {
        animations: { speed: 400 },
        height: 600,
        type: 'bar',
      },
      grid: {
        show: false,
      },
      series: [
        {
          name: t('Development Score'),
          data: values,
        },
      ],
      plotOptions: {
        bar: {
          barHeight: '95%',
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: sortedDevelopmentDomainsRatios
          .filter((x) => x.typicalDevelopmentWeightingTotalPoints)
          .map((dd) => t(dd.developmentalDomain.title)),
        max: 100,
        labels: {
          formatter: (value) => value + '%',
        },
      },
    };

    const chart = new window.ApexCharts(
      document.querySelector(`#ChecklistDevelopmentalDomainStatsBar-${childId}`),
      options,
    );
    if (chart) {
      chart.render();
    }
  }, Math.random() * 1000);

  return <div id={`ChecklistDevelopmentalDomainStatsBar-${childId}`} style={{ minHeight: '300px' }}></div>;
}

export default ChecklistDevelopmentalDomainStatsBar;
