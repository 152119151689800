import { useState, useEffect } from 'react';

import moment from 'moment';

import { getChecklistData } from '../../../../services/childStats';
import { evaluateDevelopmentalMatrixScore } from '../../../../services/denver';
import { unifyDomainAreas } from '../../../../utils/chartUtils';

const useChildChecklistAssessmentSummary = ({ childId, childDateOfBirth, checklistId }) => {
  const [childAge, setChildAge] = useState(null);
  const [levelsDevelopmentRatios, setLevelsDevelopmentRatios] = useState();
  const [developmentFactor, setDevelopmentFactor] = useState();
  const [developmentDomainsRatios, setDevelopmentDomainsRatios] = useState();
  const [unifiedDevelopmentDomainsRatios, setUnifiedDevelopmentDomainsRatios] = useState();
  const [checklistState, setChecklistState] = useState('NOT_EVALUATED');

  useEffect(() => {
    getChecklistData({ childId, checklistId, childDateOfBirth }, async (result, err) => {
      if (result && result.length > 0) {
        const childAgeInMonths = moment(result[0].childChecklistAssessment.date).diff(
          moment(childDateOfBirth),
          'months',
        );
        setChildAge(childAgeInMonths);
        const res = await evaluateDevelopmentalMatrixScore(result, childDateOfBirth);
        setLevelsDevelopmentRatios(res.levelDevelopmentWeightedMeans);
        setDevelopmentDomainsRatios(res.developmentDomainsRatios);
        setUnifiedDevelopmentDomainsRatios(unifyDomainAreas(res.developmentDomainsRatios));
        setDevelopmentFactor(res.developmentFactor);
        setChecklistState('LOADED');
      } else {
        setChecklistState('EMPTY');
      }
    });
  }, [childId, checklistId, childDateOfBirth]);

  return {
    childAge,
    levelsDevelopmentRatios,
    developmentDomainsRatios,
    unifiedDevelopmentDomainsRatios,
    developmentFactor,
    checklistState,
  };
};

export default useChildChecklistAssessmentSummary;
