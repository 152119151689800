import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import logo from './logo.png';
import { passwordReset } from './services/auth';
import useUserContext from './useUserContext';

function Login() {
  const { t } = useTranslation();

  const { authenticate } = useUserContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const doLogin = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage(t('Please enter an email'));
    } else {
      if (!isLoading) {
        setIsLoading(true);
        authenticate(email, password, (err) => {
          setErrorMessage(err);
          setIsLoading(false);
        });
      }
    }
  };

  const doReset = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage(t('Please enter an email'));
    } else {
      passwordReset(email, (response) => {
        if (response.status === 202) {
          setErrorMessage(t('Check your e-mail and click on link to reset your password'));
        } else {
          setErrorMessage(t('Server error'));
        }
      });
    }
  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-6 mx-auto">
              <div className="card">
                <div className="auth-form-wrapper px-4 py-5">
                  <div className="noble-ui-logo d-block mb-2">
                    <img src={logo} width="50px" className="mr-2" alt="logo-denver" />
                    Early<span>Denver</span>
                  </div>
                  <h5 className="text-muted font-weight-normal mb-4 mt-4">{t('Welcome! Log in to continue')}</h5>
                  {errorMessage && (
                    <div className="alert alert-fill-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <form className="forms-sample" onSubmit={(e) => doLogin(e)}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">E-mail</label>
                      <input
                        type="email"
                        disabled={isLoading}
                        onChange={(ev) => setEmail(ev.target.value)}
                        className="form-control"
                        id="email"
                        placeholder="e-mail"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">{t('Password')}</label>
                      <input
                        type="password"
                        disabled={isLoading}
                        onChange={(ev) => setPassword(ev.target.value)}
                        className="form-control"
                        id="password"
                        autoComplete="current-password"
                        placeholder={t('Password')}
                      />
                    </div>
                    <div className="mt-3">
                      <button type="submit" className="btn btn-primary mr-2 mb-2 mb-md-0 text-white">
                        {!isLoading ? t('Login') : t('Loading')}
                      </button>
                    </div>
                  </form>
                </div>
                <div>
                  <button className="btn btn-link" onClick={(e) => doReset(e)}>
                    {t('Password Reset')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
