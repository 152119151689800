import React from 'react';

import { AnamneseReportPDF } from './anamneseReport/AnamneseReport';
import { ChildResumePdf } from './childResume/ChildResume';
import { SessionReportPDF } from './sessionReport/sessionReport';

export const Templates = {
  childResume: (props) => <ChildResumePdf {...props} />,
  anamneseReport: (props) => <AnamneseReportPDF {...props} />,
  sessionReport: (props) => <SessionReportPDF {...props} />,
};
