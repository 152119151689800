import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { checklistSummarizedDetails } from '../../../services/childStats';
import ChecklistDetailsDomainLevelsChart from './contents/ChecklistDetailsDomainLevelsChart';
import ChecklistDetailsInfoTable from './contents/ChecklistDetailsInfoTable';
import ChecklistDetailsRadialChart from './contents/ChecklistDetailsRadialChart';

export default function ChecklistPerformanceDetails({ childId, childDateOfBirth, allChecklists }) {
  const { t } = useTranslation();

  const [selectedChecklist, setSelectedChecklist] = useState(allChecklists ? allChecklists[0] : null);
  const [selectedChecklistData, setSelectedChecklistData] = useState();
  const [availableLevels, setAvailableLevels] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [childAge, setChildAge] = useState(0);
  const [graphsType, setGraphsType] = useState('percentage');

  useEffect(() => {
    // start up the page with the data from the last checklist
    const fetchData = async () => {
      window.Swal.showLoading();

      const calculateChildAge = moment(selectedChecklist.date).diff(moment(childDateOfBirth), 'months');

      setChildAge(calculateChildAge);

      const [levelsData, domainsData, summaryData] = await checklistSummarizedDetails(
        childId,
        selectedChecklist.id,
        childDateOfBirth,
      );

      const calculateAvailableLevels = levelsData
        .filter((l) => Object.values(l)[0].length > 0)
        .map((k) => Object.keys(k))
        .flat()
        .map((n) => parseInt(n));

      setSelectedChecklistData({ levelsData, domainsData, summaryData });
      setAvailableLevels(calculateAvailableLevels);
      setSelectedLevel(0);

      window.Swal.close();
    };

    fetchData();
  }, [selectedChecklist]);

  const handleSelectChecklist = (input) => {
    const selection = allChecklists.find((checklist) => checklist.id === input.target.value);
    setSelectedChecklist(selection);
  };

  if (!selectedChecklist || !selectedChecklistData || !availableLevels) return <></>;

  return (
    <>
      <div className="row grid-margin mb-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="input-group mb-2">
                <label htmlFor="select-checklist" className="input-group-text">
                  {t('child-stats-page.select-checklist')}
                </label>
                <select
                  name="select-checklist"
                  id="select-checklist"
                  className="custom-select"
                  onChange={handleSelectChecklist}
                  value={selectedChecklist.id}
                >
                  {allChecklists.map((checklist) => (
                    <option value={checklist.id} key={checklist.id}>
                      {moment(checklist.date).format(t('MM/DD/YYYY'))}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <a className="mb-2" href={`/children/${childId}/checklist/${selectedChecklist.id}`}>
                  {t('View this Checklist')} &#x2197;
                </a>
              </div>
              <p>
                {t('child-stats-page.age-at-checklist')}: <strong>{`${childAge} ${t('months')}`}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row grid-margin">
        <div className="container-fluid mb-4">
          <div className="card ">
            <div className="card-body d-flex flex-column justify-content-center">
              <h3 className="mb-4 text-center">{t('Overview By Levels')}</h3>

              <div className="btn-group btn-group-sm mb-4" role="group">
                <button
                  onClick={(_) => setSelectedLevel(0)}
                  type="button"
                  disabled={availableLevels.indexOf(1) === -1}
                  className={`btn btn-${selectedLevel !== 0 ? 'outline-' : ''}${
                    availableLevels.indexOf(1) === -1 ? 'secondary' : 'primary'
                  }`}
                >
                  {t('child-stats-page.summary')}
                </button>

                <button
                  onClick={(_) => setSelectedLevel(1)}
                  type="button"
                  disabled={availableLevels.indexOf(1) === -1}
                  className={`btn btn-${selectedLevel !== 1 ? 'outline-' : ''}${
                    availableLevels.indexOf(1) === -1 ? 'secondary' : 'primary'
                  }`}
                >
                  {t('Level')} 1
                </button>

                <button
                  disabled={availableLevels.indexOf(2) === -1}
                  onClick={(_) => setSelectedLevel(2)}
                  type="button"
                  className={`btn btn-${selectedLevel !== 2 ? 'outline-' : ''}${
                    availableLevels.indexOf(2) === -1 ? 'secondary' : 'primary'
                  }`}
                >
                  {t('Level')} 2
                </button>

                <button
                  onClick={(_) => setSelectedLevel(3)}
                  type="button"
                  disabled={availableLevels.indexOf(3) === -1}
                  className={`btn btn-${selectedLevel !== 3 ? 'outline-' : ''}${
                    availableLevels.indexOf(3) === -1 ? 'secondary' : 'primary'
                  }`}
                >
                  {t('Level')} 3
                </button>

                <button
                  disabled={availableLevels.indexOf(4) === -1}
                  onClick={(_) => setSelectedLevel(4)}
                  type="button"
                  className={`btn btn-${selectedLevel !== 4 ? 'outline-' : ''}${
                    availableLevels.indexOf(4) === -1 ? 'secondary' : 'primary'
                  }`}
                >
                  {t('Level')} 4
                </button>
              </div>

              {selectedLevel === 0 ? (
                <>
                  <div className="mb-4">
                    <h6 className="text-center">{t('child-stats-page.checklist-overview')}</h6>
                    <ChecklistDetailsInfoTable tableData={selectedChecklistData.summaryData} />
                  </div>
                  <div className="row justify-content-center">
                    <div className="container-fluid d-flex justify-content-center">
                      <ChecklistDetailsRadialChart
                        chartData={[selectedChecklistData.summaryData]}
                        chartLevel={selectedLevel}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-4">
                    <h6 className="text-center">{`${t('child-stats-page.level-summary')} ${selectedLevel}`}</h6>
                    <ChecklistDetailsInfoTable
                      tableData={Object.values(selectedChecklistData.levelsData[selectedLevel - 1])[0]}
                    />
                  </div>
                  <div className="row justify-content-center">
                    <div className="container-fluid d-flex justify-content-center">
                      <ChecklistDetailsRadialChart
                        chartData={selectedChecklistData.levelsData[selectedLevel - 1]}
                        chartLevel={selectedLevel}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row grid-margin">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body d-flex flex-wrap flex-column justify-content-around">
              <h3 className="mb-4 text-center">{t('Overview By Developmental Domains')}</h3>

              <div className="btn-group btn-group-sm mb-4" role="group">
                <button
                  onClick={(_) => setGraphsType('percentage')}
                  type="button"
                  className={`btn btn-${graphsType !== 'percentage' ? 'outline-' : ''}primary`}
                >
                  {t('child-stats-page.graph-type-percent')}
                </button>

                <button
                  onClick={(_) => setGraphsType('score')}
                  type="button"
                  className={`btn btn-${graphsType !== 'score' ? 'outline-' : ''}primary`}
                >
                  {t('child-stats-page.graph-type-score')}
                </button>
              </div>

              <div className="row">
                {Object.keys(selectedChecklistData.domainsData).map((domain, index) => (
                  <div className="col-xl-4 col-lg-6 container-fluid" key={`domains-chart-${index}`}>
                    <ChecklistDetailsDomainLevelsChart
                      chartData={selectedChecklistData.domainsData[domain]}
                      chartDomain={domain}
                      chartType={graphsType}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
