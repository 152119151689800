import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';

export function CalendarForSessionButton({ handleSelectDate, onNavbar, leftAlign, childId }) {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (onNavbar) {
    return (
      <div
        className="collapse mt-2 position-absolute"
        style={{ zIndex: 10, top: '30px', left: leftAlign ? '-107px' : '-50px' }}
        id={`newSessionCalendar-${childId}`}
      >
        <Calendar maxDate={new Date()} onChange={(selectedDate) => handleSelectDate(selectedDate)} />
      </div>
    );
  }

  const isSmallScreen = screenHeight <= 768;

  return (
    <div
      className={isSmallScreen ? 'collapse mt-2' : 'collapse mt-2 position-absolute'}
      style={isSmallScreen ? null : { zIndex: 10, top: '30px', left: leftAlign ? '-107px' : '-50px' }}
      id={`newSessionCalendar-${childId}`}
    >
      <Calendar maxDate={new Date()} onChange={(selectedDate) => handleSelectDate(selectedDate)} />
    </div>
  );
}
