import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import moment from 'moment';

import DomainsChecklistScoreCharts from './DomainsChecklistScoreCharts';

export default function DomainsChecklistScoreProgress({ allChecklistsRawData }) {
  const { t } = useTranslation();

  const [graphsType, setGraphsType] = useState('percentage');
  const [selectedSeries, setSelectedSeries] = useState();
  const [usePonderedScores, setUsePonderedScores] = useState(true);

  const parseDomainsData = (domainsData) => {
    const developmentalDomains = [
      ...new Set(...domainsData.map((checklist) => checklist.developmentDomainsRatios.map((domain) => domain.code))),
    ];

    const developmentalDomainsScores = developmentalDomains.map((domain) => {
      const dds = {};
      dds[domain] = {};

      dds[domain].ids = domainsData.map((checklist) => checklist.id);

      dds[domain].dates = domainsData.map((checklist) => checklist.checklistDate);

      dds[domain].title = domainsData[0].developmentDomainsRatios.find(
        (domainRatio) => domainRatio.code === domain,
      )?.developmentalDomain.title;

      dds[domain].order = domainsData[0].developmentDomainsRatios.find(
        (domainRatio) => domainRatio.code === domain,
      )?.developmentalDomain.order;

      dds[domain].goals = domainsData.map(
        (checklist) =>
          checklist.developmentDomainsRatios.find((domainRatio) => domainRatio.code === domain)
            ?.maximumEvaluatedScore ?? null,
      );

      dds[domain].scores = domainsData.map(
        (checklist) =>
          checklist.developmentDomainsRatios.find((domainRatio) => domainRatio.code === domain)?.scoreTotal ?? null,
      );

      dds[domain].percentualScores = domainsData.map((checklist) => {
        const result = checklist.developmentDomainsRatios.find(
          (domainRatio) => domainRatio.code === domain,
        )?.percentualGrowth;
        return result ? result * 100 : null;
      });

      dds[domain].goalsNotPondered = domainsData.map(
        (checklist) =>
          checklist.developmentDomainsRatios.find((domainRatio) => domainRatio.code === domain)
            ?.maximumScoreNotPondered ?? null,
      );

      dds[domain].scoreNotPondered = domainsData.map(
        (checklist) =>
          checklist.developmentDomainsRatios.find((domainRatio) => domainRatio.code === domain)?.scoreNotPondered ??
          null,
      );

      dds[domain].percentualScoresNotPondered = domainsData.map(
        (checklist) =>
          checklist.developmentDomainsRatios.find((domainRatio) => domainRatio.code === domain)
            ?.percentualScoreNotPondered * 100 ?? null,
      );

      return dds;
    });

    // sort by order
    developmentalDomainsScores.sort((a, b) => a[Object.keys(a)[0]].order - b[Object.keys(b)[0]].order);

    return developmentalDomainsScores;
  };

  /* Sorts the series by date, and keeps only the last 4 dates */
  const orderSeries = (series) => {
    const sortedSeries = series.map((domain) => {
      const sortedDomain = {};

      const currentDomain = Object.keys(domain)[0];

      const indices = domain[currentDomain].dates
        .map((date, index) => index)
        .sort((a, b) => moment(domain[currentDomain].dates[a]).diff(moment(domain[currentDomain].dates[b]), 'days'))
        .slice(-4);

      sortedDomain[currentDomain] = {
        ...domain[currentDomain],
        dates: indices.map((index) => domain[currentDomain].dates[index]),
        ids: indices.map((index) => domain[currentDomain].ids[index]),
        goals: indices.map((index) => domain[currentDomain].goals[index]),
        scores: indices.map((index) => domain[currentDomain].scores[index]),
        percentualScores: indices.map((index) => domain[currentDomain].percentualScores[index]),
        goalsNotPondered: indices.map((index) => domain[currentDomain].goalsNotPondered[index]),
        scoresNotPondered: indices.map((index) => domain[currentDomain].scoreNotPondered[index]),
        percentualScoresNotPondered: indices.map((index) => domain[currentDomain].percentualScoresNotPondered[index]),
      };

      return sortedDomain;
    });

    return sortedSeries;
  };

  useEffect(() => {
    const allChartsData = parseDomainsData(allChecklistsRawData);
    const orderedChartsData = orderSeries(allChartsData);

    setSelectedSeries(orderedChartsData);
  }, [allChecklistsRawData]);

  const handleSelectSeries = (seriesType) => {
    if (seriesType === 'percentage') {
      setGraphsType('percentage');
    } else if (seriesType === 'score') {
      setGraphsType('score');
    }
  };

  if (!selectedSeries) return <div>{t('Loading')}</div>;

  return (
    <>
      <div className="d-flex mb-2">
        <BootstrapSwitchButton
          checked={usePonderedScores}
          onlabel=" "
          offlabel=" "
          size="xs"
          width={40}
          onChange={(checked) => setUsePonderedScores(checked)}
          style="radar-graph-switch mr-2"
        />
        <span>{t('usePonderedScore')}</span>
      </div>
      <div className="container d-flex justify-content-center">
        <div className="btn-group btn-group-sm mb-4" role="group">
          <button
            onClick={() => handleSelectSeries('percentage')}
            type="button"
            className={`btn btn-${graphsType !== 'percentage' ? 'outline-' : ''}primary`}
          >
            {t('child-stats-page.graph-type-percent')}
          </button>

          <button
            onClick={() => handleSelectSeries('score')}
            type="button"
            className={`btn btn-${graphsType !== 'score' ? 'outline-' : ''}primary`}
          >
            {t('child-stats-page.graph-type-score')}
          </button>
        </div>
      </div>

      <div className="row">
        {selectedSeries.map((series, index) => (
          <div className="container-fluid col-lg-6" key={`domains-graph-${index}`}>
            <DomainsChecklistScoreCharts
              chartData={
                graphsType === 'score'
                  ? usePonderedScores
                    ? Object.values(series)[0].scores
                    : Object.values(series)[0].scoresNotPondered
                  : usePonderedScores
                  ? Object.values(series)[0].percentualScores
                  : Object.values(series)[0].percentualScoresNotPondered
              }
              chartTitle={Object.values(series)[0].title}
              chartLabels={Object.values(series)[0].dates}
              chartDomain={Object.keys(series)[0]}
              chartYMax={
                graphsType === 'score'
                  ? usePonderedScores
                    ? Math.max(...Object.values(series)[0].goals)
                    : Math.max(...Object.values(series)[0].goalsNotPondered)
                  : 100
              }
              chartgoal={
                graphsType === 'score'
                  ? usePonderedScores
                    ? Math.max(...Object.values(series)[0].goals)
                    : Math.max(...Object.values(series)[0].goalsNotPondered)
                  : null
              }
            />
          </div>
        ))}
      </div>
    </>
  );
}
