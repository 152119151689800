import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Document, Packer, Paragraph, HeadingLevel, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { get, getAllAnotattionFromChildren, getFilteredAnotattionFromChildren } from '../../../services/children';
import { graphQLRequest } from '../../../services/common';
import useUserContext from '../../../useUserContext';
import convertHtmlToParagraphs from '../../../utils/convertFromHtmlToDoc';
import docxTemplate from '../../../utils/docxTemplate';

export const ChildWeeklyCommentModel = () => {
  const pathName = window.location.pathname;
  const listAll = pathName.includes('listAll');
  const isNew = !listAll;
  const { t } = useTranslation();

  const { childId } = useParams();
  const [child, setChild] = useState(null);
  const [destinationAnottation, setDestinationAnottation] = useState('All');
  const [selectedAnottation, setSelectedAnottation] = useState(null);
  const [textAreaValue, setTextAreaValue] = useState();
  const [comment, setComment] = useState('');
  const [annotationDate, setAnnotationDate] = useState(moment(new Date()).format(t('MM/DD/YYYY')));
  const [busy, setBusy] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [allAnottationChildren, setAllAnnotationsChildren] = useState([]);
  const [levelUser, setLevelUser] = useState(0);
  const [dateFilter, setDateFilter] = useState([new Date(), new Date()]);
  const { loggedUser } = useUserContext();

  useEffect((_) => {
    const getChild = async () => {
      get(childId, (result, err) => {
        setChild(result);
        setBusy(false);
        const { role } = loggedUser;
        const staffProf = result.staffAssignments.find((sa) => sa.staffMember.id === loggedUser.id);
        if (
          role === 'admin' ||
          staffProf?.staffAttribution?.title === 'Supervisor' ||
          staffProf?.staffAttribution?.title === 'Main Supervisor'
        ) {
          if (listAll) {
            getAllAnotattionFromChildren(childId, (res) => {
              setAllAnnotationsChildren(res?.data?.childAnotattionses ?? []);
            });
          } else {
            setLevelUser(2);
          }
        } else if (staffProf?.staffAttribution?.title === 'Therapist Assistant') {
          if (listAll) {
            getFilteredAnotattionFromChildren(
              {
                id: result.id,
                domainReached: staffProf?.staffAttribution?.title,
              },
              (res, error) => {
                setAllAnnotationsChildren(res?.data?.childAnotattionses ?? []);
              },
            );
          } else {
            setLevelUser(1);
          }
        } else if (role === 'relatives') {
          if (listAll) {
            getFilteredAnotattionFromChildren({ id: result.id, domainReached: role }, (res) => {
              setAllAnnotationsChildren(res?.data?.childAnotattionses ?? []);
            });
          }
        }
      });
    };
    getChild();
  }, []);

  useEffect(() => {
    const annotationsDates = allAnottationChildren.map((a) => a.annotationDate ?? a.createdAt);
    const annotationsDatesSorted = annotationsDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    if (annotationsDatesSorted.length > 0) {
      const beginDate = moment(annotationsDatesSorted[0]).add(-1, 'days');
      const endDate = moment(annotationsDatesSorted[annotationsDatesSorted.length - 1]).add(1, 'days');
      setDateFilter([beginDate.toDate(), endDate.toDate()]);
    }
  }, [allAnottationChildren]);

  const removeThisAnottation = ({ id }) => {
    window.Swal.fire({
      title: t('Confirm') + '?',
      icon: 'warning',
      text: t('Are you sure you want to remove this annotation') + '?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then((result) => {
      if (result.value) {
        deleteAnottation(id);
      }
    });
  };

  const updateComment = (value) => setComment(value);

  const saveAnottation = () => {
    const annotationDateFormatted =
      annotationDate?.length === 0 || !annotationDate
        ? new Date().toISOString(true)
        : moment(annotationDate, t('MM/DD/YYYY')).toDate().toISOString();
    const graphQL = {
      query: `mutation {
          createChildAnotattions(
               data: {
                anottation : """${comment.replace(/"/g, '“')}"""
                visibility:  "${destinationAnottation}"
                attendant: {
                    connect: {
                        id: "${loggedUser.id}"
                    }
                }
                child: {
                    connect: {
                        id: "${child.id}"
                    }
                }
                annotationDate: "${annotationDateFormatted}"
              }
            ){
              id
              anottation
        }
      }`,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error(err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('annotation-save-error'),
        });
      } else {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          window.location.href = 'listAll';
        }, 400);
      }
    });
  };

  const deleteAnottation = (id) => {
    const graphQL = {
      query: ` mutation {                
        deleteChildAnotattions(
            where:{
                id: "${id}"
            }
        )
        {
            id
        }                  
    }`,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error(err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('annotation-delete-error'),
        });
      } else {
        getAllAnotattionFromChildren(childId, (res, error) => {
          setAllAnnotationsChildren(res?.data?.childAnotattionses ?? []);
        });
      }
    });
  };

  const updateAnottation = (anottation) => {
    const graphQL = {
      query: ` mutation {
        updateChildAnotattions (
            data: {
                anottation: """${textAreaValue}"""
                annotationDate: "${moment(annotationDate).toISOString(true) ?? anottation.createdAt}"
            }
            where: {
                id: "${anottation.id}"
            }
        ){
            id
        }
    }`,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error(err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('annotation-update-error'),
        });
      } else {
        getAllAnotattionFromChildren(childId, (res, error) => {
          setAllAnnotationsChildren(res?.data?.childAnotattionses ?? []);
          setSelectedAnottation(null);
        });
      }
    });
  };

  const fixAnottation = (anottation) => {
    const fixed = anottation.fixed;
    const graphQL = {
      query: ` mutation {
        updateChildAnotattions (
            data: {
                fixed: ${!fixed}
            }
            where: {
                id: "${anottation.id}"
            }
        ){
            id
        }
    }`,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error('error fixing note', err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('fix-anottation-error'),
        });
      } else {
        getAllAnotattionFromChildren(childId, (res, error) => {
          setAllAnnotationsChildren(res?.data?.childAnotattionses ?? []);
          setSelectedAnottation(null);
        });
      }
    });
  };

  const filteredAnnotations = dateFilter
    ? allAnottationChildren
        .filter((annotation) =>
          annotation.annotationDate
            ? moment(annotation.annotationDate) >= moment(dateFilter[0]) &&
              moment(annotation.annotationDate) <= moment(dateFilter[1])
            : moment(annotation.createdAt) >= moment(dateFilter[0]) &&
              moment(annotation.createdAt) <= moment(dateFilter[1]),
        )
        .sort((a, b) => moment(b.annotationDate ?? b.createdAt).diff(moment(a.annotationDate ?? a.createdAt)))
    : allAnottationChildren.sort((a, b) =>
        moment(b.annotationDate ?? b.createdAt).diff(moment(a.annotationDate ?? a.createdAt)),
      );

  const generateAnnotationsDocx = async (child, annotations) => {
    const annotationsText = await Promise.all(
      annotations.map(async (annotation) =>
        [
          await convertHtmlToParagraphs([annotation.anottation]),
          new Paragraph({
            spacing: {
              before: 50,
            },
            children: [
              new TextRun({
                text: `${t('Annotation date')}: `,
                font: 'Arial',
                bold: true,
                size: 18,
                color: '6c757d',
              }),
              new TextRun({
                text: moment(annotation.annotationDate ?? annotation.createdAt).format(t('MM/DD/YYYY')),
                font: 'Arial',
                size: 18,
                color: '6c757d',
              }),
              new TextRun({
                text: ` | ${t('created-by')} `,
                font: 'Arial',
                size: 18,
                color: '6c757d',
              }),
              new TextRun({
                text: annotation.attendant.person.name,
                font: 'Arial',
                bold: true,
                size: 18,
                color: '6c757d',
              }),
            ],
          }),
          new Paragraph(''),
          new Paragraph(''),
        ].flat(),
      ),
    );

    try {
      const dateOfBirth = moment(new Date(child.person.dateOfBirth));
      const today = moment(new Date());
      const years = today.diff(dateOfBirth, 'year');
      dateOfBirth.add(years, 'years');
      const months = today.diff(dateOfBirth, 'months');

      const doc = new Document({
        ...docxTemplate.defaultListsProperties,
        sections: [
          {
            properties: { ...docxTemplate.defaultPageProperties },
            children: [
              new Paragraph({
                heading: HeadingLevel.TITLE,
                children: [
                  new TextRun({
                    text: child.person.name,
                    font: 'Arial',
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: 'Data de Nascimento: ',
                    bold: true,
                    font: 'Arial',
                  }),
                  new TextRun({
                    font: 'Arial',
                    text: `${moment(new Date(child.person.dateOfBirth)).format(
                      'DD/MM/YYYY',
                    )} | Idade: ${years} anos e ${months} meses`,
                  }),
                ],
              }),
              new Paragraph(''),
              ...annotationsText.flat(),
            ],
          },
        ],
      });
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${child.person.name} - ${moment(new Date()).format('DD/MM/YYYY')}`);
      });
    } catch (error) {
      console.error(error);
      return window.Swal.fire({
        type: 'error',
        title: t('Error'),
        text: t('There was an error generating the DOCX report'),
      });
    }
  };

  return {
    child,
    busy,
    comment,
    updateComment,
    annotationDate,
    setAnnotationDate,
    saveAnottation,
    fixAnottation,
    showSuccess,
    isNew,
    filteredAnnotations,
    loggedUser,
    removeThisAnottation,
    selectedAnottation,
    setSelectedAnottation,
    textAreaValue,
    setTextAreaValue,
    updateAnottation,
    listAll,
    destinationAnottation,
    setDestinationAnottation,
    levelUser,
    dateFilter,
    setDateFilter,
    generateAnnotationsDocx,
  };
};
