export const unifyDomainAreas = (developmentDomainsRatios) => {
  const socialDomains = ['CSA', 'CSP', 'CAP', 'CSO'];
  const gameDomains = ['JOG', 'JOR', 'JOI'];
  const independencyDomains = ['IPA', 'IPV', 'IPH', 'IPT', 'IPE'];

  function unifyDevelopmentDomains(domains, newDomainName) {
    if (domains.length < 1) return;
    const average = (elements) => {
      const reduction = elements.reduce((a, b) => a + b);
      const result = reduction / elements.length;
      return result;
    };

    return {
      typicalDevelopmentTotalPoints: average(domains.map((item) => item.typicalDevelopmentTotalPoints)).toFixed(2),
      typicalDevelopmentWeightingTotalPoints: average(
        domains.map((item) => item.typicalDevelopmentWeightingTotalPoints),
      ).toFixed(2),
      scoreTotal: average(domains.map((item) => item.scoreTotal)).toFixed(2),
      score: average(domains.map((item) => item.scoreTotal)).toFixed(0),
      developmentWindow: average(domains.map((item) => item.developmentWindow)).toFixed(2),
      developmentGrowth: average(domains.map((item) => item.developmentGrowth)).toFixed(2),
      percentualGrowth: average(domains.map((item) => item.percentualGrowth)).toFixed(2),
      developmentFactor: average(domains.map((item) => item.developmentFactor)).toFixed(2),
      percentualScoreNotPondered: average(domains.map((item) => item.percentualScoreNotPondered)).toFixed(2),
      itensCount: domains.map((item) => item.itensCount).reduce((a, b) => a + b),
      evaluatedItensCount: domains.map((item) => item.evaluatedItensCount).reduce((a, b) => a + b),
      maximumEvaluatedScore: average(domains.map((item) => item.maximumEvaluatedScore)).toFixed(0),
      developmentalDomain: {
        title: newDomainName,
        order: domains[0].developmentalDomain.order,
      },
    };
  }

  // Unify social, game and independency domains.
  const socialCompetences = unifyDevelopmentDomains(
    developmentDomainsRatios.filter((domain) => socialDomains.includes(domain.code)),
    'Competências Sociais',
  );

  const gameCompetences = unifyDevelopmentDomains(
    developmentDomainsRatios.filter((domain) => gameDomains.includes(domain.code)),
    'Jogo',
  );

  const independencyCompetences = unifyDevelopmentDomains(
    developmentDomainsRatios.filter((domain) => independencyDomains.includes(domain.code)),
    'Independência',
  );

  // Return an array with unified domains
  const unifiedDomains = developmentDomainsRatios.filter(
    (domain) =>
      // filter out domains that won't be unified
      !socialDomains.includes(domain.code) &&
      !gameDomains.includes(domain.code) &&
      !independencyDomains.includes(domain.code),
  );

  socialCompetences && unifiedDomains.push(socialCompetences);
  gameCompetences && unifiedDomains.push(gameCompetences);
  independencyCompetences && unifiedDomains.push(independencyCompetences);

  return unifiedDomains;
};
