import { useEffect, useState } from 'react';

import { getInterventionSessionsReport } from '../../services/interventionSession';

export const useSessionList = (childId) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionList, setSessionList] = useState([]);

  useEffect(() => {
    if (!childId) {
      return;
    }
    setLoading(true);
    getInterventionSessionsReport(childId, (result, err) => {
      if (!err) {
        const nameAndIdOnly = result.map((session) => ({
          id: session.id,
          checkin: session.checkinAt,
          checkout: session.checkoutAt,
          individualizedPlanId: session.childIndividualizedPlan.id,
          individualizedPlanDate: session.childIndividualizedPlan.date,
        }));
        setSessionList(nameAndIdOnly);
      } else {
        setError(err);
      }
      setLoading(false);
    });
  }, [childId]);

  return { loading: loading && childId, error, sessionList };
};
