import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import AccessControl from '../../../../../AccessControl';
import { LazyDownloadPDFButton, Loading } from '../../../../../components';
import { ChildIndividualizedPlanSkillCard } from '../../components';
import useChildIndividualizedPlanReadOnlyModel from './useChildIndividualizedPlanReadOnlyModel';

export function ChildIndividualizedPlanReadOnly({ individualizedPlan, showPlanSelection, showChildName }) {
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);

  const {
    plans,
    busy,
    loadingErrorMessage,
    individualizedPlanUniqueSkills,
    deleteCurentIndividualizedPlan,
    resolveSessionsObjectiveSteps,
  } = useChildIndividualizedPlanReadOnlyModel({ individualizedPlan });

  const removeThisPlan = () => {
    window.Swal.fire({
      title: t('Confirm') + '?',
      icon: 'warning',
      text: t('Are you sure you want to remove this plan') + '?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then((result) => {
      if (result.value) {
        deleteCurentIndividualizedPlan();
      }
    });
  };

  const filterSkillType = (el) => {
    switch (tab) {
      case 0:
        return true;
      case 1:
        return !el.code.match(/CTM/);
      case 2:
        return el.code.match(/CTM/);
      default:
        return false;
    }
  };

  const filteredindividualizedPlanUniqueSkills = individualizedPlanUniqueSkills.filter(filterSkillType);

  return (
    <>
      {!loadingErrorMessage && busy && <Loading loadingText={t('Fetching Child Individualized Plan')} />}
      {!loadingErrorMessage && !busy && individualizedPlan && (
        <>
          {showChildName && individualizedPlan.child && (
            <div className="row">
              <h4 className="page-title col-12 mb-3">
                <div className="d-flex">
                  {t('Therapeutic Goals')}
                  {showPlanSelection && (
                    <>
                      <button
                        className="btn btn-outline-secondary ml-2 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {moment(individualizedPlan.date).format(t('MM/DD/YYYY'))}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {plans.map((ca, idx) => (
                          <a key={ca.id} className="dropdown-item" href={`/children/${ca.child.id}/plan/${ca.id}`}>
                            {moment(ca.date).format(t('MM/DD/YYYY'))} {idx === 0 && '(' + t('current') + ')'}
                          </a>
                        ))}
                      </div>
                      <div className="d-flex justify-content-end align-items-center" style={{ flex: '1' }}>
                        <AccessControl
                          requiredPermissions={[
                            'child:*:updateChildIndividualizedPlan:*',
                            'child:' + individualizedPlan.child.id + ':updateChildIndividualizedPlan:*',
                            'child:*:updateChildIndividualizedPlan:' + individualizedPlan.id,
                            'child:' +
                              individualizedPlan.child.id +
                              ':updateChildIndividualizedPlan:' +
                              individualizedPlan.id,
                          ]}
                        >
                          <a
                            href={`/children/${individualizedPlan.child.id}/plan/${individualizedPlan.id}?mode=edit`}
                            className="btn btn-outline-info ml-2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t('Edit this Plan')}
                          </a>
                        </AccessControl>
                        <AccessControl
                          requiredPermissions={[
                            'child:*:deleteChildIndividualizedPlan:*',
                            'child:' + individualizedPlan.child.id + ':deleteChildIndividualizedPlan:*',
                            'child:*:deleteChildIndividualizedPlan:' + individualizedPlan.id,
                            'child:' +
                              individualizedPlan.child.id +
                              ':deleteChildIndividualizedPlan:' +
                              individualizedPlan.id,
                          ]}
                        >
                          <button className="btn btn-outline-info ml-2" onClick={removeThisPlan}>
                            {t('Delete this Plan')}
                          </button>
                        </AccessControl>
                        <LazyDownloadPDFButton
                          saveNameButton={`${individualizedPlan.child.person.name}_${moment(
                            individualizedPlan.date,
                          ).format(t('MM/DD/YYYY'))}`}
                          template="childResume"
                          buttonName={t('Generate PDF')}
                          resolveSessionsObjectiveSteps={resolveSessionsObjectiveSteps}
                          individualizedPlan={individualizedPlan}
                          data={{
                            childName: individualizedPlan.child.person.name,
                            date: moment(individualizedPlan.date).format(t('MM/DD/YYYY')),
                            individualPlans: individualizedPlanUniqueSkills.filter(filterSkillType),
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </h4>
            </div>
          )}

          <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body main-content">
                  {loadingErrorMessage && (
                    <div className="alert alert-primary" role="alert">
                      {loadingErrorMessage}
                    </div>
                  )}
                  <div className="row mb-2">
                    <div className="col-12">
                      <div className="btn-group btn-group-sm">
                        <button
                          className={'btn ' + (tab === 0 ? 'btn-primary' : 'btn-outline-primary')}
                          onClick={(_) => setTab(0)}
                        >
                          {t('All')}
                        </button>
                        <button
                          className={'btn ' + (tab === 1 ? 'btn-primary' : 'btn-outline-primary')}
                          onClick={(_) => setTab(1)}
                        >
                          {t('Checklist skills')}
                        </button>
                        <button
                          className={'btn ' + (tab === 2 ? 'btn-primary' : 'btn-outline-primary')}
                          onClick={(_) => setTab(2)}
                        >
                          {t('Custom objectives')}
                        </button>
                      </div>
                    </div>
                  </div>
                  {individualizedPlanUniqueSkills && (
                    <div className="row">
                      <div className="accordion" id="accordionSkills">
                        <ul>
                          {filteredindividualizedPlanUniqueSkills.map((item, idx) => (
                            <li key={item.id}>
                              <div className="col-12 grid-margin">
                                <ChildIndividualizedPlanSkillCard
                                  number={idx}
                                  skill={item}
                                  individualizedPlan={individualizedPlan}
                                  substeps={resolveSessionsObjectiveSteps(
                                    item,
                                    individualizedPlan.sessions,
                                    individualizedPlan.skillSteps,
                                  )}
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
