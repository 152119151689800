import { Paragraph, TextRun, AlignmentType, Table, TableRow, TableCell, WidthType, VerticalAlign } from 'docx';
import moment from 'moment';

import { getAllDevelopmentalDomains } from '../../services/denver';
import { borders, margins } from './LazyButtonStyles';

export default async ({ checklistScores, t }) => {
  const allDomains = await getAllDevelopmentalDomains();
  let ddTabulatedScores = [];
  allDomains.forEach((dd) => {
    ddTabulatedScores.push({
      developmentalDomainTitle: dd.title,
      consolidatedScores: checklistScores.map((css) => {
        const res = css.developmentDomainsRatios.find((ddr) => ddr.code === dd.code);
        if (!res) {
          return '-';
        }
        return (res.percentualGrowth * 100).toFixed(0) + '%';
      }),
    });
  });

  const docxTable = new Paragraph({
    children: [
      new TextRun({
        text: '',
        italics: true,
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                margins,
                borders,
                width: { size: 50, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: t('Developmental Domain'),
                        bold: true,
                        font: 'Arial',
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
              ...checklistScores.map(
                (selChk) =>
                  new TableCell({
                    margins,
                    borders,
                    width: { size: 10, type: WidthType.PERCENTAGE },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${moment(selChk.checklistDate).format('DD/MM/YYYY')}`,
                            bold: true,
                            font: 'Arial',
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                  }),
              ),
            ],
          }),
          ...ddTabulatedScores.map(
            (ssa) =>
              new TableRow({
                children: [
                  new TableCell({
                    margins,
                    borders,
                    width: { size: 50, type: WidthType.PERCENTAGE },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: ssa.developmentalDomainTitle,
                            font: 'Arial',
                          }),
                        ],
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  ...ssa.consolidatedScores.map(
                    (vl) =>
                      new TableCell({
                        margins,
                        borders,
                        width: { size: 10, type: WidthType.PERCENTAGE },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: vl,
                                bold: true,
                                font: 'Arial',
                              }),
                            ],
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                  ),
                ],
              }),
          ),
        ],
      }),
    ],
  });

  return docxTable;
};
