import React from 'react';

import useChildInterventionSessionAssessmentButtonModel from './useChildInterventionSessionAssessmentButtonModel';

export function ChildInterventionSessionAssessmentButton({ stepResult, interventionSession, type }) {
  const {
    positiveAchievement,
    negativeAchievement,
    updatePositiveResult,
    updateNegativeResult,
    loadingNegativeButton,
    loadingPositiveButton,
  } = useChildInterventionSessionAssessmentButtonModel(stepResult, interventionSession);

  if (type === 'positive') {
    return (
      <div className="vote-button">
        <button
          disabled={loadingPositiveButton}
          className="btn btn-success btn-icon-text d-flex justify-content-center align-items-center left-button"
          onClick={(_) => positiveAchievement !== 0 && !loadingPositiveButton && updatePositiveResult('decrease')}
        >
          <i className="mdi mdi-arrow-down"></i>
        </button>
        <div
          className={`justify-content-center align-items-center positive ${
            loadingPositiveButton ? 'isLoading' : 'no-loading-positive'
          }`}
        >
          <div className="wrapper-values-button">
            {!loadingPositiveButton ? (
              <span className="span-button-vote">{positiveAchievement}</span>
            ) : (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
        <button
          disabled={loadingPositiveButton}
          className="btn btn-success btn-icon-text d-flex justify-content-center align-items-center right-button"
          onClick={(_) => !loadingPositiveButton && updatePositiveResult('increase')}
        >
          <i className="mdi mdi-arrow-up"></i>
        </button>
      </div>
    );
  } else {
    return (
      <div className="vote-button margin-second-button">
        <button
          disabled={loadingNegativeButton}
          className="btn btn-danger btn-icon-text d-flex justify-content-center align-items-center left-button"
          onClick={(_) => negativeAchievement !== 0 && !loadingNegativeButton && updateNegativeResult('decrease')}
        >
          <i className="mdi mdi-arrow-down"></i>
        </button>
        <div
          className={`justify-content-center align-items-center negative ${
            loadingNegativeButton ? 'isLoading' : 'no-loading-negative'
          }`}
        >
          <div className="wrapper-values-button">
            {!loadingNegativeButton ? (
              <span className="span-button-vote">{negativeAchievement}</span>
            ) : (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
        <button
          disabled={loadingNegativeButton}
          className="btn btn-danger btn-icon-text  d-flex justify-content-center align-items-center right-button"
          onClick={(_) => !loadingNegativeButton && updateNegativeResult('increase')}
        >
          <i className="mdi mdi-arrow-up"></i>
        </button>
      </div>
    );
  }
}
