import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import AccessControl from '../../AccessControl';
import Navbar from '../../partials/Navbar';
import { StaffChildrenRelated } from './StaffChildrenRelated';
import StaffMemberRegisterForm from './StaffMemberRegisterForm';

function StaffMemberDetails() {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      <Navbar />

      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/staff/">{t('Sidebar.StaffMembers')}</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <AccessControl requiredPermissions={['createStaffMember']}>
                <a href="/staff/new">{t('New Staff Member')}</a>
              </AccessControl>
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md grid-margin stretch-card">
            <StaffMemberRegisterForm />
          </div>
          <div className="col-md grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">{t('Related children')}</h6>
                <StaffChildrenRelated id={id} variant="full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffMemberDetails;
