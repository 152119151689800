import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Fuse from 'fuse.js';

import { Loading } from '../../components';
import ChecklistFilter from './components/ChecklistFilter';
import useChecklistModel from './useChecklistModel';

function Checklist() {
  const { t } = useTranslation();

  const {
    busy,
    loadingErrorMessage,
    checklistItems,
    denverDevelopmentalDomains,
    currentDevelopmentalDomainFilter,
    setCurrentDevelopmentalDomainFilter,
    currentLevel,
    setCurrentLevel,
    availableLevels,
  } = useChecklistModel({ shouldUpdateChecklistURL: false });
  // Search
  const [query, updateQuery] = useState('');
  const fuse = new Fuse(checklistItems, {
    // minMatchCharLength: 3,
    keys: ['title', 'description'],
    distance: 300,
    threshold: 0.3,
  });
  function onSearch({ currentTarget }) {
    updateQuery(currentTarget.value);
  }
  const results = fuse.search(query);
  const filterFuse = (el) => {
    if (results.length > 0) {
      for (let rs of results) {
        if (rs.item.id === el.id) return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                id="search"
                name="search"
                value={query}
                onChange={onSearch}
                required
                type="text"
                className="form-control form-control-sm"
                autoComplete="off"
                placeholder={t('Search here')}
              />
            </div>
          </form>
        </div>
      </nav>

      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('Sidebar.Settings')}</li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/denver">{t('Checklist Curriculum')}</a>
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {loadingErrorMessage && (
                  <div className="alert alert-primary" role="alert">
                    {loadingErrorMessage}
                  </div>
                )}
                <div className="mb-4 flex-rbs">
                  <ChecklistFilter
                    availableLevels={availableLevels}
                    denverDevelopmentalDomains={denverDevelopmentalDomains}
                    currentDevelopmentalDomainFilter={currentDevelopmentalDomainFilter}
                    setCurrentDevelopmentalDomainFilter={setCurrentDevelopmentalDomainFilter}
                    currentLevel={currentLevel}
                    setCurrentLevel={setCurrentLevel}
                  />
                </div>
                <div>
                  {!loadingErrorMessage && busy && <Loading loadingText={t('Fetching Denver Checklist')} />}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t('Skill')}</th>
                        <th className="text-center">{t('table.Actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loadingErrorMessage &&
                        !busy &&
                        checklistItems.filter(filterFuse).map((item, idx) => (
                          <tr key={item.id} className="actionable">
                            <th>{idx + 1}</th>
                            <td>
                              <a href={`/denver/${item.id}`} title={item.title}>
                                {item.title}
                              </a>
                            </td>
                            <td className="text-center">
                              <a href={`/denver/${item.id}`} title={t('table.Edit')}>
                                <i className="mdi mdi-pencil"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Checklist;
