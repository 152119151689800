import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

export default function ConsolidatedChecklistScoreProgress({ activeSeries }) {
  const { t } = useTranslation();

  const chartOptions = {
    title: {
      text: t('child-stats-page.total-score'),
    },
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    dataLabels: { enabled: true },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: activeSeries.categories.map((c) => moment(c).format(t('MM/DD/YYYY'))),
    },
    yaxis: {
      max: activeSeries.yMax,
      min: 0,
      labels: {
        formatter: function (val, _i) {
          return val.toFixed(0);
        },
      },
    },
  };

  return (
    <div className="container-fluid border rounded mb-2">
      <Chart
        options={chartOptions}
        type="line"
        series={[{ name: t('Score'), data: activeSeries.consolidatedSeries }]}
        height={350}
      />
    </div>
  );
}
