import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateChildIndividualizedPlanSkillStep } from '../../../../../services/individualizedPlan';

export default (achievementDateParam, onStepAchievedChange) => {
  const [achievementDate, setAchievementDate] = useState(achievementDateParam);
  const { t } = useTranslation();

  const toggleStepAchieved = (skillStep) => {
    skillStep.achievementDate = achievementDate ? '0' : new Date().toISOString();

    updateChildIndividualizedPlanSkillStep(
      skillStep.id,
      {
        achievementDate: skillStep.achievementDate,
      },
      (_, err) => {
        if (!err) {
          if (skillStep.achievementDate === '0') {
            setAchievementDate(null);
          } else {
            setAchievementDate(skillStep.achievementDate);
          }
        } else {
          window.Swal.fire({
            type: 'error',
            title: t('Error'),
            text: t('There was an error saving') + '!',
          });
        }
      },
    );
    if (onStepAchievedChange) {
      onStepAchievedChange(skillStep);
    }
  };

  return {
    achievementDate,
    toggleStepAchieved,
  };
};
