import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { getSkill, createSkill, updateSkill } from '../../services/denver';

export default () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [currentId, setCurrentId] = useState(id !== 'new' ? id : null);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState('');
  const [formSubmissionMessageType, setFormSubmissionMessageType] = useState('');

  const [planItem, setPlanItem] = useState();
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [developmentalDomain, setDevelopmentalDomain] = useState('');
  const [level, setLevel] = useState('');
  const [description, setDescription] = useState('');
  const [objectiveInstruction, setObjectiveInstruction] = useState('');
  const [assessmentSteps, setAssessmentSteps] = useState([]);

  useEffect(
    (_) => {
      setBusy(true);
      setLoadingErrorMessage('');
      getSkill(currentId, (result, err) => {
        if (!err) {
          setPlanItem(result);
          setTitle(result.title);
          setCode(result.code);
          setLevel(result.level);
          setDevelopmentalDomain(result.developmentalDomain);
          setDescription(result.description);
          setObjectiveInstruction(result.objectiveInstruction);
          setAssessmentSteps(result.assessmentSteps.sort((a, b) => a.order - b.order));
        } else {
          setLoadingErrorMessage(t('There was a problem fetching Denver Checklist Skill'));
        }
        setBusy(false);
      });
    },
    [t, currentId],
  );

  const save = () => {
    if (!currentId) {
      createSkill({ code, level, title, description, objectiveInstruction, developmentalDomain }, (result, err) => {
        if (!err) {
          setCurrentId(result.id);
          setFormSubmissionMessageType('success');
          setFormSubmissionMessage(t('Successfully registered Skill!'));
          setSubmitting(false);
        } else {
          setFormSubmissionMessageType('fail');
          setFormSubmissionMessage(t('Error registering this new Skill'));
        }
      });
    } else {
      updateSkill(
        { id: currentId, title, description, objectiveInstruction, developmentalDomain, level },
        (result, err) => {
          if (!err) {
            setFormSubmissionMessageType('success');
            setFormSubmissionMessage(t('Successfully updated Skill!'));
            setSubmitting(false);
          } else {
            setFormSubmissionMessageType('fail');
            setFormSubmissionMessage(t('Error updating this new Skill'));
          }
        },
      );
    }
  };

  return {
    id,
    code,
    developmentalDomain,
    level,
    title,
    setTitle,
    description,
    setDescription,
    objectiveInstruction,
    setObjectiveInstruction,
    assessmentSteps,
    loadingErrorMessage,
    busy,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    planItem,
    save,
  };
};
