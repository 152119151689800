import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { pdf } from '@react-pdf/renderer';

import { saveAs } from 'file-saver';

import { Templates } from '../../../components/templatesPDF/Templates';
import { graphQLRequest } from '../../../services/common';

export const useAnamnese = () => {
  const [anamnese, setAnamnese] = useState({});
  const [anamneses, setAnamneses] = useState([]);
  const { t } = useTranslation();
  const { id } = useParams();

  const childId = id;

  useEffect(() => {
    if (childId === 'new') return;
    getAnamneseList();
    getAnamneseForChild();
  }, []);

  const getAnamneseList = () => {
    const graphQL = {
      query: `
      {
        anamneses(where: {
          child: null
        }) {
          id
          nomeMae
          nomeCrianca
          dataEnvio
        }
      }
    `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error('error requesting anamnesesList: ', err);
        throw new Error('error requesting anamnesesList');
      } else {
        const resAnamneses = result.data.anamneses;
        resAnamneses.sort((a, b) => {
          if (a.nomeCrianca.toUpperCase() < b.nomeCrianca.toUpperCase()) return -1;
          if (a.nomeCrianca.toUpperCase() > b.nomeCrianca.toUpperCase()) return 1;
          return 0;
        });
        setAnamneses(resAnamneses);
      }
    });
  };

  const getAnamneseForChild = () => {
    const graphQL = {
      query: `
      {
        child(where: { id: "${childId}" }) {
          anamneses {
            id
            nomeMae
            nomeCrianca
          }
        }
      }
    `,
    };

    graphQLRequest(graphQL, (result, err) => {
      const resAnamnese = result?.data?.child?.anamneses;
      if (resAnamnese && resAnamnese.length === 0) {
        return;
      } else {
        setAnamnese(resAnamnese[0]);
      }

      if (err) {
        console.error('error requesting anamnese: ', err);
        throw new Error('error requesting anamnese');
      }
    });
  };

  const connectAnamneseWithChild = (anamneseId) => {
    const graphQL = {
      query: `
      mutation {
        updateAnamnese(
          where: { id: "${anamneseId}" }
          data: { child: {
            connect: {
              id: "${childId}"
            }
          } }
        ) {
          id
        }
      }      
      `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error('error connecting children with anamnese', err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('error-connecting-child-to-anamnese'),
        });
      } else {
        window.Swal.fire('Associado', `A anamnese foi associada`, 'success').then((result) =>
          result.value ? getAnamneseForChild() : null,
        );
      }
    });
  };
  const disconnectAnamneseFromChild = (anamneseId) => {
    const graphQL = {
      query: `
      mutation {
        updateAnamnese(
          where: { id: "${anamneseId}" }
          data: { child: { disconnect: true } }
        ) {
          id
        }
      }      
      `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error('error disconnecting anamnese', err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('error-disconnecting-anamnese'),
        });
      } else {
        window.Swal.fire('Desconectado', `A anamnese foi desconectada`, 'success').then((result) =>
          result.value ? window.location.reload() : null,
        );
      }
    });
  };

  const connectAnamneseHandle = (anamneseId, childName, anamneseChildName, anamneseData) => {
    window.Swal.fire({
      title: `Deseja associar a anamnese a criança?`,
      html: `Anamnese da criança <strong>${anamneseChildName}</strong> feita dia <strong>${anamneseData}</strong> com a criança <strong>${childName}</strong>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, associar',
      cancelButtonText: 'Não, cancelar',
    }).then((result) => {
      if (result.value) {
        connectAnamneseWithChild(anamneseId);
      }
    });
  };

  const saveAsPDF = async (anamnese, fileName) => {
    const doc = Templates.anamneseReport(anamnese);
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, fileName);
  };

  const downloadAnamneseHandle = (anamneseId) => {
    const graphQL = {
      query: `
      {
        anamnese(where: {
          id: "${anamneseId}"
        }) {
          id,
          updatedAt,
          dataEnvio,
          email,
          nomeCrianca,
          dataNascimentoCrianca,
          idadeCrianca,
          nomeMae,
          idadeMae,
          profissaoMae,
          nomePai,
          idadeDoPai,
          profissaoDoPai,
          endereco,
          telefone,
          idadeMaeNascimentoCrianca,
          gestacaoPlanejada,
          acompanhamentoPreNatal,
          maeMedicamentosDuranteGravidez,
          problemaSaudeDuranteGravidez,
          drogasDuranteAGravidez,
          frequenciaUsoDroga,
          partoMode,
          idadeGestacional,
          peso,
          posPartoTratamento,
          complicacoesParto,
          hospitalizadoMotivo,
          dificuldadeAmamentacao,
          aleitamentoMaternoExclusivo,
          periodoAleitamento,
          introducaoAlimentar,
          alimentacaoAtual,
          restricoesAlimentares,
          eletronicosNaAlimentacao,
          historicoMedicoCrianca,
          frequenciaHistoricoMedico,
          examesAuditivos,
          criancaMedicamento,
          profissionaisAcompanhamento,
          regrecaoDesenvolvimento,
          sustentouACabeca,
          sentou,
          engatinhou,
          andou,
          correu,
          utensiliosAlimentacao,
          controleXixiCoco,
          respondeAoSerChamado,
          comecouBalbuciar,
          primeirasPalavras,
          entendeFilho,
          obedeceVerbal,
          ecoliase,
          movimentoCorporalRepetitivoEmpolgado,
          expressaDesejos,
          principaisDificuldadeComunicacao,
          quemConviveDiariamente,
          relacionamentoFamiliares,
          relacionamentoCriancas,
          comportamentoFrustrado,
          reacaoDianteEstranhos,
          atividadePreferida,
          brinquedoPreferido,
          frequenciaUsoEletronicos,
          comportamentoPrimeiroAno,
          comportamentoCotidiano,
          idadeIrEscola,
          nomeDaEscola,
          horarioEscola,
          outrasAtividades,
          gostaDaEscola,
          histFamiliarNeuro,
          histFamiliarDificuldadeComunicacao,
          histFamiliarDificuldadeAprendizado,
          outrasInformacoes
        }
      }
      `,
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        console.error('error getting data from anamnese', err);
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('error-graphQL-request-donwloadAnamneseHandle'),
        });
      } else {
        const anamnese = result.data.anamnese;
        saveAsPDF(anamnese, anamnese.nomeCrianca);
      }
    });
  };

  return {
    anamnese,
    anamneses,
    connectAnamneseHandle,
    downloadAnamneseHandle,
    connectAnamneseWithChild,
    disconnectAnamneseFromChild,
  };
};
