import { createNewAccount, loginPasswordClick, changePasswordForm } from './auth';
import { graphQLRequest } from './common';

export const createAdmin = (name, email, password, responseCallback) => {
  const graphQL = {
    query: `
          mutation {
              createStaffMember (
                  data: {
                      active: true  
                      person: {
                          create: {
                              name: "${name}"
                              email: "${email}"
                          }
                      }
                  }
              ){
                  id
                  createdAt
                  updatedAt
              }
          }
      `,
    variables: {},
  };

  createNewAccount(name, email, password, (response) => {
    if (response.status == 201 || response.status == 250) {
      graphQLRequest(graphQL, (result, err) => {
        if (!err) {
          responseCallback(result);
        } else {
          responseCallback(null, err);
        }
      });
    } else {
      responseCallback(null, response);
    }
  });
};

export const updateAdmin = (id, name, email, password, phone, responseCallback) => {
  const graphQL = {
    query: `
			  mutation {
				  updatePerson (
					  data: {
							phone: "${phone}"
							name: "${name}"
					  }
					  where: {
						  id: "${id}"
					  }
				  ){
					  id
					  createdAt
					  updatedAt
				  }
			  }
		  `,
    variables: {},
  };

  if (password) {
    changePasswordForm(email, password, (response, err) => {
      if (response.status !== 200) {
        responseCallback(null, response);
      } else {
        graphQLRequest(graphQL, responseCallback);
      }
    });
  } else {
    graphQLRequest(graphQL, responseCallback);
  }
};

export const getAdmin = (email, password, responseCallback) => {
  const graphQL = {
    query: `
		{
            staffMembers(
              where: { 
                person:{email:"""${email}"""} 
              }
            ) {
                id
                active
                person {
                  id
				  email
                }
                profession {
                  id
                }
                mig__personId                          
            }
        } 
      `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, resultToken, err) => {
    if (!err && result && result.data && result.data.staffMembers && result.data.staffMembers.length > 0) {
      loginPasswordClick(email, password, (resultToken, err) => {
        if (err) {
          responseCallback(null, null, err);
        } else {
          responseCallback(result.data.staffMembers[0], resultToken, null);
        }
      });
    } else {
      responseCallback(null, null, 'admin not found');
    }
  });
};

export const getAdminForm = (email, responseCallback) => {
  const graphQL = {
    query: `
    {
          persons(
            where:{
                email: """${email}"""
            }) 
		{  
			id
			email
			name
			gender
			dateOfBirth
			phone
        }
    }
      `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err && result.data.persons && result.data.persons.length > 0) {
      responseCallback(result.data.persons[0], null);
    } else {
      responseCallback(null, 'admin not found');
    }
  });
};
