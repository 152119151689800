import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createAssessmentStep, removeAssessmentStep, updateAssessmentStep } from '../../services/denver';
import { FormSubmissionMessage } from '../FormSubmissionMessage';

export const EditableSteps = ({ idSkill, assessmentStep, saveStep, deleteStep, onStepAdded, onStepRemoved }) => {
  const { t } = useTranslation();

  const id = assessmentStep ? assessmentStep.id : null;
  const [deleted, setDeleted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState('');
  const [formSubmissionMessageType, setFormSubmissionMessageType] = useState(null);

  const [order] = useState(assessmentStep ? assessmentStep.order : 0);
  const [code] = useState(assessmentStep.code);
  const [description, setDescription] = useState(assessmentStep ? assessmentStep.description : '');
  const [achievementMininumAttempts, setAchievementMininumAttempts] = useState(
    assessmentStep ? assessmentStep.achievementMininumAttempts : null,
  );
  const [achievementMinimumScore, setAchievementMinimumScore] = useState(
    assessmentStep ? assessmentStep.achievementMinimumScore : null,
  );

  useEffect(() => {
    let autoSave = null;
    if (!id) return; //disable autosave when creating
    if (description && achievementMinimumScore && achievementMininumAttempts) {
      autoSave = setTimeout((_) => save(), 1000);
    }
    return () => clearTimeout(autoSave);
  }, [description, achievementMinimumScore, achievementMininumAttempts]);

  const save = () => {
    if (!id) {
      setSubmitting(true);

      const creationCallback = (result, err) => {
        if (!err) {
          setFormSubmissionMessageType('success');
          setFormSubmissionMessage(t('Successfully added Step!'));
          //resetar o formulario
          setDeleted(false);
          setTimeout((_) => {
            if (onStepAdded) {
              onStepAdded(
                Object.assign(
                  {},
                  {
                    code,
                    description,
                    order,
                    achievementMinimumScore,
                    achievementMininumAttempts,
                  },
                  result,
                ),
              );
            }
          }, 400);
        } else {
          setFormSubmissionMessageType('fail');
          setFormSubmissionMessage(t('Error adding this new step'));
        }
        setSubmitting(false);
      };

      if (saveStep) {
        saveStep(
          {
            stepId: null,
            idSkill,
            code,
            description,
            order,
            achievementMininumAttempts,
            achievementMinimumScore,
          },
          creationCallback,
        );
      } else {
        createAssessmentStep(
          idSkill,
          code,
          description,
          order,
          achievementMininumAttempts,
          achievementMinimumScore,
          creationCallback,
        );
      }
    } else {
      //update
      setSubmitting(true);

      const updateCallback = (result, err) => {
        setSubmitting(false);
        if (!err) {
          setFormSubmissionMessageType('success');
        } else {
          setFormSubmissionMessageType('error');
        }
        setTimeout((_) => {
          setFormSubmissionMessageType(null);
        }, 2000);
      };

      if (saveStep) {
        saveStep(
          {
            stepId: id,
            idSkill,
            code,
            description,
            order,
            achievementMininumAttempts,
            achievementMinimumScore,
          },
          updateCallback,
        );
      } else {
        updateAssessmentStep(id, description, achievementMininumAttempts, achievementMinimumScore, updateCallback);
      }
    }
  };

  const remove = () => {
    const deleteCallback = (_, err) => {
      if (!err) {
        setFormSubmissionMessageType('success');
        setDeleted(true);
        if (onStepRemoved) {
          onStepRemoved(id);
        }
      } else {
        console.error(err);
        setFormSubmissionMessageType('fail');
        setFormSubmissionMessage(t('Error removing this step'));
      }
    };
    if (deleteStep) {
      deleteStep(id, deleteCallback);
    } else {
      removeAssessmentStep(id, deleteCallback);
    }
  };

  if (deleted) {
    return <></>;
  }

  return (
    <>
      <form className="form-group">
        <div className="bg-light p-1">
          <FormSubmissionMessage message={formSubmissionMessage} type={formSubmissionMessageType} />
          <input
            defaultValue={description}
            required
            type="text"
            onChange={(e) => setDescription(e.target.value)}
            className="form-control"
            autoComplete="off"
            placeholder={t('placeholder.Look at a favorite object without support')}
          />
          <div className="mt-2 pl-3 d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
              {t('minimum of')}{' '}
              <input
                required
                style={{ width: '30px', textAlign: 'center' }}
                className="ml-1 mr-1"
                onChange={(e) => setAchievementMininumAttempts(e.target.value)}
                defaultValue={achievementMininumAttempts}
              />{' '}
              {t('opportunities yielding at least')}
              <input
                required
                style={{ width: '50px', textAlign: 'center' }}
                className="ml-1 mr-1"
                onChange={(e) => setAchievementMinimumScore(e.target.value / 100)}
                defaultValue={achievementMinimumScore ? achievementMinimumScore * 100 : null}
              />
              % {t('of success')}
              {achievementMininumAttempts && achievementMinimumScore && (
                <span className="ml-2" style={{ color: '#AAA' }}>
                  ({achievementMininumAttempts}/{Math.floor(achievementMininumAttempts / achievementMinimumScore)})
                </span>
              )}
            </div>
            <div className="d-flex flex-row">
              {id && !submitting && !formSubmissionMessageType && (
                <div>
                  <i
                    onClick={(_) => save()}
                    className="mdi mdi-content-save ml-1 actionable"
                    title={t('Save changes')}
                  ></i>
                </div>
              )}
              {id && !submitting && formSubmissionMessageType === 'success' && (
                <div className="blink-1">
                  <i className="mdi mdi-check text-success ml-1" title={t('Save changes')}></i>
                </div>
              )}
              {id && !submitting && formSubmissionMessageType === 'error' && (
                <div className="blink-1">
                  <i className="mdi mdi-window-close text-danger ml-1" title={t('Save changes')}></i>
                </div>
              )}
              {id && submitting && (
                <div className="pulsate-bck">
                  <i className="mdi mdi-repeat text-info ml-1 actionable" title={t('Save changes')}></i>
                </div>
              )}

              {id && <i onClick={(_) => remove()} className="mdi mdi-delete ml-1 actionable" title={t('Remove')}></i>}
            </div>
          </div>
          {!id && (
            <div className="m-3">
              {!submitting && (
                <button
                  type="submit"
                  onClick={(_) => save()}
                  className="btn btn-primary mr-2"
                  disabled={!description || !achievementMininumAttempts || !achievementMinimumScore}
                  title={t('detail-assessment-step-button')}
                >
                  {t('Save')}
                </button>
              )}
              {submitting && (
                <button type="submit" disabled className="btn btn-primary mr-2">
                  <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                  {t('Submitting') + '...'}
                </button>
              )}
            </div>
          )}
        </div>
      </form>
    </>
  );
};
