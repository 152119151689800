import React from 'react';
import { useTranslation } from 'react-i18next';

import { getNumberFromSkillCode } from '../../../../utils/legacy';
import { useChildIndividualizedPlanContext } from '../../childIndividualizedPlan/ChildIndividualizedPlanContext';
import SummaryItemModel from './SummaryItemModel';

export const SummaryItem = ({ item, itemAdded, itemCustomized, setPlanItems }) => {
  const { t } = useTranslation();
  const { isLoading, addSkillToPlan, removeSkillFromPlan, removeCustomizedSkillFromPlan } =
    SummaryItemModel(setPlanItems);
  const { customizeDenverSkill, planData, setEditStep } = useChildIndividualizedPlanContext();
  const { individualizedPlan, customPlanItems } = planData;

  const handleRemove = async (item) => {
    if (!itemAdded && !itemCustomized) {
      // Adicionar novo item
      await addSkillToPlan(item);
    } else if (!itemAdded && itemCustomized) {
      // Remover objetivo customizado
      const swalWithBootstrapButtons = window.Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: t('Are you sure?'),
          text: t('remove-custom-objective-confirm-dialog'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'mr-2',
          confirmButtonText: t('Confirm'),
          cancelButtonText: t('Cancel'),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            removeCustomizedSkillFromPlan(item);
          }
        });
    } else {
      // Remover objetivo não customizado
      removeSkillFromPlan(item);
    }
  };

  const handleCustomize = (item) => {
    // Objetivo ainda não foi adicionado
    if (!itemAdded && !itemCustomized) customizeDenverSkill(individualizedPlan, item);
    // Objetivo já adicionado mas não customizado
    if (itemAdded && !itemCustomized) {
      removeSkillFromPlan(item);
      customizeDenverSkill(individualizedPlan, item);
    }
    // Objetivo já customizado
    if (!itemAdded && itemCustomized) {
      const goToItem = customPlanItems.filter((cpi) => cpi.skill.skillFrom?.id === item.id);
      setEditStep(3);
      setTimeout(() => {
        const element = window.document.getElementById('addObjectiveForm_CustomSkill-' + goToItem[0].id);
        var topPos = element.getBoundingClientRect().top + window.scrollY - 60;
        window.scrollTo({
          top: topPos,
          behavior: 'smooth',
        });
      }, 300);
    }
  };

  let score = itemAdded || itemCustomized ? <i className="mdi mdi-check text-white" /> : <span />;

  let bg = 'bg-secondary';
  if (item.score === 'PASS') {
    bg = 'bg-success';
  } else if (item.score === 'PASS_FAIL') {
    bg = 'bg-warning';
  }
  if (item.score === 'FAIL') {
    bg = 'bg-danger';
  }

  return (
    <div className="row">
      <div className={`card col-11 rounded-0`}>
        <div className="card-body row">
          <div className="col-12">
            <h4
              className="card-title actionable mb-1"
              data-toggle="collapse"
              data-target={'#skillDetails-' + item.code}
              aria-expanded="false"
              aria-controls={'skillDetails-' + item.code}
            >
              {t('LEVEL_SINGLE_LETTER')}
              {item.level}.{getNumberFromSkillCode(item.code)}&nbsp;—&nbsp;{item.title}
            </h4>
            <div id={'skillDetails-' + item.code} className="collapse">
              <div className="actionable" style={{ color: '#888' }}>
                {item.description}
              </div>
              <div className="mt-1 p-2" style={{ background: '#F9F9F9' }}>
                {item.objectiveInstruction}
              </div>

              <div className="mt-4">
                {addSkillToPlan && (
                  <button
                    className={`btn ${itemAdded || itemCustomized ? 'btn-secondary' : 'btn-success'}`}
                    onClick={(_) => handleRemove(item)}
                    disabled={isLoading}
                  >
                    {!itemAdded && !itemCustomized ? t('Add to Plan') : t('Remove from Plan')}
                  </button>
                )}

                {/* Customize skill */}
                <button className="btn btn-outline-info ml-2" onClick={() => handleCustomize(item)}>
                  {itemCustomized ? t('Edit Customized Objective') : t('Customize Objective')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {item.score && (
        <button
          onClick={(_) => !isLoading && handleRemove(item)}
          disabled={isLoading}
          className={`card d-flex justify-content-center align-items-center ${bg} col-1 rounded-0 border-top border-light`}
          style={{ fontSize: '1.5rem', cursor: 'pointer' }}
        >
          {isLoading ? (
            <div className="spinner-border spinner-border-sm text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            score
          )}
        </button>
      )}
    </div>
  );
};
