import React from 'react';

import { pdf } from '@react-pdf/renderer';

import { saveAs } from 'file-saver';

import { Templates } from './templatesPDF/Templates';

export const LazyDownloadPDFButton = (props) => (
  <button
    className="btn btn-outline-info btn-icon ml-2"
    onClick={async () => {
      const doc = Templates[props.template](props);
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, props.saveNameButton);
    }}
  >
    <i className="mdi mdi-file-pdf"></i>
  </button>
);
