import React from 'react';

export const FormSubmissionMessage = ({ message, type }) => {
  return (
    <>
      {message && (
        <div className="form-group">
          {type === 'success' && (
            <div className="alert alert-icon-success flex-rss" role="alert">
              <i className="mdi mdi-check-circle-outline mr-1"></i>
              {message}
            </div>
          )}

          {type === 'fail' && (
            <div className="alert alert-icon-danger flex-rss" role="alert">
              <i className="mdi mdi-check-circle-outline mr-1"></i>
              {message}
            </div>
          )}
        </div>
      )}
    </>
  );
};
