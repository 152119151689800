import React, { useState, useEffect } from 'react';

import { getChildLastChecklist } from '../../services/checklistAssessment';
import ChecklistLevelStatsColumn from './ChecklistLevelStatsColumn';
import ChecklistLevelStatsRadial from './ChecklistLevelStatsRadial';

export function LastChecklistStatsLevel({ child, lastChecklistData, fetchChecklists, type = 'radial' }) {
  // This useState and useEffect are only to bypass new service, when rendering childrenDashboard page.
  const [checkListId, setCheckListId] = useState();

  useEffect(() => {
    if (fetchChecklists) {
      const fetchData = async () => {
        const lastCheckList = await getChildLastChecklist(child.id);
        if (lastCheckList) {
          setCheckListId(lastCheckList.id);
        }
      };
      fetchData();
    }
  }, [child, fetchChecklists]);

  if (!lastChecklistData && !fetchChecklists) {
    return <></>;
  }

  if (type === 'radial') {
    return (
      <ChecklistLevelStatsRadial
        childId={child.id}
        checklistId={checkListId}
        childDateOfBirth={child.person.dateOfBirth}
      />
    );
  }

  if (type === 'bar') {
    return <ChecklistLevelStatsColumn childId={child.id} lastChecklistData={lastChecklistData} />;
  }
}
