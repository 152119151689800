import stringHash from 'string-hash';

import config from '../config';

export const graphQLRequest = (graphQL, responseCallback, cached = false) =>
  new Promise((resolve, reject) => {
    const cacheKey = 'q--' + stringHash(JSON.stringify(graphQL));
    if (cached && window.localStorage.getItem(cacheKey)) {
      try {
        if (responseCallback) {
          responseCallback(JSON.parse(window.localStorage.getItem(cacheKey)));
        } else {
          resolve(JSON.parse(window.localStorage.getItem(cacheKey)));
        }
      } catch (error) {
        window.localStorage.removeItem(cacheKey);
      }
    }
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append(
      'Authorization',
      'Bearer ' + JSON.parse(window.localStorage.getItem('token'))?.accessToken ?? 1,
    );

    const requestOptions = {
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify(graphQL).replace(/[\t\r\n\s]+|\\[nt]| +(?=\S)/g, ' '),
    };

    fetch(config.backendURL, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.errors) {
          console.error('Error requesting:', graphQL);
          if (responseCallback) {
            responseCallback(null, result.errors);
          } else {
            reject(result.errors);
          }
        } else {
          // if that's the first time, there is no cache so the cb must be invoked here
          if (!cached || !window.localStorage.getItem(cacheKey)) {
            if (responseCallback) {
              responseCallback(result);
            } else {
              resolve(result);
            }
          }
          if (cached) {
            try {
              window.localStorage.setItem(cacheKey, JSON.stringify(result));
            } catch (error) {
              window.localStorage.removeItem(cacheKey);
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error requesting graphQL:', graphQL);
        console.error('Error graphQL details:', error);

        if (responseCallback) {
          responseCallback(null, error);
        } else {
          reject(null, error);
        }
      });
  });

export const graphQLRequestCaching = (graphQL, responseCallback) => {
  graphQLRequest(graphQL, responseCallback, false);
};
