import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router';

import useUserContext from './useUserContext';

/**
 * Redireciona o usuario para a pagina
 * inicial caso ele não seja admin ou
 * não tenha permissão sobre a criança
 */

export const withChildrenPermission = (Component) => {
  return (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    const id = params.childId || params.id;
    const { loggedUser } = useUserContext();
    const admin = loggedUser?.role === 'admin';
    const child = loggedUser?.children ? loggedUser.children.some((child) => child === id) : false;

    useEffect(() => {
      if (id === 'new') {
        return;
      } else if (admin === false && child === false) {
        window.alert(t('No permission for this child'));
        history.push('/');
      }
    }, [admin, child]);

    return (
      <>
        <Component {...props} />
      </>
    );
  };
};
