import moment from 'moment';

import { graphQLRequest } from './common';
import { getTypicalLevelByAge } from './denver';

export const getChecklists = (childId, responseCallback) => {
  const graphQL = {
    query: `
            {
                childChecklistAssessments (
                    where:{
                        child:{
                            id:"${childId}"
                        }
                }) 
                {
                    id
                    date
                    createdBy {
                        id
                    }
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childChecklistAssessments.sort((a, b) => new Date(b.date) - new Date(a.date)));
  });
};

export const getChildChecklistAssessmentsFromArray = ({ childId, arrayIDs }, responseCallback) => {
  const arrayIds = arrayIDs
    .map(
      (ai) =>
        `{
      childChecklistAssessment: {
        id:  "${ai}"
        child:{
          id: "${childId}"
        }
      } 
    }`,
    )
    .join(',');

  const graphQL = {
    query: `
        {
            childChecklistAssessmentSkills (
                where:{
                  OR: [${arrayIds}]
                }
            ){
              id
              childChecklistAssessment {
                id
                date
                child {
                  id
                  person {
                    id
                    name
                    dateOfBirth
                  }
                }
              }
              skill {
                id
                code
                order
                level
                developmentalDomain {
                  id
                  code
                  title
                  order
                }
                title
                description
                objectiveInstruction
                
              }
              score
            }
          }
          
    `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(null, err);
    }
    const resultOrdered = result.data.childChecklistAssessmentSkills.sort((a, b) =>
      a.skill.code.localeCompare(b.skill.code),
    );
    return responseCallback(resultOrdered);
  });
};

export const childChecklistAssessmentProjection = `
      id
      childChecklistAssessment {
        id
        date
        child {
          id
          person {
            id
            name
            dateOfBirth
          }
        }
      }
      skill {
        id
        code
        order
        level
        developmentalDomain {
          id
          code
          title
          order
        }
        title
        description
        objectiveInstruction
        assessmentSteps {
          id
          code
          description
          order
          achievementMinimumScore
          achievementMininumAttempts
        }
        skillFrom{
          id
        }
      }
      score
`;

export const getChildChecklistAssessments = async (
  { childId, checklistId, developmentDomainList, levelList, calculateLevelByAge },
  responseCallback,
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let whereDevelopmentDomainFilter = '';

    let checklistIdFilter = '';
    if (checklistId) {
      checklistIdFilter = `id:"${checklistId}"`;
    }

    if (developmentDomainList && developmentDomainList.length > 0) {
      whereDevelopmentDomainFilter = `
                  developmentalDomain:{ 
                      code_in: [${developmentDomainList.map((d) => '"' + d + '"').join(',')}] 
                  }
          `;
    }

    let whereLevelFilter = '';

    if (levelList && levelList.length > 0) {
      whereLevelFilter = `level_in: [${levelList.map((l) => l).join(',')}]`;
    }

    if (calculateLevelByAge) {
      const auxQuery = {
        query: `{
          childChecklistAssessment(where: {id: "${checklistId}"}){
            date
          }
        }`,
        variables: {},
      };

      const levelFilter = await graphQLRequest(auxQuery);

      const childAgeInMonths = moment(levelFilter.data.childChecklistAssessment.date).diff(
        moment(calculateLevelByAge),
        'months',
      );

      const typicalLevelByAge = getTypicalLevelByAge(childAgeInMonths);

      whereLevelFilter = `level_in: [${Array(typicalLevelByAge)
        .fill(1)
        .map((i, j) => i + j)}]`;
    }

    let whereSkill = '';
    if (whereDevelopmentDomainFilter || whereLevelFilter) {
      whereSkill = `
              skill:{
                  ${whereLevelFilter}    
                  ${whereDevelopmentDomainFilter}
              }
          `;
    }

    const graphQL = {
      query: `
          {
              childChecklistAssessmentSkills (
                  where:{
                      AND:{
                          childChecklistAssessment: {
                              ${checklistIdFilter}
                              child: {
                                  id: "${childId}"
                              }
                          }
                          ${whereSkill}    
                      }
                  }
              ){
                ${childChecklistAssessmentProjection}
              }
            }
            
      `,
      variables: {},
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        if (responseCallback) {
          return responseCallback(null, err);
        }
        return reject(err);
      }
      const resultOrdered = result.data.childChecklistAssessmentSkills.sort((a, b) =>
        a.skill.code.localeCompare(b.skill.code),
      );
      if (responseCallback) {
        return responseCallback(resultOrdered);
      }
      return resolve(resultOrdered);
    });
  });
};

export const getChildChecklistAssessmentsByDomainWithLevels = async ({
  childId,
  checklistIDs,
  developmentDomainLevelList,
}) => {
  return new Promise((resolve, reject) => {
    if (!childId) {
      return reject('You must provide a the childId param');
    }
    if (!developmentDomainLevelList || developmentDomainLevelList.length === 0) {
      return reject('You must provide a the developmentDomainLevelList param');
    }

    let checklistIdFilter = '';
    if (checklistIDs) {
      checklistIdFilter =
        checklistIDs instanceof Array && checklistIDs.length > 1
          ? `id_in: [${checklistIDs.map((cd) => '"' + cd + '"').join(',')}]`
          : `id_in: [${'"' + checklistIDs + '"'}]`;
    }

    const ddAndLevelClause = developmentDomainLevelList
      .map(
        (ddl) => `
      {
        skill:{                          
          AND:[
            {
              developmentalDomain:{
                code: "${ddl.developmentalDomainCode}"
              }
            },
            { level_in:[${ddl.levels.join(',')}] }
          ]                            
        }
      }
    `,
      )
      .join(',');

    const whereDevelopmentDomainLevelFilter = `OR:[${ddAndLevelClause}]`;

    const graphQL = {
      query: `
          {
            childChecklistAssessmentSkills (
              where:{
                AND:[
                  {
                    childChecklistAssessment: {
                        ${checklistIdFilter}
                        child: {
                            id: "${childId}"
                        }
                    }
                  },
                  { ${whereDevelopmentDomainLevelFilter} }
                ]
              }
            ){
              ${childChecklistAssessmentProjection}
            }
          }            
      `,
      variables: {},
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        return reject(err);
      }
      const resultOrdered = result.data.childChecklistAssessmentSkills.sort((a, b) =>
        a.skill.code.localeCompare(b.skill.code),
      );
      return resolve(resultOrdered);
    });
  });
};

export const getLastChecklist = (
  // eslint-disable-next-line no-unused-vars
  { childId, individualizedPlan },
  responseCallback,
) => {
  const graphQL = {
    query: `
            {
                childChecklistAssessments (
                    where:{
                        child:{
                            id:"${childId}"
                        }
                    }
                    orderBy: date_DESC
                    first: 1
                ) 
                {
                    id
                    date
                    createdBy {
                        id
                    }
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    if (!result || result.length === 0) {
      return responseCallback(null, null);
    }
    return responseCallback(result.data.childChecklistAssessments[0]);
  });
};

export const getChildLastChecklist = (childId, lastAmount = 1) => {
  return new Promise((resolve, reject) => {
    const graphQL = {
      query: `
                    {
                        childChecklistAssessments (
                            where:{
                                child:{
                                    id:"${childId}"
                                }
                            }
                            orderBy: date_DESC
                            first: ${lastAmount}
                        ) 
                        {
                            id
                            date
                            createdBy {
                                id
                            }
                        }
                    }
                `,
      variables: {},
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        return reject(err);
      }
      if (!result || result.length === 0) {
        return resolve(null);
      }

      return resolve(
        lastAmount === 1 ? result.data.childChecklistAssessments[0] : result.data.childChecklistAssessments,
      );
    });
  });
};

export const getChecklistSkillsBasicInfoByLevel = async (checklistAssessmentId, level) => {
  const graphQl = {
    query: `{
      childChecklistAssessmentSkills(
        where: {
          AND: {
            childChecklistAssessment: { id: "${checklistAssessmentId}" }
            skill: { level: ${level} }
          }
        }
      ) {
        id
        skill {
          code
        }
      }
    }`,
    variables: {},
  };

  const { data } = await graphQLRequest(graphQl);
  return data.childChecklistAssessmentSkills;
};

export const getChecklistSkillsCodeAndScore = async (checklistAssessmentId) => {
  const graphQl = {
    query: `{
      childChecklistAssessmentSkills(
        where: { childChecklistAssessment: { id: "${checklistAssessmentId}" } }
      ) {
        skill {
          code
        }
        score
      }
    }`,
    variables: {},
  };

  const { data } = await graphQLRequest(graphQl);
  return data.childChecklistAssessmentSkills;
};

export const createCheckListAssessment = (checklistAssessment, responseCallback) => {
  let graphQL = {};

  if (!checklistAssessment.id) {
    //create

    graphQL = {
      query: `
            mutation {
                createChildChecklistAssessment (
                    data: {
                        date: """${checklistAssessment.date.toISOString()}"""
                        child: {
                            connect: {
                                id: "${checklistAssessment.child.id}"
                              }
                        }
                        createdBy: {
                            connect: {
                                id: """${checklistAssessment.createdBy.id}"""
                            }
                        }
                        mig__interventionId: """${
                          checklistAssessment.mig__interventionId ? checklistAssessment.mig__interventionId : '-1'
                        }"""
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
      variables: {},
    };
  } else {
    return responseCallback(null, new Error('Could not edit the main parameters of the Checklist assessment'));
  }

  graphQLRequest(graphQL, (result, err) => {
    // let arrChildChecklistAssessmentSkills = "[" + checklistAssessment.skills.map(s => {
    //     return `{
    //         skill: {
    //             connect: {
    //                 id: """${s.id}"""
    //             }
    //         }
    //         score: NOT_EVALUATED
    //     }
    //     `
    // }).join(",") + "]"

    // https://github.com/prisma/prisma1/issues/3300#issuecomment-430696015
    // mutation {
    //     executeRaw(
    //       query: "insert into \"default$default\".\"Address\" (\"address\", \"createdAt\", \"id\", \"updatedAt\") values ('00:01:cc:00:00:02', '2018-10-26T08:51:04.242Z', 'MDA6MDE6Y2M6MDA6MDA6MDIwMD', '2018-10-26T08:51:04.242Z')"
    //     )
    //   }
    responseCallback(result && result.data ? result.data.createChildChecklistAssessment : null, err);
  });
};

export const upsertChecklistAssessmentSkill = (
  checklistAssessmentSkillId,
  checklistAssessmentId,
  skillCode,
  score,
  responseCallback,
) => {
  let queryString = `
        mutation {
            updateChildChecklistAssessmentSkill (
                data: {
                    childChecklistAssessment: {

                    }
                    score: ${score}
                }
                where: {
                    id: """${checklistAssessmentSkillId}"""
                }
            ){
                id
                createdAt
                updatedAt
            }
        }
    `;
  if (!checklistAssessmentSkillId) {
    queryString = `
            mutation {
                createChildChecklistAssessmentSkill (
                    data: {
                        childChecklistAssessment: {
                            connect: {
                                id: """${checklistAssessmentId}"""
                            } 
                        }
                        skill: {
                            connect: {
                                code: """${skillCode}"""
                            }
                        }
                        score: ${score}
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `;
  }
  const graphQL = {
    query: queryString,
    variables: {},
  };
  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(null, err);
    } else if (result.data.updateChildChecklistAssessmentSkill) {
      return responseCallback(result.data.updateChildChecklistAssessmentSkill);
    } else {
      return responseCallback(result.data.createChildChecklistAssessmentSkill);
    }
  });
};

export const deleteChildChecklistAssessment = (checklistAssessment, responseCallback) => {
  const graphQL = {
    query: `
                mutation {                
                    deleteChildChecklistAssessment(
                        where:{
                            id: """${checklistAssessment.id}"""
                        }
                    )
                    {
                        id
                    }                  
                }
            `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const updateChildChecklistAssessment = (id, date, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                updateChildChecklistAssessment(
                    data: {
                        date: "${date}"  
                    }
                    where: {
                        id: "${id}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};
