import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation, Trans } from 'react-i18next';

function ChecklistLevelStatsColumn({ childId, lastChecklistData }) {
  const { t } = useTranslation();

  if (lastChecklistData.checklistState === 'EMPTY') {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        <Trans i18nKey="ZZLongTexts.EmptyChecklistsOnStats">
          No items evaluated in the last checklist.
          <a href={`/children/${childId}/checklist/${lastChecklistData.id}`} target="_blank" rel="noopener noreferrer">
            Click here to view the checklist
          </a>
        </Trans>
      </div>
    );
  }

  if (!lastChecklistData.levelsDevelopmentRatios) {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        {t('Loading')}
      </div>
    );
  }

  const options = {
    chart: {
      animations: { speed: 400 },
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '85%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: lastChecklistData.levelsDevelopmentRatios.map((_, idx) => t('Level') + ' ' + (idx + 1)),
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      show: false,
      max: 100,
    },
  };

  return (
    <Chart
      options={options}
      type="bar"
      series={[
        {
          data: lastChecklistData.levelsDevelopmentRatios.map((val) => (val * 100).toFixed(0)),
          name: t('Development Score'),
        },
      ]}
      height={350}
    />
  );
}

export default ChecklistLevelStatsColumn;
