import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Fuse from 'fuse.js';

import AccessControl from '../../AccessControl';
import { Loading } from '../../components';
import { StaffChildrenRelated } from './StaffChildrenRelated.jsx';
import useStaffMembersModel from './useStaffMembersModel';

function StaffMembers() {
  const { t } = useTranslation();
  const { busy, loadingErrorMessage, staffList, delet } = useStaffMembersModel();

  const loggedUserData = JSON.parse(window.localStorage.getItem('loggedUserData'));
  const { id, role } = loggedUserData;

  // Search
  const [query, updateQuery] = useState('');
  const fuse = new Fuse(staffList, {
    // minMatchCharLength: 3,
    keys: ['person.name'],
    distance: 100,
    threshold: 0.3,
  });
  function onSearch({ currentTarget }) {
    updateQuery(currentTarget.value);
  }
  const results = fuse.search(query);
  const filterFuse = (el) => {
    if (results.length > 0) {
      for (let rs of results) {
        if (rs.item.id === el.id) return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <nav className="navbar">
        <span className="sidebar-toggler">
          <i data-feather="menu"></i>
        </span>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search"></i>
                </div>
              </div>
              <input
                id="search"
                name="search"
                value={query}
                onChange={onSearch}
                required
                type="text"
                className="form-control form-control-sm"
                autoComplete="off"
                placeholder={t('Search here')}
              />
            </div>
          </form>
          <AccessControl requiredPermissions={['createStaffMember']}>
            <div className="flex-ccc">
              <a href="/staff/new">
                <button type="button" className="btn btn-outline-primary">
                  {t('Register new Staff Member')}
                </button>
              </a>
            </div>
          </AccessControl>
        </div>
      </nav>

      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/staff/">
                {t('Sidebar.StaffMembers')} ({staffList.length})
              </a>
            </li>
          </ol>
        </nav>

        <div className="row">
          {/* <div className={"col-md-12 grid-margin " + (!busy && !loadingErrorMessage ? "card-columns" : "")}> */}

          {loadingErrorMessage && (
            <div className="col-md-12 grid-margin">
              <div className="col-12 alert alert-primary" role="alert">
                {loadingErrorMessage}
              </div>
            </div>
          )}
          {!loadingErrorMessage && busy && (
            <div className="col-md-12 grid-margin">
              <Loading loadingText={t('Fetching staff members')} />
            </div>
          )}
          {!loadingErrorMessage &&
            !busy &&
            staffList.filter(filterFuse).map((st) => (
              <div className="col-12 col-md-6 col-lg-4 grid-margin" key={st.id}>
                <div className="card" style={{ height: '22rem' }}>
                  <div className="card-body">
                    <div className="card-title-group">
                      <h4 className="card-title actionable">
                        {st.active && (
                          <a href={`/staff/${st.id}`} style={{ color: 'black' }}>
                            {st.person.name}
                          </a>
                        )}
                        {!st.active && (
                          <a href={`/staff/${st.id}`} style={{ color: 'red' }}>
                            {st.person.name}
                          </a>
                        )}
                        <i
                          className={
                            'mdi mdi-human-' + (st.person.gender === 'FEMALE' ? 'female' : 'male') + ' side-icon'
                          }
                        ></i>
                      </h4>
                    </div>
                    {st.id === id || role === 'admin' ? <StaffChildrenRelated id={st.id} showAmmount={4} /> : null}
                    <div className="card-internal-group">
                      <h6 className="card-internal-group-title">{t('About')}</h6>
                      {st.profession && <p className="card-text">{t(st.profession.title)}</p>}
                      <p className="card-text">{st.person.phone} </p>
                      <p className="card-text">{st.person.email}</p>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between">
                    <div className="flex-csc">
                      <a href={`/staff/${st.id}`} className="btn btn-primary">
                        {t('View profile')}
                      </a>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="card-text">
                        {/* <small className="text-muted">{t("Last intervention session 3 weeks ago")} há 3 dias atrás // Last access 3 weeks ago</small> */}
                      </p>

                      <AccessControl requiredPermissions={['deleteStaffMember:*', 'deleteStaffMember:' + st.id]}>
                        <div>
                          <i
                            title={t('Delete')}
                            onClick={delet}
                            data-id={st.id}
                            data-name={st.person.name}
                            data-email={st.person.email}
                            className={'mdi mdi-delete side-icon text-danger actionable'}
                          ></i>
                        </div>
                      </AccessControl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default StaffMembers;
