import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  getRelativeRelationships,
  createRelative,
  getRelatives,
  getRelative,
  updateRelativeInfo,
  removeChildRelative,
} from '../../../../services/children';

export default () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [currentRelativeId, setCurrentRelativeId] = useState(null);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('NOT_INFORMED');
  const [relationship, setRelationship] = useState({ id: '', title: '' });
  const [familyRelationshipsTypes, setFamilyRelationshipsTypes] = useState([]);

  const [familyMembers, setFamilyMembers] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState('');
  const [formSubmissionMessageType, setFormSubmissionMessageType] = useState('');

  useEffect(() => {
    getRelativeRelationships((relativeRelationships) => {
      setFamilyRelationshipsTypes(relativeRelationships);
      setRelationship(relativeRelationships[0]);
    });

    getRelatives(id, (relatives, err) => {
      if (!err) {
        setFamilyMembers(relatives);
      } else {
        console.error(err);
      }
    });
  }, [id]);

  useEffect(() => {
    if (currentRelativeId) {
      getRelative(currentRelativeId, (res) => {
        setName(res.person.name);
        setPhone(res.person.phone);
        setEmail(res.person.email);
        setGender(res.person.gender);
        setRelationship(res.relationship);
      });
    } else {
      setName('');
      setPhone('');
      setEmail('');
      setGender('NOT_INFORMED');
      setRelationship({ id: '', title: '' });
    }
  }, [t, currentRelativeId]);

  const save = () => {
    setSubmitting(true);

    if (!currentRelativeId) {
      //create

      createRelative(id, name, password, phone, email, gender, relationship, (result, err) => {
        if (!err) {
          setCurrentRelativeId(result.id);
          setFormSubmissionMessageType('success');
          setFormSubmissionMessage(t('Successfully registered family member'));

          // refresh
          getRelatives(
            id,
            (relatives) => setFamilyMembers(relatives),
            (err) => console.error(err),
          );
          setSubmitting(false);
        } else {
          var errText = t('Error registering this new family member');
          if (err.status == 450) {
            errText = t('Error registering (Invalid name)');
          } else if (err.status == 455) {
            errText = t('Error registering (Invalid email)');
          } else if (err.status == 460) {
            errText = t('Error registering (Invalid password)');
          } else if (err.status == 465) {
            errText = t('Error registering (Email already exists)');
          }
          setFormSubmissionMessageType('fail');
          setFormSubmissionMessage(errText);
          setSubmitting(false);
        }
      });
    } else {
      //update

      updateRelativeInfo(currentRelativeId, name, password, phone, email, gender, relationship, (_, err) => {
        if (!err) {
          setFormSubmissionMessageType('success');
          setFormSubmissionMessage(t('Successfully updated family member information!'));
          // refresh
          getRelatives(
            id,
            (relatives) => setFamilyMembers(relatives),
            (err) => console.error(err),
          );
          setSubmitting(false);
        } else {
          setFormSubmissionMessageType('fail');
          setFormSubmissionMessage(t('Error updating this family member info'));
          setSubmitting(false);
        }
      });
    }
  };

  const removeRelative = (relativeId, email) => {
    removeChildRelative(relativeId, email, (_, err) => {
      if (!err) {
        setFormSubmissionMessageType('success');
        // refresh
        getRelatives(
          id,
          (relatives) => setFamilyMembers(relatives),
          (err) => console.error(err),
        );
        setCurrentRelativeId(null);
      } else {
        console.error(err);
        setFormSubmissionMessage(t('Error removing this family member'));
      }
    });
  };

  return {
    id,
    name,
    setName,
    password,
    setPassword,
    email,
    setEmail,
    phone,
    setPhone,
    gender,
    setGender,
    relationship,
    setRelationship,
    familyRelationshipsTypes,

    familyMembers,

    save,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    removeRelative,

    currentRelativeId,
    setCurrentRelativeId,
  };
};
