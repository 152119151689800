import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import AccessControl from '../../../AccessControl';
import { LazyDownloadDocButton, NewSessionButton, LastChecklistStatsLevel } from '../../../components';
import Navbar from '../../../partials/Navbar';
import { getIntervalInMonths } from '../../../utils/time';
import { withChildrenPermission } from '../../../withChildrenPermission';
import { ChildRegisterForm, ChildRelativesForm, ChildInterventionSessionsList } from '../components';
import { useAnamnese } from './useAnamnese';
import useChildDetailsModel from './useChildDetailsModel';

function ChildDetails() {
  const { t } = useTranslation();

  const {
    currentId,
    child,
    staffAssignments,
    checklistAssessments,
    individualizedPlans,
    interventionSessions,

    staffAttributions,
    setCurrentStaffAttribution,

    staffMembers,
    setCurrentStaffMember,

    assignStaff,
    unassignStaff,

    annotations,
    fixedAnnotations,
    unpinAnnotation,
  } = useChildDetailsModel();

  const { anamnese, anamneses, connectAnamneseHandle, downloadAnamneseHandle, disconnectAnamneseFromChild } =
    useAnamnese();

  useEffect((_) => {
    window.$('[data-toggle="tooltip]').tooltip();
  }, []);

  const swalWithBootstrapButtons = window.Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  const confirmStaffUnassignment = (staffAssignmentId) => {
    swalWithBootstrapButtons
      .fire({
        title: t('Are you sure?'),
        text: t('To unassign this staff from this child, click "Confirm"'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'mr-2',
        confirmButtonText: t('Confirm'),
        cancelButtonText: t('No, cancel'),
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          unassignStaff(staffAssignmentId, (err) => {
            if (!err) {
              swalWithBootstrapButtons.fire(t('Deleted!'), t('Staff successfully unassigned'), 'success');
            } else {
              swalWithBootstrapButtons.fire(t('Error'), t('Error unassigning staff from this child'), 'error');
            }
          });
        }
      });
  };

  const unlinkAnamnese = (anamneseId) => {
    swalWithBootstrapButtons
      .fire({
        title: t('Are you sure?'),
        text: t('To unassign this anamnesis from this child, click "Confirm"'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'mr-2',
        confirmButtonText: t('Confirm'),
        cancelButtonText: t('No, cancel'),
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          disconnectAnamneseFromChild(anamneseId);
        }
      });
  };

  return (
    <>
      <Navbar />

      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('Therapeutic Work')}</li>
            <li className="breadcrumb-item">
              <a href="/children">{t('Sidebar.Children')}</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!currentId && <a href="/children/new">{t('New Child')}</a>}
              {currentId && child && (
                <a href={`/children/${currentId}`}>
                  {child.person.name} ({getIntervalInMonths(child.person.dateOfBirth, new Date())} {t('months')})
                </a>
              )}
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="container-fluid p-0 m-0">
              <div className="col-sm-12 col-md-12 p-0 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">{t('highlighted-annotations')}</h6>
                    {fixedAnnotations.length > 0 ? (
                      fixedAnnotations.map((annotation, index) => (
                        <div className="card mt-1 child-annotation ql-editor" key={index}>
                          <button
                            className="btn btn-primary btn-icon justify-content-end ml-auto mt-1 mb-0 mr-1"
                            onClick={() => unpinAnnotation(annotation)}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={t('unpin-annotation')}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 256 256"
                              height="20"
                              width="20"
                              fill="currentColor"
                              color="white"
                            >
                              <path d="M53.91992,34.61865a8.00027,8.00027,0,0,0-11.83984,10.7627l31.459,34.605c-12.05029-.32324-27.7832,2.78711-43.96,15.8374a16.01339,16.01339,0,0,0-1.2832,23.78613L76.686,168,42.34277,202.34326a8.00018,8.00018,0,0,0,11.31446,11.31348L88,179.314l48.19238,48.1919a16.0039,16.0039,0,0,0,11.31934,4.69141q.561,0,1.124-.03956a15.96882,15.96882,0,0,0,11.64649-6.33056c5.48828-7.29541,12.91992-19.33838,15.37451-33.51123l26.42334,29.06543a8.00027,8.00027,0,0,0,11.83984-10.7627Zm93.58692,181.57373L39.625,108.27686c25.041-20.20215,47.8877-9.55909,48.79688-9.12159a7.95965,7.95965,0,0,0,3.28955.82032l67.71484,74.48632C163.707,191.23291,153.8833,207.71973,147.50684,216.19238ZM236.68652,96a15.895,15.895,0,0,1-4.6875,11.31445L190.5957,148.71826a8.00035,8.00035,0,1,1-11.31445-11.314L220.68555,96,160,35.31348,122.02441,73.28955A8.00018,8.00018,0,0,1,110.71,61.97607L148.68555,24a16.02162,16.02162,0,0,1,22.62793-.001L232,84.68652A15.89427,15.89427,0,0,1,236.68652,96Z"></path>
                            </svg>
                          </button>
                          <blockquote>
                            <div
                              className="p-3 mb-0"
                              dangerouslySetInnerHTML={{ __html: annotation.anottation.replace(/\n/g, '<br />') }}
                            ></div>

                            <footer className="blockquote-footer ml-3">
                              <strong>
                                {t('Annotation date')}
                                {': '}
                              </strong>
                              <span>
                                {moment(annotation.annotationDate ?? annotation.createdAt).format('DD/MM/YYYY')}
                                {' | '}
                              </span>
                              {t('created-by')}{' '}
                              <strong style={{ color: 'black' }}> {annotation.attendant.person.name}</strong> {t('to')}{' '}
                              <strong style={{ color: 'black' }}>{t(annotation.visibility)}</strong>
                            </footer>
                          </blockquote>
                        </div>
                      ))
                    ) : (
                      <div className="card mt-1">
                        <blockquote>
                          <div className="p-3">{t('no-fixed-annotation')}</div>
                        </blockquote>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 p-0">
                <div className="card">
                  <div className="card-body">
                    <div className="pb-2">
                      <ChildRegisterForm />
                    </div>
                    <div className="mt-5">{currentId && <ChildRelativesForm />}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {currentId && (
            <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">{t('Intervention Process Status')}</h6>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    {checklistAssessments && checklistAssessments.length > 0 && (
                      <LastChecklistStatsLevel child={child} fetchChecklists />
                    )}
                    {(!checklistAssessments || checklistAssessments.length === 0) && (
                      <div style={{ minHeight: '300px' }}></div>
                    )}
                    <a href={`/stats/${currentId}`} className="btn btn-outline-primary">
                      {t('View full report')}
                    </a>
                  </div>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:childChecklistAssessment',
                      'child:' + currentId + ':childChecklistAssessment',
                    ]}
                  >
                    <div className="form-group">
                      <div className="mt-3 mb-2">{t('Choose a checklist assessment above to view')}:</div>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {checklistAssessments.length} {t('Checklist Assessments')}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {checklistAssessments.map((ca, idx) => (
                          <a
                            key={ca.id}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="dropdown-item"
                            href={`/children/${currentId}/checklist/${ca.id}`}
                          >
                            {moment(ca.date).format(t('MM/DD/YYYY'))} {idx === 0 && '(' + t('current') + ')'}
                          </a>
                        ))}
                      </div>
                    </div>
                  </AccessControl>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:childChildIndividualizedPlan',
                      'child:' + currentId + ':childChildIndividualizedPlan',
                    ]}
                  >
                    <div className="form-group">
                      <div className="mt-3 mb-2">{t('Choose an individualized plan above to view')}:</div>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {individualizedPlans.length} {t('Individualized Plans')}
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {individualizedPlans.map((ip, idx) => (
                          <a
                            key={ip.id}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="dropdown-item"
                            href={`/children/${currentId}/plan/${ip.id}`}
                          >
                            {moment(ip.date).format(t('MM/DD/YYYY'))} {idx === 0 && '(' + t('current') + ')'}
                          </a>
                        ))}
                      </div>
                    </div>
                  </AccessControl>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:childIndividualizedPlanSession',
                      'child:' + currentId + ':childIndividualizedPlanSession',
                    ]}
                  >
                    <div className="form-group">
                      <div className="mt-3 mb-2">{t('See collected anamnesis')}:</div>
                      {anamnese?.id ? (
                        <div style={{ gap: '0.5rem' }} className="d-flex">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => downloadAnamneseHandle(anamnese.id)}
                          >
                            {t('Download anamnesis')}
                          </button>
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => unlinkAnamnese(anamnese.id)}
                          >
                            -
                          </button>
                        </div>
                      ) : (
                        <>
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {t('Link anamnesis to children')}
                          </button>
                          <div
                            className="dropdown-menu shadow"
                            style={{ maxHeight: '400px', maxWidth: '90%', overflow: 'auto' }}
                            aria-labelledby="dropdownMenuButton"
                          >
                            {anamneses.length !== 0 ? (
                              anamneses.map((ana) => (
                                <div
                                  onClick={() =>
                                    connectAnamneseHandle(
                                      ana.id,
                                      child?.person?.name,
                                      ana.nomeCrianca,
                                      moment(new Date(ana.dataEnvio)).format(t('MM/DD/YYYY')),
                                    )
                                  }
                                  key={ana.id}
                                  className="dropdown-item cursor-pointer"
                                >
                                  <span className="text-capitalize font-weight-bold">
                                    {ana.nomeCrianca.toLowerCase()}
                                  </span>{' '}
                                  - {moment(new Date(ana.dataEnvio)).format(t('MM/DD/YYYY'))}
                                </div>
                              ))
                            ) : (
                              <div className="dropdown-item">{t('No anamnesis found')}</div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </AccessControl>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:childIndividualizedPlanSession',
                      'child:' + currentId + ':childIndividualizedPlanSession',
                    ]}
                  >
                    <div className="form-group">
                      <div className="mt-3 mb-2">{t('Choose an intervention session above to view')}:</div>
                      <button
                        className="btn btn-secondary d-flex align-items-center"
                        type="button"
                        data-toggle="collapse"
                        data-target="#interventionSessionCalendar"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        {interventionSessions.length} {t('Intervention Session')}
                        <i className="mdi mdi mdi-calendar ml-2" />
                      </button>
                      <div className="collapse mt-2" id="interventionSessionCalendar">
                        <ChildInterventionSessionsList
                          id="interventionSessionModal"
                          childId={currentId}
                          interventionSessions={interventionSessions}
                        />
                      </div>
                    </div>
                  </AccessControl>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:childIndividualizedPlanSession',
                      'child:' + currentId + ':childIndividualizedPlanSession',
                    ]}
                  >
                    <div className="form-group">
                      <div className="mt-3 mb-2">{t('View the annotations')}:</div>
                      <a href={`/children/${currentId}/comment/listAll`}>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          data-toggle="collapse"
                          data-target="#comment"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          {annotations.length} {t('Annotations')}
                        </button>
                      </a>
                    </div>
                  </AccessControl>

                  <AccessControl
                    requiredPermissions={[
                      'child:*:createChildIndividualizedPlanSession',
                      'child:' + currentId + ':createChildIndividualizedPlanSession',
                    ]}
                  >
                    <div className="form-group">
                      <div className="mt-3 mb-2">{t('Generate document about the child')}:</div>
                      <LazyDownloadDocButton
                        child={child}
                        checklistAssessments={checklistAssessments}
                        annotations={annotations}
                      />
                    </div>
                  </AccessControl>

                  <hr />
                  <AccessControl
                    requiredPermissions={[
                      'child:*:createChildChecklistAssessment',
                      'child:' + currentId + ':createChildChecklistAssessment',
                    ]}
                  >
                    <div className="form-group">
                      <a href={`/children/${currentId}/checklist/new`}>
                        <button type="button" className="btn btn-primary btn-icon-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-check-square btn-icon-prepend"
                          >
                            <polyline points="9 11 12 14 22 4"></polyline>
                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                          </svg>
                          {t('New checklist assessment')}
                        </button>
                      </a>
                    </div>
                  </AccessControl>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:createChildIndividualizedPlan',
                      'child:' + currentId + ':createChildIndividualizedPlan',
                    ]}
                  >
                    <div className="form-group">
                      <a href={`/children/${currentId}/plan/new`}>
                        <button type="button" className="btn btn-primary btn-icon-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-file-text btn-icon-prepend"
                          >
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="16" y1="13" x2="8" y2="13"></line>
                            <line x1="16" y1="17" x2="8" y2="17"></line>
                            <polyline points="10 9 9 9 8 9"></polyline>
                          </svg>
                          {t('New individualized plan')}
                        </button>
                      </a>
                    </div>
                  </AccessControl>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:createChildIndividualizedPlanSession',
                      'child:' + currentId + ':createChildIndividualizedPlanSession',
                    ]}
                  >
                    <div className="form-group">
                      <NewSessionButton childId={currentId} />
                    </div>
                  </AccessControl>
                  <AccessControl
                    requiredPermissions={[
                      'child:*:createChildIndividualizedPlanSession',
                      'child:' + currentId + ':createChildIndividualizedPlanSession',
                    ]}
                  >
                    <div className="form-group">
                      <a href={`/children/${currentId}/comment/new`}>
                        <button type="button" className="btn btn-primary btn-icon-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-briefcase btn-icon-prepend"
                          >
                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                          </svg>
                          {t('New annotation')}
                        </button>
                      </a>
                    </div>
                  </AccessControl>
                </div>
              </div>
            </div>
          )}

          {currentId && (
            <div className="col-md-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">{t('Staff Assignment')}</h6>

                  <ul className="carded-list mb-4 mt-3">
                    {staffAssignments.map((sa) => (
                      <li key={sa.id}>
                        <div className="carded-list-group">
                          {sa.staffAttribution.title === 'Main Supervisor' && <i className="mdi mdi-crown mr-1"></i>}
                          {sa.staffMember.person.name}
                          <span style={{ color: '#999' }} className="ml-1">
                            / {t(sa.staffAttribution.title)}
                          </span>
                          <AccessControl requiredPermissions={['updateChild:*', 'updateChild:' + currentId]}>
                            <i
                              onClick={(_) => confirmStaffUnassignment(sa.id)}
                              className="mdi mdi-delete ml-1 actionable"
                              title={t('Remove')}
                            ></i>
                          </AccessControl>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <AccessControl requiredPermissions={['updateChild:*', 'updateChild:' + currentId]}>
                    <div className="form-group">
                      <label htmlFor="personSelectDD">
                        <strong>{t('New staff assignment')}</strong>
                      </label>
                      <div>
                        <div className="btn-group">
                          <select
                            onChange={(event) => {
                              const { value } = event.target;
                              const current = staffMembers.find((smc) => smc.id === value);
                              setCurrentStaffMember(current);
                            }}
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="custom-select custom-select-sm mr-sm-2"
                          >
                            {staffMembers.map((sm) => (
                              <option value={sm.id} key={sm.id}>
                                {sm.person.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="btn-group">
                          <select
                            onChange={(event) => {
                              const { value } = event.target;
                              const currentAtribbution = staffAttributions.find((sac) => sac.id === value);
                              setCurrentStaffAttribution(currentAtribbution);
                            }}
                            id="staffAssignmentDD"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="custom-select custom-select-sm mr-sm-2"
                            aria-labelledby="staffAssignmentDD"
                          >
                            {staffAttributions.map((fr) => (
                              <option value={fr.id} key={fr.id} className="dropdown-item actionable">
                                {' '}
                                {t(fr.title)}
                              </option>
                            ))}
                          </select>
                        </div>
                        <button className="btn btn-primary ml-1" onClick={(_) => assignStaff()}>
                          {t('assign')}
                        </button>
                      </div>
                    </div>
                  </AccessControl>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withChildrenPermission(ChildDetails);
