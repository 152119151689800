import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import manuh from 'manuh';

import topics from '../../../../manuh-topics';
export default function SelectedObjectives({
  initialTotalObjectives = 0,
  initialSpecificObjectivesSelected = 0,
  initialDevelopmentalDomainCode = 'CRE',
  initialLevel = 1,
}) {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const [specificObjectivesSelected, setSpecificObjectivesSelected] = useState(initialSpecificObjectivesSelected);
  const [totalObjectivesSelected, setTotalObjectivesSelected] = useState(initialTotalObjectives);
  const [currentSelectedDevelopmentalDomainCode, setCurrentSelectedDevelopmentalDomainCode] =
    useState(initialDevelopmentalDomainCode);
  const [currentSelectedLevel, setCurrentSelectedLevel] = useState(initialLevel);

  manuh.subscribe(topics.components.interventionPlan.selectObjectives.toggle, 'SelectedObjectives', (msg) => {
    setTotalObjectivesSelected(msg.selectedPlanItems.length);
    const specificObjs = msg.selectedPlanItems.filter(
      (spi) =>
        spi.skill.developmentalDomain?.code === currentSelectedDevelopmentalDomainCode &&
        spi.skill.level === currentSelectedLevel,
    );
    setSpecificObjectivesSelected(specificObjs.length);
  });

  manuh.subscribe(topics.components.global.selectedDevelopmentalDomain, 'SelectedObjectives', (msg) => {
    setCurrentSelectedDevelopmentalDomainCode(msg.code);
  });

  manuh.subscribe(topics.components.global.selectedLevel, 'SelectedObjectives', (msg) => {
    setCurrentSelectedLevel(msg.level);
  });

  return (
    <div className="p-2 text-dark bg-light d-flex flex-column align-items-start justify-content-start">
      <div className="mb-1">
        <strong className="mr-1">{totalObjectivesSelected}</strong>
        <span>{t('total skills selected')}</span>
      </div>
      {/* <div>
        <strong className="mr-1 text-dark">{specificObjectivesSelected}</strong>
        <span>{t('skills for the current developmental domain')}</span>
      </div> */}
    </div>
  );
}
