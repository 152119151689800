import moment from 'moment';

import { graphQLRequest } from './common';

export const getInterventionSessions = (childId, responseCallback) => {
  const graphQL = {
    query: `
            {
                childIndividualizedPlanSessions (
                    orderBy:checkinAt_DESC
                    where:{
                      childIndividualizedPlan:{
                        child:{
                            id:"${childId}"
                        }
                      }
                    }
                  ) 
                {
                    id
                    checkinAt
                    childIndividualizedPlan {
                        id
                        child {
                            id
                            person {
                                name
                            }
                        }
                    }
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSessions);
  });
};

export const getLastInterventionSessions = (childId) => {
  return new Promise((resolve, reject) => {
    const graphQL = {
      query: `
            {
                childIndividualizedPlanSessions (
                    orderBy:checkinAt_DESC
					          first: 1
                    where:{
                      childIndividualizedPlan:{
                        child:{
                            id:"${childId}"
                        }
                      }
                    }
                  ) 
                {
                    id
                    checkinAt
					          mig__sessionNumber
                    childIndividualizedPlan {
                        id
                        child {
                            id
                            person {
                                name
                            }
                        }
                    }
                }
            }
        `,
      variables: {},
    };

    graphQLRequest(graphQL, (result, err) => {
      if (err) {
        return reject(err);
      }
      return resolve(result.data.childIndividualizedPlanSessions[0]);
    });
  });
};

export const createInterventionSession = (interventionSession, responseCallback) => {
  let graphQL = {};

  if (!interventionSession.id) {
    //create
    if (moment(interventionSession.checkinAt) > new Date())
      return responseCallback(null, new Error('You can not create a session in a future date'));

    const skillAssessmentStepScoreCreate = `{
            create: [
                ${interventionSession.individualizedPlan.skillSteps.map(
                  (ipss) => `{
                    childIndividualizedPlanSkillStep: {
                        connect: {
                            id: """${ipss.id}"""
                        }
                    }
                    positiveAchievement: 0
                    negativeAchievement: 0
                },`,
                )}
            ]
        }`;

    graphQL = {
      query: `
            mutation {
                createChildIndividualizedPlanSession (
                    data: {
                        checkinAt: """${interventionSession.checkinAt.toISOString()}"""
                        attendant: {
                            connect: {
                                id: """${interventionSession.attendant.id}"""
                            }
                        }
                        childIndividualizedPlan: {
                            connect: {
                                id: """${interventionSession.individualizedPlan.id}"""
                            }
                        }
                        skillAssessmentStepScore: ${skillAssessmentStepScoreCreate}

                        mig__sessionNumber: ${
                          interventionSession.mig__sessionNumber ? interventionSession.mig__sessionNumber : -1
                        }
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
      variables: {},
    };
  } else {
    return responseCallback(null, new Error('Could not edit the main parameters of the Plan'));
  }

  graphQLRequest(graphQL, (result, err) =>
    responseCallback(result && result.data ? result.data.createChildIndividualizedPlanSession : null, err),
  );
};

export const updateInterventionSession = (id, date, responseCallback) => {
  if (moment(date) > new Date())
    return responseCallback(null, new Error('You can not create a session in a future date'));

  const graphQL = {
    query: `
            mutation {
                updateChildIndividualizedPlanSession(
                    data: {
                        checkinAt: "${date}"  
                    }
                    where: {
                        id: "${id}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const getInterventionSession = (sessionId, responseCallback) => {
  const graphQL = {
    query: `        
            {
                childIndividualizedPlanSession(
                    where:{
                        id:"""${sessionId}"""
                    }
                ) 
                {
                    id
                    checkinAt
                    checkoutAt
                    lat
                    long
                    
                    attendant {
                        id
                        person {
                            id
                            name
                        }
                    }

                    childIndividualizedPlan {
                        id
                        date
                        child {
                            id
                            person {
                                name
                                dateOfBirth
                            }
                        }

                        skills {
                            id
                            skill {
                                id
                                title
                                code
                                order
                                description
                                objectiveInstruction
                                assessmentSteps {
                                    id
                                    order
                                    description
                                    achievementMininumAttempts
                                    achievementMinimumScore
                                }
                                developmentalDomain {
                                    id
                                    code
                                    order
                                    title
                                }
                                skillFrom{
                                  id
                                  order
                                }
                            }
                            objectiveInstruction
                        }
                        skillSteps {
                            id
                            code
                            order
                            description
                            achievementMininumAttempts
                            achievementMinimumScore    
                            childIndividualizedPlanSkill {
                              skill {
                                id
                                code
                                order
                                level  
                                title
                                description
                                objectiveInstruction
                                developmentalDomain {
                                    code
                                    order
                                    title
                                }
                                skillFrom{
                                  id
                                  order
                                }
                              }              
                            }
                            denverChecklistSkillAssessmentStep {
                                id
                                code
                                description
                                order
                                skill {
                                    id
                                    code
                                    order
                                    level  
                                    title
                                    description
                                    objectiveInstruction
                                    developmentalDomain {
                                        code
                                        order
                                        title
                                    }
                                    skillFrom{
                                      id
                                      order
                                    }
                                }
                            }
                        }                        
                    }
                    
                    skillAssessmentStepScore {
                        id
                        positiveAchievement
                        negativeAchievement
                        childIndividualizedPlanSkillStep {
                            id
                            code
                            order
                            description
                            achievementMininumAttempts
                            achievementMinimumScore    
                            childIndividualizedPlanSkill {
                              skill {
                                id
                                code
                                order
                                level  
                                title
                                description
                                objectiveInstruction
                                developmentalDomain {
                                    code
                                    order
                                    title
                                }
                                skillFrom{
                                  id
                                  order
                                }
                              }              
                            }                            
                            achievementDate
                            denverChecklistSkillAssessmentStep {
                                id
                                code
                                order
                                description
                                achievementMinimumScore
                                achievementMininumAttempts
                                skill {
                                    id
                                    code
                                    order
                                    level
                                    title
                                    developmentalDomain {
                                        id
                                        code
                                        order
                                        title
                                    }
                                    skillFrom{
                                      id
                                      order
                                    }
                                }
                            }
                        }
                    }                    
                }
            }                    
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSession);
  });
};

export const deleteInterventionSession = (interventionSession, responseCallback) => {
  //delete steps
  const graphQLSteps = {
    query: `
            mutation {
                deleteManyChildIndividualizedPlanSessionSkillAssessmentStepScores(
                where:{
                    childIndividualizedPlanSession:{
                    id:"""${interventionSession.id}"""
                    }
                }
                ) {
                    count
                }
            }
            `,
    variables: {},
  };

  graphQLRequest(graphQLSteps, (result, err) => {
    if (err) {
      return responseCallback(null, err);
    }
    if (!result) {
      return responseCallback(null, null);
    }
    const graphQL = {
      query: `
                    mutation {                
                        deleteChildIndividualizedPlanSession(
                            where:{
                                id: """${interventionSession.id}"""
                            }
                        )
                        {
                            id
                        }                  
                    }
                `,
      variables: {},
    };

    graphQLRequest(graphQL, responseCallback);
  });
};

export const getSkillAssessmentStepScore = (skillStepAssessmentId, responseCallback) => {
  const graphQL = {
    query: `
            {
                childIndividualizedPlanSessionSkillAssessmentStepScore(
                    where:{
                      id:"""${skillStepAssessmentId}"""
                    }
                ) 
                {
                    id
                    positiveAchievement
                    negativeAchievement               
                }
            } 
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSessionSkillAssessmentStepScore);
  });
};

export const getLastSkillAssessmentStepScores = (
  childIndividualizedPlanId,
  denverChecklistSkillAssessmentStepId,
  childIndividualizedPlanSessionCheckinAt,
  responseCallback,
) => {
  const graphQL = {
    query: `
			{
			  childIndividualizedPlanSessionSkillAssessmentStepScores(where:{
					childIndividualizedPlanSession:{
					checkinAt_gt:"""${childIndividualizedPlanSessionCheckinAt}"""
					childIndividualizedPlan:{
						id:"""${childIndividualizedPlanId}"""
					}
				}
				childIndividualizedPlanSkillStep:{
					denverChecklistSkillAssessmentStep:{
						id:"""${denverChecklistSkillAssessmentStepId}"""
					}
				}
			  }
			  ){
				id
				positiveAchievement
				negativeAchievement
					  childIndividualizedPlanSession { 
					id
					checkinAt
				  }
				  childIndividualizedPlanSkillStep {
					id
					achievementDate
					denverChecklistSkillAssessmentStep {
						achievementMinimumScore
						achievementMininumAttempts
					}
				  }
			   }
			} 
        `,
    variables: {},
  };
  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSessionSkillAssessmentStepScores);
  });
};

export const increasePositiveSkillAssessmentStepScore = (skillStepAssessmentId, amount, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                updateChildIndividualizedPlanSessionSkillAssessmentStepScore(data:{
                  positiveAchievement: ${amount}
                }
                where:{
                    id:"""${skillStepAssessmentId}"""
                }){
                  id
                  positiveAchievement
                }
              }
                `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const increaseNegativeSkillAssessmentStepScore = (skillStepAssessmentId, amount, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                updateChildIndividualizedPlanSessionSkillAssessmentStepScore(data:{
                  negativeAchievement: ${amount}
                }
                where:{
                    id:"""${skillStepAssessmentId}"""
                }){
                  id
                  negativeAchievement
                }
              }
                `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const getInterventionSessionReport = (sessionId, responseCallback) => {
  const graphQL = {
    query: `        
            {
                childIndividualizedPlanSession(
                    where:{
                        id:"""${sessionId}"""
                    }
                ) 
                {
                    id
                    checkinAt
                    checkoutAt
                    lat
                    long
                    
                    attendant {
                        id
                        person {
                            id
                            name
                        }
                    }

                    childIndividualizedPlan {
                        id
                        date
                        child {
                            id
                            person {
                                name
                            }
                        }

                        skills {
                            id
                            skill {
                                id
                                title
                                code
                                order
                                description
                                objectiveInstruction
                                assessmentSteps {
                                    id
                                    order
                                    description
                                    achievementMininumAttempts
                                    achievementMinimumScore
                                }
                                developmentalDomain {
                                    id
                                    code
                                    order
                                    title
                                }
                                skillFrom{
                                  id
                                  order
                                }
                            }
                            objectiveInstruction
                        }
                        skillSteps {
                            id
                            code
                            order
                            description
                            achievementMininumAttempts
                            achievementMinimumScore    
                            childIndividualizedPlanSkill {
                              skill {
                                id
                                code
                                order
                                level  
                                title
                                description
                                objectiveInstruction
                                developmentalDomain {
                                    code
                                    order
                                    title
                                }
                                skillFrom{
                                  id
                                  order
                                }
                              }              
                            }
                            denverChecklistSkillAssessmentStep {
                                id
                                code
                                description
                                order
                                skill {
                                    id
                                    code
                                    order
                                    level  
                                    title
                                    description
                                    objectiveInstruction
                                    developmentalDomain {
                                        code
                                        order
                                        title
                                    }
                                    skillFrom{
                                      id
                                      order
                                    }
                                }
                            }
                        }                        
                    }
                    
                    skillAssessmentStepScore {
                        id
                        positiveAchievement
                        negativeAchievement
                        childIndividualizedPlanSkillStep {
                            id
                            code
                            order
                            description
                            achievementMininumAttempts
                            achievementMinimumScore    
                            childIndividualizedPlanSkill {
                              skill {
                                id
                                code
                                order
                                level  
                                title
                                description
                                objectiveInstruction
                                developmentalDomain {
                                    code
                                    order
                                    title
                                }
                                skillFrom{
                                  id
                                  order
                                }
                              }              
                            }                            
                            achievementDate
                            denverChecklistSkillAssessmentStep {
                                id
                                code
                                order
                                description
                                achievementMinimumScore
                                achievementMininumAttempts
                                skill {
                                    id
                                    code
                                    order
                                    level
                                    title
                                    developmentalDomain {
                                        id
                                        code
                                        order
                                        title
                                    }
                                    skillFrom{
                                      id
                                      order
                                    }
                                }
                            }
                        }
                    }                    
                }
            }                    
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSession);
  });
};

export const getInterventionSessionsReport = (childId, responseCallback) => {
  const graphQL = {
    query: `
            {
  childIndividualizedPlanSessions(
    orderBy: checkinAt_DESC
    where: {
      childIndividualizedPlan: { child: { id: "${childId}" } }
    }
  ) {
    id
    checkinAt
    checkoutAt
    childIndividualizedPlan {
      id
      date
    }
  }
}
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSessions);
  });
};
