import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import manuh from 'manuh';

import topics from '../../../manuh-topics';
import { getLastChecklist } from '../../../services/checklistAssessment';
import { get } from '../../../services/children';
import { getSkill } from '../../../services/denver';
import {
  createIndividualizedPlan,
  createIndividualizedPlanCustomSkill,
  getIndividualizedPlan,
  updateIndividualizedPlan,
} from '../../../services/individualizedPlan';
import { hideLoading, showLoading } from '../../../utils/loading';
import { pad } from '../../../utils/text';

export const IndividualizedPlanContext = createContext({});

export function IndividualizedPlanProvider({ children }) {
  const { t } = useTranslation();

  const { planId, childId } = useParams();
  const query = new URLSearchParams(useLocation().search);

  const [editMode] = useState(query.get('mode') === 'edit' || planId === 'new');
  const [checklistId, setChecklistId] = useState(null);

  const [editStep, setEditStep] = useState(1);

  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');
  const [busy, setBusy] = useState(false);

  const [tab, setTab] = useState(0);

  const blankPlanData = {
    planItems: [],
    customPlanItems: [],
    individualizedPlan: null,
  };

  const planDataReducer = (state, action) => {
    switch (action.type) {
      case 'blankPlanDataLoad':
        return blankPlanData;

      case 'initialPlanDataLoad':
        return action.payload;

      case 'updatePlanData':
        return { ...state, ...action.payload };

      case 'addPlanItem':
        return { ...state, planItems: [...state.planItems, action.payload] };

      case 'addCustomItem':
        return { ...state, customPlanItems: [...state.customPlanItems, action.payload] };

      case 'addCustomItemStep':
        return {
          ...state,
          customPlanItems: [
            ...state.customPlanItems.map((customPlanItem) =>
              customPlanItem.id === action.payload.id ? action.payload : customPlanItem,
            ),
          ],
        };

      case 'updateCustomItem':
        return {
          ...state,
          customPlanItems: [
            ...state.customPlanItems.map((customPlanItem) =>
              customPlanItem.skill.code === action.payload.skill.code ? action.payload : customPlanItem,
            ),
          ],
        };

      case 'removePlanItem':
        return { ...state, planItems: state.planItems.filter((pi) => pi.id !== action.payload.id) };

      case 'removeCustomItem':
        return { ...state, customPlanItems: state.customPlanItems.filter((pi) => pi.id !== action.payload.id) };

      default:
        break;
    }
  };

  const [planData, planDataDispatcher] = useReducer(planDataReducer, blankPlanData);

  useEffect(
    (_) => {
      showLoading(t, 'Fetching Child Individualized Plan');
      //creating new plan
      if (planId === 'new') {
        get(childId, (result, err) => {
          if (!err) {
            const loggedUser = JSON.parse(window.localStorage['loggedUserData']);
            createIndividualizedPlan({ date: new Date(), child: result, createdBy: loggedUser }, (result, err) => {
              if (err || !result) {
                window.Swal.fire({
                  type: 'error',
                  title: t('There was a problem fetching Child Individualized Plan'),
                  showConfirmButton: true,
                });
              }
              getIndividualizedPlan({ individualizedPlanId: result.id }, (planRetrieved, err) => {
                if (!err) {
                  if (planRetrieved) {
                    window.location.href = `/children/${childId}/plan/${result.id}?mode=edit`;
                    return;
                  } else {
                    window.Swal.fire({
                      type: 'error',
                      title: t('There was a problem fetching Child Individualized Plan'),
                      showConfirmButton: true,
                    });
                    planDataDispatcher({ type: 'blankPlanDataLoad' });
                  }
                } else {
                  window.Swal.fire({
                    type: 'error',
                    title: t('There was a problem fetching Child Individualized Plan'),
                    showConfirmButton: true,
                  });
                }
                hideLoading();
              });
              hideLoading();
            });
          } else {
            window.Swal.fire({
              type: 'error',
              title: t('There was a problem fetching Child Individualized Plan'),
              showConfirmButton: true,
            });
            planDataDispatcher({ type: 'blankPlanDataLoad' });
            hideLoading();
          }
        });
      } else {
        //editing or reading an existing plan
        getIndividualizedPlan({ individualizedPlanId: planId }, (result, err) => {
          if (!err) {
            if (result) {
              const planItems = sortPlanItems(result.skills.filter((s) => !s.skill.code.match(/CTM/)));
              const customPlanItems = sortPlanItems(result.skills.filter((s) => s.skill.code.match(/CTM/)));
              planDataDispatcher({
                type: 'initialPlanDataLoad',
                payload: { individualizedPlan: result, planItems, customPlanItems },
              });
            } else {
              window.Swal.fire({
                type: 'error',
                title: t('There was a problem fetching Child Individualized Plan'),
                showConfirmButton: true,
              });
              planDataDispatcher({ type: 'blankPlanDataLoad' });
            }
          } else {
            window.Swal.fire({
              type: 'error',
              title: t('There was a problem fetching Child Individualized Plan'),
              showConfirmButton: true,
            });
          }
          hideLoading();
        });
      }
    },
    [t, planId, childId],
  );

  useEffect(() => {
    if (editMode) {
      getLastChecklist({ childId, individualizedPlan: planData.individualizedPlan }, (result, err) => {
        if (!err && result) {
          setChecklistId(result.id);
        }
      });
    }
  }, [childId, editMode, planData.individualizedPlan]);

  useEffect(() => {
    if (planData) {
      manuh.publish(topics.components.interventionPlan.selectObjectives.toggle, {
        selectedPlanItems: [...planData.planItems, ...planData.customPlanItems],
      });
    }
  }, [planData]);

  // FUNCTION DEFINITIONS

  /**
   * It updates the individualized plan date for the individualized plan with the given id.
   * @param individualizedPlanDate - The individualized plan date object that was clicked on.
   */
  const setIndividualizedPlanDate = (individualizedPlanDate) => {
    updateIndividualizedPlan(individualizedPlanDate.id, individualizedPlanDate.date, (_, err) => {
      if (!err) {
        window.location.reload();
      }
    });
  };

  /**
   * It sorts the plan items by the order of the skill, then by the level of the skill, then by the code of the skill.
   * @param planItemsParam - The plan items to sort.
   * @returns The planItems are sorted by the code of the skill and then by the level of the skill.
   */
  const sortPlanItems = (planItemsParam) => {
    return planItemsParam.sort((a, b) => {
      // group skills by code
      if (a.skill.developmentalDomain.order === b.skill.developmentalDomain.order) {
        // group skills by level
        if (a.skill.level === b.skill.level) {
          // order customized skills: if a skill has the skillFrom property, it goes first
          if (a.skill.code.match(/CTM/) && !a.skill.skillFrom && !b.skill.code.match(/CTM/)) return 1;
          if (b.skill.code.match(/CTM/) && !b.skill.skillFrom && !a.skill.code.match(/CTM/)) return -1;
          if (a.skill.code.match(/CTM/) && b.skill.code.match(/CTM/)) {
            if (a.skill.skillFrom && !b.skill.skillFrom) return -1;
            if (!a.skill.skillFrom && b.skill.skillFrom) return 1;
          }
          // order skills that have the skillFrom property
          if (a.skill.skillFrom && b.skill.skillFrom) return a.skill.skillFrom.order - b.skill.skillFrom.order;
          if (a.skill.skillFrom && !b.skill.skillFrom) return a.skill.skillFrom.order - b.skill.order;
          if (!a.skill.skillFrom && b.skill.skillFrom) return a.skill.order - b.skill.skillFrom.order;

          return a.skill.order - b.skill.order;
        }
        if (a.skill.skillFrom && b.skill.skillFrom) return b.skill.skillFrom.order - b.skill.skillFrom.order;
        if (a.skill.skillFrom && !b.skill.skillFrom) return a.skill.skillFrom.order - a.skill.order;
        if (!a.skill.skillFrom && b.skill.skillFrom) return b.skill.order - b.skill.skillFrom.order;
        return b.skill.level - a.skill.level;
      }
      return a.skill.developmentalDomain.order - b.skill.developmentalDomain.order;
    });
  };

  /**
   * Check if a skill is already added to the plan.
   * @param skill - The skill that is being checked.
   */
  const isSkillAdded = (skill) => planData.planItems.some((s) => s.skill.id === skill.id);

  /**
   * Given a skill, return true if the skill is customized in the current plan.
   * @param skill - The skill that is being checked.
   */
  const isSkillCustomized = (skill) => planData.customPlanItems.some((s) => s.skill.skillFrom?.id === skill.id);

  /**
   * It updates the planData object in order to update the view.
   * @param planItems - Current plan items.
   */
  const updatePlanItems = (planItems) => {
    planDataDispatcher({ type: 'updatePlanData', payload: planItems });
  };

  /**
   * Given a plan item, remove it from the plan items array
   * @param planItem - The plan item that was removed.
   */
  const onPlanItemRemoved = (planItem) => {
    planDataDispatcher({ type: 'removePlanItem', payload: planItem });
  };

  /**
   * It removes the custom plan item from the custom plan items array.
   * @param customPlanItem - The custom plan item that was removed.
   */
  const onCustomPlanItemRemoved = (customPlanItem) => {
    planDataDispatcher({ type: 'removeCustomItem', payload: customPlanItem });
  };

  /**
   * Create a new custom skill
   */
  const createNewCustomSkill = () => {
    const newCustomSkill = {
      childIndividualizedPlan: planData.individualizedPlan,
      skill: {
        code: 'CTM' + new Date().getTime(),
        level: 0,
        title: '',
        description: '',
        objectiveInstruction: '',
        assessmentSteps: [],
      },
      childIndividualizedPlanSkillSteps: [],
    };

    planDataDispatcher({ type: 'addCustomItem', payload: newCustomSkill });
  };

  /**
   * Create a new customized skill, copying all the data from the original one
   * @param individualizedPlan - The individualized plan that the skill will be added to.
   * @param originalSkill - The skill that will be customized.
   * @returns The new customized skill is being returned.
   */
  const customizeDenverSkill = (individualizedPlan, originalSkill) => {
    showLoading(t, 'loading-customize-skill');

    if (!individualizedPlan || !originalSkill) {
      window.Swal.fire({
        type: 'error',
        title: t('There was an error customizing this objective'),
        showConfirmButton: true,
      });
      console.error('Parâmetros individualizedPlan ou originalSkill não encontrados na request');
      return;
    }

    // Fetch originalSkill data, so we can pass the steps to the new customized originalSkill
    getSkill(originalSkill.id, (getSkillResponse, getSkillError) => {
      if (getSkillError) {
        console.error('Erro ao recuperar dados da originalSkill');
        console.error(getSkillError);
        window.Swal.fire({
          type: 'error',
          title: t('There was an error customizing this objective'),
          showConfirmButton: true,
        });
        return;
      }

      // Create a code for the customized skill
      const customizedSkillCode = 'CTM' + new Date().getTime();

      // change the steps code to custom
      const assessmentSteps = getSkillResponse.assessmentSteps
        .map((step) => {
          return {
            ...step,
            code: customizedSkillCode + 'CS' + pad(step.order, 2),
          };
        })
        .sort((a, b) => a.order - b.order);

      // Create an object with the original skill data
      const customizedSkill = {
        code: customizedSkillCode,
        description: originalSkill.description,
        developmentalDomain: {
          id: originalSkill.developmentalDomain.id,
          order: originalSkill.developmentalDomain.order,
          title: originalSkill.developmentalDomain.title,
        },
        level: originalSkill.level,
        objectiveInstruction: originalSkill.objectiveInstruction,
        title: originalSkill.title,
        assessmentSteps: assessmentSteps,
        skillFrom: { id: originalSkill.id },
      };

      // Create the new customized skill, copying all the data from the original one
      createIndividualizedPlanCustomSkill(
        {
          childIndividualizedPlan: individualizedPlan,
          skill: customizedSkill,
          assessmentSteps: assessmentSteps,
        },
        ({ individualizedPlanSkill, skill }, createSkillError) => {
          if (createSkillError) {
            console.error(createSkillError);
            window.Swal.fire({
              type: 'error',
              title: t('There was an error customizing this objective'),
              showConfirmButton: true,
            });
            return;
          }

          const newSkill = {
            id: individualizedPlanSkill.id,
            childIndividualizedPlan: individualizedPlan,
            skill: {
              id: skill.createDenverChecklistSkill.id,
              code: customizedSkill.code,
              level: customizedSkill.level,
              title: customizedSkill.title,
              description: customizedSkill.description,
              objectiveInstruction: customizedSkill.objectiveInstruction,
              assessmentSteps: customizedSkill.assessmentSteps,
              developmentalDomain: {
                ...originalSkill.developmentalDomain,
              },
              skillFrom: { id: originalSkill.id },
            },
            childIndividualizedPlanSkillSteps: customizedSkill.assessmentSteps,
          };

          // const customPlanItems = planData.customPlanItems.concat(newSkill);

          planDataDispatcher({ type: 'addCustomItem', payload: newSkill });

          setEditStep(3);

          setTimeout(() => {
            const element = window.document.getElementById('addObjectiveForm_CustomSkill-' + newSkill.id);
            var topPos = element.getBoundingClientRect().top + window.scrollY - 60;
            window.scrollTo({
              top: topPos,
              behavior: 'smooth',
            });
          }, 300);
          hideLoading();
        },
      );
    });
  };

  return (
    <IndividualizedPlanContext.Provider
      value={{
        planId,
        childId,
        editMode,
        checklistId,
        setChecklistId,
        planData,
        planDataDispatcher,
        sortPlanItems,
        updatePlanItems,
        editStep,
        setEditStep,
        setIndividualizedPlanDate,
        loadingErrorMessage,
        setLoadingErrorMessage,
        busy,
        setBusy,
        onPlanItemRemoved,
        onCustomPlanItemRemoved,
        isSkillAdded,
        isSkillCustomized,
        createNewCustomSkill,
        customizeDenverSkill,
        tab,
        setTab,
      }}
    >
      {children}
    </IndividualizedPlanContext.Provider>
  );
}

export const useChildIndividualizedPlanContext = () => {
  return useContext(IndividualizedPlanContext);
};
