import charol from 'charol';

let topics = {
  components: {
    global: {
      selectedLevel: null,
      selectedDevelopmentalDomain: null,
    },
    developmentalDomainSelector: {
      select: null,
    },
    interventionPlan: {
      selectObjectives: {
        toggle: null,
      },
      openSkillSessionDetails: null,
    },
  },
};
topics = charol(topics);
export default topics;
