import React, { useState } from 'react';

import { ExportSessionPdf } from '../children/childInterventionSession/components/exportSessionPdf/ExportSessionPdf';
import { useChildrenList } from './useChildrenList';
import { useSessionList } from './useSessionList';

export function ReportPage() {
  const [selectedChild, setSelectedChild] = useState(null);
  const { error, loading, staffList } = useChildrenList();
  const { error: errorSession, loading: loadingSession, sessionList } = useSessionList(selectedChild?.id);

  return (
    <main style={{ padding: '2rem', display: 'flex', flexDirection: 'column', gap: '1rem' }} className="container">
      <h1>Relatórios</h1>
      <div className="btn-group">
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <select
            onChange={(event) => {
              const { value } = event.target;
              const current = staffList.find((smc) => smc.id === value);
              setSelectedChild(current);
            }}
            style={{ maxWidth: '100%' }}
            className="custom-select custom-select-sm"
            defaultValue=""
          >
            {loading ? (
              <option>Carregando...</option>
            ) : (
              <option value="" disabled hidden>
                Escolha uma criança
              </option>
            )}
            {staffList.map((staff) => (
              <option value={staff.id} key={staff.id}>
                {staff.name}
              </option>
            ))}
          </select>
          {error && <span>Erro ao carregar dados</span>}
        </div>
      </div>
      <div style={{ maxWidth: '720px' }}>
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>Check-in</th>
              <th>Plano</th>
              <th>Sessão</th>
              <th>Baixar</th>
            </tr>
          </thead>
          <tbody>
            {!selectedChild && (
              <tr>
                <td colSpan="5">Selecione uma criança</td>
              </tr>
            )}
            {errorSession && (
              <tr>
                <td colSpan="5">Erro ao carregar dados</td>
              </tr>
            )}
            {loadingSession ? (
              <tr>
                <td colSpan="5">Carregando...</td>
              </tr>
            ) : sessionList.length === 0 && selectedChild?.id ? (
              <tr>
                <td colSpan="5">Nenhuma sessão</td>
              </tr>
            ) : (
              sessionList.map((session) => (
                <tr key={session.id}>
                  <td>{new Date(session.checkin)?.toLocaleDateString()}</td>
                  <td>
                    <a href={`/children/${selectedChild?.id}/plan/${session.individualizedPlanId}`}>
                      {new Date(session?.individualizedPlanDate)?.toLocaleDateString()}
                    </a>
                  </td>
                  <td>
                    <a href={`/children/${selectedChild?.id}/session/${session.id}`}>Ver sessão</a>
                  </td>
                  <td>
                    <ExportSessionPdf sessionId={session.id}>
                      <a href="#">Baixar</a>
                    </ExportSessionPdf>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </main>
  );
}
