import { createNewAccount, loginPasswordClick, changePasswordForm, deleteAccount } from './auth';
import { graphQLRequest, graphQLRequestCaching } from './common';

export const create = async (active, name, dateOfBirth, gender, videoURL, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `
              mutation {
                  createChild (
                      data: {
                          active: ${active}  
                          videoUrl: "${videoURL}"  
                          person: {
                              create: {
                                  name: "${name}"
                                  dateOfBirth: "${dateOfBirth}"
                                  gender: ${gender}
                              }
                          }
                      }
                  ){
                      id
                      createdAt
                      updatedAt
                  }
              }
          `,
      variables: {},
    };

    if (responseCallback) return graphQLRequest(graphQL, responseCallback);

    graphQLRequest(graphQL, (res, err) => {
      if (!err) return resolve(res);
      return reject(err);
    });
  });

export const verifyRegistered = async (name, dateOfBirth, responseCallback) =>
  new Promise((resolve) => {
    const graphQL = {
      query: `{
              children (
                where: {
                  person: {
                    OR: [
                      { name_contains: "${name}" }
                      { dateOfBirth: "${dateOfBirth}" }
                    ]
                  }
                }
                ) {  
                  id
                  person {
                    name   
                    gender
                    dateOfBirth
                  }
                }
              }`,
      variables: {},
    };

    if (responseCallback) return graphQLRequest(graphQL, responseCallback);

    graphQLRequest(graphQL, (result, err) => {
      if (!err) {
        return resolve({ data: result.data, error: null });
      } else {
        return resolve({ data: null, error: err });
      }
    });
  });

export const update = (id, active, name, dateOfBirth, gender, videoURL, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                updateChild (
                    data: {
                        active: ${active}  
                        videoUrl: "${videoURL}"  
                        person: {
                            update: {
                                name: "${name}"
                                dateOfBirth: "${dateOfBirth}"
                                gender: ${gender}
                            }
                        }
                    }
                    where: {
                        id: "${id}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const del = (id, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `mutation { deleteChild ( where: {id: "${id}"} ) {id createdAt updatedAt} }`,
      variables: {},
    };

    if (responseCallback) return graphQLRequest(graphQL, responseCallback);

    graphQLRequest(graphQL, (res, err) => {
      if (!err) return resolve(res);
      return reject(err);
    });
  });

export const get = (id, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `{
            child(where: { id: "${id}"}) {
              id
			  active
              videoUrl
              person {
                id
                name   
                gender
                dateOfBirth
              }
              
              relatives {
                person {
                    id
                    name
                    phone
                    email
                }
              }
              staffAssignments {
                id
                staffAttribution {
                    id
                    title
                }
                staffMember {
                    id
                    person {
                        id
                        name
                        phone
                    }
                }
              }              
              
            }
          }
        `,
      variables: {},
    };
    graphQLRequestCaching(graphQL, (result, err) => {
      if (!err) {
        let resChild = JSON.parse(JSON.stringify(result.data.child));
        resChild.staffAssignments = resChild.staffAssignments.sort((a, b) => {
          if (a.staffAttribution.id === '1' && b.staffAttribution.id !== '1') {
            return -1;
          }
          if (b.staffAttribution.id === '1' && a.staffAttribution.id !== '1') {
            return 1;
          }
          return a.staffMember.person.name.localeCompare(b.staffMember.person.name);
        });
        if (responseCallback) return responseCallback(resChild);
        return resolve(resChild);
      } else {
        if (responseCallback) return responseCallback(null, err);
        return reject(null, err);
      }
    });
  });

export const getAllChildren = (responseCallback) => {
  const graphQL = {
    query: `{
      children(where: { patient: true }) {
        id
        active
        person {
          id
          name
          gender
          dateOfBirth
        }
        relatives {
          person {
            name
            phone
            email
          }
        }
        staffAssignments {
          id
          staffAttribution {
            id
            title
          }
          staffMember {
            person {
              name
              phone
            }
          }
        }
        checklistAssessments(orderBy: date_DESC, first: 1) {
          id
          date
        } 
        lastSessions: individualizedPlans(
          where: { sessions_some: { createdAt_gte: "2000-01-01" } }
          orderBy: date_DESC
          first: 1
        ) {
          sessions(orderBy: checkinAt_DESC, first: 1) {
            id
            checkinAt
            mig__sessionNumber
          }
        }
      }
    }`,
    variables: {},
  };

  graphQLRequestCaching(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.children.sort((a, b) => a.person.name.localeCompare(b.person.name)));
    } else {
      responseCallback(null, err);
    }
  });
};

export const getRelativeRelationships = (responseCallback) => {
  const graphQL = {
    query: `{
            relativeRelationships {
              id
              title
            }
          }
        `,
    variables: {},
  };
  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.relativeRelationships.sort((a, b) => a.title - b.title));
    } else {
      responseCallback(null, err);
    }
  });
};

export const createRelative = (childId, name, password, phone, email, gender, relationship, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                createRelative (
                    data: {
                        person: {
                            create: {
                                name: "${name}"
                                phone: "${phone}"
                                email: "${email}"
                                gender: ${gender}
                            }
                        }
                        relationship: { 
                            connect: { 
                                id: "${relationship.id}"  
                            }
                        }
                        child: { 
                            connect: { 
                                id: "${childId}"
                            }
                        }                        
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      createNewAccount(name, email, password, (response) => {
        if (response.status === 201 || response.status === 250) {
          responseCallback(result);
        } else {
          responseCallback(null, response);
        }
      });
    } else {
      responseCallback(null, err);
    }
  });
};

export const getRelatives = (childId, responseCallback) => {
  const graphQL = {
    query: `{
            child(where: { id: "${childId}"}) {
              id 
              relatives {
                id
                person {
                    name
                    phone
                    email
                }
                relationship {
                    title
                }
              }             
            }
          }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.child.relatives);
    } else {
      responseCallback(null, err);
    }
  });
};

export const getRelative = (id, responseCallback) => {
  if (!id) return null;

  const graphQL = {
    query: `{
            relative(where: { id: "${id}"}) {
              id
              person {
                name   
                phone
                email
                gender
              }       
              relationship {
                  id
                  title
              }      
            }
          }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (!err) {
      responseCallback(result.data.relative);
    } else {
      responseCallback(null, err);
    }
  });
};

export const getRelativeByEmail = (email, password, responseCallback) => {
  if (!email) return null;
  const graphQL = {
    query: `
		{
		relatives(
            where:{
              person:{
                email:"""${email}"""
            }
            }) {
              id
              person {
				id
                name   
                phone
                email
                gender
              }       
              relationship {
                  id
                  title
              }
			  child {
				  id
			  }			  
            }
        }	
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, resultToken, err) => {
    if (!err && result.data.relatives && result.data.relatives.length > 0) {
      loginPasswordClick(email, password, (resultToken, err) => {
        if (err) {
          responseCallback(null, null, err);
        } else {
          responseCallback(result.data.relatives, resultToken);
        }
      });
    } else if (!err) {
      responseCallback(null);
    } else {
      responseCallback(null, null, err);
    }
  });
};

export const updateRelativeInfo = (id, name, password, phone, email, gender, relationship, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                updateRelative (
                    data: {
                        person: {
                            update: {
                                name: "${name}"
                                phone: "${phone}"
                                email: "${email}"
                                gender: ${gender}
                            }
                        }
                        relationship: { 
                            connect: { 
                                id: "${relationship.id}"  
                            }
                        }
                    }
                    where: {
                        id: "${id}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  if (password.length !== 0) {
    changePasswordForm(email, password, (response, err) => {
      if (response.status === 455) {
        createNewAccount(name, email, password, (response) => {
          if (response.status === 201 || response.status === 250) {
            graphQLRequest(graphQL, (result, err) => {
              if (!err) {
                graphQLRequest(graphQL, responseCallback);
              } else {
                responseCallback(null, err);
              }
            });
          } else {
            responseCallback(null, response);
          }
        });
      } else if (response.status !== 200) {
        responseCallback(null, response);
      } else {
        graphQLRequest(graphQL, responseCallback);
      }
    });
  } else {
    graphQLRequest(graphQL, responseCallback);
  }
};

export const removeChildRelative = (relativeId, email, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                deleteRelative (
                    where: {
                        id: "${relativeId}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback); // Todo: trocar após uso de base de testes
  deleteAccount(email, (_response) => {
    // if (response.status !== 200) {
    // responseCallback(null, response)
    // } else {
    // graphQLRequest(graphQL, responseCallback);
    // }
  });
};

export const createStaffAssignment = (childId, staffMemberId, staffAttributionId, responseCallback) =>
  new Promise((resolve, reject) => {
    const graphQL = {
      query: `
            mutation {
                createChildStaffAssignment (
                    data: {
                        child: {
                            connect: {
                                id: "${childId}"
                            }
                        }
                        staffMember: {
                            connect: {
                                id: "${staffMemberId}"
                            }
                        }
                        staffAttribution: {
                            connect: {
                                id: "${staffAttributionId}"
                            }
                        }
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
      variables: {},
    };

    if (responseCallback) return graphQLRequest(graphQL, responseCallback);

    graphQLRequest(graphQL, (res, err) => {
      if (!err) return resolve(res);
      return reject(err);
    });
  });

export const removeStaffAssignment = (staffAssignmentId, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                deleteChildStaffAssignment (
                    where: {
                        id: "${staffAssignmentId}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const getAllAnotattionFromChildrenByDate = (childID, date, responseCallback) => {
  const graphQL = {
    query: `{
      childAnotattionses(
       where :{
         child:{
           id : "${childID}"
         }
       }
     )
       {
        id
        attendant{
         id
          person {
           name
           id
         }
        }
        dataAnottation
        anottation
        child {
          id
        }
      }
    }`,
  };

  graphQLRequest(graphQL, responseCallback);
};

export const getFilteredAnotattionFromChildren = ({ id, domainReached }, responseCallback) => {
  const domain =
    domainReached === 'Family'
      ? `[{  visibility: "Family"}, {visibility: "All"}]`
      : domainReached === 'Supervisor'
      ? `[ {  visibility: "Family"},{  visibility: "Therapist Assistant" }, {visibility: "All"}, {visibility: "Supervisor"}]`
      : domainReached === 'Therapist Assistant'
      ? `[ {  visibility: "Family"},{  visibility: "Therapist Assistant" }, {visibility: "All"}]`
      : `[{ visibility: "All" }]`;

  const graphQL = {
    query: `{
      childAnotattionses(
      orderBy: annotationDate_DESC
       where :{
        OR: ${domain},
         child:{
           id : "${id}"
         }
       }
     )
       {
        fixed
        visibility
        id
        attendant{
         id
          person {
           name
           id
         }
        }
        createdAt
        anottation
        child {
          id
        }
        annotationDate
      }
    }`,
  };

  graphQLRequest(graphQL, responseCallback);
};

export const getAllAnotattionFromChildren = (childID, responseCallback) => {
  const graphQL = {
    query: `{
      childAnotattionses(
      orderBy: annotationDate_DESC
       where :{
         child:{
           id : "${childID}"
         }
       }
     )
       {
        fixed
        visibility
        id
        attendant{
         id
          person {
           name
           id
         }
        }
        createdAt
        anottation
        child {
          id
        }
        annotationDate
      }
    }`,
  };

  graphQLRequest(graphQL, responseCallback);
};
