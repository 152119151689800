import {
  Paragraph,
  TextRun,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  VerticalAlign,
  ShadingType,
} from 'docx';
import moment from 'moment';

import { borders, margins } from './LazyButtonStyles';
import { mapValuesScore, scoreForColorsCell } from './mapValuesScore';

export default async ({ checkListAssessments, selectedChecklists, t }) => {
  try {
    if (checkListAssessments.length === 0) {
      return window.Swal.fire({
        type: 'warning',
        title: t('Warning'),
        text: t('No checklist assessments found for the selected Domain Development and Levels'),
      });
    }

    // build the checklist assessment DOCX tables
    checkListAssessments.sort((a, b) => {
      // first, sort by developmental domain order, CRE, CEX and so on
      if (a.skill.developmentalDomain.order - b.skill.developmentalDomain.order !== 0) {
        return a.skill.developmentalDomain.order - b.skill.developmentalDomain.order;
      }
      // then, sort by the skill order, 1CRE, 2CRE,...
      if (a.skill.level - b.skill.level !== 0) {
        return a.skill.level - b.skill.level;
      }
      // then, sort by the skill order, 1CRE01, 1CRE02,...
      if (a.skill.order - b.skill.order !== 0) {
        return a.skill.order - b.skill.order;
      }
      return new Date(a.childChecklistAssessment.date).getTime() - new Date(b.childChecklistAssessment.date).getTime();
    });

    const orderedSelectedChecklistsByDate = selectedChecklists.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
    const checklistDates = orderedSelectedChecklistsByDate.map((cl) => cl.date);

    // Convert the assessment full list to a list o tabular data with developmental domain per table

    // #1 - Separate each developmental domain assessments
    let ddAssessmentsMap = {};
    for (let i = 0; i < checkListAssessments.length; i++) {
      const checkListAssessment = checkListAssessments[i];
      if (!ddAssessmentsMap[checkListAssessment.skill.developmentalDomain.code]) {
        ddAssessmentsMap[checkListAssessment.skill.developmentalDomain.code] = {
          code: checkListAssessment.skill.developmentalDomain.code,
          title: checkListAssessment.skill.developmentalDomain.title,
          checklistDates,
          skillAssessmentScoresUngrouped: [],
        };
      }
      ddAssessmentsMap[checkListAssessment.skill.developmentalDomain.code].skillAssessmentScoresUngrouped.push(
        checkListAssessment,
      );
    }

    // #2 - Group each skillAssessment into a single row with an array of scores with the same length as checklistDates selected
    // for each of the Developmental Domains
    const ddAssessmentTablesTabularData = Object.keys(ddAssessmentsMap).map((dda) => {
      ddAssessmentsMap[dda].skillAssessmentScoresGrouped = [];
      for (let i = 0; i < ddAssessmentsMap[dda].skillAssessmentScoresUngrouped.length; i++) {
        let developmentalDomainRow = {
          skill: ddAssessmentsMap[dda].skillAssessmentScoresUngrouped[i].skill,
          scores: [],
        };

        // Look for a score for each respective checklist. If there is not for a date, put "NOT_EVALUATED" and move on
        for (let j = 0; j < selectedChecklists.length; j++) {
          if (
            ddAssessmentsMap[dda].skillAssessmentScoresUngrouped[i] &&
            ddAssessmentsMap[dda].skillAssessmentScoresUngrouped[i].childChecklistAssessment.id ===
              selectedChecklists[j].id
          ) {
            developmentalDomainRow.scores.push(ddAssessmentsMap[dda].skillAssessmentScoresUngrouped[i].score);
            if (j < selectedChecklists.length - 1) {
              // leave the last increment for the outer loop increment to avoid duplication
              i++;
            }
          } else {
            developmentalDomainRow.scores.push('NOT_EVALUATED');
          }
        }
        ddAssessmentsMap[dda].skillAssessmentScoresGrouped.push(developmentalDomainRow);
      }
      return ddAssessmentsMap[dda];
    });

    let docxChecklistTable = ddAssessmentTablesTabularData.map(
      (ddt) =>
        new Paragraph({
          children: [
            new TextRun({
              text: '',
              italics: true,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      margins,
                      borders,
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: ddt.title,
                              bold: true,
                              font: 'Arial',
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                    }),
                    ...ddt.checklistDates.map(
                      (val) =>
                        new TableCell({
                          margins,
                          borders,
                          width: { size: 10, type: WidthType.PERCENTAGE },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${moment(new Date(val)).format('DD/MM/YYYY')}`,
                                  bold: true,
                                  font: 'Arial',
                                }),
                              ],
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                        }),
                    ),
                  ],
                }),
                ...ddt.skillAssessmentScoresGrouped.map(
                  (ssa) =>
                    new TableRow({
                      children: [
                        new TableCell({
                          margins,
                          borders,
                          width: { size: 50, type: WidthType.PERCENTAGE },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `N${ssa.skill.level}.${
                                    ssa.skill.order >= 10 ? ssa.skill.order : '0' + ssa.skill.order
                                  } - ${ssa.skill.title}`,
                                  font: 'Arial',
                                }),
                              ],
                            }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        ...ssa.scores.map(
                          (vl) =>
                            new TableCell({
                              margins,
                              borders,
                              shading: {
                                // fill: "red",
                                val: ShadingType.SOLID,
                                color: scoreForColorsCell(vl),
                              },
                              width: { size: 10, type: WidthType.PERCENTAGE },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: mapValuesScore(vl),
                                      size: 32,
                                      font: 'Courier New',
                                    }),
                                  ],
                                  alignment: AlignmentType.CENTER,
                                }),
                              ],
                              verticalAlign: VerticalAlign.CENTER,
                            }),
                        ),
                      ],
                    }),
                ),
              ],
            }),
          ],
        }),
    );
    return docxChecklistTable;
  } catch (error) {
    console.error(error);
    return window.Swal.fire({
      type: 'warning',
      title: t('Warning'),
      text: t(error),
    });
  }
};
