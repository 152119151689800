import { Paragraph, TextRun } from 'docx';

export function convertFromHtmlToDoc(content) {
  content = content.replace(/&gt;/g, '>');
  content = content.replace(/&lt;/g, '<');
  content = content.replace(/&amp;/g, '&');
  content = content.replace(/&pound;/g, '£');
  content = content.replace(/&sect;/g, '§');
  content = content.replace(/&sum;/g, '∑');
  content = content.replace(/&alpha;/g, 'α');
  content = content.replace(/&beta;/g, 'β');
  content = content.replace(/&ndash;/g, '–');
  content = content.replace(/&mdash;/g, '—');
  content = content.replace(/&deg;/g, '°');
  content = content.replace(/&plusmn;/g, '±');
  content = content.replace(/&sup2;/g, '²');
  content = content.replace(/&sup3;/g, '³');
  content = content.split('</p>').join('\n');
  content = content.replace('<br/>', '\n');
  content = content.replace(/(<([^>]+)>|(&nbsp;))/g, '');
  return content.trim();
}

export default async function convertHtmlToParagraphs(htmlText) {
  const asHtml = new DOMParser().parseFromString(htmlText, 'text/html');

  const elements = [...asHtml.body.childNodes];

  // This function will handle formatting (bold, italics)
  const handleChildren = (children) => {
    let flatChildren = [...children.childNodes].length > 1 ? [...children.childNodes].flat() : [children];

    const handledElements = flatChildren.map((child) => {
      // heading 1
      if (child.nodeName === 'H1')
        return [
          new TextRun({
            text: child.innerText ?? child.textContent,
            font: 'Arial',
            size: 28,
            bold: true,
          }),
        ];
      // heading 2
      if (child.nodeName === 'H2')
        return [
          new TextRun({
            text: child.innerText ?? child.textContent,
            font: 'Arial',
            size: 26,
            bold: true,
          }),
        ];
      // heading 3
      if (child.nodeName === 'H3')
        return [
          new TextRun({
            text: child.innerText ?? child.textContent,
            font: 'Arial',
            size: 24,
            bold: true,
          }),
        ];

      // Bold
      if (child.nodeName === 'STRONG')
        return [
          new TextRun({
            text: child.innerText ?? child.textContent,
            font: 'Arial',
            size: 22,
            bold: true,
          }),
        ];

      // Italics
      if (child.nodeName === 'EM')
        return [
          new TextRun({
            text: child.innerText ?? child.textContent,
            font: 'Arial',
            size: 22,
            italics: true,
          }),
        ];

      // Underlined
      if (child.nodeName === 'U')
        return [
          new TextRun({
            text: child.innerText ?? child.textContent,
            font: 'Arial',
            size: 22,
            underline: true,
          }),
        ];

      // Plain text (no formatting)
      return [
        new TextRun({
          text: child.innerText ?? child.textContent,
          font: 'Arial',
          size: 22,
        }),
      ];
    });

    return handledElements;
  };

  const convertedText = elements.map((element) => {
    // Handle Unordered Lists
    if (element.nodeName === 'UL') {
      return [...element.childNodes].map(
        (el) =>
          new Paragraph({
            spacing: {
              line: 276,
            },
            children: [...handleChildren(el)].flat(),
            numbering: {
              reference: 'bullet-points',
              level: 0,
            },
          }),
      );
    }

    // Handle Ordered Lists
    if (element.nodeName === 'OL') {
      return [...element.childNodes].map(
        (el) =>
          new Paragraph({
            spacing: {
              line: 276,
            },
            children: [...handleChildren(el)].flat(),
            numbering: {
              reference: 'list-numbering',
              level: 0,
            },
          }),
      );
    }

    // Handle Titles
    if (element.nodeName === 'H1' || element.nodeName === 'H2' || element.nodeName === 'H3') {
      return new Paragraph({
        spacing: {
          line: 276,
        },
        children: [...handleChildren(element)].flat(),
      });
    }

    // Handle Paragraphs
    return new Paragraph({
      spacing: {
        line: 276,
      },
      children: [...element.childNodes].map((el) => handleChildren(el)).flat(Infinity),
    });
  });

  return convertedText.flat();
}
