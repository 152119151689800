import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import manuh from 'manuh';

import topics from '../../../../../manuh-topics';
import { getChildChecklistAssessments } from '../../../../../services/checklistAssessment';
import {
  getDevelopmentalDomainsByLevel,
  getAvailableLevelsByDevelopmentalDomain,
  getAllDevelopmentalDomains,
  getAllSkillsFiltered,
} from '../../../../../services/denver';
import { hideLoading, showLoading } from '../../../../../utils/loading';

export default ({ childId, checklistId }) => {
  const { t } = useTranslation();

  const query = new URLSearchParams(window.location.search);

  // eslint-disable-next-line no-unused-vars
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('');

  const [visualizationMode, setVisualizationMode] = useState('FLAT');
  const [denverDevelopmentalDomains, setDenverDevelopmentalDomains] = useState([]);
  const [currentDevelopmentalDomainFilter, setCurrentDevelopmentalDomainFilter] = useState();
  const [currentLevel, setCurrentLevel] = useState(query.get('level') ? parseInt(query.get('level')) : 1);
  const [availableLevels, setAvailableLevels] = useState([1, 2, 3, 4]);

  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistItemsFiltered, setChecklistItemsFiltered] = useState([]);
  const [filterScore, setFilterScore] = useState(query.get('score') ? query.get('score') : 'ALL');

  useEffect(
    (_) => {
      showLoading(t, 'loading-fetch-denver-developmental-domains');

      getAllDevelopmentalDomains((denverDevelopmentalDomains, err) => {
        if (!err) {
          setDenverDevelopmentalDomains(denverDevelopmentalDomains);
        } else {
          window.Swal.fire({
            type: 'error',
            title: t('There was a problem fetching Denver Developmental Domains'),
            showConfirmButton: true,
          });
        }
        hideLoading();
      });
    },
    [t],
  );

  useEffect(
    (_) => {
      showLoading(t, 'loading-fetch-checklist-items');
      let currDevelopmentalDomain = null;
      // definir o filtro do domínio quando for atualizada a listagem de domínios
      if (!query.get('domain') && !currentDevelopmentalDomainFilter) {
        //caso não tenha nada definido, pegar o primeiro item da lista de domínios
        currDevelopmentalDomain = denverDevelopmentalDomains[0];
      } else if (query.get('domain')) {
        // se tiver definido um parâmetro de URL, tentar seta-lo
        let curr = denverDevelopmentalDomains.find((rd) => rd.code === query.get('domain'));

        if (curr) {
          currDevelopmentalDomain = curr;
        } else {
          //se a URL está apontando para um domínio que não está na listagem filtrada
          currDevelopmentalDomain = denverDevelopmentalDomains[0];
        }
      } else if (currentDevelopmentalDomainFilter) {
        currDevelopmentalDomain = currentDevelopmentalDomainFilter;
      }
      setCurrentDevelopmentalDomainFilter(currDevelopmentalDomain);
      manuh.publish(topics.components.global.selectedDevelopmentalDomain, {
        denverDevelopmentalDomain: currDevelopmentalDomain,
      });
      hideLoading();
    },
    [currentDevelopmentalDomainFilter, denverDevelopmentalDomains, query, t],
  );

  useEffect(
    (_) => {
      showLoading(t, 'loading-fetch-checklist-items');
      getDevelopmentalDomainsByLevel(currentLevel, (resultDevelopmental, err2) => {
        if (!err2) {
          setDenverDevelopmentalDomains(resultDevelopmental);
          manuh.publish(topics.components.global.selectedLevel, currentLevel);
        } else {
          window.Swal.fire({
            type: 'error',
            title: t('There was a problem fetching Child Denver Checklist'),
            showConfirmButton: true,
          });
        }
      });
      hideLoading();
    },
    [currentLevel, t],
  );

  useEffect(
    (_) => {
      showLoading(t, 'Fetching Child Assessment Checklist');

      if (currentDevelopmentalDomainFilter && currentLevel) {
        getChildChecklistAssessments(
          {
            childId,
            checklistId,
            developmentDomainList: [currentDevelopmentalDomainFilter.code],
            levelList: [currentLevel],
          },
          (result, err) => {
            if (!err) {
              let checkListAssessmentItems = [];
              if (result && result.length > 0) {
                checkListAssessmentItems = result.map((r) => Object.assign({}, r.skill, { score: r.score }));
              }

              getAllSkillsFiltered(
                {
                  developmentDomainCode: currentDevelopmentalDomainFilter.code,
                  level: currentLevel,
                },
                (skillsRetrieved, err) => {
                  if (skillsRetrieved && skillsRetrieved.length > 0) {
                    const notEvaluatedItems = skillsRetrieved.filter(
                      (sk) => !checkListAssessmentItems.find((cl) => cl.code === sk.code),
                    );
                    setChecklistItems(checkListAssessmentItems.concat(notEvaluatedItems));
                  } else {
                    setChecklistItems(checkListAssessmentItems);
                  }
                },
              );

              getAvailableLevelsByDevelopmentalDomain(currentDevelopmentalDomainFilter.code, (res, err) => {
                if (!err) {
                  setAvailableLevels(res);
                }
              });

              window.Swal.close();
            } else {
              window.Swal.fire({
                type: 'error',
                title: t('There was a problem fetching Child Denver Checklist'),
                showConfirmButton: true,
              });
            }
          },
        );
      }
      hideLoading();
    },
    [t, currentDevelopmentalDomainFilter, filterScore, currentLevel, childId, checklistId],
  );

  useEffect(() => {
    getDevelopmentalDomainsByLevel(currentLevel, (dds) => {
      setDenverDevelopmentalDomains(dds);
    });
  }, [currentLevel]);

  useEffect(() => {
    if (!filterScore || filterScore === 'ALL') {
      setChecklistItemsFiltered(checklistItems.filter((p) => !p.code.match(/CTM/)));
    } else {
      setChecklistItemsFiltered(checklistItems.filter((c) => !c.code.match(/CTM/) && c.score === filterScore));
    }
  }, [checklistItems, filterScore]);

  return {
    visualizationMode,
    setVisualizationMode,
    checklistItemsFiltered,

    denverDevelopmentalDomains,
    currentDevelopmentalDomainFilter,
    setCurrentDevelopmentalDomainFilter,
    currentLevel,
    setCurrentLevel,
    availableLevels,

    loadingErrorMessage,

    filterScore,
    setFilterScore,
  };
};
