import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { updateSkill } from '../../../../../services/denver';
import {
  createIndividualizedPlanCustomSkill,
  deleteIndividualizedPlanSkill,
  createIndividualizedPlanSkillStep,
  getIndividualizedPlanSkill,
  getIndividualizedPlanSkillStepsBySkill,
  deleteIndividualizedPlanSkillStep,
  updateChildIndividualizedPlanSkillStep,
} from '../../../../../services/individualizedPlan';
import { pad } from '../../../../../utils/text';
import { useChildIndividualizedPlanContext } from '../../ChildIndividualizedPlanContext';

export default (individualizedPlan, individualizedPlanSkill) => {
  const { t } = useTranslation();

  const [busyStatus, setBusyStatus] = useState('off');
  const [planItem, setPlanItem] = useState(individualizedPlanSkill);
  const [assessmentSteps, setAssessmentSteps] = useState([]);

  const { planDataDispatcher } = useChildIndividualizedPlanContext();

  const loadAssessmentSteps = () => {
    getIndividualizedPlanSkillStepsBySkill(individualizedPlan, individualizedPlanSkill, (result, err) => {
      if (err) {
        return window.Swal.fire({
          icon: 'error',
          title: t('Error'),
          text: t('There was an error creating assessment skill step for this plan'),
        });
      }
      setPlanItem((pi) => ({
        ...pi,
        childIndividualizedPlanSkillSteps: result.data.childIndividualizedPlanSkillSteps,
      }));
      setAssessmentSteps(result.data.childIndividualizedPlanSkillSteps);
    });
  };

  useEffect(() => {
    setPlanItem(individualizedPlanSkill);
    loadAssessmentSteps();
  }, [individualizedPlanSkill]);

  const savePlanItem = () => {
    setBusyStatus('loading');
    if (!planItem.id) {
      createIndividualizedPlanCustomSkill(planItem, (result, err) => {
        if (err) {
          setBusyStatus('error');
          return setTimeout((_) => setBusyStatus('off'), 4000);
        } else {
          getIndividualizedPlanSkill(result.individualizedPlanSkill.id, (newCustomSkill, err) => {
            if (err) {
              setBusyStatus('error');
              return setTimeout((_) => setBusyStatus('off'), 4000);
            }
            setBusyStatus('success');
            setTimeout((_) => setBusyStatus('off'), 2000);
            setPlanItem(newCustomSkill);
            planDataDispatcher({ type: 'updateCustomItem', payload: newCustomSkill });
          });
        }
      });
    } else {
      updateSkill(planItem.skill, (result, err) => {
        if (err) {
          setBusyStatus('error');
          setTimeout((_) => setBusyStatus('off'), 4000);
        } else {
          setBusyStatus('success');
          setTimeout((_) => setBusyStatus('off'), 2000);
        }
      });
    }
  };

  const deletePlanItem = (done) => {
    setBusyStatus('loading');
    deleteIndividualizedPlanSkill(individualizedPlan, planItem, (result, err) => {
      if (err) {
        setBusyStatus('error');
        done(null, err);
      } else {
        setBusyStatus('success');
        done(result);
      }
    });
  };

  const saveIndividualizedPlanSkillStep = (createdStep, updateCallback) => {
    createdStep.code = planItem.skill.code + 'CS' + pad(createdStep.order, 2);
    if (!createdStep.stepId) {
      createIndividualizedPlanSkillStep(individualizedPlan, createdStep, planItem, (result, err) => {
        if (err) {
          return window.Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('There was an error creating assessment skill step for this plan'),
          });
        } else {
          updateCallback(result);
          const updatedSkill = { ...planItem, childIndividualizedPlanSkillSteps: [...assessmentSteps, createdStep] };
          planDataDispatcher({ type: 'addCustomItemStep', payload: updatedSkill });
        }
      });
    } else {
      updateChildIndividualizedPlanSkillStep(createdStep.stepId, createdStep, updateCallback);
      const updatedSkill = { ...planItem, childIndividualizedPlanSkillSteps: [...assessmentSteps, createdStep] };
      planDataDispatcher({ type: 'addCustomItemStep', payload: updatedSkill });
    }
  };

  const removeIndividualizedPlanSkillStep = (stepToDeleteId, deleteCallback) => {
    if (stepToDeleteId) {
      deleteIndividualizedPlanSkillStep(stepToDeleteId, (_, err) => {
        if (err) {
          deleteCallback(err);
          return window.Swal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('There was an error removing this assessment skill step'),
          });
        }
        loadAssessmentSteps();
        return deleteCallback();
      });
    }
  };

  return {
    busyStatus,
    planItem,
    assessmentSteps,
    savePlanItem,
    deletePlanItem,
    saveIndividualizedPlanSkillStep,
    removeIndividualizedPlanSkillStep,
  };
};
