import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import { evaluateChecklistForCharts, verifyPresentDomain } from '../../charts/chartHelpers/chartsHelpers';
import CalculusDescriptionModal from './calculusDescriptionModal/CalculusDescriptionModal';

function RadarChecklistDevelopmentalDomains({ child, allChecklistsRawData }) {
  const { t } = useTranslation();

  const [activeSeries, setActiveSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allChecklistsData, setAllChecklistsData] = useState();
  const [usePonderedScores, setUsePonderedScores] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Evaluating data for radar and line charts
      const evaluated = await evaluateChecklistForCharts(allChecklistsRawData, usePonderedScores);

      // Start loading last 4 checklists
      setActiveSeries(
        evaluated.series && evaluated.series.length >= 2
          ? [evaluated.series[0], evaluated.series[1]]
          : evaluated.series,
      );

      setAllChecklistsData(evaluated);
      setLoading(false);
    };

    fetchData();
  }, [allChecklistsRawData, usePonderedScores]);

  const { notEvaluated, notEvaluatedJoined } = verifyPresentDomain(activeSeries, allChecklistsData);

  const sortByDate = (obj1, obj2) => {
    try {
      const dayMonthYear1 = obj1.name.split('/').reverse().join('/');
      const dayMonthYear2 = obj2.name.split('/').reverse().join('/');
      return new Date(dayMonthYear1) - new Date(dayMonthYear2);
    } catch {
      return 0;
    }
  };

  const handleActiveChecklists = (item) => {
    if (activeSeries.includes(item)) {
      const filteredChecklists = activeSeries.filter((i) => i !== item).sort(sortByDate);
      setActiveSeries(filteredChecklists);
    } else {
      const sortedItems = [...activeSeries, item].sort(sortByDate);
      setActiveSeries(sortedItems);
    }
  };

  const optionsRadar = {
    plotOptions: {
      radar: {
        size: 140,
      },
    },
    dataLabels: { enabled: false },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: loading ? [t('Loading')] : allChecklistsData?.categories,
    },
    yaxis: {
      tickAmount: 5,
      max: 100,
      labels: {
        formatter: function (val, _i) {
          return val.toFixed(0);
        },
      },
    },
  };

  if (!allChecklistsData) return <></>;

  return (
    <>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h5 className="d-flex justify-content-center mb-4">{t('Checklists Comparison')}</h5>

              {loading ? (
                <div className="position relative">
                  <Chart
                    options={optionsRadar}
                    type="radar"
                    series={[{ name: t('Loading'), data: [t('Loading')] }]}
                    height={350}
                  />
                </div>
              ) : (
                <div className="row justify-content-center">
                  <div className="col-lg-2">
                    <div className="container-fluid mt-4 mb-4">
                      <strong>{t('Checklists')}</strong>
                      <ul>
                        {allChecklistsData.series.map((item) => (
                          <li key={item.id} className="d-flex" style={{ marginBottom: '-5px', gap: '0.25rem' }}>
                            <label className="d-flex align-items-center" style={{ gap: '0.25rem' }}>
                              <input
                                type="checkbox"
                                checked={activeSeries.includes(item)}
                                onChange={() => handleActiveChecklists(item)}
                                style={{ marginBottom: '3px' }}
                              />
                              {item.name}
                            </label>
                            {item.id && (
                              <a
                                title={`${t('Open checklist')}: ${item.name}`}
                                className="d-inline"
                                href={`/children/${child.id}/checklist/${item.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                &#x2197;
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-10 justify-content-center">
                    <div className="container-fluid d-flex justify-content-center border rounded mb-2">
                      <div className="position-absolute d-flex radar-graph-switch-container justify-content-center">
                        <span>{t('usePonderedScore')}</span>
                        <BootstrapSwitchButton
                          checked={usePonderedScores}
                          onlabel=" "
                          offlabel=" "
                          size="xs"
                          width={40}
                          onChange={(checked) => setUsePonderedScores(checked)}
                          style="radar-graph-switch"
                        />
                      </div>
                      <Chart options={optionsRadar} type="radar" series={activeSeries} height={375} width={670} />
                    </div>

                    {usePonderedScores && (
                      <div className="container-fluid d-flex justify-content-center mt-4">
                        <CalculusDescriptionModal
                          child={child}
                          allChecklists={allChecklistsRawData.map((ch) => ({ id: ch.id, date: ch.checklistDate }))}
                        />
                      </div>
                    )}

                    {!!notEvaluated.length && (
                      <div className="container-fluid d-flex justify-content-center mt-4">
                        {notEvaluatedJoined.map((ne) => {
                          return (
                            <div key={ne.name + ne.date}>
                              <strong>{t('Not Evaluated yet') + ': '}</strong>
                              <span>
                                {ne.date} | {t(ne.name)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RadarChecklistDevelopmentalDomains;
