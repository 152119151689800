import { graphQLRequest } from './common';
import { getSkill } from './denver';

export const getIndividualizedPlans = (childId, responseCallback) => {
  const graphQL = {
    query: `
            {
                childIndividualizedPlans (
                    where:{
                        child:{
                            id:"${childId}"
                        }
                }) 
                {
                    id
                    date
                    createdBy {
                        id
                    }
                    child {
                        id
                        person {
                            name
                        }
                    }
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlans.sort((a, b) => new Date(b.date) - new Date(a.date)));
  });
};

export const getLastIndividualizedPlan = (childId, responseCallback) => {
  const graphQL = {
    query: `
            {
                childIndividualizedPlans (
                    where:{
                        child:{
                            id:"""${childId}"""
                        }
                    }
                    orderBy: date_DESC
                    first: 1
                ) 
                {
                    id
                    date
                    createdBy {
                        id
                    }
                    child {
                        id
                        person {
                            name
                        }
                    }
                    skills {
                        id
                        skill {
                            id
                            title
                            code
                            order
                            description
                            objectiveInstruction
                            assessmentSteps {
                                id
                                order
                                description
                                achievementMininumAttempts
                                achievementMinimumScore
                            }
                            developmentalDomain {
                                id
                                code
                                title
                                order
                            }
                            skillFrom {
                              id
                            }
                        }
                        objectiveInstruction
                    }
                    skillSteps {
                        id
                        code
                        order
                        description
                        achievementMininumAttempts
                        achievementMinimumScore    
                        childIndividualizedPlanSkill {
                          skill {
                            id
                            code
                            order
                            level  
                            title
                            description
                            objectiveInstruction
                            developmentalDomain {
                                code
                                order
                                title
                            }
                            skillFrom {
                              id
                            }
                          }              
                        }                        
                        denverChecklistSkillAssessmentStep {
                            id
                            code
                            description
                            order
                            skill {
                                id
                                code
                                order
                                level  
                                title
                                description
                                objectiveInstruction
                                developmentalDomain {
                                    code
                                    title
                                    order
                                }
                                skillFrom {
                                  id
                                }
                            }
                        }
                    }
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(
      result.data.childIndividualizedPlans && result.data.childIndividualizedPlans.length > 0
        ? result.data.childIndividualizedPlans[0]
        : null,
    );
  });
};

export const getIndividualizedPlan = (
  // eslint-disable-next-line no-unused-vars
  { individualizedPlanId, fetchSessions },
  responseCallback,
) => {
  let sessionsQuery = `sessions {
      id
      attendant {
          id
          person {
              id
              name
          }
      }
      checkinAt
      mig__sessionNumber
      skillAssessmentStepScore {
          id
          positiveAchievement
          negativeAchievement
          childIndividualizedPlanSkillStep {
              id
              code
              order
              description
              achievementMinimumScore
              achievementMininumAttempts                                
              achievementDate
              childIndividualizedPlanSkill {
                skill {
                  id
                  code
                  order
                  level  
                  title
                  description
                  objectiveInstruction
                  developmentalDomain {
                      code
                      order
                      title
                  }
                  skillFrom {
                    id
                  }
                }              
              }
              denverChecklistSkillAssessmentStep {
                  id
                  code
                  order
                  description
                  achievementMinimumScore
                  achievementMininumAttempts
                  skill {
                      id
                      code
                      order
                      level
                      title
                      developmentalDomain {
                          id
                          code
                          title
                          order
                      }
                      skillFrom {
                        id
                      }
                  }
              }
          }
      }
  }`;

  const graphQL = {
    query: `        
            {
                childIndividualizedPlan(
                    where:{
                        id:"""${individualizedPlanId}"""
                    }
                ) 
                {
                    id
                    date
                    child {
                        id
                        person {
                            name
                        }
                    }
                    createdBy {
                        id
                        person {
                            name
                        }
                    }
                    skills {
                        id
                        skill {
                            id
                            title
                            code
                            order
                            level
                            description
                            objectiveInstruction
                            assessmentSteps {
                                id
                                order
                                description
                                achievementMininumAttempts
                                achievementMinimumScore
                            }
                            developmentalDomain {
                                id
                                code
                                order
                                title
                            }
                            skillFrom {
                              id
                            }
                        }
                        objectiveInstruction
                    }
                    skillSteps {
                        id
                        code
                        order
                        description
                        achievementMininumAttempts
                        achievementMinimumScore    
                        childIndividualizedPlanSkill {
                          skill {
                            id
                            code
                            order
                            level  
                            title
                            description
                            objectiveInstruction
                            developmentalDomain {
                                code
                                order
                                title
                            }
                            skillFrom {
                              id
                            }
                          }              
                        }
                        denverChecklistSkillAssessmentStep {
                            id
                            code
                            description
                            order
                            achievementMininumAttempts
                            achievementMinimumScore                               
                            skill {
                                id
                                code
                                order
                                level  
                                title
                                description
                                objectiveInstruction
                                developmentalDomain {
                                    code
                                    order
                                    title
                                }
                                skillFrom {
                                  id
                                }
                            }
                        }
                    }
                    ${sessionsQuery}
                }
            }                    
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlan);
  });
};

export const resolvePlanUniqueSkills = (individualizedPlan) => {
  let uniqueSkills = {};
  if (individualizedPlan.skillSteps) {
    individualizedPlan.skillSteps.forEach((ss) => {
      if (ss.denverChecklistSkillAssessmentStep) {
        uniqueSkills[ss.denverChecklistSkillAssessmentStep.skill.code] = ss.denverChecklistSkillAssessmentStep.skill;
      } else {
        uniqueSkills[ss.childIndividualizedPlanSkill.skill.code] = ss.childIndividualizedPlanSkill.skill;
      }
    });
  }
  return Object.values(uniqueSkills).sort((a, b) => {
    // group skills by code
    if (a.developmentalDomain.order === b.developmentalDomain.order) {
      // group skills by level
      if (a.level === b.level) {
        // order customized skills: if a skill has the skillFrom property, it goes first
        if (a.code.match(/CTM/) && !a.skillFrom && !b.code.match(/CTM/)) return 1;
        if (b.code.match(/CTM/) && !b.skillFrom && !a.code.match(/CTM/)) return -1;
        if (a.code.match(/CTM/) && b.code.match(/CTM/)) {
          if (a.skillFrom && !b.skillFrom) return -1;
          if (!a.skillFrom && b.skillFrom) return 1;
        }
        // order skills that have the skillFrom property
        if (a.skillFrom && b.skillFrom) return a.skillFrom.order - b.skillFrom.order;
        if (a.skillFrom && !b.skillFrom) return a.skillFrom.order - b.order;
        if (!a.skillFrom && b.skillFrom) return a.order - b.skillFrom.order;

        return a.order - b.order;
      }
      if (a.skillFrom && b.skillFrom) return b.skillFrom.order - b.skillFrom.order;
      if (a.skillFrom && !b.skillFrom) return a.skillFrom.order - a.order;
      if (!a.skillFrom && b.skillFrom) return b.order - b.skillFrom.order;
      return b.level - a.level;
    }
    return a.developmentalDomain.order - b.developmentalDomain.order;
  });
};

/**
 * For the skill param, searches in the sessions param and builts an array of objects with
 * skill merged with respective sessions
 *
 * @param {DenverChecklistSkill} skill
 * @param {ChildIndividualizedPlanSessionSkillAssessmentStepScore[]} sessions
 * @param {ChildIndividualizedPlanSkillStep[]} skillSteps
 * @returns array of ChildDenverChecklistSkill with respective sessions
 */
export const combineSessionsObjectiveSteps = (skill, sessions, skillSteps) => {
  const registeredSessionAssessments = sessions
    .sort((a, b) => a.date - b.date)
    .map((s) =>
      s.skillAssessmentStepScore
        .filter((sas) => {
          if (
            !sas.childIndividualizedPlanSkillStep ||
            (!sas.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep &&
              !sas.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill)
          ) {
            return false;
          }
          let skillCode = '';
          if (sas.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep) {
            skillCode = sas.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.skill.code;
          } else {
            skillCode = sas.childIndividualizedPlanSkillStep.childIndividualizedPlanSkill.skill.code;
          }
          return skillCode === skill.code;
        })
        .map((dcs) => ({
          ...dcs,
          date: s.checkinAt,
          sessionNumber: s.mig__sessionNumber,
        })),
    );

  const sortedStepAssessments = [].concat(...registeredSessionAssessments).sort((a, b) => {
    const aCode = a.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep?.code;
    const bCode = b.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep?.code;
    if (aCode === bCode) {
      if (b.date === a.date) {
        return b.sessionNumber - a.sessionNumber;
      }
      return Date.now(b.date) - Date.now(a.date);
    }
    return aCode?.localeCompare(bCode);
  });

  if (sortedStepAssessments) {
    // Use a map to store the idSession for each id
    const idSessionMap = new Map();
    for (const session of sessions) {
      for (const score of session.skillAssessmentStepScore) {
        idSessionMap.set(score.id, session.id);
      }
    }

    // Use a set to store the unique code values
    const stepCodes = new Set();
    let stepSessions = [];
    // build a StepScore list with the Steps that have been evaluated at least once
    for (const element of sortedStepAssessments) {
      // Use early exit to skip steps with the wrong code
      let stepCode = '';
      if (element.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep) {
        stepCode = element.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.code;
      } else {
        stepCode = element.childIndividualizedPlanSkillStep.code;
      }
      if (!stepCodes.has(stepCode)) {
        stepCodes.add(stepCode);
        stepSessions.push({
          ...element.childIndividualizedPlanSkillStep,
          id: element.childIndividualizedPlanSkillStep.id,
          sessions: [],
        });
      }

      // Use the map to look up the idSession value
      const resId = idSessionMap.get(element.id) || '';

      // merge session info with assessment info
      const stepSession = stepSessions.find((ss) =>
        ss.denverChecklistSkillAssessmentStep
          ? ss.denverChecklistSkillAssessmentStep.code === stepCode
          : ss.code === stepCode,
      );
      stepSession.sessions.push({
        id: element.id,
        idSession: resId,
        date: element.date,
        achievementMininumAttempts:
          element.childIndividualizedPlanSkillStep.achievementMininumAttempts ??
          element.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMininumAttempts,
        achievementMinimumScore:
          element.childIndividualizedPlanSkillStep.achievementMinimumScore ??
          element.childIndividualizedPlanSkillStep.denverChecklistSkillAssessmentStep.achievementMinimumScore,
        positiveAchievement: element.positiveAchievement,
        negativeAchievement: element.negativeAchievement,
        sessionNumber: element.sessionNumber,
      });
    }
    // fulfill the StepScore list with the Steps that have NOT been evaluated yet
    for (const ss of skillSteps) {
      let stepCode = '';
      if (ss.denverChecklistSkillAssessmentStep && ss.denverChecklistSkillAssessmentStep.skill.code === skill.code) {
        stepCode = ss.denverChecklistSkillAssessmentStep.code;
      } else if (
        !ss.denverChecklistSkillAssessmentStep &&
        ss.childIndividualizedPlanSkill &&
        ss.childIndividualizedPlanSkill.skill.code === skill.code
      ) {
        stepCode = ss.code;
      } else {
        continue;
      }

      if (!stepCodes.has(stepCode)) {
        stepCodes.add(stepCode);
        stepSessions.push({
          id: ss.id,
          achievementMininumAttempts: ss.achievementMininumAttempts,
          achievementMinimumScore: ss.achievementMinimumScore,
          denverChecklistSkillAssessmentStep: ss.denverChecklistSkillAssessmentStep
            ? ss.denverChecklistSkillAssessmentStep
            : ss,
          achievementDate: null,
          sessions: [],
        });
      }
    }

    const sortedStepSessions = stepSessions.sort(
      (a, b) => a.denverChecklistSkillAssessmentStep?.order - b.denverChecklistSkillAssessmentStep?.order,
    );

    return sortedStepSessions;
  }

  return [];
};

export const createIndividualizedPlan = (individualizedPlan, responseCallback) => {
  let graphQL = {};

  if (!individualizedPlan.id) {
    //create

    graphQL = {
      query: `
            mutation {
                createChildIndividualizedPlan (
                    data: {
                        date: """${individualizedPlan.date.toISOString()}"""
                        child: {
                            connect: {
                                id: "${individualizedPlan.child.id}"
                              }
                        }
                        createdBy: {
                            connect: {
                                id: """${individualizedPlan.createdBy.id}"""
                            }
                        }
                        mig__interventionPlanId: """${
                          individualizedPlan.mig__interventionPlanId ? individualizedPlan.mig__interventionPlanId : '-1'
                        }"""
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
      variables: {},
    };
  } else {
    return responseCallback(null, new Error('Could not edit the main parameters of the Plan'));
  }

  graphQLRequest(graphQL, (result, err) =>
    responseCallback(result && result.data ? result.data.createChildIndividualizedPlan : null, err),
  );
};

export const deleteIndividualizedPlan = (individualizedPlan, responseCallback) => {
  const graphQL = {
    query: `
                mutation {                
                    deleteChildIndividualizedPlan(
                        where:{
                            id: """${individualizedPlan.id}"""
                        }
                    )
                    {
                        id
                    }                  
                }
            `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const updateIndividualizedPlan = (id, date, responseCallback) => {
  const graphQL = {
    query: `
            mutation {
                updateChildIndividualizedPlan(
                    data: {
                        date: "${date}"  
                    }
                    where: {
                        id: "${id}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };
  graphQLRequest(graphQL, responseCallback);
};

/**
 * This function adds a Denver Skill to the individualized Plan.
 * @param individualizedPlan - The plan to which the skill will be added to
 * @param skill - The skill which is going to be added to the plan
 * @param responseCallback - A function that will be called with the response from the server.
 * @returns the result of the mutation.
 */
export const createIndividualizedPlanSkill = async ({ individualizedPlan, skill }, responseCallback) => {
  const skillToBeAdded = await getSkill(skill.id);

  if (skillToBeAdded.error) {
    if (responseCallback) return responseCallback(null, skillToBeAdded.error);
    return { data: null, error: skillToBeAdded.error };
  }

  // TODO: Checar se a skill já está no plano. Se estiver, retornar a função sem completar

  const skillStepsQuery = skillToBeAdded.assessmentSteps.map(
    (as) =>
      `{
        denverChecklistSkillAssessmentStep: {
          connect: {
              id: "${as.id}"
          }
        }
        description: "${as.description}"
        achievementMinimumScore: ${as.achievementMinimumScore}
        achievementMininumAttempts: ${as.achievementMininumAttempts}
      }`,
  );

  const graphQL = {
    query: `
      mutation {
        updateChildIndividualizedPlan(
          where: { id: "${individualizedPlan.id}" }
          data: {
            skillSteps: {
              create: [
                ${skillStepsQuery.join('')}
              ]
            }
            skills: {
              create: { skill: { connect: { id: "${skill.id}" } } }
            }
          }
        ){
          skills (last: 1) {id}
        }
      }`,
    variables: {},
  };

  const queryResult = await graphQLRequest(graphQL, responseCallback)
    .then((response) => response.data.updateChildIndividualizedPlan.skills[0])
    .catch((error) => ({ error: error }));

  if (responseCallback) return responseCallback(queryResult);
  return queryResult;
};

export const createIndividualizedPlanCustomSkill = (
  { childIndividualizedPlan, skill, assessmentSteps },
  responseCallback,
) => {
  let orderSkill = childIndividualizedPlan.skills.length + 1;

  //1) create skill
  let DenverChecklistSkillAssessmentStepCreateManyWithoutSkillInput = '';
  if (assessmentSteps && assessmentSteps.length > 0) {
    const skillSteps = assessmentSteps
      .map(
        (as) => `
            {
                code: """${as.code}"""
                description: """${as.description}"""
                order: ${as.order}
                achievementMinimumScore: ${as.achievementMinimumScore}
                achievementMininumAttempts: ${as.achievementMininumAttempts}
            }
        `,
      )
      .join(',');

    DenverChecklistSkillAssessmentStepCreateManyWithoutSkillInput = `
            assessmentSteps: {
                create:[${skillSteps}]
            }
        `;
  }

  const skillCustomizationGraphQL = skill.skillFrom
    ? `skillFrom: {
        connect: {
          id: """${skill.skillFrom.id}"""
        }
      }`
    : '';

  const skillGraphQL = {
    query: `
            mutation {
                createDenverChecklistSkill (
                    data: {
                        ${skillCustomizationGraphQL}
                        code: """${skill.code}"""
                        level: ${skill.level}
						            order: ${orderSkill}
                        title: """${skill.title}"""
                        description: """${skill.description}"""
                        objectiveInstruction: """${skill.objectiveInstruction}"""
                        developmentalDomain: {
                          connect: {
                            id: """${skill.developmentalDomain.id}"""
                          }
                        }
                        ${DenverChecklistSkillAssessmentStepCreateManyWithoutSkillInput}
                    }
                )
                {
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  // create the skill with steps
  graphQLRequest(skillGraphQL, (createdSkillResult, err) => {
    if (err) {
      console.error(err);
      return responseCallback(null, err);
    }
    //end create skill

    // 2) create ChildIndividualizedPlanSkill
    let createChildIndividualizedPlanSkillSteps = '';
    if (assessmentSteps && assessmentSteps.length > 0) {
      const childIndividualizedPlanSkillSteps = assessmentSteps
        .map(
          (as) => `
                {
                    childIndividualizedPlan: {
                        connect: {
                            id: "${childIndividualizedPlan.id}"
                        }
                    }
                    denverChecklistSkillAssessmentStep: {
                        connect: {
                            code: """${as.code}"""
                        }
                    }
                    code: """${as.code}"""
                    order: ${as.order}
                    achievementMinimumScore: ${as.achievementMinimumScore}
                    achievementMininumAttempts: ${as.achievementMininumAttempts}
                    description: """${as.description}"""                  
                }
            `,
        )
        .join(',');

      createChildIndividualizedPlanSkillSteps = `
                childIndividualizedPlanSkillSteps: {
                    create: [${childIndividualizedPlanSkillSteps}]
                }
            `;
    }
    const createPlanSkillRelationshipQL = {
      query: `
                mutation {
                    createChildIndividualizedPlanSkill(
                        data: {
                            childIndividualizedPlan: {
                                connect: {
                                    id: "${childIndividualizedPlan.id}"
                                }
                            }
                            skill: {
                                connect: {
                                    id: """${createdSkillResult.data.createDenverChecklistSkill.id}"""
                                }
                            }
                            ${createChildIndividualizedPlanSkillSteps}
                        }
                    ){
                        id
                        createdAt
                        updatedAt
                    }
                }
            `,
      variables: {},
    };

    graphQLRequest(createPlanSkillRelationshipQL, (result, err) =>
      responseCallback(
        result && result.data
          ? {
              individualizedPlanSkill: result.data.createChildIndividualizedPlanSkill,
              skill: createdSkillResult.data,
            }
          : null,
        err,
      ),
    );
  });
};

export const updateChildIndividualizedPlanSkillStep = (
  individualizedPlanSkillStepId,
  { code, description, achievementDate, achievementMinimumScore, achievementMininumAttempts },
  responseCallback,
) => {
  let graphQL = {};
  let codeStmt = code ? `code: """${code}"""` : '';
  let descriptionStmt = description ? `description: """${description}"""` : '';
  let achievementDateStmt = achievementDate
    ? `achievementDate: ${achievementDate === '0' ? null : '"""' + achievementDate + '"""'}`
    : '';
  let achievementMinimumScoreStmt = achievementMinimumScore
    ? `achievementMinimumScore: ${achievementMinimumScore}`
    : '';
  let achievementMininumAttemptsStmt = achievementMininumAttempts
    ? `achievementMininumAttempts: ${achievementMininumAttempts}`
    : '';

  graphQL = {
    query: `
            mutation {
                updateChildIndividualizedPlanSkillStep(
                    data: {
                        ${codeStmt}
                        ${descriptionStmt}
                        ${achievementDateStmt}
                        ${achievementMinimumScoreStmt}
                        ${achievementMininumAttemptsStmt}
                    }
                    where: {
                        id: "${individualizedPlanSkillStepId}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => responseCallback(result, err));
};

export const updateIndividualizedPlanSkill = (individualizedPlanSkill, responseCallback) => {
  let graphQL = {};

  graphQL = {
    query: `
            mutation {
                updateChildIndividualizedPlanSkill(
                    data: {
                        objectiveInstruction: """${individualizedPlanSkill.objectiveInstruction}"""
                    }
                    where: {
                        id: "${individualizedPlanSkill.id}"
                    }
                ){
                    id
                    createdAt
                    updatedAt
                }
            }
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) =>
    responseCallback(result && result.data ? result.data.createChildIndividualizedPlanSkill : null, err),
  );
};

export const getIndividualizedPlanSkillStepsBySkill = async (
  individualizedPlan,
  individualizedPlanSkill,
  responseCallback,
) => {
  const graphQL = {
    query: `
            {
                childIndividualizedPlanSkillSteps (
                  where:
                    {
                      OR:[{
                          AND:[{
														denverChecklistSkillAssessmentStep:{
                              skill:{
                                id: """${individualizedPlanSkill.skill.id}"""
                              }
                            },
                            childIndividualizedPlan:{
                              id:"""${individualizedPlan.id}"""
                            }
                          }]                            
                        },
                        {
                          childIndividualizedPlanSkill:{
                            id: """${individualizedPlanSkill.id}"""
                          }
                        }]
                  }
                ) 
                {
                    id                    
                    code
                    description
                    order
                    achievementMinimumScore
                    achievementMininumAttempts                    
                  	denverChecklistSkillAssessmentStep {
                      id
                      code
                      description
                      order
                      achievementMinimumScore
                      achievementMininumAttempts
                     	skill {
                        id
                      } 
                    }            
                }
            }
        `,
    variables: {},
  };

  const ret = await graphQLRequest(graphQL, responseCallback)
    .then((response) => response)
    .catch((error) => ({ error: error }));

  if (ret.error) {
    if (responseCallback) return responseCallback(ret, ret);
    return ret;
  }

  if (responseCallback) return responseCallback(ret.data.childIndividualizedPlanSkillSteps);
  return ret.data.childIndividualizedPlanSkillSteps;
};

export const deleteIndividualizedPlanSkill = async (individualizedPlan, individualizedPlanSkill, responseCallback) => {
  if (individualizedPlanSkill.skill) {
    const skillSteps = await getIndividualizedPlanSkillStepsBySkill(individualizedPlan, individualizedPlanSkill)
      .then((response) => response)
      .catch((error) => ({ error: error }));

    if (skillSteps.error) {
      if (responseCallback) return responseCallback(null, skillSteps);
      return skillSteps;
    }

    const graphQL = {
      query: `
        mutation {
          updateChildIndividualizedPlan(
            where: { id: "${individualizedPlan.id}" }
            data: {
              skillSteps: {
                deleteMany: {id_in: [${skillSteps.map((ss) => `"${ss.id}"`).join(',')}]}
              }
              skills: { delete: { id: "${individualizedPlanSkill.id}" } }
            }
          ) {
            skills(last: 1) {
              id
            }
          }
        }`,
      variables: {},
    };

    const queryResult = await graphQLRequest(graphQL)
      .then((result) => result.data.updateChildIndividualizedPlan)
      .catch((err) => ({ error: err }));

    return queryResult;
  } else {
    const graphQL = {
      query: `
        mutation {                
          deleteChildIndividualizedPlanSkill(
            where:{
              id: """${individualizedPlanSkill.id}"""
            }
          )
          {
            id
          }                  
        }`,
      variables: {},
    };

    graphQLRequest(graphQL, responseCallback);
  }
};

export const createIndividualizedPlanSkillStep = (
  individualizedPlan,
  assessmentStep,
  childIndividualizedPlanSkill,
  responseCallback,
) => {
  const graphQL = {
    query: ` mutation {
            createChildIndividualizedPlanSkillStep(
                data: {
                    childIndividualizedPlan: {
                        connect: {
                            id: """${individualizedPlan.id}"""
                        }
                    }
                    childIndividualizedPlanSkill: {
                        connect: {
                            id: """${childIndividualizedPlanSkill.id}"""
                        }
                    }
                    code: """${assessmentStep.code}"""
                    description: """${assessmentStep.description}"""
                    order: ${assessmentStep.order}
                    achievementMinimumScore: ${assessmentStep.achievementMinimumScore}
                    achievementMininumAttempts: ${assessmentStep.achievementMininumAttempts}
                }
            ){
                id
                createdAt
                updatedAt
            }
        }`,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.createChildIndividualizedPlanSkillStep);
  });
};

export const deleteIndividualizedPlanSkillStep = (id, responseCallback) => {
  let graphQL = {};

  graphQL = {
    query: `
                mutation {                
                    deleteChildIndividualizedPlanSkillStep(
                        where:{
                            id: """${id}"""
                        }
                    )
                    {
                        id
                    }                  
                }
            `,
    variables: {},
  };

  graphQLRequest(graphQL, responseCallback);
};

export const getIndividualizedPlanSkill = (id, responseCallback) => {
  const graphQL = {
    query: `        
            {
                childIndividualizedPlanSkill(
                    where:{
                        id:"""${id}"""
                    }
                ) 
                {
                    id
                    childIndividualizedPlan {
                        id
                        date
                        child {
                            id
                        }
                    }
                    skill {
                        id
                        code
                        order
                        level  
                        title
                        description
                        objectiveInstruction
                        developmentalDomain {
                            code
                            order
                            title
                        }
                        assessmentSteps {
                            id
                            code 
                            description
                            order
                            achievementMinimumScore
                            achievementMininumAttempts
                        }
                        skillFrom {
                          id
                        }
                    }
                    objectiveInstruction                    
                }
            }                    
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSkill);
  });
};

export const getIndividualizedPlanSkillStep = (id, responseCallback) => {
  const graphQL = {
    query: `        
            {
                childIndividualizedPlanSkillStep(
                    where:{
                        id:"""${id}"""
                    }
                ) 
                {
                    id
                    description
                    achievementDate
                    childIndividualizedPlanSkill {
                        skill {
                            id
                            code
                            order
                            level  
                            title
                            description
                            objectiveInstruction
                            developmentalDomain {
                                code
                                title
                                order
                            }
                            skillFrom {
                              id
                            }
                        }
                        objectiveInstruction
                    }
                    denverChecklistSkillAssessmentStep {
                        id
                        code
                        description
                        order
                        skill {
                            id
                            code
                            order
                            level  
                            title
                            description
                            objectiveInstruction
                            developmentalDomain {
                                code
                                title
                                order
                            }
                            skillFrom {
                              id
                            }
                        }
                    }
                }
            }                    
        `,
    variables: {},
  };

  graphQLRequest(graphQL, (result, err) => {
    if (err) {
      return responseCallback(result, err);
    }
    return responseCallback(result.data.childIndividualizedPlanSkillStep);
  });
};
