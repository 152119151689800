import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { colors } from '../../../../utils/constants';

export default function DomainsChecklistScoreCharts({
  chartData,
  chartTitle,
  chartLabels,
  chartDomain,
  chartYMax,
  chartGoals,
}) {
  const { t } = useTranslation();

  const chartOptions = {
    title: {
      text: chartTitle,
    },
    colors: [colors[chartDomain]],
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val) return val.toFixed(0);
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          if (val) return val.toFixed(0);
        },
      },
    },
    xaxis: {
      categories: chartLabels.map((c) => moment(c).format(t('MM/DD/YYYY'))),
    },
    yaxis: {
      max: chartYMax,
      min: 0,
      labels: {
        formatter: function (val, _i) {
          if (val) return val.toFixed(0);
        },
      },
    },
  };

  return (
    <div className="container-fluid border rounded mb-2 p-0">
      <Chart
        options={chartOptions}
        type="bar"
        series={[
          {
            name: chartDomain,
            data: chartData.map((d, i) => ({
              x: '',
              y: d,
              goals: chartGoals && [
                { name: t('Maximum Score'), value: chartGoals[i], strokeHeight: 5, strokeColor: '#aaa' },
              ],
            })),
          },
        ]}
        height={300}
      />
    </div>
  );
}
