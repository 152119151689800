export const useHistory = (_) => {
  const updateChecklistAssessmentURL = (level, domain, score, mode) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      (window.location.pathname + `/?level=${level}&domain=${domain}&score=${score}&mode=${mode}`).replace(
        /(\/)+/g,
        '/',
      );
    window.history.pushState({ path: newurl }, '', newurl);
  };

  // eslint-disable-next-line no-unused-vars
  const updateChecklistURL = (level, domain, score, mode) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      (window.location.pathname + `/?level=${level}&domain=${domain}`).replace(/(\/)+/g, '/');
    window.history.pushState({ path: newurl }, '', newurl);
  };

  return {
    updateChecklistAssessmentURL,
    updateChecklistURL,
  };
};
