import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../utils/constants';

export default function ChecklistDetailsDomainLevelsChart({ chartData, chartDomain, chartType = 'percentage' }) {
  const { t } = useTranslation();

  const chartLabels = chartData.map((lvl) => Object.keys(lvl));
  const chartTitle = Object.values(chartData[0])[0].developmentalDomain.title;
  const chartCode = Object.values(chartData[0])[0].code;

  const chartSeries =
    chartType === 'percentage'
      ? [
          {
            name: chartDomain,
            data: chartData.map((a) => (Object.values(a)[0].percentualGrowth * 100).toFixed(0)),
          },
        ]
      : [
          {
            name: t('Score'),
            data: chartData.map((a) => ({
              x: '',
              y: Object.values(a)[0].score.toFixed(0),
              goals: [
                {
                  name: t('Maximum Score'),
                  value: Object.values(a)[0].maximumScore,
                  strokeHeight: 5,
                  strokeColor: '#aaa',
                },
              ],
            })),
          },
        ];

  const yMax = chartType === 'percentage' ? 100 : Math.max(...chartData.map((a) => Object.values(a)[0].maximumScore));

  const options = {
    chart: {
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    title: { text: chartTitle, align: 'center' },
    colors: [colors[chartCode]],
    dataLabels: { enabled: true },
    stroke: { curve: 'straight' },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    xaxis: {
      categories: chartLabels,
      title: { text: t('Levels') },
    },
    yaxis: {
      max: yMax,
      min: 0,
      labels: {
        formatter: function (val, _i) {
          return val.toFixed(0);
        },
      },
    },
  };

  return (
    <div className="container-fluid border rounded mb-2 p-0">
      <Chart options={options} type="bar" series={chartSeries} height={300} />
    </div>
  );
}
