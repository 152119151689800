import React, { useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { colors } from '../../../utils/constants';

function ChecklistDevelopmentalDomainStatsPolarArea({ childId, lastChecklistData }) {
  const { t } = useTranslation();
  const timer = useRef();

  if (lastChecklistData.checklistState === 'EMPTY') {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        <Trans i18nKey="ZZLongTexts.EmptyChecklistsOnStats">
          No items evaluated in the last checklist.
          <a href={`/children/${childId}/checklist/${lastChecklistData.id}`} target="_blank" rel="noopener noreferrer">
            Click here to view the checklist
          </a>
        </Trans>
      </div>
    );
  }

  if (!lastChecklistData.unifiedDevelopmentDomainsRatios) {
    return (
      <div className="mt-3" style={{ minHeight: '300px' }}>
        {t('Loading')}
      </div>
    );
  }

  const values = lastChecklistData.unifiedDevelopmentDomainsRatios
    .filter((x) => x.typicalDevelopmentWeightingTotalPoints)
    .map((a) => (a.developmentFactor * 100).toFixed(2));

  clearTimeout(timer.current);
  timer.current = setTimeout(() => {
    var options = {
      colors: [
        colors.CRE,
        colors.CEX,
        colors.CAC,
        colors.IMI,
        colors.COG,
        colors.MFI,
        colors.MGR,
        colors.COM,
        colors.CSO,
        colors.JOG,
        colors.IND,
      ],
      chart: {
        animations: { enabled: false },
        type: 'polarArea',
        height: 350,
      },
      series: values,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
        colors: undefined,
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 1,
          },
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },

      labels: lastChecklistData.unifiedDevelopmentDomainsRatios
        .filter((x) => x.typicalDevelopmentWeightingTotalPoints)
        .map((dd) => t(dd.developmentalDomain.title)),
    };

    var chart = new window.ApexCharts(
      document.querySelector(`#ChecklistDevelopmentalDomainStatsPolarArea-${childId}`),
      options,
    );
    if (chart) {
      chart.render();
    }
  }, 400);

  return <div id={`ChecklistDevelopmentalDomainStatsPolarArea-${childId}`} style={{ minHeight: '300px' }}></div>;
}

export default ChecklistDevelopmentalDomainStatsPolarArea;
