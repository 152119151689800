import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditableStepsGroup, Loading, FormSubmissionMessage } from '../../components';
import Navbar from '../../partials/Navbar';
import useChecklistDetailsModel from './useChecklistDetailsModel';

function ChecklistDetails() {
  const { t } = useTranslation();

  const shrink = (text) => {
    return text.split(/\s+/g).splice(0, 4).join(' ') + '...';
  };

  const {
    busy,
    submitting,
    formSubmissionMessage,
    formSubmissionMessageType,
    loadingErrorMessage,
    id,
    code,
    developmentalDomain,
    level,
    title,
    setTitle,
    description,
    setDescription,
    objectiveInstruction,
    setObjectiveInstruction,
    assessmentSteps,
    planItem,
    save,
  } = useChecklistDetailsModel();

  return (
    <>
      <Navbar doSearch={(_) => {}}></Navbar>

      <div className="page-content">
        <nav className="page-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">{t('Sidebar.Settings')}</li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/denver/">{t('Checklist Curriculum')}</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {!busy && <a href={`/denver/${id}`}>{shrink(t(title))}</a>}
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-md-12  grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {loadingErrorMessage && (
                  <div className="alert alert-primary" role="alert">
                    {loadingErrorMessage}
                  </div>
                )}

                {!loadingErrorMessage && busy && <Loading loadingText={t('Fetching Denver Checklist')} />}

                {!loadingErrorMessage && !busy && (
                  <>
                    <h6 className="card-title">{t('Skill Details')}</h6>
                    <div className="mb-2">
                      <span className="badge badge-light mr-2">{developmentalDomain.title}</span>
                      <span className="badge badge-light mr-2">
                        {t('Level')} {level}
                      </span>
                    </div>
                    <form
                      id="registerForm"
                      className="forms-child-info"
                      onSubmit={(e) => {
                        e.preventDefault();
                        window.jQuery('#registerForm').valid() && save();
                      }}
                    >
                      <div className="form-group">
                        <label htmlFor="title">{t('Skill')}</label>
                        <input
                          id="title"
                          name="title"
                          defaultValue={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          placeholder={t('placeholder.Understands quantity Concepts')}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="description">{t('Description')}</label>
                        <textarea
                          rows="5"
                          id="description"
                          name="description"
                          defaultValue={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                          type="text"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="objectiveInstruction">{t('Session Tips')}</label>
                        <textarea
                          rows="5"
                          id="objectiveInstruction"
                          name="objectiveInstruction"
                          defaultValue={objectiveInstruction}
                          onChange={(e) => setObjectiveInstruction(e.target.value)}
                          required
                          type="text"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>

                      <FormSubmissionMessage message={formSubmissionMessage} type={formSubmissionMessageType} />

                      {!submitting && (
                        <button type="submit" onClick={(_) => save()} className="btn btn-primary mr-2">
                          {t('Save')}
                        </button>
                      )}
                      {submitting && (
                        <button type="submit" disabled className="btn btn-primary mr-2">
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {t('Submitting') + '...'}
                        </button>
                      )}
                      <a href="/denver/" className="btn btn-light">
                        {t('Back')}
                      </a>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12  grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                {planItem && (
                  <EditableStepsGroup
                    item={planItem}
                    idSkill={id}
                    codePrefix={code}
                    title={t('Skill Assessment Steps')}
                    assessmentSteps={assessmentSteps}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChecklistDetails;
